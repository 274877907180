import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast} from "react-toastify";
import registrationLogo from "../../../../../images/reg_login_logo.png";
import regBackground from "../../../../../images/bg.png";
import logoWatermark from "../../../../../images/m_watermark.png";
import "./Otp.css";
import { englishToBengaliNumberFormat } from "../../../../../utilities/utilities";
import TagManager  from "react-gtm-module";
const Otp = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');

    /* const [someData, setSomeData] = useState({});
    let componentMounted = true;
    const [loading, setLoading] = useState(false);
    useEffect(async () => {
        setLoading(true);
        someResponse = await doVeryLongRequest(); 
        if (componentMounted){ 
            setSomeData(someResponse.data); 
            setLoading(false); 
        }
        return () => {
            componentMounted = false;
        }
        
    }, []); */

    //background Image
    const background_image = {
        backgroundImage: `url(${regBackground})`,
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    };

    

    //OTP field forward backward process
    const handleChange = (e) => {
        const { maxLength, value, name } = e.target;
        const [fieldIndex] = name.split("_")[1];

        if (value.length >= maxLength) {
            if (parseInt(fieldIndex) <= 4) {
                const nextSibling = document.querySelector(
                    `input[name=otp_${parseInt(fieldIndex) + 1}]`
                );
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
        if (e.keyCode === 8) {
            if (parseInt(fieldIndex) <= 4) {
                const previousSibling = document.querySelector(
                    `input[name=otp_${parseInt(fieldIndex) - 1}]`
                );
                if (previousSibling !== null) {
                    previousSibling.focus();
                }
            }
        }
    };

    

    useEffect(() => {
        if (location.state.message) {
            toast.success(location.state.message);
        }
    }, [location.state.message]);



    /* const user = localStorage.getItem("auth_token"); */
    let from = location.state?.from?.pathname + location.state?.from?.search || "/";


    const handelOtp = (event) => {
        event.preventDefault();

        const otp_1 = event.target.otp_1.value;
        const otp_2 = event.target.otp_2.value;
        const otp_3 = event.target.otp_3.value;
        const otp_4 = event.target.otp_4.value;

        const otp_number = otp_1 + otp_2 + otp_3 + otp_4;

        const otp_information = {
            mobile_or_email: location.state.mobile_or_email,
            user_id: location.state.user_id,
            otp: otp_number,
        };

        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}verify-otp`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(otp_information),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message_type === "success") {
                    if(page === 'registration'){
                        TagManager.dataLayer({
                            dataLayer:{
                                event: 'new_registration',
                                pagePath: 'otp',
                                PageTitle:'Otp verification', 
                                mobile_or_email:  location.state.mobile_or_email,
                            }
                        })
                    }
                    localStorage.setItem("auth_token", data.auth_token);
                    localStorage.setItem("auth_credential", data.auth_credential);
                    localStorage.setItem("user_id", location.state.user_id);
                    localStorage.setItem("user_name", location.state.user_id);
                    localStorage.setItem("mobile_or_email", location.state.mobile_or_email);

                    // navigate(from, { replace: true });
                    navigate("/dashboard");
                } else {
                    toast.error(data.message);
                }
            });
    };


    return (
        <section style={background_image}>
            <div className="h-100 d-flex align-items-center position-relative register-container">
                <div className="w-100">
                    <div className="logo d-flex justify-content-center">
                        <img src={registrationLogo} alt="" />
                    </div>

                    <div className="input-container position-relative">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <img
                                src={logoWatermark}
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <form onSubmit={handelOtp}>
                            <p
                                className="text-center text-white"
                                style={{ fontSize: "32px", fontWeight: "500" }}
                            >
                                ওটিপি প্রদান করুন
                            </p>
                            <div className="d-flex justify-content-center">
                                <div className="otp_input-section">
                                    <input
                                        type="text"
                                        id="mobile_or_email"
                                        name="otp_1"
                                        onFocus={(e) =>
                                            (e.target.placeholder = "")
                                        }
                                        onBlur={(e) =>
                                            (e.target.placeholder = "_")
                                        }
                                        placeholder="_"
                                        autoComplete="off"
                                        className="otp_input-area"
                                        onChange={handleChange}
                                        maxLength={1}
                                    />
                                </div>
                                <div className="otp_input-section">
                                    <input
                                        type="text"
                                        id="mobile_or_email"
                                        name="otp_2"
                                        onFocus={(e) =>
                                            (e.target.placeholder = "")
                                        }
                                        onBlur={(e) =>
                                            (e.target.placeholder = "_")
                                        }
                                        placeholder="_"
                                        autoComplete="off"
                                        className="otp_input-area"
                                        onChange={handleChange}
                                        maxLength={1}
                                    />
                                </div>
                                <div className="otp_input-section">
                                    <input
                                        type="text"
                                        id="mobile_or_email"
                                        name="otp_3"
                                        onFocus={(e) =>
                                            (e.target.placeholder = "")
                                        }
                                        onBlur={(e) =>
                                            (e.target.placeholder = "_")
                                        }
                                        placeholder="_"
                                        autoComplete="off"
                                        className="otp_input-area"
                                        onChange={handleChange}
                                        maxLength={1}
                                    />
                                </div>
                                <div className="otp_input-section">
                                    <input
                                        type="text"
                                        id="mobile_or_email"
                                        name="otp_4"
                                        onFocus={(e) =>
                                            (e.target.placeholder = "")
                                        }
                                        onBlur={(e) =>
                                            (e.target.placeholder = "_")
                                        }
                                        placeholder="_"
                                        autoComplete="off"
                                        className="otp_input-area"
                                        onChange={handleChange}
                                        maxLength={1}
                                    />
                                </div>
                            </div>

                            <div className="d-flex justify-content-center position-relative">
                                <input
                                    type="submit"
                                    value="Submit"
                                    className="btn_reg"
                                />
                            </div>
                        </form>
                    </div>
                    <p className="position-absolute w-100 d-flex justify-content-center bottom-text">
                        কপিরাইট {englishToBengaliNumberFormat(new Date().getFullYear())} © মজারু এডুকেশন টেকনোলজিস লিমিটেড.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Otp;
