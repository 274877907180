import React, { useEffect, useState, lazy, Suspense } from "react";
import Banner from "./Banner/Banner";
import Courses from "./Courses/Courses";
/* import Books from "./Books/Books";
import Information from "./Information/Information";
import Think from "./Think/Think";
import Working from "./Working/Working";
import InformationMobileView from "./InformationMobileView.js/Information";
import BookMobileView from "./BooksMobileView/BooksMobileView";
import MassMedia from "./MassMedia/MassMedia"; */
import PageTitle from "../../shared/PageTitle/PageTitle";
import Loader from "../../shared/Loader/Loader";
import PopupAds from "./PopupAds/PopupAds";
/* import scheduleClass from '../../../../images/schedule_class.webp';
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast} from "react-toastify"; */
import FreeDemoClass from "../../shared/FreeDemoClass/FreeDemoClass";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";
import utils from "../../../../utils/index";

/* import Media from "../../../../components/student/Dashboard/Media/Media"; */
const CoursesMobileView = lazy(() => import('./CoursesMobileView/CoursesMobileView'));
const Books = lazy(() => import('./Books/Books'));
const BookMobileView = lazy(() => import('./BooksMobileView/BooksMobileView'));
const Information = lazy(() => import('./Information/Information'));
const Think = lazy(() => import('./Think/Think'));
const Working = lazy(() => import('./Working/Working'));
const InformationMobileView = lazy(() => import('./InformationMobileView.js/Information'));
const MassMedia = lazy(() => import('./MassMedia/MassMedia'));




const Home = () => {
    const [homePageInfo, setHomePageInfo] = useState({});
    const [popUpAds, setPopUpAds] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const location = useLocation();
/*     const [inputMobileNumber, setInputMobileNumber] = useState('');
    const [isShaking, setIsShaking] = useState(false);
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShowOrentaionClassList = () => setShow(true);

    let navigate = useNavigate(); */

    useEffect(()=>{
        TagManager.dataLayer({
            dataLayer:{
                event: 'pageView',
                pagePath:location.pathname,
                PageTitle: 'home' 
            }
        })
    },[location])

    useEffect(()=>{
        let advancedMatching = {};
        if(localStorage.getItem('mobile_or_email')){
          advancedMatching =  { 
            ph : Number(localStorage.getItem('mobile_or_email')),
         external_id: localStorage.getItem('user_id')
        };
      }
    
        const pixelData = {page: 'Home'};
        utils.addFacebookEvent('Page View', pixelData, advancedMatching);
        async function fetchHomePageInfo(){
            let response = await fetch(`${process.env.REACT_APP_BASE_URL}api/home-page-details`);
            response = await response.json();
            setHomePageInfo(response.data);
            setIsLoading(false)
        }

        fetchHomePageInfo()
        /* const shakeInterval = setInterval(() => {
        setIsShaking(true);
        setTimeout(() => setIsShaking(false), 820);
        }, 2000);
        return () => clearInterval(shakeInterval); */
    },[])

    useEffect(() => {
        async function fetchPopUp(){
            let response = await fetch(`${process.env.REACT_APP_BASE_URL}api/pop-up-ads`);
            response = await response.json();
            setPopUpAds(response.data);
        }

        fetchPopUp()
        const popupShown = sessionStorage.getItem('popupShown');

        if (!popupShown) {
            fetchPopUp();
            setShowPopup(true);
            sessionStorage.setItem('popupShown', 'true');
        }
        
    }, []);

    if(isLoading){
        return (
            <>
                <Loader/>
            </>
        );
    }

    let newCount = homePageInfo?.news.length;

    /* function handleInputChange(event) {
        const newValue = event.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
        setInputMobileNumber(newValue);
    }
    
    const handelShowBookingSlotList = (e) =>{
        e.preventDefault();
        
        fetch(`${process.env.REACT_APP_BASE_URL}api/get-orientation-class-user-details?mobile_number=${inputMobileNumber}`,{
            method:'get',
            headers:{
                'content-type' : 'application/json',
            },
        })
        .then((res) => res.json())
        .then((data) => {
            if(data?.message_type === 'success' && data?.data?.user_details?.id){   
             navigate("/schedule/class-list", {
                        state: {
                            free_class_user_id: data?.data?.user_details?.id,
                            age: data?.data?.user_details?.age
                        },
                    });
                    setShow(false);
 
            }else{
                toast.error(data?.message);
                setShow(false);
            }
        });
    }
 */
    
    return (
        <>
            <PageTitle title="Home"></PageTitle>
           {/*  <Header navlinks={homePageInfo.course_categories}/> */}

            {/* free class section */}
            {/* <div className="position-sticky bg-white schedule-section">
                <div className="container" >
                    <div className="row" >
                        <div className="col-lg-6">
                            <img src={scheduleClass} className='img-fluid' alt="schedule-class" />
                        </div>
                        <div className="col-lg-6">
                            <div className="row h-100">
                                <div className="col-lg-6">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <button className='btn-course text-center' style={{ backgroundColor:"#2DC372", color:'#fff', borderRadius:'0.3rem' }} onClick={handleShowOrentaionClassList}> <i className="fas fa-video me-2"></i>Join Your Free Demo Class</button>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <Link to='/schedule/class' className={`btn-attempt text-center shake-button${isShaking ? ' shake' : ''}`}  style={{ borderRadius:'0.3rem' }}><i className="fas fa-calendar me-2"></i>Book a Free Demo Class</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton style={{ border:'none' }}>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handelShowBookingSlotList}>
                        <p>ওরিয়েন্টেশন ক্লাসে জয়েন করার জন্য তোমার মোবাইল নম্বর লিখ <span className="text-danger">(যে নম্বর দিয়ে রেজিস্ট্রেশন করেছ)</span></p>
                        <div className="row">
                        <div className="col-lg-12">
                            <p className='mb-2 font-400'>Mobile Number <span className='text-danger'>*</span></p>
                            <input className="mb-3 form-control form-control-lg student-profile-info" value={inputMobileNumber} onChange={handleInputChange} name="student_phone_number" type="text" placeholder="Enter Your Mobile Number" required />
                        </div>
                        </div>
                        <div className=" d-flex justify-content-center">
                        <input className="btn text-white py-2 px-4" style={{ backgroundColor:'#532D80', fontSize:'22' }} type="submit" value='Submit'></input>
                        </div>
                    </form>
                </Modal.Body>
            </Modal> */}


            <FreeDemoClass/>




           
            <Banner settings={homePageInfo}/>
            <Courses categories = {homePageInfo?.course_categories} courses = {homePageInfo.courses} path_link={homePageInfo.course_image_path}/>
            {
                popUpAds?.ads?.length > 0 &&

                popUpAds?.ads?.slice(0,1)?.map( ad => 
                    <PopupAds
                        key={ad?.id}
                        show={showPopup}
                        ad={ad}
                        ad_image_path={popUpAds?.ad_image_path}
                        onHide={() => setShowPopup(false)}
                    />
                )
                    
            }

            
            {/*<Suspense fallback={null}>*/}
            {/*   <CoursesMobileView categories = {homePageInfo?.course_categories} courses = {homePageInfo.courses} path_link={homePageInfo.course_image_path}/>*/}
            {/*</Suspense>*/}
            <Suspense fallback={null}>
               <Books categories = {homePageInfo?.ebook_categories} e_books = {homePageInfo.e_books} path_link={homePageInfo.ebook_image_path}/>
            </Suspense>
            <Suspense fallback={null}>
               <BookMobileView categories = {homePageInfo?.ebook_categories} e_books = {homePageInfo.e_books} path_link={homePageInfo.ebook_image_path} />
            </Suspense>
            <Suspense fallback={null}>
               <Information />
            </Suspense>
            <Suspense fallback={null}>
               <InformationMobileView />
            </Suspense>
            <Suspense fallback={null}>
                <Think/>
            </Suspense>
            <Suspense fallback={null}>
                <Working newCount = {newCount}/>
            </Suspense>
            <Suspense fallback={null}>
                <MassMedia news= {homePageInfo?.news} path_link={homePageInfo.news_image_path}/>
            </Suspense>
            {/* <Message /> */}
            {/* <Footer /> */}
        </>
    );
};

export default Home;
