import React, { useEffect, useState } from "react";
import "./LiveClasses.css";
import TodayLiveClass from "./TodayLiveClass/TodayLiveClass";
import TomorrowLiveClass from "./TomorrowLiveClass/TomorrowLiveClass";
import Swiper from "swiper";
import i18next from "i18next";
import { useTranslation } from "react-i18next";


const LiveClasses = () => {
    /* let [mySwiper, setMySwiper] = useState(null); */
    const [liveCLasses, setLiveClasses] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        async function fetchLiveClassInfo() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL_STUDENT}live-classes?user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            response = await response.json();
            setLiveClasses(response);
        }
        fetchLiveClassInfo();
        const useLanguage = sessionStorage.getItem('useLanguage');
        if(!useLanguage){
            sessionStorage.setItem('useLanguage', 'bn');
            i18next.changeLanguage('bn');
        }else{
            i18next.changeLanguage(useLanguage);
        }

    }, []);

    useEffect(() => {
        new Swiper(".swiper", {
            /* slidesPerView: 2.04, */
            /* spaceBetween: 20, */
            grabCursore: true,
            loop: false,
            breakpoints: {
                240: {
                    slidesPerView: 1.08,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 1.8,
                    spaceBetween: 15,
                },
                1200: {
                    slidesPerView: 2.04,
                    spaceBetween: 20,
                },
            },
        });
        new Swiper(".next-class", {
            /* slidesPerView: 2.4,
            spaceBetween: 20, */
            grabCursore: true,
            loop: false,
            breakpoints: {
                240: {
                    slidesPerView: 1.08,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 2.08,
                    spaceBetween: 15,
                },
                1200: {
                    slidesPerView: 2.4,
                    spaceBetween: 20,
                },
            },
        });
        /* setMySwiper(mySwiper, mySwiper2); */
    }, []);

    

    


    return (
        <div className="live-class-section">
            <div className="mid-section">
                <p className="mb-lg-2 mb-md-2 mb-3 live-class-title">{t("Today Live Classes")}</p>

                <div className="swiper mySwiper pt-lg-4 pe-lg-3 pt-2 pe-2">
                    <div className="swiper-wrapper">
                        {liveCLasses?.today_live_classes?.length !== 0 ? (
                            liveCLasses?.today_live_classes?.filter(liveCLass => liveCLass?.permission)?.map(
                                (liveCLass) => (
                                    <TodayLiveClass
                                        key={liveCLass.live_class_id}
                                        liveCLass={liveCLass}
                                        toDayClass = {true}
                                    ></TodayLiveClass>
                                )
                            )
                        ) : (
                            <p className="d-flex justify-content-center mb-0 w-100 no-class-title">{t("You have no class")}</p>
                        )}
                    </div>
                </div>
                
                <p className="pt-lg-5 pt-md-4 pt-4 mb-lg-2 mb-md-2 mb-3 live-class-title">{t("Upcoming Live Classes")}</p>
                <div className="mySwiper next-class pt-lg-4 pe-lg-3 pt-2 pe-2">
                    <div className="swiper-wrapper">
                        {liveCLasses?.upcoming_live_classes?.length !== 0 ? (
                            liveCLasses?.upcoming_live_classes?.filter(liveCLass => liveCLass?.permission)?.map(
                                (liveCLass) => (
                                    <TomorrowLiveClass key={liveCLass.live_class_id} liveCLass={liveCLass} toDayClass = {true}></TomorrowLiveClass>
                                )
                            )
                         ): (<p className="d-flex justify-content-center mb-0 w-100 no-class-title">{t("You have no upcomming class")}</p>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveClasses;
