import React, {useEffect, useState} from "react";
import {OverlayProvider, usePreventScroll} from 'react-aria';
import {useLocation, useParams} from "react-router-dom";
import "./CourseDetails.css";
/* import CourseDetailsInformation from "./CourseDetailsInformation/CourseDetailsInformation"; */
import CourseShortInformation from "./CourseShortInformation/CourseShortInformation";
import Icon from "../../../../images/people.png";
import videoIconCourseDetails from "../../../../images/course-video.png";
import CourseDescription from "./CourseDetailsInformation/CourseDescription/CourseDescription";
import ClassSchedule from "./CourseDetailsInformation/ClassSchedule/ClassSchedule";
import CourseReview from "./CourseDetailsInformation/CourseReview/CourseReview";
import NoVideo from "../../../../images/default_video_banner.jpg";
import Faq from "./CourseDetailsInformation/Faq/Faq";
import {decode as base64_decode} from "base-64";
import {RadioGroup} from '@headlessui/react';
import PageTitle from "../PageTitle/PageTitle";
import Loader from "../Loader/Loader";
import {CheckCircleIcon} from '@heroicons/react/20/solid'
import {createContext} from "react";
import {englishToBengaliNumberFormatWithZero} from "../../../../utilities/utilities";
import Card from "../../components/Home/Courses/Card/Card";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ReactGA from "react-ga4";
import mixpanel from 'mixpanel-browser';
import TagManager from "react-gtm-module";
import utils from "../../../../utils/index";
import {Link} from 'react-router-dom';
import Button from "./components/Button";
import SlideOver from "./components/SlideOver";
import PaymentSegment from "./components/PaymentSegment";
import {useStore} from "../../../../store/cartStore";

export const CourseDetailContext = createContext({});


const CourseDetails = () => {
    const [courseDetail, setCourseDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const handleOnClose = () => setIsOpen(false);
    const [payment, setPayment] = useState([]);
    const [batchName, setBatchName] = useState("");
    const [selectedBatch, setSelectedBatch] = useState();
    const [selectedPayment, setSelectedPayment] = useState(null);
    usePreventScroll({isDisabled: !isOpen});

    /* get id from params */
    const location = useLocation();
    const search = useLocation().search;
    const course_id = location?.state?.course_id || new URLSearchParams(search).get("course_id");
    const affiliate_id = new URLSearchParams(search).get("aff");
    let decode = base64_decode(`${course_id}`);

    const {courseName} = useParams();

    let url;

    if (!courseName) {
        url = `${process.env.REACT_APP_BASE_URL}api/course-detail?course_id=${decode}`;
    } else {
        url = `${process.env.REACT_APP_BASE_URL}api/course-detail-by-slug?course_slug=${courseName}`;
    }

    useEffect(() => {
        async function fetchMyAPI() {
            let response = await fetch(
                url
            );
            response = await response.json();
            TagManager.dataLayer({
                dataLayer: {
                    event: 'pageView',
                    pagePath: `${response.data?.course_detail?.course_name}`,
                    PageTitle: `${response.data?.course_detail?.course_name}`,
                    price: response.data?.course_detail?.course_current_price,
                    currency: 'BDT'
                }
            })

            const pixelData = {page: `${response.data?.course_detail?.course_name} course`};
            utils.addFacebookEvent('Content View', pixelData);

            setCourseDetail(response.data);
            setIsLoading(false);
        }

        fetchMyAPI();
    }, [decode]);

    const courseId = courseDetail?.course_detail?.id;

    useEffect(() => {
        async function fetchMyAPI() {
            let response = await fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}course-payment-information?course_id=${courseId}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
                    'SecretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                },
            });
            response = await response.json();
            setPayment(response.data);
            if(response.data.course_payment_segments.length > 0){
                setSelectedPayment(response.data.course_payment_segments[0]);
            }
        }

        fetchMyAPI();

    }, [courseId]);

    const imagePath = courseDetail?.course_image_path;
    const imageLink = courseDetail?.course_detail?.course_picture;
    const courseBatch = payment?.course_batches || [];

    const coursePayment = payment?.course_payment_segments || [];

    useEffect(() => {
        if (courseDetail?.course_detail?.course_name) {
            ReactGA.event({
                category: 'course view',
                action: 'course view action',
                label: `Viewed ${courseDetail?.course_detail?.course_name} course`,
                value: localStorage.getItem('mobile_or_email') ? Number(localStorage.getItem('mobile_or_email')) : undefined
            });
        }

    }, [courseDetail]);

    useEffect(() => {
        if (courseBatch.length > 0) {
            setBatchName(courseBatch[0].batch_name);
            setSelectedBatch(courseBatch[0]);
        }
    }, [courseBatch]);

    mixpanel.track('Course Open', {
        number: localStorage.getItem('mobile_or_email') ? Number(localStorage.getItem('mobile_or_email')) : 'Not Registered User'
    });

    const handleBatch = (event) => {
        const batchName = event.target.value;
        const selectedBatch = courseBatch.find(batch => batch.batch_name === batchName);
        setBatchName(batchName);
        setSelectedBatch(selectedBatch);
    }
    const handleSelectedPaymentChange = (value) => {
        setSelectedPayment(value);
    };

    const courseNames = courseDetail?.course_detail?.course_name;
    const courseClasses = courseDetail?.course_detail?.course_total_live_class;
    const basePath = imagePath;
    const courseImagePath = imageLink;

    const courseInfo = {
        courseNames,
        courseClasses,
        basePath,
        courseImagePath,
    }
    localStorage.setItem("course_info", JSON.stringify(courseInfo));
    localStorage.setItem("payment", JSON.stringify(payment));

    const handleSetItem = () => {
        localStorage.setItem("selected_batch", JSON.stringify(selectedBatch));
        localStorage.setItem("selected_payment", JSON.stringify(selectedPayment));
    }
    const handleClick = () => {
        handleOnClose();
        handleSetItem();
    };
    if (isLoading) {
        return (<Loader/>);
    }


    return (
        <CourseDetailContext.Provider value={{courseDetail, batchName, selectedPayment}}>
            <PageTitle title="Course Details"></PageTitle>
            <ScrollToTop></ScrollToTop>
            <>
                {/* <Header></Header> */}
                <OverlayProvider>
                    <div className="borderTop" style={{paddingBottom: "23rem"}}>
                        <div className="container">
                            <div className="row mx-0 gy-3 course-detail-top">
                                <div className="col-lg-9">
                                    {/* <p className="update-date"> সর্বশেষ আপডেট হয়েছে: 21 ডিসেম্বর 2021</p> */}
                                    <p className="course-name"> {courseDetail?.course_detail?.course_name} </p>
                                    <div className="d-flex">

                                        {
                                            courseDetail?.total_students >= 50 &&
                                            <div className="d-flex align-items-center pe-4">
                                                <img src={Icon} alt="" height={18} width={20}/>
                                                <p className="font-16 mb-0 pt-1 ps-1 course-student balooda-regular"> ভর্তি
                                                    হয়েছে
                                                    : {englishToBengaliNumberFormatWithZero(courseDetail?.total_students)} জন </p>
                                            </div>
                                        }
                                        <div className="d-flex align-items-center">
                                            <img src={videoIconCourseDetails} alt="" height={18}
                                                 width={21}/>
                                            <p className="font-16 mb-0 pt-1 ps-1 course-student balooda-regular"> বিষয়
                                                : {englishToBengaliNumberFormatWithZero(courseDetail?.course_subject_count)} টি </p>
                                        </div>
                                    </div>

                                    {courseDetail?.course_detail?.course_introduction_video_link !== undefined && courseDetail?.course_detail?.course_introduction_video_link !== null ? (
                                        <iframe
                                            className="course-video"
                                            src={`https://www.youtube.com/embed/${courseDetail?.course_detail?.course_introduction_video_link}`}
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    ) : (
                                        <img src={NoVideo} alt="" className="my-4"
                                             style={{width: "100%"}}/>
                                    )}

                                    <ul className="nav nav-tabs profileTab" id="myTab" role="tablist">
                                        <li className="nav-item profileNavItem" role="presentation">
                                            <button
                                                className="nav-link profileNavLink active mb-0 course_details_tap_title"
                                                id="description-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#description"
                                                type="button"
                                                role="tab"
                                                aria-controls="description"
                                                aria-selected="true"
                                            >
                                                বর্ণনা
                                            </button>
                                        </li>
                                        <li className="nav-item profileNavItem" role="presentation">
                                            <button
                                                className="nav-link profileNavLink mb-0"
                                                id="curriculum-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#curriculum"
                                                type="button"
                                                role="tab"
                                                aria-controls="curriculum"
                                                aria-selected="false"
                                            >
                                                পাঠ্যক্রম
                                            </button>
                                        </li>

                                        <li className="nav-item profileNavItem" role="presentation">
                                            <button
                                                className="nav-link profileNavLink mb-0"
                                                id="review-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#my_review"
                                                type="button"
                                                role="tab"
                                                aria-controls="my_review"
                                                aria-selected="false"

                                            >
                                                রিভিউ
                                            </button>
                                        </li>

                                        <li className="nav-item profileNavItem" role="presentation">
                                            <button
                                                className="nav-link profileNavLink mb-0"
                                                id="faq-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#faq"
                                                type="button"
                                                role="tab"
                                                aria-controls="faq"
                                                aria-selected="false"
                                                style={{width: "100%"}}
                                            >
                                                <span className="course-faq-btn">সাধারণ</span> জিজ্ঞাসা
                                            </button>
                                        </li>
                                    </ul>

                                    <div className="tab-content profileTabContent" id="myTabContent">
                                        <div className="tab-pane fade show active" id="description"
                                             role="tabpanel" aria-labelledby="description-tab">
                                            <CourseDescription
                                                description={courseDetail?.course_detail?.course_description}></CourseDescription>
                                        </div>
                                        <div className="tab-pane fade" id="curriculum" role="tabpanel"
                                             aria-labelledby="curriculum-tab">
                                            <div className="faq-sections">
                                                <div className="container" data-aos="fade-up">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div
                                                                className="d-flex align-items-start mb-3 public-syllabus-show">
                                                                <div
                                                                    className="nav flex-column nav-pills assignment-subject p-2 bg-white custom-card-shadow mt-4 me-3 public-syllabus-subject-show"
                                                                    id="v-pills-tab" role="tablist"
                                                                    aria-orientation="vertical"
                                                                    style={{borderRadius: '8px'}}>
                                                                    {
                                                                        courseDetail?.syllabuses?.map((syllabus, index) =>
                                                                            <button
                                                                                key={syllabus?.subject_id}
                                                                                className={`text-start nav-link text-dark public-syllabus font-400 ${index === 0 ? "active" : ""}`}
                                                                                id={`v-pills-public-syllabus-${syllabus?.subject_id}-tab`}
                                                                                data-bs-toggle="pill"
                                                                                data-bs-target={`#v-pills-public-syllabus-${syllabus?.subject_id}`}
                                                                                type="button" role="tab"
                                                                                aria-controls={`#v-pills-public-syllabus-${syllabus?.subject_id}`}
                                                                                aria-selected={index === 0 ? "true" : "false"}>{syllabus?.subject_name}</button>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div
                                                                    className="tab-content w-100 bg-white custom-card-shadow all-assignment-section mt-4"
                                                                    id="v-pills-tabContent">

                                                                    {
                                                                        courseDetail?.syllabuses?.map((syllabus, index) => {
                                                                                return (
                                                                                    <div
                                                                                        key={syllabus?.subject_id}
                                                                                        className={index === 0 ? 'tab-pane fade active show' : 'tab-pane fade'}
                                                                                        id={`v-pills-public-syllabus-${syllabus?.subject_id}`}
                                                                                        role="tabpanel"
                                                                                        aria-labelledby={`v-pills-public-syllabus-${syllabus?.subject_id}-tab`}>
                                                                                        <p className='course-total-assignment mb-0'>{syllabus?.syllabus_title}</p>
                                                                                        <div
                                                                                            className='total-assignment-divider'></div>
                                                                                        <div
                                                                                            className="accordion accordion-flush mt-4 px-4"
                                                                                            id="public-syllabus">
                                                                                            {
                                                                                                syllabus?.lessons?.length === 0 ?
                                                                                                    <p className="d-flex justify-content-center mb-0 w-100"
                                                                                                       style={{
                                                                                                           fontSize: "20px",
                                                                                                           fontWeight: "600"
                                                                                                       }}>এখনো
                                                                                                        কোনো
                                                                                                        বিষয়বস্তু
                                                                                                        যুক্ত
                                                                                                        করা
                                                                                                        হয়নি।</p>
                                                                                                    :
                                                                                                    syllabus?.lessons?.map((lesson, index) => {
                                                                                                        return <ClassSchedule
                                                                                                            key={index}
                                                                                                            lesson={lesson}
                                                                                                            LessonSerial={index}/>
                                                                                                    })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        )}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="tab-pane fade" id="faq" role="tabpanel"
                                             aria-labelledby="faq-tab">
                                            <div className="faq-sections">
                                                <div className="container" data-aos="fade-up">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div
                                                                className="accordion accordion-flush mt-4"
                                                                id="faqlist2">
                                                                {courseDetail?.course_faqs?.map(
                                                                    (course_faq) => (
                                                                        <Faq
                                                                            key={course_faq.course_faq_id}
                                                                            course_faq={course_faq}></Faq>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="my_review" role="tabpanel"
                                             ria-labelledby="review-tab">
                                            <CourseReview course_id={decode}
                                                          isCoursePurchase={courseDetail?.course_detail?.already_purchased}></CourseReview>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 ">
                                    <CourseShortInformation
                                        id={decode}
                                        isCoursePurchase={courseDetail?.course_detail?.already_purchased}
                                        courseDetail={courseDetail?.course_detail}
                                        course_features={courseDetail?.course_features}
                                        course_teachers={courseDetail?.course_teachers}
                                        teacher_profile_path={courseDetail?.teacher_profile_picture_path}
                                        affiliate_id={affiliate_id}
                                        open={setIsOpen}
                                    ></CourseShortInformation>
                                </div>
                            </div>


                            <div>
                                {
                                    courseDetail?.popular_courses?.length > 0 &&
                                    <p className="popular-course-title px-2 ">মজারুর জনপ্রিয় কোর্স</p>
                                }
                                <div className="row gy-4 px-2">
                                    {
                                        courseDetail?.popular_courses?.slice(0, 3).map(course => <Card
                                            key={course?.id} course={course}
                                            path_link={courseDetail?.course_image_path}/>)
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                    <SlideOver
                        isOpen={isOpen}
                        onClose={handleOnClose}
                        title="মজারু কোর্স বাকেট"
                    >
                        <div className="tw-flex tw-flex-col">
                            <div className="tw-flex">
                                <img className="tw-w-24 tw-mr-6 tw-rounded-lg"
                                     src={imagePath + imageLink} alt="iamge"/>
                                <div className="">
                                    <p>{courseDetail?.course_detail?.course_name}</p>
                                    <div className="tw-flex tw-mt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                             viewBox="0 0 20 20" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9.5547 7.16795C9.24784 6.96338 8.8533 6.94431 8.52814 7.11833C8.20298 7.29235 8 7.63121 8 8V12C8 12.3688 8.20298 12.7077 8.52814 12.8817C8.8533 13.0557 9.24784 13.0366 9.5547 12.8321L12.5547 10.8321C12.8329 10.6466 13 10.3344 13 10C13 9.66565 12.8329 9.35342 12.5547 9.16795L9.5547 7.16795Z"
                                                  fill="#9CA3AF"/>
                                        </svg>
                                        <p className="tw-ml-2">{courseDetail?.course_detail?.course_total_live_class} class</p>
                                    </div>
                                    <div className="tw-flex tw-mt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                             viewBox="0 0 20 20" fill="none">
                                            <path
                                                d="M2 11C2 10.4477 2.44772 10 3 10H5C5.55228 10 6 10.4477 6 11V16C6 16.5523 5.55228 17 5 17H3C2.44772 17 2 16.5523 2 16V11Z"
                                                fill="#9CA3AF"/>
                                            <path
                                                d="M8 7C8 6.44772 8.44772 6 9 6H11C11.5523 6 12 6.44772 12 7V16C12 16.5523 11.5523 17 11 17H9C8.44772 17 8 16.5523 8 16V7Z"
                                                fill="#9CA3AF"/>
                                            <path
                                                d="M14 4C14 3.44772 14.4477 3 15 3H17C17.5523 3 18 3.44772 18 4V16C18 16.5523 17.5523 17 17 17H15C14.4477 17 14 16.5523 14 16V4Z"
                                                fill="#9CA3AF"/>
                                        </svg>
                                        <p className="tw-ml-2">Level 4</p>
                                    </div>
                                </div>
                            </div>

                            <div className="tw-relative tw-mt-5">
                                <div className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                                     aria-hidden="true">
                                    <div className="tw-w-full tw-border-t tw-border-gray-300"/>
                                </div>
                            </div>

                            <div className="tw-mt-2">
                                <label for="location"
                                       className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                                    <div className="tw-flex tw-items-center">
                                        <p className="tw-mr-2">ব্যাচ সিলেক্ট করো</p>
                                        <svg className="" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                             viewBox="0 0 20 20" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M18 10.01C18 14.4283 14.4183 18.01 10 18.01C5.58172 18.01 2 14.4283 2 10.01C2 5.59173 5.58172 2.01001 10 2.01001C14.4183 2.01001 18 5.59173 18 10.01ZM11 6.01001C11 6.56229 10.5523 7.01001 10 7.01001C9.44772 7.01001 9 6.56229 9 6.01001C9 5.45773 9.44772 5.01001 10 5.01001C10.5523 5.01001 11 5.45773 11 6.01001ZM9 9.01001C8.44772 9.01001 8 9.45773 8 10.01C8 10.5623 8.44772 11.01 9 11.01V14.01C9 14.5623 9.44772 15.01 10 15.01H11C11.5523 15.01 12 14.5623 12 14.01C12 13.4577 11.5523 13.01 11 13.01V10.01C11 9.45773 10.5523 9.01001 10 9.01001H9Z"
                                                  fill="#9CA3AF"/>
                                        </svg>
                                    </div>
                                </label>
                                <select
                                    id=""
                                    name=""
                                    className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-focus:ring-2 tw-focus:ring-indigo-600 tw-sm:text-sm tw-sm:leading-6"
                                    defaultValue={batchName}
                                    onChange={handleBatch}
                                >
                                    {
                                        courseBatch && courseBatch.map((batch, index) => <option
                                            key={batch.id}>{batch?.batch_name}</option>)
                                    }
                                </select>
                            </div>

                            <div className="tw-relative tw-mt-6">
                                <div className="tw-absolute tw-inset-0 tw-flex tw-items-center"
                                     aria-hidden="true">
                                    <div className="tw-w-full tw-border-t tw-border-gray-300"/>
                                </div>
                            </div>

                            <div className="tw-mt-6">
                                <PaymentSegment coursePayment={coursePayment} handleSelectedPaymentChange={handleSelectedPaymentChange} setSelectedPayment={setSelectedPayment} selectedPayment={selectedPayment}/>
                            </div>
                                <Link to="/course-price"
                                      className="tw-mt-4 tw-bg-[#6F39B7] tw-text-white tw-flex tw-justify-center tw-py-3 tw-rounded-lg"
                                      onClick={handleClick}>
                                    <p className="tw-text-white">কোর্সে ভর্তি কনফার্ম করো</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                         viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M9.29324 16.707C9.68424 17.098 10.3162 17.098 10.7072 16.707L14.7072 12.707C15.0982 12.316 15.0982 11.684 14.7072 11.293L10.7072 7.29299C10.5122 7.09799 10.2562 6.99999 10.0002 6.99999C9.74424 6.99999 9.48824 7.09799 9.29324 7.29299C8.90224 7.68399 8.90224 8.31599 9.29324 8.70699L12.5862 12L9.29324 15.293C8.90224 15.684 8.90224 16.316 9.29324 16.707Z"
                                              fill="white"/>
                                    </svg>
                                </Link>
                        </div>
                    </SlideOver>

                </OverlayProvider>
            </>
        </CourseDetailContext.Provider>
    );
};

export default CourseDetails;
