import React from 'react';
import { Link } from 'react-router-dom';
import dueTimeIcon from '../../../../../../images/due-clock-fill.png';
import bookHalfIcon from '../../../../../../images/book-half.png';


const PracticeTest = ({assignment}) => {


    return (
        <div className="col-lg-6 px-2 mt-0 mb-4">
            <div className='assignment-card custom-card-shadow'>
            <div className='round-padding'>
                <span className='assignment-type' style={{ backgroundColor: '#ABDDA4' }}>Practice</span>
                
                <div className='d-flex justify-content-between mt-3'>
                    <div>
                        <p className='assignment-name mb-1'>প্র্যাকটিস টেস্ট নাম</p>
                        <div className='d-flex align-items-center mb-1'>
                            <img src={bookHalfIcon} className='img-fluid me-2' alt="" />
                            <p className='mb-0 assignment-due-date'>পদার্থবিজ্ঞান</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <img src={dueTimeIcon} className='img-fluid me-2' alt="" />
                            <p className='mb-0 assignment-due-date'>ডিউ ডেট 12:00 AM, 20 October</p>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
            <div className='total-assignment-divider'></div>

            <div className='round-padding'>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                    <p className='assignment-total-numbers'>৩০ নাম্বার</p>
                    <p className='assignment-total-questions'>৩০ টি প্রশ্ন</p>
                    <p className='assignment-total-time'>৩০ মিনিট</p>
                    
                </div>
                <Link to='' className="btn-view-lecture">সাবমিট করুন</Link>
            </div>

                

            </div>
        </div>
    );
};

export default PracticeTest;