import React, { useState } from 'react';
import { formatAMPMDate } from '../../../../../../utilities/utilities';

const TimeSlot = ({slot,slots, handelTimeSlotChange}) => {
    /* const [slotListOption, setSlotListOption] = useState(''); */
        /* if(orientationCourseLists[0]?.id && selectedOptionStart){
            setSelectedOption(orientationCourseLists[0]?.id);
            setSelectedOptionStart(false);
        } */

        /* const handelTimeSlotChange = (event) => {
            setSlotListOption(parseInt(event.target.value));
        }; */

        /* console.log(slotListOption); */

    return (
        <div className="col-lg-2 ps-0">
            <div className="class-list slot-list">
                <input
                type="radio"
                name={`slot-list`}
                id={`slot-list-${slot?.id}`}
                value={slot?.id}
                onChange={handelTimeSlotChange}
                />
                <label htmlFor={`slot-list-${slot?.id}`}> {formatAMPMDate(slot?.orientation_class_time)}</label>
                
            </div>
        </div>
    );
};

export default TimeSlot;