import React from 'react';
import banner_girl from '../../../../../images/recorded.png';

const Banner = () => {
    return (
        <div className="academic_background">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <div>
                            <p className="banner-title">
                                মজারুর সাথে শেখা হবে এখন আরো মজাদার
                                {/* <br />
                                ক্লাসের পড়া ক্লাসেই হয় */}
                            </p>
                            <p className="banner-text">
                                দক্ষ ও অভিজ্ঞ দেশি-বিদেশি টিচার, কিডস ফ্রেন্ডলি এনভায়রনমেন্টে হেসে খেলে ব্রেন ডেভেলপমেন্ট থেকে শুরু করে নতুন কারিকুলামের স্কুলের পড়া কিংবা ক্যাডেট কলেজের ভর্তি প্রস্তুতি, সবকিছুর রেকর্ডেড ভার্সন পেয়ে যাবেন এখন এক জায়গাতেই!
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <img src={banner_girl} alt="" className="img-fluid pb-5" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;