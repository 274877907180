import React from 'react';

const TermsOfService = () => {
    return (
        <div className="container tw-mb-96
        ">
            <h2 className="tw-text-center tw-mt-8 tw-mb-2 tw-border-b-2 tw-pb-4">Terms of service</h2>
            <div className="tw-mt-6">
                <p className="tw-p-2">
                    The following terminology applies to these Terms and Conditions, Privacy Statement, Disclaimer Notice, and any or all 2.
                    Agreements: “Client”, "You" and “Your” refers to you, the person accessing this website and accepting the Company's
                    terms and conditions. “The Company”, “Ourselves”, "We", “Our” and "Us", refers to our Company. “Party”, “Parties”, or"us’, Hf
                    refers to both the Client and us, or either the Client or ourselves. All terms refer to the offer, acceptance, and

                    consideration of payment necessary to undertake the process of our assistance to the Client most appropriately,

                    whether by formnal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client's

                    needs in respect of the provision of the Company's stated services/products, by and subject to, prevailing law of

                    Bangladesh. Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she or

                    they, are taken as interchangeable and therefore as referring to same
                </p>



                <h4 className="tw-mb-2 tw-mt-2">license</h4>

                <p className="tw-p-2">
                    Unless otherwise stated, mojaru and/or its licensors own the intellectual property rights for all material on mojaru.
                    All intellectual property rights are reserved. You may view and/or print pages from https://mojaru.com/ for your

                    personal use subject to restrictions set in these terms and conditions. You must not:
                    Republish material from https://mojaru.com/

                    Sell, rent, or sub-license material from https://mojaru.com/

                    Reproduce, duplicate, or copy material from https://mojaru.com/

                    Redistribute content from mojaru (unless content is specifically made for redistribution/ reselling by authorization).
                </p>

                <h4 className="tw-mb-2 tw-mt-2">
                    WEB SERVICE AGREEMENT
                </h4>

                <p className="tw-p-2">
                    This mojaru Terms of Service agreement (hereafter referred to as the "ToS”) lays down the terms and conditions by
                    which mojaru's Services (hereafter referred to as the “Services”) will be provided to you upon registration. You certify
                    that you are at least 18 years of age. The ToS also describes prohibited uses of the Services. This ToS is not exhaustive
                    and mojaru reserves the right to modify it from time to time, effective when notification is posted at
                    www.mojaru.com. No verbal or written agreement/statement by any mojaru employee or at mojaru’s website
                    will take precedence over the ToS. On registering for and using any Services provided by mojaru, you the Customer
                    agree to all the terms and conditions of the ToS, as modified from time to time, in its entirety. if the Customer
                    resells/represents the Services he or she is paying for, the Customer will be responsible for his or her clients. mojaru
                    only recognizes the Customer who is paying for the Services and not his or her clients. Violation of the ToS or any such
                    action that mojaru deems inappropriate may result in the termination or suspension of the Services for the
                    Customer.

                    For detailed service level agreement please visit through this link: https://mojaru.com/sla/
                </p>
                <h4 className="tw-mb-2 tw-mt-2">1. NEW ACCOUNTS:</h4>

                <p className="tw-p-2">
                    We will set up your account after we have received payment and we and/or our payment partner(s) have screened the
                    order(s) in case of fraud. It is your responsibility to provide us with an email address that is not @ the domain(s) you are
                    signing up under. If there is ever an abuse issue or we need to contact you, the primary email address on file will be

                    used for this purpose. It is your responsibility to ensure the email address on file is always current or up-to-date.

                    If you have a domain name registered with mojaru, it is your responsibility to ensure that the contact information for
                    your domain account and your actual domain name(s) are correct and up to date. mojaru is not responsible for a
                    lapsed registration due to outdated contact information being associated with the domain. if you need to verify or
                    change this information, you should log in to your client area and change them by yourself. Providing false contact

                    information of any kind may result in the termination of your account.

                    For any kind of service purchase from mojaru, it will be necessary to provide government-issued identification
                    (Passport/National ID/Driving License, etc.), proof of your address (Utility Bill) within 3 days after establishing your
                    account with mojaru and this will strictly follow from April 12, 2018. If you fail to meet these requirements, the order
                    may be considered fraudulent and be denied, Due to unforeseen complications with various mail servers and any
                    connection problems between your ISP and mojaru’s operation office, the new account setup process may

                    sometimes be delayed.
                </p>

                <h4 className="tw-mb-2 tw-mt-2">
                    2) TRANSFERS:
                </h4>
                <p className="tw-p-2">
                    Our migration team will make every effort to help you move your site to us. However, transfers are provided as a best-
                    effort service by our migration team, and we cannot make guarantees regarding the availability, possibility, or time

                    reauired ta eamnlete an account transfer Fach hastina eamnany is canfiaured differently eind same hastina nletfarms
                    peor

                    —

                    ~ ~. ay ny .
                    save data in an incompatible or proprietary format, which may make it extremely difficult if not impossible to migrate

                    some or all account data. We will try our best, but in some cases, we may be unable to assist you in the transfer of data
                    from an old host. The free transfer services are available for 03 (three)days from your sign update. Transfers outside of
                    the 03 (three) day free period will incur a charge.

                    Domain Transfer: If a domain transfer initiate, it can be the reason you want to transfer your domain to us or you want to
                    transfer your domain from us to another register. The domain transfer may take up to 14 days to finish. Unfortunately,
                    this timeframe is something we are unable to affect, as it depends on the time the Domain Registry takes to approve
                    the transfer and the time the two registrars need to arrange it. The Domain Registrar will take a number of business
                    days to complete the transfer based on several variables. If this takes longer than the mentioned 14 days, please,

                    contact our technical support team by submitting a ticket through Client Portal.
                </p>

                <h4 className="tw-mb-2 tw-mt-2">3) SATISFACTION GUARANTEED:</h4>

                <p className="tw-p-2">30-Day Guarantee Statement valid only for Web Hosting service. If for any reason you are unhappy with the services
                    you receive from mojaru during the first 30 days of service (Only Web Hosting), you may request in writing via email
                    or by our cancellation form (as mentioned below) that your services be discontinued at which time you will receive a
                    full refund*. You must also state a detailed reason as to why you are cancelling (as we request any cancellation, no
                    matter the time frame). We are the sole arbiter as to if your reason is valid reason and if all possible steps were taken on
                    your side and on ours to correct the situation causing the interest to cancel. If the client violated the terms and
                    conditions and is suspended a case refund will not be offered also if He/she asked for cancellation and then agreed to
                    continue using services can’t ask a second time for a refund.

                    * Domain Registration/renew/transfer or SSL or Site Lock etc. + Processing Fee will apply.</p>

                <h4 className="tw-mb-2 tw-mt-2">4) PAYMENTS & TERMINATION:</h4>

                <p className="tw-p-2">
                    When you subscribe to our services, you are subscribing to a pre-paid service and all the ITES (domain registration, web
                    hosting, SSL, Site Lock, etc.) services are subject to 5% VAT & also for web development services are to 5% VAT and 1%
                    sales TAX. The VAT & TAX is as per government rules. Alll the prices of services are inclusive of VAT.

                    All due payment invoices can be paid online using Rocket, Kash, Nagad, PayPal, and through an offline method like
                    Bank Transfer. Except for any payment paid using our offline method, all online methods payments are subject to an
                    additional payment gateway fee/ mobile banking transaction fee, and it won't be added automatically if using direct
                    payment as you will choose the payment methods in your Clients Area > Invoice. If using the payment gateway of
                    aamarPay or ShurjoPay the payment gateway fee & tax will be automatically calculated & added to the amount
                    payable.

                    Renewal notices are sent several times via email from 30 to | day before the renewal date. We only accept Rocket,
                    bkash, Nagad, PayPal, and Bank Transfers for payment of services. If your yearly charge is denied, and we are unable to
                    contact you, then the late fees to enable your account shall apply. Please address any questions regarding payment to

                    mojaru sales from our clients’ area.

                    Any Services overdue will cause the suspension of your service. You must clear the dues on time. If you do not renew &
                    aiter the mentioned period, the server data will be deleted & won't be able to recover. If you have enough credit in your
                    account, the adjustment will be automnatic by the renewal date. if you haven't paid the dues in time & don't have any

                    credit your services will be in suspension.

                    If the services we offered, are put to use for any incident that violates Bangladesh government legislation, you are not
                    eligible for a refund, the agreement between mojaru Solutions with the Client shall be terminated as soon as we
                    identify it. Hence, mojaru Solutions has to take legal action against the Client

                    If a shared/reseller/VPS/Dedicated/Email Server/Spam Filtering/File Hosting/ Web Development/ Services etc. account
                    has a past due invoice, we will perform the following actions.

                    1 Day - No Action Taken

                    2 Days — Accounts are suspended

                    10 Days ~ Late Fee Added

                    1S to 21 Days - Accounts will be terminated

                    For the Web application, design, nent, mobile application development, TechByte software, Saas, Paas,

                    branding, monthly content, any graphics & video content, etc. services must be paid 30% up to 50% after the agreement
                    sign & before the working starts.

                    The rest amount must be paid within 7 working days of the project completion. After the overdue, the services will be
                    suspended & after 10 days a Late fee will be added, The client won't be able to claim any data if the service is
                    terminated. All data and workings will be lost permanently.

                    To find the actual Domain pricing, please follow the real-time domain pricing. To find the registration, renewal, transfer,
                    redemption & restoration pricing, please visit: https://secure.mojaru.com

                    Domain price can be changing any time without prior notice based on Global Currency Conversion Rate, Domain
                    Availability, for Premium Domain, Registry & extension Company price hikes.

                    Domain renewal fee can be changed any time with or without prior notice as the fee is decided by the Domain registry
                    &USD to BDT may vary year to year. Any domain abuse is strictly prohibited. If Registry or ICAAN reports for any illegal
                    activity with the domain, mojaru will open a support ticket or try to contact owner for legal documents, if client can’t
                    be reachable or can't provide any supporting documents mojaru can remove the domain from owners account &
                    delete it. The same case can be happened when a domain is purchased which brand name/ trademark infringements.

                    Several domain renewal notice will be sent to domain owner before the expiry date. All clients are advised to renew their

                    domain before the expiry date. After the expiry date his/her website or email may not be in working order, website can
                    be down.

                    mojaru sends SMS text, emails before 30 days of expiry of clients domains. A Client can renew their domain after the
                    expiry date with late fee when the domain is in grace period. If the domain goes to redemption period thatis after 14
                    days of the expiry additional fee is automatically applied. After 30 days of the expiry Domain Name will be terminated &
                    deleted from our registry. The ownership of the domain can't be claim after the expiry date at any means. To see the
                    domain grace & redemption fee of a domain please visit here: https://secure.mojaru.com

                    To avail of the free domain with any hosting which is bundled up with the plan, please follow the order procedure, if you
                    do not order the free domain with hosting, it can't be claimed differently or separately.

                    Any premium domain extension can't be claimed as free with a bundle. Please find the extensions before you purchase.

                    Once provisioned or if your service is active our system cant do any changes.
                </p>

                <h4 className="tw-mb-2 tw-mt-2">5) CANCELLATIONS:</h4>

                <p className="tw-p-2">If you wish to cancel your services, you must write to sales or request a cancellation from your client area.</p>

                <h4 className="tw-mb-2 tw-mt-2">6) OVERAGE CHARGES:</h4>

                <p className="tw-p-2">Our service plans are designed to accommodate most usage scenarios given the needs of our customers. However,
                    your usage may vary and require additional allocations, specifically in the areas of storage and traffic. We will never
                    charge your card automatically if your account requires more resources. You must keep your email address updated
                    within your cPanel, so that you may receive an email when your account reaches certain limits determined solely by our
                    system, ie., bandwidth exceeded warning at 80% at which time you may contact us to purchase a higher transfer quota
                    for your account. If you do not contact us, the system will suspend your site if your allotments exceed.
                </p>

                <h4 className="tw-mb-2 tw-mt-2">7) REFUNDS/ACCOUNT TERMINATION:</h4>
                <p className="tw-p-2">If for any reason, mojaru fails to deliver said services, a prorated refund will be issued. No refunds will be issued if
                    abuse of our stated policies forces mojaru to suspend or terminate your services prematurely. In the event, you

                    request account cancellation, once your service has been cancelled your account will be suspended from use. It will
                    then be deleted within 30 days. if for any other reason, your account becomes suspended and remains suspended it
                    will be deleted after 10 days. If a customer has more than one account under his name, mojaru reserves the right to

                    ‘suspend alll the accounts due to a non-payment of one or more accounts till all the payments are cleared.

                    No Refund will be issued while terminating the services with revoking all the agreements, if you use our services which
                    violate the Laws of Bangladesh including the Digital Security Act 2018, and the Information and Communication
                    Technology Act 2008.

                    * No Refund will be issued if you are using a second-time service it's only offered once a customer for any product.
                    ** No Refund will be issued if you will be found in violation of our terms and conditions.</p>

                <h4 className="tw-mb-2 tw-mt-2">8) CONTENT:</h4>

                <p className="tw-p-2"> All services provided by mojaru. are to be used for lawful purposes only. Transmission, storage, or presentation of
                any information, data, or material in violation of any United States Federal, State, or Bangladesh Government's local law
                is prohibited. This includes, but is not limited to copyrighted material, the material we judge to be threatening or
                obscene, material that jeopardizes national security, or material protected by trade secrets or other laws. The
                subscriber agrees to indemnify and hold harmless

                mojaru, from any claims resulting from the subscriber's use of mojaru's services which damages the subscriber
                or any other party.

                mojaru believes in freedom of speech; however, any content (written, visual, or implied) in the following areas will not
                be tolerated and is grounds for immediate suspension of service (in most cases customers will be allowed a zip file of
                their files): racism or prejudice due to color, creed, or belief; profanity or obscene language towards anyone but
                yourself; any violent/ malicious content. We prefer that you self-govern your content and are responsible for what your
                website provides. We do not have the resources and time needed to govern our servers constantly which have the
                growing and evolving content. However, if mojaru is made aware of any questionable content on a mojaru-
                hosted site, we will address the concerns directly with you and seek an amicable resolution. If anyone contacts us about
                content, they have found on a mojaru server that copyrights their material or that they wish to be removed, we

                require legal documentation to take any further action.

                We dio not allow Web Proxy Services / Scripts or file hosting services on our web hosting Server or web hosting plans. No
                remote uploading/processing script usage like the rapid leech, Phpmusicsearch, Rapid share premium link generator,

                etc are not allowed on our server. Torrent seeding using torrent flux or similar script may also cause a suspension in our

                network immediately. You are also not allowed to host an Image Hosting site with us which takes lots of CPU resources
                and abuses the server memory. Although, we do allow video streaming using FFMPEG on the server till you are in the
                acceptable CPU range, though if malicious material is found through a complaint from the abuse department, certain

                action will be taken urgently. Examples of prohibited content or links include (but are not limited to):

                Topsites

                IRC Scripts/Bots

                Proxy Scripts/Anonymizers

                Pirated Software/Warez

                Image Hosting Scripts (similar to Photobucket or Tinypic)
                AuToSurt/PTC/PTS/PPC sites

                IP Scanners

                Bruteforce Programs/Scripts/Applications

                Mail Bombers/Spam Scripts

                Banner-Ad services (commercial banner ad rotation)

                File Dump/Mirror Scripts (similar to Rapidshare)

                ‘Commercial Audio Streaming (more than one or two streams)
                Escrow/Bank Debentures

                High-Yield Interest Programs (HYIP) or Related Sites
                Investment Sites (FOREX, E-Gold Exchange, Second Life/Linden Exchange, Ponzi, MLM/Pyramid Scheme)
                Sale of any controlled substance without prior proof of appropriate permit(s)
                Prime Banks Programs

                Lottery Sites

                Betting Sites

                MUDs/RPGs/PBBGs

                Hateful/Racist/Harassment oriented sites

                Hacker-focused sites/archives/ programs

                Sites promoting illegal activities

                Forums and/or websites that distribute or link to warez/pirated/illegal content

                Bank Debentures/Bank Debenture Trading Programs

                Fraudulent Sites (Including, but not limited to sites listed at aa419.org & escrow-fraud.com)
                Mailer Pro

                of ial on all
                IRCD (IRC servers)

                servers include:

                IRC Scripts/Bots

                Pirated Software/Warez

                IP Scanners

                Bruteforce Programs/Scripts/Applications

                Mail Bombers/Spam Scripts

                Escrow

                Crypto trading

                Any phishing, scamming websites that pretend to be any renowned brands/ identity.
                High-Yield Interest Programs (HYIP) or Related Sites

                Investment Sites (FOREX, E-Gold Exchange, Second Life/Linden Exchange, Ponzi, MLM/Pyramid Scheme)
                Sale of any controlled substance without prior proof of appropriate permit(s)

                Prime Banks Programs

                Lottery Sites

                Hateful/Racist/Harassment oriented sites

                Hacker-focused sites/archives/ programs

                Sites promoting illegal activities

                Forums and/or websites that distribute or link to warez/pirated/illegal content

                Bank Debentures/Bank Debenture Trading Programs

                Fraudulent Sites (Including, but not limited to sites listed at aa419.org & escrow-fraud.com)

                Mailer Pro

                Using any web hosting account provided by mojaru as a backup/storage device is not permitted. mojaru web
                hosting accounts are not to be used to distribute and/or store an unusual number of files. We are a web hosting service,
                not a remote file storage service. Any website whose disk space usage for storing the files exceed 70% of its total usage,
                either in terms of total size or number of files, will be deemed to be using an unusual number of files. At that point, if we
                find that those files are illegal or harmful in any way, you will be notified. If you do not have any negative intentions such

                as causing harm to our server or others with the files you keep, then you have nothing to worry about.

                mojaru services, including all related equipment, networks, and network devices are provided only for authorized
                customer use. | echAbyte systems may be monitored tor alll |awTul purposes, including to ensure that use Is authorized,
                for management of the system, to facilitate protection against unauthorized access, and to verify security procedures,
                survivability, and operational security. During monitoring, information may be examined, recorded, copied, and used for
                authorized purposes. Use of mojaru system(s) constitutes consent to monitoring for these purposes.

                Any account found connecting to a third-party network or system without authorization from the third party is subject
                to suspension. Access to networks or systems outside of your direct control must be with expressed written consent
                from the third party. mojaru may, at its discretion, request and require documentation to prove access to a third-
                party network or system is authorized.

                We reserve the right to refuse service to anyone. Any material that, in our judgment, is obscene, threatening, illegal, or
                violates our terms of service in any manner may be removed from our servers (or otherwise disabled), with or without
                notice.

                Failure to respond to an email from our abuse department within 48 hours may result in the suspension or termination

                of your services. All abuse issues must be dealt with via trouble ticket/email and will have a response within 48 hours.

                Ifin doubt regarding the acceptability of your site or service, please contact us at and we will be
                happy to assist you.

                Potential harm to minors is strictly forbidden, including but not limited to child pornography or content perceived to be
                child pornography: Any site found to host child pornography or linking to child pornography will be suspended
                immediately without notice.

                Resellers: we will suspend the site in question and will notify you so you may terminate the account. We will further
                monitor your activity; more than one infraction of this type may result in the immediate termination of your account.
                Direct customers: Your services will be terminated with or without notice.

                Opt-in Email Marketing:

                If you choose to engage in marketing your site and/or services via email please heed the following. The only permitted
                form of email marketing is that in which the list is accepted. Opt-in is where people provide you with their email address

                specifically requesting to be on your email list for information about your site and/or service. If we find your account

                transmitting email to persons that did not authorize such activity, that is grounds to suspend and terminate your site.

                Note: On the flip side, the joining of an opt-in email list does not guarantee the legitimacy of such a list. Please contact

                us before taking any steps so that you will not be a victim of spam from second and third parties of such lists.</p>

                <h4 className="tw-mb-2 tw-mt-2">9) ZERO TOLERANCE SPAM POLICY:</h4>
                <p className="tw-p-2">mojaru has a zero-tolerance policy regarding unsolicited commercial email (UCE) and unsolicited bulk email (UBE)
                    also known as spam mail. The definition of spam:

                    An electronic message is spam mai

                    (1) the recipient's identity and context are irrelevant because the ge is equally i to many other p
                    recipients; (2) the recipient has not verifiably granted deliberate, explicit, and still-revocable permission for it to be sent;
                    AND (3) the transmission and reception of the message appears to the recipient to give a disproportionate benefit to
                    the sender.

                    You will be cited with UCE/UBE Abuse if you send an email to persons who have not specifically asked to receive the
                    ‘email from you. The sending origin of such an emailis irrelevant. Upon confirmation of abuse, any mojaru customer
                    ‘engaging in this practice will have their service suspended. Not only does spam place a strain on the server that can
                    negatively affect that server's uptime record and performance record, but you also subject that server to the possibility
                    of being blacklisted.

                    If your site will be sending mailings out to subscribers, this is a checklist of what you are required to do:

                    You must not try to hide, forge, or misrepresent the sender of the e-mail and sending site of the e-mail.

                    Bulk mailings must specifically state how the person’s e-mail addresses were obtained and must indicate the
                    frequency of the mailing.

                    gs should contain simple and obvious unsubscribe mechanisms. We recommend that this be in the form of

                    link to a one-click unsubscribe system; however, a valid address may be used instead.
                    All subscription-based e-mails must have valid, non-electronic, contact information for the sending organization in the
                    text of each e-mail including either a phone number or physical mailing address.

                    All bulk e-mails must be solicited, meaning that the sender has an existing and provable relationship with the e-mail
                    recipient and the recipient has not requested not to receive future mailings from the sender. Documentation of the
                    relationship between the sender and the recipient must be made available to AOL upon request.

                    Sending Unsolicited Bulk Email. The sending of any form of Unsolicited Bulk Email through mojaru servers is
                    prohibited. Likewise, the sending of UBE from another service provider advertising a website, or emnail address, or utilizing
                    any resource hosted on mojaru servers, is prohibited. mojaru accounts or services may not be used to solicit
                    customers from, or collect replies to, messages sent from another Internet Service Provider where those messages
                    violate this Policy or that of the other provider.

                    Running Unconfirmed Mailing Lists. Subscribing email addresses to any mailing list without the express and verifiable
                    permission of the email address owner is prohibited. All mailing lists run by mojaru customers must be Closed-loop.
                    The subscription confirmation 9 ived from each address owner must be kept on file for the duration of the

                    existence of the mailing list. Purchasing lists of email addresses from 3rd parties for mailing from any mojaru-hosted

                    domain, or referencing any mojaru account, is prohibited.
                    Operating an account on behalf of, or in connection with, or reselling any service to, persons or firms listed in the
                    Spamhaus Register of Known Spam Operations (ROKSO) database at www.spamhaus.org.</p>

                <h4 className="tw-mb-2 tw-mt-2">10) SCRIPT USAGE POLICY:</h4>

                mojaru provides our clients with Web Hosting accounts that permit the use of CGI files, PHP scripts, and other
                executable programs. Because these scripts use much more system resources than standard HTML pages, certain
                restrictions must be made governing the use of such “active content” systems. We regret that in many cases we are
                unable to determine what specific script or application is causing the system resource over-run. Under most
                circumstances, we can pinpoint it to the offending account on the system. The following rules apply to all “active
                content’ files as listed above:

                The script must use low system resources. Scripts that consume a large amount of our RAM or processor will be subject
                to immediate suspension until the owner can be notified and an alternative solution is reached.

                Each user account may not use more than 20% of system resources at any given time. If an account is consistently
                using 20% or greater system resources, the account holder will be warned and if no action is taken on behalf of the
                account holder the account may be suspended.

                The scripts may not interact with any server configuration or hardware. Accounts running these scripts are subject to
                immediate account termination without a refund.

                The script cannot be used or referenced from any other site not hosted by mojaru.

                The script must execute in a timely fashion. Any script that uses the processor for more than a few milliseconds is
                ‘subject to removal.

                Any script that appears to be designed specifically to attack or otherwise maliciously affect the server is completely
                unacceptable, and if such scripts are found in your account the account will be terminated immediately and your
                actions will be reported to the proper local, state, and federal authorities and legal action will be taken to prosecute the
                account holder to the fullest extent of the law by mojaru.

                Perl-based chat room scripts are strictly prohibited.

                Scripts that require high-bandwidth usage, such as non-Perl chat room scripts or banner rotation scripts, will be
                allowed to run only on our high-end accounts.

                Scripts must be kept secure. Unnecessary chmodina scripts to 777 is a violation of this policy. Keepina scripts in a
                publicly viewable directory (one without an index.htm! file) is also in violation of this policy.
                Ensuring the security of your website script is your responsibility. mojaru won't take any liability for any kind of loss
                that happens to your account because of the leak or backdoor of your script.

                NOTE: If a script will NOT execute with permission other than 777 (rarely the case) you may contact customer care to get
                approval for the script which will be given under most circumstances.

                Any accounts with scripts found in violation of these policies are subject to the future scrutiny of all CGI, Perl, CFM, ASP,
                and PHP scripts by our System Administrators. If a script is found to be harmful to the system, it will be Killed
                immediately, and the account locked down until the account owners have been contacted. Malicious scripts are
                subject to immediate account termination without refund of any pre-paid monies

                Violations of the Terms of Service will waive the refund policy.

                <h4 className="tw-mb-2 tw-mt-2">11) RESOURCE USAGE POLICY:</h4>

                <p className="tw-p-2">11. a) Server Resource Abuse Policy (SRAP):</p>

                <p className="tw-p-2">Any website on our shared servers that uses a high amount of server resources (CPU, memory usage, and network
                    resources) will be given the option to pay for a dedicated server. The abuse and misuse of system resources is a serious
                    problem, and mojaru does not tolerate it. Accounts on a shared server must be shared with other users, so we must

                    limit how much of the CPU and memory can be used by anyone's account.

                    In all fairness, we cannot allow one or two clients to use alll the system resources on a shared machine and have alll
                    other clients suffer because of it. Excessive CPU & memory usage by one or more clients causes extreme slowness in all
                    areas: MySQL, Email, HTTP, and more. if the CPU & memory Usage by a few clients gets out of hand, all sites hosted on

                    the machine will return errors and not be accessible in any way.

                    AVERAGE SERVER RESOURCE LIMITS

                    Memory usage may not exceed 10% per account/file/application

                    CPU usage differs in different shared hosting plans, which is found on their plan details page.

                    Apache connections may not exceed 30 connections

                    15 MySQL maxinnum user connections allowed

                    250 emails per hour, per domain

                    Run stand-alone, unattended server-side processes at any point in time on the server. This includes all daemons, such
                    as IRCD.

                    Run any type of web spider or indexer (including Google Cash / AdSpy) on shared servers.

                    Run any software that interfaces with an IRC (Internet Relay Chat) network.

                    Run any bit torrent application, tracker, or client. You may link to legal torrents off-site, but may not host or store them
                    ‘on our shared servers.

                    Participate in any file-sharing/ peer-to-peer activities

                    Run any gaming servers such as counter-strike, half-life, battlefieldi942, etc.

                    Run cron entries with intervals of less than 15 minutes.

                    When using PHP include functions for including a local file, including the local file rather than the URL Instead of
                    including(“http://yourdomain.com/include.php”) use include(“include.php”)

                    11.b) Illegal Usage:
                    mojaru servers may be used for lawful purposes only. Transmission, storage, or distribution of any information,
                    data, or material in violation of any applicable law or regulation is prohibited. This includes, but is not limited to
                    copyrighted material, trademark, trade secret, or other intellectual property right used without proper
                    authorization, and material that is obscene, defamatory, constitutes an illegal threat, or violates export control
                    laws. Examples of non-acceptable content or links: Pirated software, Hacker's programs or archives, Warez sites,
                    MP8, and IRC bots. Any type of forum/discussion board discussing where to obtain such files or one that distributes
                    the files is also prohibited. The subscriber to mojaru service agrees to indemnify and hold harmless mojaru
                    from any claims resulting from the use of the service that damages the subscriber or any other party. mojaru
                    will be the sole arbiter as to what constitutes a violation of this provision.

                    5

                    .. ¢) Illegal Content:
                    mojaru does not allow background Daemons such as IRC bots; eggdrop; Bitchx; XiRCON; and any other
                    program that interferes with normal server operation. No warez is allowed. Web proxies are not tolerated. This,
                    includes NPH-Proxy and web provy. If your site is found to hold any proxy files, your account will be suspended, and
                    the account holder will be issued a fee of USD 75. When using an account with Shell, you are not allowed to run ANY
                    servers or services on the mojaru network. Servers are defined as perlbots, proxies, BitchX, psyBnc, root exploits,
                    DDoS scripts, Shoutcast servers, ete. Running any of these will face an immediate suspension, and users will be
                    subject to a fine of no less than USD 75.00, and no more than USD 100.00. mojaru reserves the right to terminate

                    an account for these activities also.

                    T1.d) Software Distribution:
                    mojaru web hosting accounts are not to be used to distribute software and multimedia products. If you wish to

                    distribute software and/or multimedia files, please contact our sales for special arrangements.

                    Tl. e) Chat Rooms:
                    mojaru does not provide nor allow chat rooms on a shared hosting environment. You may elect a dedicated
                    server to run a chat room efficiently.

                    12. f) IRC:
                    mojaru does not allow IRC or IRC bots to be operated on our shared or reseller servers. They are allowed on
                    dedicated servers provided that no negative effects ocour to our network.

                    13. g) Liat

                    mojaru makes no endorsement, claims, or promises regarding the services offered by our customers and will

                    ies:

                    not be held liable for any disputes or claims against said customers. In no event shall mojaru be liable for any
                    direct, indirect, incidental, consequential, special, and exemplary damages, or any damages whatsoever, arising
                    from the use or performance of this website or any information, services, or products provided through your

                    website. We are not responsible for any content on any server in our network and will not take any action against

                    any questionable material or action until the website in question is brought to our attention by emailing us.</p>

                <h4 className="tw-mb-2 tw-mt-2">12) IP ADDRESS OWNERSHIP:</h4>
                <p className="tw-p-2">mojaru shall maintain and control ownership of all IP numbers and addresses that may be assigned to you by

                    mojaru. mojaru reserves the right to change or remove all such IP numbers and addresses.</p>

                <h4 className="tw-mb-2 tw-mt-2">13) SECURITY:</h4>

                <p className="tw-p-2">Users are prohibited from violating or attempting to violate the security of the mojaru Network. Violations of system
                    ‘or network security may result in civil or criminal liability. mojaru will investigate occurrences, which may involve
                    cooperation with law enforcement authorities in prosecuting Users who are involved in such violations. These violations
                    include, without limitation:

                    1) Accessing data not intended for such user or logging into a server or account, which such user is not authorized to
                    access,

                    2) Attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication
                    measures without proper authorization,

                    3) Attempting to interfere with service to any user, host, or network, including, without limitation, via means of
                    overloading, ping flooding, mail bombing,

                    4) Forging any TCP/IP packet header or any part of the header information in any e-mail or forum posting,

                    5) Taking any action to obtain services to which such User is not entitled,
                    6) Attempting to couple many hosting packages together to take advantage of massive space or bandwidth
                    allocations

                    Each hosting package purchased is unique to a domain name and use - you are not allowed to purchase multiple
                    plans to obtain combined bandwidth & space. mojaru will stop such transactions and label them as deceptive. We
                    have an upgrade path for all services, and we can often work out special deals for users if they ask us for assistance.
                    Violations of system or network security are prohibited and may result in criminal and civil liability. Examples include but

                    are not limited to the following: Unauthorized access, use, probe, or scan of a systems security or authentication

                    measures, data, or traffic. Interference with service to any user, host, or network including, without limitation, mail
                    bombing, flooding, deliberate attempts to overload a system, and broadcast attacks. Forging any TCP-IP packet header
                    or any part of the header information in an email or a newsgroup posting.</p>

                <h4 className="tw-mb-2 tw-mt-2">14) SERVER ABUSE:</h4>

                <p className="tw-p-2">Any attempts to undermine or cause harm to a mojaru Web server or subscriber of mojaru Web is strictly
                    prohibited including, but not limited to:

                    Logging into a server or account that you are not authorized to access

                    Accessing data or taking any action to obtain services not intended for you or your use

                    Attempting to probe, scan or test the vulnerability of any system, subsystem, or network

                    Tampering, hacking, modifying, or otherwise corrupting or breaching security or authentication measures without
                    proper authorization

                    Transmitting material that contains viruses, Trojan horses, worms, time bombs, cancelbots, or other computer
                    programming routines or engines with the intent or effect of damaging, destroying, disrupting, or otherwise impairing a
                    computer's functionality or the operation of the System

                    Interfering with, intercepting, or expropriating any system, data, or information

                    Interfering with service to any user, host, or network including, without limitation, utilizing overloading, “flooding,”

                    “mailbombing,” or “crashing” any computer system

                    You will be held responsible for all actions performed by your account whether it be done by you or by others.
                    All sub-networks of mojaru. and all dedicated servers must adhere to the above policies.
                </p>

                <h4 className="tw-mb-2 tw-mt-2">15) BANDWIDTH MEASUREMENT AND OVERAGE FEES POLICY:</h4>

               <p className="tw-p-2"> As of August Il, 2004, all bandwidth listings for Startup, Enterprise, Corporate, VPS, and Dedicated Servers consist of the
                   ‘sum of incoming and outgoing bandwidth allowance. incoming and outgoing bandwidth allowances are calculated on
                   2.50/50 split of incoming to outgoing bandwidth based on the total bandwidth, therefore the incoming and outgoing
                   bandwidth allowances are each half of the total listed bandwidth. Any bandwidth usage exceeding either the incoming
                   oF outgoing bandwidth total will be billed cat the rate described in your plan while you purchased per MB/GB over.

                   mojaru’s bandwidth usage policy is simple! You are allotted a portion of bandwidth per month to do as you please.
                   For shared hosting plans, if the amount of data transfer exceeds the user's plan subscription, the account will be
                   suspended but the user will receive warnings through an email, and you may be contacted by mojaru/mojaru

                   staff for overuse and you may be given positive options.</p>

                <h4 className="tw-mb-2 tw-mt-2">16) SCHEDULED MAINTENANCE AND DOWNTIME:</h4>

                <p className="tw-p-2">mojaru Web server will use its commercially reasonable efforts to provide services 24 hours a day, seven days a
                    week. Subscriber acknowledges that from time to time the services may be inaccessible or inoperable for various
                    reasons, including periodic maintenance procedures or upgrades (“Scheduled Downtime”); or service malfunctions,
                    and causes beyond mojaru Web's control or which are not reasonably foreseeable by mojaru Web, including the
                    interruption or failure of telecommunications or digital transmission links, hostile network attacks, or network congestion
                    ll provide at least 48 hours advance notice to the subscriber for Scheduled

                    lity, and/or
                    inoperability of its web servers. mojaru Web has no responsibility for downtime resulting from a user's actions. Also,

                    or other failures. mojaru Web

                    Downtimes and will use commercially reasonable efforts to minimize any other disruption, inaccessibi

                    the 99.99% network uptime guarantee does not apply to shared accounts.</p>

                <h4 className="tw-mb-2 tw-mt-2">17) RESPONSIBILITY (RESELLER/CLIENT):</h4>

                <p className="tw-p-2">The client is responsible for all activity originating from the account unless proven to be a victim of outside hacking or
                    address forgery. The client is responsible for securing their username/password. The client assumes responsibility for all
                    material on their site that may be put on by a third party (such as the usage of Free for All links pages). Use of

                    mojaru's service requires a certain level of knowledge in the use of Internet languages, protocols, and software.
                    level of knowledge varies depending on the anticipated use and desired content of the web space by the client. The
                    following examples are offered: Web Publishing: requires knowledge of HTML, properly locating and linking documents,
                    FTPing Web contents, Graphics, text, sound, image mapping, etc. FrontPage web publishing: knowledge of the FrontPage
                    tools as well as Telnet and FTP understanding and capability. CGI-Scripts require a knowledge of the UNIX environment,
                    TAR & GUNZIP commands, Perl, CShell scripts, permissions, etc. Mail: a use of mail clients to receive and send mail, etc.
                    The client agrees that he or she has the necessary knowledge to create and maintain their web space. The client
                    agrees that it is not the responsibility of mojaru to provide this knowledge or support outside matters specific to
                    mojaru servers.</p>

                <h4 className="tw-mb-2 tw-mt-2">18) BACKUPS:</h4>

                <p className="tw-p-2">mojaru takes extreme steps to maintain system integrity. However, mojaru shall not be held responsible for the
                    loss of data due to any cause. To ensure the highest level of data protection, mojaru recommends that ALL
                    information be backed up on your computer. As with any data that you hold a high importance to, it should be always

                    in more than one location. All mojaru data backups are designed for hardware failure, not customer user error.</p>

                <h4 className="tw-mb-2 tw-mt-2">19) ACTIONS:</h4>
                <p className="tw-p-2">The failure of a customer to meet or follow any of the above policies/terms is grounds for account deactivation.
                    mojaru will be the sole arbiter as to what constitutes a violation of the AUP. mojaru reserves the right to remove

                    any account without prior notice.

                    When mojaru becomes aware of an alleged violation of its AUP, mojaru will initiate an investigation. During the
                    investigation, mojaru may restrict a customer's access to prevent further potentially unauthorized activity.
                    Depending on the severity of the violation, mojaru may, at its sole discretion, restrict, suspend, or terminate a
                    customer's web hosting account and/or pursue other civil remedies. If such a violation is a criminal offense, mojaru
                    will notify the appropriate law enforcement authorities of such a violation.

                    mojaru does not issue credits for outages incurred through service disablement resulting from AUP violations.

                    mojaru customers agree to indemnify and hold harmless mojaru from any claims resulting from the use of our
                    services that damages them or any other party. The mojaru service is provided on an as-is, as-available basis
                    without warranties of any kind, either express or implied, including, but not limited to, warranties of merchantability,
                    fitness for a particular purpose, or non-infringement. mojaru expressly disclaims any representation or warranty that
                    the mojaru service will be error-free, secure, or uninterrupted. No oral advice or written information given by
                    mojaru, its employees, licensors, or the like, will create a warranty; nor may you rely on any such information or
                    advice. mojaru and its partners and suppliers will not be liable for any cost or damage arising either directly or

                    indirectly from any transaction or use of the service.</p>

                <h4 className="tw-mb-2 tw-mt-2">20) MODIFICATION:</h4>
                <p className="tw-p-2">mojaru reserves the right to add, delete, or modify any provision of this policy at any time without notice.</p>

                <h4 className="tw-mb-2 tw-mt-2">21) REFUSAL OF SERVICE:</h4>
                <p className="tw-p-2">We reserve the right to refuse, cancel, or suspend service at our sole discretion. An example of a refusal of service is if an

                    account continues to abuse our policies after warninas. Refusal. cancellation. or suspension of services especiallv holds
                    to issues with spam or any script abuse. Another example of a refusal of service is if a customer becomes very
                    unreasonable, unprofessional, extremely difficult to work with, or threatening. Said customer's account will be

                    suspended and we will recommend to the customer find a new host. A backup of the account(s) will be provided.

                    There are special exceptions to these rules to be determined at the time we discover abuse. Different combinations of
                    usage can create problems and other special cases. At any given time, if a server is unstable, and an account is found
                    to be the cause, we reserve the right to suspend the account to keep the server online and operating normally. if the
                    usage is an increasing trend, we recommend the user invest in a dedicated server so the website can grow at a normal
                    rate without restriction.</p>

                <h4 className="tw-mb-2 tw-mt-2">22) HOSTING SERVER & MANAGED SERVICE:</h4>

                <p className="tw-p-2">1. Any VPS, Cloud, Dedicated Server bills are Non-Refundable, if you cancel the work ne refund will be given for running
                    month.

                    2. Full advance payment for the services should pay before work started.

                    3. Any Hosting & Domain services are 100% prepayment.

                    4, The support for the maintenance should request at least 1 day prior for any effective day. However, any support
                    request can be made anytime & mojaru will solve or give support by the means of priority.

                    5. For any emergency support case such as hacked, attack, malware, security, breach etc. we de respond immediately
                    but the turnaround time can take longer based on how critical the case is.

                    6. All the support request should be maintained & requested by online documentation ie. office 365 or google docs for
                    managed clients & support ticket for only web hosting users.

                    7. mojaru Support policy: https://mojaru.com/sla

                    8. Due Bills should be paid each months Ist week, otherwise mojaru won't be liable for any suspension or
                    termination of the services. It may cause Data Loss for your server; Clients are requested to keep backups of thier data
                    every month.

                    9. For additional services or features, client should mail service provider and, service provider will send a quotation

                    for those services.

                    10. No support will be given if client shares Admin or Root Credentials to any 3rd party providers.

                    Tl. For Webmail & Hosting related Support can only be given via Ticketing system at our Client Portal -
                    secure.mojaru

                    12. Any Marketing email, bulk email will consider as a violation of our terms. For marketing & bulk email sending to

                    your subscribers, please contact our sales.

                    1. Support that is not mentioned but can arise, if solvable, mojaru will solve within timeframe, If the problem is

                    very critical solve can be done upon discussion.

                    14. Any Feature request or new requirements will not covered by Support or Maintenance.

                    15. mojaru will provide basic suggestions & todos to take necessary steps whe manages the socials & website.

                    16. API (Write Permission) from mojaru is not open & only can open prior discussion.

                    17. After downgrading to your website hosting server, website may go slow, performance can drop, or server can go
                    down for visitor/ user activity as of limitations of resources.

                    18. if you use coprporate email services please consider our Business Mail, Google Workspace, Zoho Mail, BDIX Email
                    Hosting or OX App Suite. Webmail support for marking spamming or IP, Dornain reputation will not cover any support
                    from mojaru.

                    19. For shared hosting, unlimited & turbo hosting mojaru do not support any problems with emails, you have to
                    maintain the spam control, Domain & IP Reputation. You may buy Spam Protection as addon & Dedicated IP address for
                    better result.

                    20. mojaru will not give any support for your Computer, Network, Software, social media or 3rd party that is not
                    related to mojaru or Work Order.</p>

                <h4 className="tw-mb-2 tw-mt-2">23) WEB SERVICES:</h4>

                <p className="tw-p-2">For web Services, Designing & You can view our from the client area for full features,

                    requirements, edits, limitations, access, number of pages, number of total revisions, maximum uploads & other options.
                    These must limit, we cannot give you unlimited services for a fixed price, but you are always welcome to meet your
                    requirements upon support, mail, over the phone or office meeting, or online call. You will be provided a quotation, then
                    following the agreement, a final invoice will be delivered on your behallf by our official correspondence. If you have a
                    registered account in our client portal, you will be given the invoice there in the billing areas. Our 85% workings are
                    centered on what our client's brand's customers require. As a result, we prioritize what you require over what we offer in
                    the packages. These are for the newbie or those who want basic or informative websites. These apply for Website
                    Design & Development, WordPress or Other CMS, News or any website design & development of business or e-
                    commerce & Website Customizations, and Editing. (Without the system of Web Application Solutions like CRM,
                    Management Systems, Restaurants, Gym, Hospital, Education, or any custom web application).

                    The Bill is DUE after indicating & Invoice generation upon agreements between mojaru Solutions & It's Clients.
                    Mi

                    um 50% or upon agreement the invoice should be paid before working. Before Delivery, you will be given for cross-
                    checking if there are any mistakes or bugs. You can revise max time of the packages or if you are not our package
                    client you can revise a maximum of 5 times for small or informative websites & 10 times for Quality & Big project Web

                    Development.

                    We do not guarantee the results of custom features, requirements, problem-solving, auditing, SEO, analytics, social

                    marketing, campaigns, lead generation, and online services.

                    Any revisions without writing can't be acceptable. The time limit with the work order or agreements strictly maintained.
                    If mojaru cannot provide the requirements in time without any reason, there will be compensation. In case of new
                    features, that need time, extra revisions, or website changes, the client should extend the work order or agreement.

                    Othemise, the validity timeline will be the same & after the working period is over, no complaint or dispute will be taken.

                    ‘Once the Website is delivered, we request you change your passwords for mail & panels. We do not take any liabilities
                    after the delivery. We only give support for the existing system we developed. If we find any logs & any changes on the
                    website you have made or any other from your panel in the logs or network, we won't be able to give you our support for

                    free.

                    A courtesy of mojaru Solutions will be placed in the client's website footer, if no price is mentioned with the quotation

                    or work order this will remain, by removing this you will be billed or services can be terminated or discontinued.</p>

                <h4 className="tw-mb-2 tw-mt-2">23) SMS, Bulk SMS, Promo SMS, Masking & Non-Masking SMS, OTP SMS Policy:</h4>
                <p className="tw-p-2">Please click here to know about our sms marketing policies.</p>

                <h4 className="tw-mb-2 tw-mt-2"></h4>
                <p className="tw-p-2">Terms of Service are subject to change without any prior notification.
                    These Terms of Service are a legally binding contract between the subscriber and mojaru.
                    By opening an account, the subscriber agrees to the above-stated terms.

                    Anything not listed in the Terms of Service is open to interpretation and change by mojaru. Administrators
                    without prior notice.

                    All prices, except for the 30-day money-back guarantee, are non-refundable and non-negotiable.


                    terminate accounts without prior notification.
                    We reserve the right to remove any account with 15 days prior notice or without any notice in case of any abuse.
                    A standard agreement between clients & mojaru Solutions must be signed with full consent, no further disput
                    against the agreement won't be coverable.</p>
            </div>
        </div>
    );
};

export default TermsOfService;