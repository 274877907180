import React from 'react';
import StudentPageTitle from '../../../../shared/StudentPageTitle/StudentPageTitle';
import bookHalfIcon from '../../../../../../images/book-half.png';
import unionIcon from '../../../../../../images/Union.png';
import subtractIcon from '../../../../../../images/Subtract.png';
import { Link, useLocation } from 'react-router-dom';
import AssignmentSubmissionViewCard from './AssignmentSubmissionViewCard/AssignmentSubmissionViewCard';
import './AssignmentSubmissionView.css';
import {
    acceptanceType,
    dateConverterEnglishWithOutYear,
    englishToBengaliNumberFormat,
    formatAMPM
} from '../../../../../../utilities/utilities';
import { useState } from 'react';
import { useEffect } from 'react';
import {FaCalendarAlt} from "react-icons/fa";
import certificateIcon from "../../../../../../images/course_play_list/certificate-icon 1.png";
import CoursePlaylist from "../../Syllabus/CoursePlaylist/CoursePlaylist";

const AssignmentSubmissionView = () => {
    let location = useLocation();
    let assignment_id = location.state.assignment_id;
    let total_attempt = location.state.total_attempt;
    let no_of_attempts = location.state.no_of_attempts;
    let subject = location.state.subject;
    let total_questions = location.state.total_questions;
    let total_marks = location.state.total_marks;
    let course_id = location.state.course_id;
    let batch_id = location.state.batch_id;
    let lessons = location?.state?.lessons;
    let topic = location?.state?.topic;
    let index = location?.state?.index;
    let lesion_name = location?.state?.lesion_name;
    let progressPercent = location?.state?.progressPercent;
    const [attempts, setAttempts] = useState([]);
    const search = location.search;
    const class_content = new URLSearchParams(search).get("class_content");

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}attempt-lists?assignment_id=${assignment_id}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            .then((res) => res.json())
            .then((data) => setAttempts(data.data));
    },[assignment_id]);

    return (
        <>
            {
                lessons ? (
                    <div>
                        <div className="main-content">
                            <StudentPageTitle title="View Smart Note"></StudentPageTitle>
                            <div className="page-content p-0">
                                <div className="container-fluid">
                                    <div className="my-course-section">
                                        <div>
                                            <p className='mb-4'><Link to='/my-course/lms' state={{
                                                course_id: course_id,
                                                batch_id: batch_id,
                                                class_content: 'content_list'
                                            }} style={{
                                                color: '#532D80',
                                                fontWeight: '400',
                                                textDecoration: 'none'
                                            }}>সাবমিট করা আসাইন্মেন্ট </Link> / <span
                                                style={{color: '#918F92', fontWeight: '400'}}> বিস্তারিত</span></p>
                                            <h2 className="tw-px-16 tw-mt-8 tw-mb-8">ক্লাস : {lesion_name}</h2>
                                            <div className="md:tw-flex tw-justify-between tw-px-16">
                                                <div className="tw-flex">
                                                    {/*<img className="tw-w-10 tw-h-10 tw-mr-4 tw-rounded-lg"*/}
                                                    {/*     src={`${lectureDetail?.video_lecture?.teacher_image_path}${lectureDetail?.video_lecture?.teacher_image}`}*/}
                                                    {/*     alt="profile image"/>*/}
                                                    <div>
                                                        <p className="tw-text-[#6B7280] tw-text-sm">Published by</p>
                                                        {/*<p className="tw-text-gray-700">{lectureDetail?.video_lecture?.teacher_name}</p>*/}
                                                    </div>
                                                </div>
                                                <div className="tw-flex">
                                                    <FaCalendarAlt className="tw-text-4xl tw-mr-4 tw-text-gray-500"/>
                                                    <div>
                                                        <p className="tw-text-[#6B7280] tw-text-sm">Published on</p>
                                                        <p className="tw-text-gray-700">২১ এপ্রিল, ২০২৩</p>
                                                    </div>
                                                </div>
                                                <div className="tw-flex tw-bg-white tw-px-5 tw-py-3 tw-rounded-lg">
                                                    <div
                                                        className="tw-mr-4 tw-border-r-2 tw-border-gray-300 tw-border-sm tw-px-7">
                                                        <p className="tw-text-2xl tw-font-semibold">কোর্স
                                                            প্রোগ্রেস {englishToBengaliNumberFormat(index + 1)}/ {englishToBengaliNumberFormat(lessons.length)}
                                                        </p>
                                                        <div
                                                            className="tw-w-[200px] tw-bg-gray-200 tw-rounded-full h-4 tw-text-white tw-text-sm tw-text-center">
                                                            <div
                                                                className="tw-bg-green-400 tw-h-4 tw-rounded-full"
                                                                style={{width: `${progressPercent}%`}}
                                                            >{progressPercent}%
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img className="tw-w-10 tw-h-10" src={certificateIcon} alt="image"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="md:tw-flex tw-justify-between md:tw-p-10">
                                            <div className="md:tw-w-[65%]">

                                            <div className="row gx-4 gy-3 mb-4">
                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div
                                                            className="p-4 border bg-white custom-card-shadow submission-top-section">
                                                            <div className='d-flex align-items-center mb-3'>
                                                                <img src={bookHalfIcon} alt="" width={24} height={24}/>
                                                                <p className='submission-view-top ms-2 mb-0'>General Info</p>
                                                            </div>

                                                            <table className="table table-borderless">
                                                                <tbody>
                                                                <tr>
                                                                    <td className='p-1 font-400' style={{width: '10rem'}}>Course
                                                                    </td>
                                                                    <td className='p-1 font-400'>{attempts?.course_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='p-1 font-400'>Subject</td>
                                                                    <td className='p-1 font-400'>{subject}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='p-1 font-400'>Total Questions</td>
                                                                    <td className='p-1 font-400'>{total_questions}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='p-1 font-400'>Total Marks</td>
                                                                    <td className='p-1 font-400'>{total_marks}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div
                                                            className="p-4 border bg-white custom-card-shadow submission-top-section">
                                                            <div className='d-flex align-items-center mb-3'>
                                                                <img src={subtractIcon} alt="" width={24} height={24}/>
                                                                <p className='submission-view-top ms-2 mb-0'>Acceptance Info</p>
                                                            </div>

                                                            <table className="table table-borderless">
                                                                <tbody>
                                                                <tr>
                                                                    <td className='p-1 font-400' style={{width: '10rem'}}>Maximum
                                                                        Attempt
                                                                    </td>
                                                                    <td className='p-1 font-400'>{no_of_attempts}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='p-1 font-400'>Acceptance type</td>
                                                                    <td className='p-1 font-400'>{acceptanceType(attempts?.acceptance_type)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='p-1 font-400'>Published on</td>
                                                                    <td className='p-1 font-400'>{formatAMPM(new Date(attempts?.assignment_published_date))}, {dateConverterEnglishWithOutYear(attempts?.assignment_published_date)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='p-1 font-400'>Due Date</td>
                                                                    <td className='p-1 font-400'>{formatAMPM(new Date(attempts?.assignment_due_date))}, {dateConverterEnglishWithOutYear(attempts?.assignment_due_date)}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                        <div
                                                            className="p-4 border bg-white custom-card-shadow submission-top-section">
                                                            <div className='d-flex align-items-center mb-3'>
                                                                <img src={unionIcon} alt="" width={24} height={24}/>
                                                                <p className='submission-view-top ms-2 mb-0'>Submission Info</p>
                                                            </div>

                                                            <table className="table table-borderless mb-3">
                                                                <tbody>
                                                                <tr>
                                                                    <td className='p-1 font-400' style={{width: '10rem'}}>Last
                                                                        Submission Date
                                                                    </td>
                                                                    <td className='p-1 font-400'>{formatAMPM(new Date(attempts?.student_last_submission_date))}, {dateConverterEnglishWithOutYear(attempts?.student_last_submission_date)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='p-1 font-400'>Total Attempt(s)</td>
                                                                    <td className='p-1 font-400'>{total_attempt}</td>
                                                                </tr>
                                                                </tbody>

                                                            </table>

                                                            <p className='text-center submission-show-marks'>Obtained Marks: <span
                                                                className='marks'>{attempts?.student_last_obtained_mark ? attempts?.student_last_obtained_mark : 'N/A'}</span>
                                                            </p>
                                                            {
                                                                no_of_attempts > total_attempt ?
                                                                    attempts?.question_show_mode === 1 ?
                                                                        <Link to='/assignment-question'
                                                                              state={{assignment_id: attempts?.assignment_id}}
                                                                              className='btn-again-submit text-center mt-3'>আবার
                                                                            সাবমিট করুন</Link> : <Link to='/assignment-questions'
                                                                                                       state={{assignment_id: attempts?.assignment_id}}
                                                                                                       className='btn-again-submit text-center mt-3'>আবার
                                                                            সাবমিট করুন</Link>
                                                                    : <p className='btn-again-submit text-center mt-3'>সকল সাবমিটে
                                                                        অংশগ্রহণ সম্পূর্ণ</p>
                                                            }


                                                        </div>
                                                    </div>
                                                </div>

                                                <ul className='nav nav-pills py-1 ps-1 bg-white mt-4 custom-card-shadow mb-4'
                                                    style={{borderRadius: '8px'}} id="pills-tab" role="tablist">

                                                    {
                                                        attempts?.attempts?.filter(attempt => attempt.is_running === 0)?.map((attempt, index) =>
                                                            <li key={attempt?.attempt_id} className="nav-item assignment-submission"
                                                                role="presentation">
                                                                <button
                                                                    className={`nav-link text-dark submission-count ${index === 0 ? "active" : ""}`}
                                                                    id={`pills-submission_${index}-tab`} data-bs-toggle="pill"
                                                                    data-bs-target={`#pills-submission_${index}`} type="button"
                                                                    role="tab" aria-controls={`pills-submission_${index}`}
                                                                    aria-selected="true">Submission #{index + 1}</button>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                                <div className="tab-content" id="pills-tabContent">

                                                    {
                                                        attempts?.attempts?.map((attempt, index) =>
                                                            <div key={attempt?.attempt_id}
                                                                 className={`tab-pane fade show ${index === 0 ? "active" : ""}`}
                                                                 id={`pills-submission_${index}`} role="tabpanel"
                                                                 aria-labelledby={`pills-submission-${index}-tab`}>
                                                                {
                                                                    <AssignmentSubmissionViewCard attempt={attempt}/>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="md:tw-w-[30%]">
                                                <CoursePlaylist lessons={lessons} topic={topic} courseId={course_id}
                                                                batchId={batch_id} index={index}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="main-content">
                        <StudentPageTitle title="View Submission"></StudentPageTitle>
                        <div className="page-content p-0">
                            <div className="container-fluid">
                                <div className="my-course-section">
                                    {
                                        class_content === 'content_list' ?
                                            <div className='d-flex justify-content-between'>
                                                <p className='mb-4'><Link to='/my-course/lms' state={{
                                                    course_id: course_id,
                                                    batch_id: batch_id,
                                                    class_content: 'content_list'
                                                }} style={{
                                                    color: '#532D80',
                                                    fontWeight: '400',
                                                    textDecoration: 'none'
                                                }}>সাবমিট করা আসাইন্মেন্ট </Link> / <span
                                                    style={{color: '#918F92', fontWeight: '400'}}> বিস্তারিত</span></p>
                                                <div className='pe-4'>
                                                    <Link to='/my-course/lms'
                                                          state={{
                                                              course_id: course_id,
                                                              batch_id: batch_id,
                                                              class_content: 'content_list'
                                                          }} className='back-btn text-center'><i
                                                        className="fa-solid fa-arrow-left-long pe-2"></i>Back to Class
                                                        List</Link>
                                                </div>
                                            </div>
                                            :
                                            <div className='d-flex justify-content-between'>
                                                <p className='mb-4'><Link to='/my-course/lms' state={{
                                                    course_id: course_id,
                                                    batch_id: batch_id,
                                                    class_content: 'assignment'
                                                }} style={{
                                                    color: '#532D80',
                                                    fontWeight: '400',
                                                    textDecoration: 'none'
                                                }}>সাবমিট করা আসাইন্মেন্ট </Link> / <span
                                                    style={{color: '#918F92', fontWeight: '400'}}> বিস্তারিত</span></p>
                                                <div className='pe-4'>
                                                    <Link to='/my-course/lms'
                                                          state={{
                                                              course_id: course_id,
                                                              batch_id: batch_id,
                                                              class_content: 'assignment'
                                                          }} className='back-btn text-center'><i
                                                        className="fa-solid fa-arrow-left-long pe-2"></i>Back to
                                                        Assignment List</Link>
                                                </div>
                                            </div>
                                    }

                                    <div className="row gx-4 gy-3 mb-4">
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div
                                                className="p-4 border bg-white custom-card-shadow submission-top-section">
                                                <div className='d-flex align-items-center mb-3'>
                                                    <img src={bookHalfIcon} alt="" width={24} height={24}/>
                                                    <p className='submission-view-top ms-2 mb-0'>General Info</p>
                                                </div>

                                                <table className="table table-borderless">
                                                    <tbody>
                                                    <tr>
                                                        <td className='p-1 font-400' style={{width: '10rem'}}>Course
                                                        </td>
                                                        <td className='p-1 font-400'>{attempts?.course_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='p-1 font-400'>Subject</td>
                                                        <td className='p-1 font-400'>{subject}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='p-1 font-400'>Total Questions</td>
                                                        <td className='p-1 font-400'>{total_questions}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='p-1 font-400'>Total Marks</td>
                                                        <td className='p-1 font-400'>{total_marks}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div
                                                className="p-4 border bg-white custom-card-shadow submission-top-section">
                                                <div className='d-flex align-items-center mb-3'>
                                                    <img src={subtractIcon} alt="" width={24} height={24}/>
                                                    <p className='submission-view-top ms-2 mb-0'>Acceptance Info</p>
                                                </div>

                                                <table className="table table-borderless">
                                                    <tbody>
                                                    <tr>
                                                        <td className='p-1 font-400' style={{width: '10rem'}}>Maximum
                                                            Attempt
                                                        </td>
                                                        <td className='p-1 font-400'>{no_of_attempts}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='p-1 font-400'>Acceptance type</td>
                                                        <td className='p-1 font-400'>{acceptanceType(attempts?.acceptance_type)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='p-1 font-400'>Published on</td>
                                                        <td className='p-1 font-400'>{formatAMPM(new Date(attempts?.assignment_published_date))}, {dateConverterEnglishWithOutYear(attempts?.assignment_published_date)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='p-1 font-400'>Due Date</td>
                                                        <td className='p-1 font-400'>{formatAMPM(new Date(attempts?.assignment_due_date))}, {dateConverterEnglishWithOutYear(attempts?.assignment_due_date)}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                            <div
                                                className="p-4 border bg-white custom-card-shadow submission-top-section">
                                                <div className='d-flex align-items-center mb-3'>
                                                    <img src={unionIcon} alt="" width={24} height={24}/>
                                                    <p className='submission-view-top ms-2 mb-0'>Submission Info</p>
                                                </div>

                                                <table className="table table-borderless mb-3">
                                                    <tbody>
                                                    <tr>
                                                        <td className='p-1 font-400' style={{width: '10rem'}}>Last
                                                            Submission Date
                                                        </td>
                                                        <td className='p-1 font-400'>{formatAMPM(new Date(attempts?.student_last_submission_date))}, {dateConverterEnglishWithOutYear(attempts?.student_last_submission_date)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='p-1 font-400'>Total Attempt(s)</td>
                                                        <td className='p-1 font-400'>{total_attempt}</td>
                                                    </tr>
                                                    </tbody>

                                                </table>

                                                <p className='text-center submission-show-marks'>Obtained Marks: <span
                                                    className='marks'>{attempts?.student_last_obtained_mark ? attempts?.student_last_obtained_mark : 'N/A'}</span>
                                                </p>
                                                {
                                                    no_of_attempts > total_attempt ?
                                                        attempts?.question_show_mode === 1 ?
                                                            <Link to='/assignment-question'
                                                                  state={{assignment_id: attempts?.assignment_id}}
                                                                  className='btn-again-submit text-center mt-3'>আবার
                                                                সাবমিট করুন</Link> : <Link to='/assignment-questions'
                                                                                           state={{assignment_id: attempts?.assignment_id}}
                                                                                           className='btn-again-submit text-center mt-3'>আবার
                                                                সাবমিট করুন</Link>
                                                        : <p className='btn-again-submit text-center mt-3'>সকল সাবমিটে
                                                            অংশগ্রহণ সম্পূর্ণ</p>
                                                }


                                            </div>
                                        </div>
                                    </div>

                                    <ul className='nav nav-pills py-1 ps-1 bg-white mt-4 custom-card-shadow mb-4'
                                        style={{borderRadius: '8px'}} id="pills-tab" role="tablist">

                                        {
                                            attempts?.attempts?.filter(attempt => attempt.is_running === 0)?.map((attempt, index) =>
                                                <li key={attempt?.attempt_id} className="nav-item assignment-submission"
                                                    role="presentation">
                                                    <button
                                                        className={`nav-link text-dark submission-count ${index === 0 ? "active" : ""}`}
                                                        id={`pills-submission_${index}-tab`} data-bs-toggle="pill"
                                                        data-bs-target={`#pills-submission_${index}`} type="button"
                                                        role="tab" aria-controls={`pills-submission_${index}`}
                                                        aria-selected="true">Submission #{index + 1}</button>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">

                                        {
                                            attempts?.attempts?.map((attempt, index) =>
                                                <div key={attempt?.attempt_id}
                                                     className={`tab-pane fade show ${index === 0 ? "active" : ""}`}
                                                     id={`pills-submission_${index}`} role="tabpanel"
                                                     aria-labelledby={`pills-submission-${index}-tab`}>
                                                    {
                                                        <AssignmentSubmissionViewCard attempt={attempt}/>
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default AssignmentSubmissionView;