import React from 'react';
import TopCircle from '../../../../../../images/top_circle.png';
import BottomCircle from '../../../../../../images/bottom_circle.png';
import CircleIcon from "../../../../../../images/arrow_white.png";
import './CourseEnroll.css';
import {englishToBengaliNumberFormat} from '../../../../../../utilities/utilities';
import {Link} from 'react-router-dom';
import {encode as base64_encode} from 'base-64';
import TagManager from 'react-gtm-module';
import utils from "../../../../../../utils/index";

const CourseEnroll = ({courseDetail, isCoursePurchase, id, affiliate_id, open}) => {
    let encoded = base64_encode(`${id}`);
    const sendGTMInfo = () => {
        const user = localStorage.getItem("auth_token");
        if (!user) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'courseRegistration',
                    pagePath: 'New login',
                    PageTitle: 'New login',
                    course: courseDetail?.course_name,
                    price: courseDetail?.course_current_price,
                    currency: 'BDT'
                }
            })
            const pixelData = {page: `${courseDetail?.course_name} course`};
            utils.addFacebookEvent('Course Registration', pixelData);
        }
    }
    return (
        <div>
            <div className="position-relative">

                <div className="course-enroll position-relative">
                    <div className="position-absolute top-0 end-0">
                        <img src={TopCircle} alt=""/>
                    </div>
                    <div className='align-items-center justify-content-around mb-3'>
                        {/*{*/}
                        {/*    (courseDetail?.course_initial_price === '0' && courseDetail?.course_current_price === '0') ? <p className="mb-0 text-center" style={{ color: '#ffffff', fontSize:'36px', fontWeight:'600'}}>ফ্রি কোর্স</p> */}
                        {/*    : (courseDetail?.course_initial_price !== '0' && courseDetail?.course_current_price !== '0'&& courseDetail?.course_initial_price === courseDetail?.course_current_price) ? <p className="balance_symbol text-center" style={{ fontSize: '36px', color:'#ffffff' }}>৳<span>{englishToBengaliNumberFormat(courseDetail?.course_initial_price)}</span></p>*/}
                        {/*    : <><p className='price text-white mb-0 text-center'><del className='balooda-medium'>৳{englishToBengaliNumberFormat(courseDetail?.course_initial_price)}</del></p>*/}
                        {/*        <p className='offer-price text-white mb-0 text-center'><span className='balooda-medium'>৳ </span><span className='balooda-medium'>{englishToBengaliNumberFormat(courseDetail?.course_current_price)}</span></p></>*/}
                        {/*}*/}
                    </div>

                    <div className='buy-course'>
                        {
                            isCoursePurchase ? <p className='mb-0'>ইতিমধ্যে ক্রয়কৃত</p> :
                                affiliate_id ? <button className='buy-course tw-font-bold tw-font-700' onClick={() => {
                                        sendGTMInfo();
                                        open(true);
                                    }}>কোর্সে ভর্তি</button> :
                                    <button className='buy-course tw-font-bold tw-font-700 tw-text-3xl tw-text-gray-600'
                                            onClick={() => {
                                                sendGTMInfo();
                                                open(true);
                                            }}>কোর্সে ভর্তি</button>
                        }
                    </div>
                    {
                        !isCoursePurchase && courseDetail?.lead_collect === 1 &&
                        <div className='mt-1 free-course-registration'>
                            {/* <Link to={`/free-course-registration?course_id=${encoded}`} >ফ্রি ক্লাসের জন্য রেজিস্ট্রেশন </Link> */}
                            <Link to={`/schedule/class`}>ফ্রি ক্লাসের জন্য রেজিস্ট্রেশন </Link>
                            {/* <p onClick={handleShow} style={{ cursor:'pointer' }} className='py-2'>ফ্রি ক্লাসের জন্য রেজিস্ট্রেশন</p> */}
                        </div>
                    }

                    <p className='text-center for-help font-16'>সাহায্যের জন্য: {courseDetail?.help_line}</p>
                    {
                        courseDetail?.course_video_related_info &&
                        <div className='d-flex align-items-center course-duration'>
                            <img src={CircleIcon} alt="" height={20} width={20}/>
                            <p className='mb-0 text-white ps-3'>{courseDetail?.course_video_related_info}</p>
                        </div>
                    }
                    {
                        courseDetail?.course_access_related_info &&
                        <div className='d-flex align-items-center course-duration'>
                            <img src={CircleIcon} alt="" height={20} width={20}/>
                            <p className='mb-0 text-white ps-3'>{courseDetail?.course_access_related_info}</p>
                        </div>
                    }
                    {
                        courseDetail?.course_certificate_related_info &&
                        <div className='d-flex align-items-center course-duration'>
                            <img src={CircleIcon} alt="" height={20} width={20}/>
                            <p className='mb-0 text-white ps-3'>{courseDetail?.course_certificate_related_info}</p>
                        </div>
                    }

                    {
                        !courseDetail?.course_video_related_info && !courseDetail?.course_access_related_info && !courseDetail?.course_certificate_related_info &&
                        <>
                            <br/>
                            <br/>
                            <br/>
                        </>
                    }

                    <div className="position-absolute bottom-0 start-0">
                        <img src={BottomCircle} alt=""/>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CourseEnroll;