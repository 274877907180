import React from 'react';
import { useLocation } from 'react-router-dom';

const Result = () => {

    const location = useLocation();
    const { score = 0, total = 0, selectedOptions = [] , data = []} = location.state || {};

    return (
        <div className="tw-max-w-[640px] tw-mx-auto tw-bg-white tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-10 tw-rounded-2xl">
            <h2 className="tw-text-4xl tw-font-bold tw-text-purple-600">Your result: {score} / {total}</h2>
            <div className="tw-mt-8 tw-w-full">
                {data.map((question, index) => {
                    const userAnswer = selectedOptions[index] !== undefined ? question.options[selectedOptions[index]]?.text : 'No answer selected';
                    const isCorrect = userAnswer === question.ans;

                    return (
                        <div key={index} className="tw-mb-8">
                            <h3 className="tw-text-2xl tw-font-bold tw-mb-2">{index + 1}. {question.question}</h3>
                            {
                                question.images &&
                                <img src={question.images} alt="Question" className="tw-mb-4 tw-shadow-lg"/>
                            }
                            <ul className="tw-mb-2">
                            {question.options.map((option, idx) => (
                                    <li key={idx} className={`tw-mb-1 ${option.text === question.ans ? 'tw-text-green-600' : userAnswer === option.text ? 'tw-text-red-600' : ''}`}>
                                        {idx+1}. {option.text}
                                    </li>
                                ))}
                            </ul>
                            <p className="tw-text-lg"><strong>Correct Answer:</strong> {question.ans}</p>
                            <p className={`tw-text-lg ${isCorrect ? 'tw-text-green-600' : 'tw-text-red-600'}`}><strong>Your Answer:</strong> {userAnswer}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Result;
