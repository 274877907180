import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import StudentPageTitle from '../../shared/StudentPageTitle/StudentPageTitle';
import './LMS.css';
import Assignment from './Assignment/Assignment';
import { createContext } from 'react';
import { useState } from 'react';
import Details from './Details/Details';
import LmsPrimarySection from '../../shared/LmsPrimarySection/LmsPrimarySection';
import Lectures from './Lectures/Lectures';
import SmartNotes from './SmartNotes/SmartNotes';
import PracticeTests from './PracticeTests/PracticeTests';
import LeaderBoard from './LeaderBoard/LeaderBoard';
import Notices from './Notices/Notices';
import Attendance from './Attendance/Attendance';
import Syllabus from './Syllabus/Syllabus';
import LockScreen from './LockScreen/LockScreen';
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { eventDate } from '../../../../utilities/utilities';
import noPerson from '../../../../images/blank.png';
/* import defaultHeaders, { eventDate } from '../../../user/shared/Helper/Helper'; */
import Skeleton from 'react-loading-skeleton';
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import StudentCertificate from './StudentCertificate/StudentCertificate';
import ProgressReport from './ProgressReport/ProgressReport';
export const LMSContext = createContext({});

const LMS = () => {
    const location = useLocation();
    const [isStudentNameExits, setIsStudentNameExits] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [studentInfo, setStudentInfo] = useState();
    const [startDate, setStartDate] = useState();
    const [dateOfBirth, setDateOfBirth] = useState(true);
    const [file, setFile] = useState();
    const [image, setImage] = useState('');
    const [isChangeImage, setIsChangeImage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const {t} = useTranslation();
    
    const course_id = location?.state?.course_id;
    const batch_id = location?.state?.batch_id;
    const class_content = location?.state?.class_content;
    const [courseBasicInfo, setCourseBasicInfo] = useState({});
    /* const search = location.search;
    const class_content = new URLSearchParams(search).get("class_content"); */



    //LMS top section
    useEffect(() => {
        async function fetchMyAPI() {
            let response = await fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}course-global-infos?course_id=${course_id}&batch_id=${batch_id}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                });
            response = await response.json();
            setCourseBasicInfo(response.data);
            setIsLoading(false);
        }
        fetchMyAPI();
        const useLanguage = sessionStorage.getItem('useLanguage');
        if(!useLanguage){
            sessionStorage.setItem('useLanguage', 'bn');
            i18next.changeLanguage('bn');
        }else{
            i18next.changeLanguage(useLanguage);
        }
    }, [course_id,batch_id]);

    



    //initial level user details
    useEffect(() => {
          fetch( `${process.env.REACT_APP_BASE_URL_STUDENT}profile?user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
              .then((res) => res.json())
              .then((data) => setStudentInfo(data.data));
      }, []);

    const handleClose = () => setModalShow(false);
    if(studentInfo?.student_detail?.get_student_detail?.father_name === null &&  isStudentNameExits ){
        setModalShow(true);
        setIsStudentNameExits(false);
      }
      if(dateOfBirth && studentInfo?.student_detail?.get_student_detail?.date_of_birth){
        setStartDate(new Date(studentInfo?.student_detail?.get_student_detail?.date_of_birth));
        setDateOfBirth(false);
    }

    const handleChange = (e) => {
        setImage(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
        setIsChangeImage(true);
    }


    //initial level user details save
    const handelStudentInfo = (e) => {
    e.preventDefault();
    let data = new FormData();

    // Validate image
    /* const image = e.target.profile_picture.files[0]; */
    if (!image) {
        toast.error('Please upload your image');
        return;
    }
    data.append('profile_picture', image);

    // Validate father name
    const fatherName = e.target.father_name.value;
    if (!fatherName) {
        toast.error('Please enter father name');
        return;
    }
    data.append('father_name', fatherName);

    // Validate mother name
    const motherName = e.target.mother_name.value;
    if (!motherName) {
        toast.error('Please enter mother name');
        return;
    }
    data.append('mother_name', motherName);


    // Validate date of birth
    if (!startDate) {
        toast.error('Please enter date of birth');
        return;
    }
    data.append('date_of_birth', eventDate(startDate));

    // Validate school name
    const schoolName = e.target.school_name.value;
    if (!schoolName) {
        toast.error('Please enter school name');
        return;
    }
    data.append('school', schoolName);


    // Validate full address
    const fullAddress = e.target.full_address.value;
    if (!fullAddress) {
        toast.error('Please enter full address');
        return;
    }
    data.append('full_address', fullAddress);

    // Validate mailing address
    const mailingAddress = e.target.mailing_address.value;
    if (!mailingAddress) {
        toast.error('Please enter courier address');
        return;
    }
    data.append('mailing_address', mailingAddress);

    // Validate WhatsApp number
    const whatsAppNumber = e.target.student_whats_app_number.value;
    if (!whatsAppNumber) {
        toast.error('Please enter WhatsApp number');
        return;
    }
    data.append('whats_app_number', whatsAppNumber);

    // Validate religion
    const religion = e.target.religion.value;
    if (!religion) {
        toast.error('Please enter religion');
        return;
    }
    data.append('religion', religion);

    // Validate medium
    const medium = e.target.medium.value;
    if (!medium) {
        toast.error('Please select medium');
        return;
    }
    data.append('medium', medium);


    // Validate gender
    const gender = e.target.gender.value;
    if (!gender) {
        toast.error('Please select gender');
        return;
    }
    data.append('gender', gender);






    // Validate class name
    const className = e.target.class_name.value;
    if (!className) {
        toast.error('Please enter class name');
        return;
    }
    data.append('class', className);





    fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}update-profile?user_id=${localStorage.getItem("user_id")}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
            'SecretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
        },
        body: data,
    })
        .then(res => res.json())
        .then(data => {
            if (data.message_type === 'success') {
                toast.success(data.message);
                setModalShow(false);
            } else {
                toast.error(data.message);
            }
        })
}

    /* const handelStudentInfo = (e) =>{
        e.preventDefault();
        let data = new FormData()
        
        isChangeImage && data.append('profile_picture', image);
        data.append('father_name', e.target.father_name.value);
        data.append('mother_name', e.target.mother_name.value);
        data.append("full_address", e.target.full_address.value);
        data.append('gender', e.target.gender.value);
        data.append('whats_app_number', e.target.student_whats_app_number.value);
        data.append('mailing_address', e.target.mailing_address.value);
        data.append('date_of_birth', eventDate(startDate));
        data.append('school', e.target.school_name.value);
        data.append('class', e.target.class_name.value);
        data.append('medium', e.target.medium.value);
        data.append('religion', e.target.religion.value);



        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}update-profile?user_id=${localStorage.getItem("user_id")}`,{
            method: 'POST',
            headers:{
                    'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                    'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                },
            body:data,
        })
        .then(res => res.json())
        .then(data => {
            if(data.message_type === 'success'){
                toast.success(data.message);
                setModalShow(false);
                
            }else{
                toast.error(data.message);
            }
        })
    } */

    
    /* console.log(courseBasicInfo?.subscription_details?.due_amount, parseInt(courseBasicInfo?.subscription_details?.course_expires_in)) */


    

    return (
        <LMSContext.Provider value={{batch_id,course_id}}>
            <div className="main-content">
                <StudentPageTitle title="LMS"></StudentPageTitle>
                <div className="page-content p-0">
                    <div className="container-fluid">
                        <div className="my-course-section">

                        {   
                            courseBasicInfo?.permission === 0 ?
                            <LockScreen courseBasicInfo={courseBasicInfo}/>
                            :
                                courseBasicInfo?.subscription_details?.due_amount !== 0 && parseInt(courseBasicInfo?.subscription_details?.course_expires_in) <= 0 ? 
                                
                                <LockScreen courseBasicInfo={courseBasicInfo}/>
                                :
                                <>
                                    {
                                        isLoading ? <Skeleton className='primary-info-loader'/> : <LmsPrimarySection courseBasicInfo={courseBasicInfo}/>
                                    }
                                    {
                                        isLoading ? <Skeleton className='primary-nav-loader'/> : 

                                        <ul className='nav nav-pills py-1 ps-1 bg-white mt-4 custom-card-shadow mb-4' style={{ borderRadius:'8px' }}  id="pills-tab" role="tablist">
                                            <li className="nav-item assignment-submission" role="presentation">
                                                <button className= {`nav-link text-dark submission-count ${class_content ? '' : 'active'}`}  id="pills-details-tab" data-bs-toggle="pill" data-bs-target="#pills-details" type="button" role="tab" aria-controls="pills-details" aria-selected="false">{t('Details')}</button>
                                            </li>
                                            
                                            {
                                                courseBasicInfo?.course_type_enum !== 2 ?
                                                <>
                                                    <li className="nav-item assignment-submission" role="presentation">
                                                        <button className= {`nav-link text-dark submission-count ${class_content === 'assignment' && 'active'}`}  id="pills-assignment-tab" data-bs-toggle="pill" data-bs-target="#pills-assignment" type="button" role="tab" aria-controls="pills-assignment" aria-selected="true">{t('Assignment')}</button>
                                                    </li>
                                                    <li className="nav-item assignment-submission" role="presentation">
                                                        <button className= {`nav-link text-dark submission-count ${class_content === 'lecture' && 'active'}`}  id="pills-lecture-tab" data-bs-toggle="pill" data-bs-target="#pills-lecture" type="button" role="tab" aria-controls="pills-lecture" aria-selected="false">{t("Lecture")}</button>
                                                    </li>
                                                    <li className="nav-item assignment-submission" role="presentation">
                                                        <button className= {`nav-link text-dark submission-count ${class_content === 'smart_note' && 'active'}`}  id="pills-smart-note-tab" data-bs-toggle="pill" data-bs-target="#pills-smart-note" type="button" role="tab" aria-controls="pills-smart-note" aria-selected="false">{t('Smart Note')}</button>
                                                    </li>
                                                    <li className="nav-item assignment-submission" role="presentation">
                                                        <button className= "nav-link text-dark submission-count"  id="pills-attendance-tab" data-bs-toggle="pill" data-bs-target="#pills-attendance" type="button" role="tab" aria-controls="pills-attendance" aria-selected="false">{t('Attendance')}</button>
                                                    </li>
                                                    
                                                    <li className="nav-item assignment-submission" role="presentation">
                                                        <button className= "nav-link text-dark submission-count"  id="pills-progress-report-tab" data-bs-toggle="pill" data-bs-target="#pills-progress-report" type="button" role="tab" aria-controls="pills-progress-report" aria-selected="false">{t('Progress Report')}</button>
                                                    </li>
                                                    
                                                    {
                                                        courseBasicInfo?.is_certificate_issued === 1 &&
                                                            <li className="nav-item assignment-submission" role="presentation">
                                                                <button className= "nav-link text-dark submission-count"  id="pills-certificate-tab" data-bs-toggle="pill" data-bs-target="#pills-certificate" type="button" role="tab" aria-controls="pills-certificate" aria-selected="false">{t('Certificate')}</button>
                                                            </li>
                                                    } 
                                                </>
                                                :
                                                <li className="nav-item assignment-submission" role="presentation">
                                                    <button className= {`nav-link text-dark submission-count ${class_content === 'content_list' && 'active'}`}  id="pills-syllabus-tab" data-bs-toggle="pill" data-bs-target="#pills-syllabus" type="button" role="tab" aria-controls="pills-syllabus" aria-selected="false">{t('Class Content')}</button>
                                                </li>
                                            }

                                            {/* <li className="nav-item assignment-submission" role="presentation">
                                                <button className= "nav-link text-dark submission-count"  id="pills-practice-tab" data-bs-toggle="pill" data-bs-target="#pills-practice" type="button" role="tab" aria-controls="pills-practice" aria-selected="false">প্র্যাকটিস</button>
                                            </li> */}
                                            
                                            {/*<li className="nav-item assignment-submission" role="presentation">*/}
                                            {/*    <button className= "nav-link text-dark submission-count"  id="pills-leader-board-tab" data-bs-toggle="pill" data-bs-target="#pills-leader-board" type="button" role="tab" aria-controls="pills-leader-board" aria-selected="false">{t('Leader Board')}</button>*/}
                                            {/*</li>*/}
                                            {/*  <li className="nav-item assignment-submission" role="presentation">
                                                    <button className= "nav-link text-dark submission-count"  id="pills-notice-tab" data-bs-toggle="pill" data-bs-target="#pills-notice" type="button" role="tab" aria-controls="pills-notice" aria-selected="false">নোটিস</button>
                                                </li>
                                                <li className="nav-item assignment-submission" role="presentation">
                                                    <button className= "nav-link text-dark submission-count"  id="pills-attendance-tab" data-bs-toggle="pill" data-bs-target="#pills-attendance" type="button" role="tab" aria-controls="pills-attendance" aria-selected="false">অ্যাটেনড্যান্স</button>
                                                </li> */}
                                        </ul>
                                    }
                                    
                                    
                                    {
                                        isLoading ? <Skeleton className='primary-tab-content-loader'/> : 
                                    
                                        <div className="tab-content" id="pills-tabContent">
                                        <div className={`tab-pane fade show ${class_content ? '' : 'active'}`} id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab">
                                                <Details/>
                                            </div>
                                            <div className={`tab-pane fade show ${class_content === 'content_list' && 'active'}`} id="pills-syllabus" role="tabpanel" aria-labelledby="pills-syllabus-tab">
                                                <Syllabus/>
                                            </div>
                                            <div className={`tab-pane fade show ${class_content === 'assignment' && 'active'}`}  id="pills-assignment" role="tabpanel" aria-labelledby="pills-assignment-tab">
                                                <Assignment/>
                                            </div>
                                            <div className={`tab-pane fade show ${class_content === 'lecture' && 'active'}`} id="pills-lecture" role="tabpanel" aria-labelledby="pills-lecture-tab">
                                                <Lectures/>
                                            </div>
                                            <div className={`tab-pane fade show ${class_content === 'smart_note' && 'active'}`} id="pills-smart-note" role="tabpanel" aria-labelledby="pills-smart-note-tab">
                                                <SmartNotes/>
                                            </div>
                                            <div className="tab-pane fade show" id="pills-practice" role="tabpanel" aria-labelledby="pills-practice-tab">
                                                <PracticeTests/>
                                            </div>
                                            {/*<div className="tab-pane fade show" id="pills-leader-board" role="tabpanel" aria-labelledby="pills-leader-board-tab">*/}
                                            {/*    <LeaderBoard/>*/}
                                            {/*</div>*/}
                                            <div className="tab-pane fade show" id="pills-notice" role="tabpanel" aria-labelledby="pills-notice-tab">
                                                <Notices/>
                                            </div>
                                            <div className="tab-pane fade show" id="pills-attendance" role="tabpanel" aria-labelledby="pills-attendance-tab">
                                                <Attendance/>
                                            </div>
                                            <div className="tab-pane fade show" id="pills-progress-report" role="tabpanel" aria-labelledby="pills-progress-report-tab">
                                                <ProgressReport courseBasicInfo={courseBasicInfo}/>
                                            </div>
                                            {
                                                courseBasicInfo?.is_certificate_issued === 1 &&
                                                    <div className="tab-pane fade show" id="pills-certificate" role="tabpanel" aria-labelledby="pills-certificate-tab">
                                                        <StudentCertificate Name={courseBasicInfo?.user_name} CourseName={courseBasicInfo?.course_name}/>
                                                    </div>
                                        }
                                        </div>
                                    } 
                                </>
                        }
                        </div>

                        {/* Student Profile modal  */}
                            <Modal show={modalShow} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    তোমার কিছু তথ্য
                                </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <p className='mb-2 font-400'>তোমার ছবি<span className='text-danger'>*</span></p>
                                        <div className="avatar-upload">
                                            <div className="avatar-edit">
                                                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"  onChange={handleChange} />
                                                <label htmlFor="imageUpload"></label> 
                                            </div>
                                            <div className="avatar-preview">

                                                {
                                                    file ? 
                                                        <img className="profile-user-img img-responsive" id="imagePreview" src={file} alt="User profile "/>
                                                        : 
                                                        <img className="profile-user-img img-responsive" id="imagePreview" src={noPerson} alt="User profile "/>
                                                    
                                                }
                            
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handelStudentInfo}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p className='mb-2 font-400'>বাবার নাম<span className='text-danger'>*</span></p>
                                            <input className="mb-3 form-control form-control-lg student-profile-info" name="father_name" type="text" placeholder="তোমার বাবার নাম লিখ" />
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='mb-2 font-400'>মায়ের নাম<span className='text-danger'>*</span></p>
                                            <input className="mb-3 form-control form-control-lg student-profile-info" name="mother_name" type="text" placeholder="তোমার মায়ের নাম লিখ " />
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='mb-2 font-400'>জন্ম তারিখ <span className='text-danger'>*</span></p>
                                            <DatePicker showYearDropdown  yearDropdownItemNumber={40} scrollableYearDropdown className="form-control form-control-lg student-profile-info" selected={startDate} onChange={(date) => setStartDate(date)} placeholderText={'তোমার জন্ম তারিখটি দাও'} />
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='mb-2 font-400'>প্রতিষ্ঠানের নাম<span className='text-danger'>*</span></p>
                                            <input className="mb-3 form-control form-control-lg student-profile-info" name="school_name" type="text" placeholder="তোমার বিদ্যালয়ের নাম লিখ" />
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='mb-2 font-400'>বর্তমান ঠিকানা<span className='text-danger'>*</span></p>
                                            <input className="mb-3 form-control form-control-lg student-profile-info" name="full_address" type="text" placeholder="তোমার বর্তমান ঠিকানা লিখ" />
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='mb-2 font-400'>কুরিয়ার ঠিকানা<span className='text-danger'>*</span></p>
                                            <input className="mb-3 form-control form-control-lg student-profile-info" name="mailing_address" type="text" placeholder="তোমার মেইলিং এড্রেস লিখ" />
                                        </div>
                                        <div className="col-lg-6">
                                            <p className='mb-2 font-400'>হোয়াটস্যাপ নাম্বার <span className='text-danger'>*</span></p>
                                            <input className="mb-3 form-control form-control-lg student-profile-info" name="student_whats_app_number" type="text" placeholder="তোমার হোয়াটস্যাপ নাম্বার লিখ" />
                                        </div>

                                        <div className="col-lg-6">
                                            <p className='mb-2 font-400'>ধর্ম<span className='text-danger'>*</span></p>
                                            <select name="religion" className="form-select form-control form-control-lg student-profile-info" id="select_box" >
                                                <option value="">তোমার ধর্ম বাছাই করো</option>
                                                <option value="Muslim">ইসলাম ধর্মাবলম্বী</option>
                                                <option value="Hindu">হিন্দু ধর্মাবলম্বী</option>
                                                <option value="Cristian">ক্রিস্টিয়ান ধর্মাবলম্বী</option>
                                                <option value="Buddhist">বৌদ্ধ ধর্মাবলম্বী</option>
                                                <option value="Other" >অন্যান্য</option> 
                                            </select>
                                        </div>
                                        
                                        

                                        <div className="col-lg-6">
                                            <p className='mb-2 font-400'>মিডিয়াম<span className='text-danger'>*</span></p>
                                            <div className='d-flex align-items-center'>
                                                <div className="radio">
                                                    <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                                        <input type="radio" name="medium" id="bangla" value="Bangla" defaultChecked ={studentInfo?.student_detail?.get_student_detail?.medium && studentInfo?.student_detail?.get_student_detail?.medium === 'Bangla'} />
                                                        &nbsp;&nbsp;Bangla
                                                    </label><br />
                                                    <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                                        <input type="radio" name="medium" id="english" value="English" defaultChecked ={studentInfo?.student_detail?.get_student_detail?.medium && studentInfo?.student_detail?.get_student_detail?.medium === 'English'} />
                                                        &nbsp;&nbsp;English
                                                    </label><br />
                                                    <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                                        <input type="radio" name="medium" id="madrasa" value="Madrasa" defaultChecked ={studentInfo?.student_detail?.get_student_detail?.medium && studentInfo?.student_detail?.get_student_detail?.medium === 'Madrasa'} />
                                                        &nbsp;&nbsp;Madrasa
                                                    </label>
                                                </div>
                                            </div> 
                                        </div>

                                        <div className="col-lg-6">
                                            <p className='mb-2 font-400'>লিঙ্গ<span className='text-danger'>*</span></p>
                                            <div className='d-flex align-items-center'>

                                                <div className="radio">
                                                    <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                                        <input type="radio" name="gender" id="boy" value="Male" defaultChecked ={studentInfo?.student_detail?.get_student_detail?.gender && studentInfo?.student_detail?.get_student_detail?.gender === 'Male'} />
                                                        &nbsp;&nbsp;Male
                                                    </label><br />
                                                    <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                                        <input type="radio" name="gender" id="girl" value="Female" defaultChecked ={studentInfo?.student_detail?.get_student_detail?.gender && studentInfo?.student_detail?.get_student_detail?.gender === 'Female'} />
                                                        &nbsp;&nbsp;Female
                                                    </label><br />
                                                    <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                                        <input type="radio" name="gender" id="other" value="Other" defaultChecked ={studentInfo?.student_detail?.get_student_detail?.gender && studentInfo?.student_detail?.get_student_detail?.gender === 'Other'} />
                                                        &nbsp;&nbsp;Other
                                                    </label>
                                                </div>
                                            </div> 
                                        </div>

                                        <div className="col-lg-12 mt-3">
                                            <p className='mb-2 font-400'>ক্লাস <span className='text-danger'>*</span></p>
                                            <select name="class_name" className="form-select form-control form-control-lg student-profile-info" id="select_box">
                                                <option value="">তোমার ক্লাস বাছাই করো</option>
                                                {
                                                    studentInfo?.class_lists.map(class_list => <option key={class_list.class_id}   value={class_list.class_name}>{class_list.class_name}</option>)
                                                }
                                            </select>
                                        </div>

                                        
                                    </div>


                                    <div className=" d-flex justify-content-end">
                                    <input className="btn text-white mt-4 py-2" style={{ backgroundColor:'#532D80', fontSize:'22' }} type="submit" value='সংরক্ষণ করুন'></input>
                                    </div>
                                    </form>
                                
                                </Modal.Body>
                                
                            </Modal>
                    </div>
                </div>
            </div>
        </LMSContext.Provider>

        
    );
};

export default LMS;