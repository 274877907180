import React,{ useEffect, useState, useContext }from 'react';
import { englishToBengaliNumberFormat } from '../../../../../utilities/utilities';


import allAssignment from '../../../../../images/all-assignment.png';
import bookIcon from '../../../../../images/icon_book.webp';
import { LMSContext } from '../LMS';
import SmartNote from './SmartNote/SmartNote';

const SmartNotes = () => {
    const[smartNotes, setSmartNotes] = useState([]);
    const {batch_id, course_id} = useContext(LMSContext);
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}smart-notes?course_id=${course_id}&batch_id=${batch_id}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            .then((res) => res.json())
            .then((data) => setSmartNotes(data.data));
    },[course_id, batch_id]);

      const [imageLoadedMap, setImageLoadedMap] = useState({});
    const handleImageLoad = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: true
        }));
    };

    const handleImageError = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: false
        }));
    };
    
    return (
        <div className="row gx-5 mx-0">
            <div className=' col-lg-3 lms-subject-section'>
                <div className="nav flex-column nav-pills assignment-subject p-2 bg-white custom-card-shadow mb-4" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ borderRadius:'16px' }}>
                    <button className="nav-link text-dark active" id="v-pills-home-smart-note-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home-smart-note" type="button" role="tab" aria-controls="v-pills-home-smart-note" aria-selected="true">
                    <img src={allAssignment} alt="" width={48} height={48} className='me-2' style={{ borderRadius: '15px' }}/>
                    সকল বিষয়
                    </button>
                    {
                        smartNotes?.course_subjects?.map((course_subject, index) => 
                            <button key={course_subject?.course_subject_id} className="nav-link text-dark mt-2" id={`v-pills-smart-note-subject_${course_subject?.course_subject_id}-tab`} data-bs-toggle="pill" data-bs-target={`#v-pills-smart-note-subject_${course_subject?.course_subject_id}`} type="button" role="tab" aria-controls={`v-pills-smart-note-subject_${course_subject?.course_subject_id}`} aria-selected="false">
                               {/*  <img src={`${smartNotes?.subject_image_path}${course_subject?.subject_image}`} alt="" width={48} height={48} className='me-2' style={{ borderRadius: '8px' }}/> */}
                               {imageLoadedMap[index] !== false && (
                                <img
                                    src={`${smartNotes?.subject_image_path}${course_subject?.subject_image}`}
                                    width={48} height={48} alt=""
                                    className="px-1"
                                    onLoad={() => handleImageLoad(index)}
                                    onError={() => handleImageError(index)}
                                />
                                )}
                                {imageLoadedMap[index] === false && <img
                                    src={bookIcon}
                                    width={48} height={48} alt=""
                                />}
                                {course_subject?.subject_name}
                            </button>

                        )
                    }
                    
                </div>
            </div>
            <div className="col-lg-9 px-0 mx-0 tab-content bg-white custom-card-shadow all-assignment-section" id="v-pills-tabContent">
        {/* <div className='bg-white custom-card-shadow all-assignment-section'> */}
                <div className="tab-pane fade show active" id="v-pills-home-smart-note" role="tabpanel" aria-labelledby="v-pills-home-smart-note-tab">
                    
                        <p className='course-total-assignment mb-0'><span className='balooda-medium'>{englishToBengaliNumberFormat(smartNotes?.smart_notes?.length)}</span> টি স্মার্ট নোট</p>
                        <div className='total-assignment-divider'></div>

                            <div className='row mt-4 p-3 g-5 mx-0'>
                            {   smartNotes?.length === 0 ? <p className="d-flex justify-content-center mb-0 w-100" style={{ fontSize: "22px", fontWeight: "600" }}>
                                তোমাকে এখনো কোন স্মার্ট নোট দেওয়া হয়নি </p> :
                                smartNotes?.smart_notes?.map(smartNote => <SmartNote key={smartNote.id} smartNote={smartNote} smartNoteLink={smartNotes?.smart_note_pdf_link}/> )
                            }
                        </div>
                        <p className='text-center mb-4' style={{ color: '#BDBCBE' }}>আপনি তালিকার শেষে পৌঁছে গিয়েছেন</p>
                    
                </div>
                
                {
                    smartNotes?.course_subjects?.map(course_subject => {
                        return(
                            <div key={course_subject?.course_subject_id} className="tab-pane fade" id={`v-pills-smart-note-subject_${course_subject?.course_subject_id}`} role="tabpanel" aria-labelledby={`v-pills-smart-note-subject_${course_subject?.course_subject_id}-tab`}>
                            <p className='course-total-assignment mb-0'>{course_subject?.subject_name} স্মার্ট নোট</p>
                            <div className='total-assignment-divider'></div>
                            <div className='row mt-4 p-3 g-5 mx-0'>
                            {
                                smartNotes?.smart_notes?.map(smartNote => {
                                    if(course_subject?.course_subject_id === smartNote?.course_subject_id) {
                                        return <SmartNote key={smartNote.id} smartNote={smartNote} smartNoteLink={smartNotes?.smart_note_pdf_link}/>
                                      }
                                    return null;
                                } )
                            }
                            </div>
                            <p className='text-center mb-4' style={{ color: '#BDBCBE' }}>আপনি তালিকার শেষে পৌঁছে গিয়েছেন</p>
                        </div>
                        )
                    }
                        
                    )
                }
            
            </div> 
        </div> 
   
        
    );
};

export default SmartNotes;