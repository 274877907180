import React from 'react';

const Banner = () => {
    return (
        <div className='review_background' style={{ marginBottom:"5rem" }}>
            <div className='container'>
                <div  style={{ padding:"6rem 0" }}>
                    <h1 className="banner-title text-center">সাধারণ জিজ্ঞাসা</h1>
                </div>
            </div>
        </div> 
    );
};

export default Banner;