import React from 'react';
import { Link } from 'react-router-dom';
import joinIssue from '../../../../images/join-issue.png';

const CanNotJoinClass = () => {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ height:'90vh' }}>
            <div>
                <div className='d-flex justify-content-center'>
                    <img src={joinIssue} alt=""/>
                </div>
                <p className='mb-0 text-center unlock-screen-title' style={{ fontSize:'32px' }}>Already logged in to separate device!</p>
                <p className='mb-0 unlock-screen-details w-75 text-center' style={{ margin: '0 auto' }}>You cannot login with same credentials in separate device. Please contact with Mojaru and do not share your account details with unknown person.</p>
                
                <div className='d-flex justify-content-center'>
                    <Link to='/dashboard' className='btn-assignment-start text-center mt-3'>Go to Dashboard</Link>
                    
                </div>
            </div>
        </div>
    );
};

export default CanNotJoinClass;