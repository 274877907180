import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import PaymentMethod from "./PaymentMethod";
import {useStoreCoupon} from "../../../../../../store/promoCodeStore";
import {toast} from "react-toastify";

const PaymentDetails = () => {
    const [bikashPyment, setBikashPyment] = useState(true);
    const [nogodPayment, setNogodPayment] = useState(false);
    const [creditCardPayment, setCreditCardPayment] = useState(false);

    const navigate = useNavigate();

    const courseInformation = JSON.parse(localStorage.getItem("course_info"));
    const selectedBatch = JSON.parse(localStorage.getItem("selected_batch"));
    const selectedPayment = JSON.parse(localStorage.getItem("selected_payment"));
    const payment = JSON.parse(localStorage.getItem("payment"))
    const couponInfo = JSON.parse(localStorage.getItem("couponInfo"));

    const courseFullImage = courseInformation?.basePath + courseInformation?.courseImagePath;
    const courseName = courseInformation?.courseNames;
    const totalLiveClass = courseInformation?.courseClasses;
    const batchName = selectedBatch.batch_name;

    const couponCode = couponInfo?.couponCode;
    const couponId = couponInfo.couponId;
    const discountPrice = couponInfo.discountPrice;
    const referenceCode = couponInfo.referenceCode;

    const finalPrice = selectedPayment?.course_final_price;
    const totalPrice = finalPrice - discountPrice;


    let max_course_price = parseInt(payment?.course_payment_segments[0]?.course_original_price);
    for (let i = 1; i < payment?.course_payment_segments?.length; ++i) {
        let averageMarksPercentage = parseInt(payment?.course_payment_segments[i]?.course_original_price);
        if (averageMarksPercentage > max_course_price) {
            max_course_price = averageMarksPercentage;
        }
    }

    const data = {
        transaction_amount: totalPrice,
        user_id: localStorage.getItem("user_id"),
        user_name: localStorage.getItem("user_name"),
        user_mobile_number: localStorage.getItem("mobile_or_email"),
        user_address: '374, Free School Street Hatirpool, Kalabagan, Dhaka-1205',
        course_id: selectedPayment?.course_id,
        course_name: payment?.courseName?.course_name,
        batch_id: selectedBatch?.id,
        batch_name: batchName,
        book_id: 1,
        book_title: 'testBook',
        // balance: englishToBengaliNumberFormat(payment?.wallet_balance?.balance),
        balance: 0,
        batch_start_date: selectedBatch?.batch_duration_start_date,
        batch_total_duration: selectedBatch?.batch_total_duration_in_days,
        admission_period: selectedPayment?.payment_segment_duration_in_days,
        course_original_price: selectedPayment?.course_original_price,
        course_total_price: max_course_price,
        transaction_type: "buy_course",
        coupon_id: couponId,
        reference_code: referenceCode,
        coupon_code: couponCode,
        affiliate_code: ""
    }

    // const handlePayment = () => {
    //     if (bikashPyment) {
    //         fetch(`${process.env.REACT_APP_BASE_URL}student/initialize-bkash-payment-via-web`, {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'accept': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
    //                 'SecretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`
    //             },
    //             body: JSON.stringify(data)
    //         })
    //             .then(res => res.json())
    //             .then(data => {
    //                 console.log(data);
    //                 if (data.message_type === "success") {
    //                     const url = data?.data;
    //                     window.location.href = url;
    //                 }
    //             })
    //
    //     }
    //
    //     if (nogodPayment) {
    //         fetch(`${process.env.REACT_APP_BASE_URL}student/nagad-payment`, {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'accept': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
    //                 'SecretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`
    //             },
    //             body: JSON.stringify(data)
    //         })
    //             .then(res => res.json())
    //             .then(data => {
    //                 console.log(data)
    //
    //             })
    //     }
    //
    //     if (creditCardPayment) {
    //         fetch(`${process.env.REACT_APP_BASE_URL}api/pay-via-ssl`, {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'accept': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
    //                 'SecretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`
    //             },
    //             body: JSON.stringify(data)
    //         })
    //             .then(res => res.json())
    //             .then(data => {
    //                 console.log(data.data);
    //
    //             })
    //     }
    // }

    const handleFreeSubscription = () => {
        const freeSunscription = {
            course_id: payment?.course_id,
            batch_id: selectedBatch?.id,
            user_id: localStorage.getItem("user_id"),
            batch_start_date: selectedBatch?.batch_duration_start_date,
            batch_end_date: selectedBatch?.batch_duration_end_date,
            batch_total_duration: selectedBatch?.batch_total_duration_in_days,
            type: "free",
        };
        fetch(`${process.env.REACT_APP_BASE_URL}api/free-course-subscription`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(freeSunscription)
        })
            .then(res => res.json())
            .then(data => {
                if (data.message_type === 'success') {
                    toast.success(data.message);
                    navigate("/my-course");
                } else {
                    toast.error(data.message);
                }
            })

    };



    return (
        <>
            <div className="md:tw-flex tw-justify-between tw-px-12 tw-mt-10">
                <div className="md:tw-w-[58%] tw-mr-[2%] tw-w-full">
                    <p className="tw-text-lg tw-font-bold tw-mt-3">কোর্স অর্ডার </p>
                    <div className="tw-border tw-rounded-2xl">
                        <div className="md:tw-flex tw-px-8 tw-pt-8">
                            <img className="tw-max-w-[224px] tw-max-h-[128px] tw-rounded-lg tw-mr-8"
                                 src={courseFullImage}
                                 alt="image"/>
                            <div className="">
                        <span
                            className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-yellow-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-yellow-800 tw-ring-1 tw-ring-inset tw-ring-yellow-600/20">
                          {batchName}
                        </span>
                                <p className="tw-mt-2">{courseName}</p>
                                <div className="tw-flex tw-mt-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                         fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9.5547 7.16795C9.24784 6.96338 8.8533 6.94431 8.52814 7.11833C8.20298 7.29235 8 7.63121 8 8V12C8 12.3688 8.20298 12.7077 8.52814 12.8817C8.8533 13.0557 9.24784 13.0366 9.5547 12.8321L12.5547 10.8321C12.8329 10.6466 13 10.3344 13 10C13 9.66565 12.8329 9.35342 12.5547 9.16795L9.5547 7.16795Z"
                                              fill="#9CA3AF"/>
                                    </svg>
                                    <p className="tw-ml-2">{totalLiveClass} টি লাইভ ক্লাস </p>
                                </div>
                                <div className="tw-flex tw-mt-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                         fill="none">
                                        <path
                                            d="M2 11C2 10.4477 2.44772 10 3 10H5C5.55228 10 6 10.4477 6 11V16C6 16.5523 5.55228 17 5 17H3C2.44772 17 2 16.5523 2 16V11Z"
                                            fill="#9CA3AF"/>
                                        <path
                                            d="M8 7C8 6.44772 8.44772 6 9 6H11C11.5523 6 12 6.44772 12 7V16C12 16.5523 11.5523 17 11 17H9C8.44772 17 8 16.5523 8 16V7Z"
                                            fill="#9CA3AF"/>
                                        <path
                                            d="M14 4C14 3.44772 14.4477 3 15 3H17C17.5523 3 18 3.44772 18 4V16C18 16.5523 17.5523 17 17 17H15C14.4477 17 14 16.5523 14 16V4Z"
                                            fill="#9CA3AF"/>
                                    </svg>
                                    <p className="tw-ml-2">৪টি লেভেল</p>
                                </div>
                            </div>
                        </div>
                        {
                            selectedPayment?.course_final_price ? <div>
                                <div className="tw-flex tw-justify-between tw-mt-8 tw-px-8">
                                    <div>
                                        <p>কোর্সের ফি </p>
                                        <div className="tw-flex">
                                            <p className="tw-mt-4">ডিসকাউন্ট</p>
                                            <div className="tw-flex tw-mt-4 tw-items-center">
                                                <svg className="tw-ml-2" xmlns="http://www.w3.org/2000/svg" width="16"
                                                     height="17"
                                                     viewBox="0 0 16 17"
                                                     fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M0 8.14844C0 3.72844 3.58 0.148438 8 0.148438C12.4133 0.148438 16 3.7351 16 8.14844C16 12.5618 12.42 16.1484 8 16.1484C3.58 16.1484 0 12.5684 0 8.14844ZM1.33333 8.14844C1.33333 11.8218 4.32 14.8151 8 14.8151C11.6733 14.8151 14.6667 11.8284 14.6667 8.14844C14.6667 4.46844 11.68 1.48177 8 1.48177C4.32 1.48177 1.33333 4.4751 1.33333 8.14844ZM4 8.14844C4 7.78177 4.29333 7.48177 4.66667 7.48177H11.3333C11.7 7.48177 12 7.78177 12 8.14844C12 8.51511 11.7 8.81511 11.3333 8.81511H4.66667C4.29333 8.81511 4 8.51511 4 8.14844Z"
                                                          fill="#EF4444"/>
                                                </svg>
                                                <p className="tw-ml-2 tw-font-bold tw-text-[#374151]">{couponCode}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p>৳ {finalPrice}</p>
                                        <p className="tw-text-[#EF4444] tw-mt-4">-৳ {discountPrice}</p>
                                    </div>
                                </div>
                                <div className="tw-relative tw-mt-6">
                                    <div className="tw-absolute tw-inset-0 tw-flex tw-items-center" aria-hidden="true">
                                        <div className="tw-w-full tw-border-t twborder-gray-300"/>
                                    </div>
                                </div>
                                <div
                                    className="tw-flex tw-justify-between tw-px-6 tw-py-4 tw-bg-gray-100 tw-rounded-bl-2xl tw-rounded-br-2xl">
                                    <p>সর্বমোট (ভ্যাট সহ)</p>
                                    <p>৳ {totalPrice}</p>
                                </div>

                            </div> : ""
                        }

                    </div>


                </div>

                <div className="md:tw-w-[38%] tw-w-full">
                    {
                        selectedPayment?.course_final_price && <div>
                            <div className="md:tw-flex tw-justify-between tw-items-center">
                                <p className="tw-text-lg tw-font-bold tw-mt-3 tw-mb-2">পেমেন্ট মেথড সিলেক্ট করো</p>
                                <div className="tw-flex tw-items-center tw-bg-green-50 tw-px-2 tw-py-2 tw-rounded-2xl tw-mb-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                         fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M3.9999 7.2001V5.6001C3.9999 3.39096 5.79076 1.6001 7.9999 1.6001C10.209 1.6001 11.9999 3.39096 11.9999 5.6001V7.2001C12.8836 7.2001 13.5999 7.91644 13.5999 8.8001V12.8001C13.5999 13.6838 12.8836 14.4001 11.9999 14.4001H3.9999C3.11625 14.4001 2.3999 13.6838 2.3999 12.8001V8.8001C2.3999 7.91644 3.11625 7.2001 3.9999 7.2001ZM10.3999 5.6001V7.2001H5.5999V5.6001C5.5999 4.27461 6.67442 3.2001 7.9999 3.2001C9.32539 3.2001 10.3999 4.27461 10.3999 5.6001Z"
                                              fill="#22C55E"/>
                                    </svg>
                                    <p className="tw-text-[#15803D] tw-ml-2">সম্পূর্ণ নিরাপদ পেমেন্ট</p>
                                </div>
                            </div>
                            <PaymentMethod setBikashPyment={setBikashPyment} setNogodPayment={setNogodPayment}
                                           setCreditCardPayment={setCreditCardPayment}/>
                            <div className="tw-mt-6">
                                <fieldset>
                                    <div className="tw-space-y-5">
                                        <div className="tw-relative tw-flex tw-items-start">
                                            <div className="tw-flex tw-h-6 tw-items-center">
                                                <input
                                                    id="comments"
                                                    aria-describedby="comments-description"
                                                    name="comments"
                                                    type="checkbox"
                                                    className="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:tw-ring-indigo-600"
                                                />
                                            </div>
                                            <div className="tw-ml-3 tw-text-sm tw-leading-6">
                                                <span id="comments-description" className="tw-text-gray-500">
                                                    আমি মজারু প্ল্যাটফর্ম ব্যবহারের শর্তাবলি ও গোপনীয়তা নীতির ব্যাপারে সম্মতি দিচ্ছি
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    }

                    {
                        selectedPayment?.course_final_price ? <div>
                                {
                                    bikashPyment && <form
                                        action={`${process.env.REACT_APP_BASE_URL}student/initialize-bkash-payment-via-web`}
                                        method='post'>
                                        <input type="hidden" value={data?.transaction_amount} name="transaction_amount"/>
                                        <input type="hidden" value={data?.user_id} name="user_id"/>
                                        <input type="hidden" value={data?.user_name} name="user_name"/>
                                        <input type="hidden" value={data?.user_mobile_number} name="user_mobile_number"/>
                                        <input type="hidden" value={data?.user_address} name="user_address"/>
                                        <input type="hidden" value={data?.course_id} name="course_id"/>
                                        <input type="hidden" value={data?.course_name} name="course_name"/>
                                        <input type="hidden" value={data?.batch_id} name="batch_id"/>
                                        <input type="hidden" value={data?.batch_name} name="batch_name"/>
                                        <input type="hidden" value={data?.batch_start_date} name="batch_start_date"/>
                                        <input type="hidden" value={data?.batch_total_duration} name="batch_total_duration"/>
                                        <input type="hidden" value={data?.course_total_price} name="course_total_price"/>
                                        <input type="hidden" value={data?.admission_period} name="admission_period"/>
                                        <input type="hidden" value={data?.course_original_price} name="course_original_price"/>
                                        <input type="hidden" value={data?.course_total_price} name="course_total_price"/>
                                        <input type="hidden" value={data?.coupon_id} name="coupon_id"/>
                                        <input type="hidden" value={data?.reference_code} name="reference_code"/>
                                        <input type="hidden" value={data?.coupon_code} name="coupon_code"/>
                                        <input type="hidden" value={data?.affiliate_code} name="affiliate_code"/>
                                        <input type="hidden" name="book_id"/>
                                        <input type="hidden" name="book_title"/>
                                        <input type="hidden" value="buy_course" name="transaction_type"/>
                                        <button type='submit' className="tw-bg-[#6F39B7] tw-flex tw-w-full tw-justify-center tw-px-4 tw-py-3 tw-mt-6 tw-mb-24 tw-rounded-3xl">
                                            <p className="tw-text-white tw-mr-2">পেমেন্ট করো</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24"
                                                 fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M9.29324 16.707C9.68424 17.098 10.3162 17.098 10.7072 16.707L14.7072 12.707C15.0982 12.316 15.0982 11.684 14.7072 11.293L10.7072 7.29299C10.5122 7.09799 10.2562 6.99999 10.0002 6.99999C9.74424 6.99999 9.48824 7.09799 9.29324 7.29299C8.90224 7.68399 8.90224 8.31599 9.29324 8.70699L12.5862 12L9.29324 15.293C8.90224 15.684 8.90224 16.316 9.29324 16.707Z"
                                                      fill="white"/>
                                            </svg>
                                        </button>
                                    </form>
                                }
                                {
                                    nogodPayment &&
                                    <form action={`${process.env.REACT_APP_BASE_URL}student/nagad-payment`}
                                          method='get'>
                                        <input type="hidden" value={data?.transaction_amount}
                                               name="transaction_amount"/>
                                        <input type="hidden" value={data?.user_id} name="user_id"/>
                                        <input type="hidden" value={data?.user_name} name="user_name"/>
                                        <input type="hidden" value={data?.user_mobile_number}
                                               name="user_mobile_number"/>
                                        <input type="hidden" value={data?.user_address} name="user_address"/>
                                        <input type="hidden" value={data?.course_id} name="course_id"/>
                                        <input type="hidden" value={data?.course_name} name="course_name"/>
                                        <input type="hidden" value={data?.batch_id} name="batch_id"/>
                                        <input type="hidden" value={data?.batch_name} name="batch_name"/>
                                        <input type="hidden" value={data?.batch_start_date} name="batch_start_date"/>
                                        <input type="hidden" value={data?.batch_total_duration}
                                               name="batch_total_duration"/>
                                        <input type="hidden" value={data?.course_total_price}
                                               name="course_total_price"/>
                                        <input type="hidden" value={data?.admission_period} name="admission_period"/>
                                        <input type="hidden" value={data?.course_original_price}
                                               name="course_original_price"/>
                                        <input type="hidden" value={data?.course_total_price}
                                               name="course_total_price"/>
                                        <input type="hidden" value={data?.coupon_id} name="coupon_id"/>
                                        <input type="hidden" value={data?.reference_code} name="reference_code"/>
                                        <input type="hidden" value={data?.coupon_code} name="coupon_code"/>
                                        <input type="hidden" value={data?.affiliate_code} name="affiliate_code"/>
                                        <input type="hidden" name="book_ids"/>
                                        <input type="hidden" name="book_title"/>
                                        <input type="hidden" value="buy_course" name="transaction_type"/>

                                        <button type='submit'
                                                className="tw-bg-[#6F39B7] tw-flex tw-w-full tw-justify-center tw-px-4 tw-py-3 tw-mt-6 tw-rounded-3xl">
                                            <p className="tw-text-white tw-mr-2">পেমেন্ট করো</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24"
                                                 fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M9.29324 16.707C9.68424 17.098 10.3162 17.098 10.7072 16.707L14.7072 12.707C15.0982 12.316 15.0982 11.684 14.7072 11.293L10.7072 7.29299C10.5122 7.09799 10.2562 6.99999 10.0002 6.99999C9.74424 6.99999 9.48824 7.09799 9.29324 7.29299C8.90224 7.68399 8.90224 8.31599 9.29324 8.70699L12.5862 12L9.29324 15.293C8.90224 15.684 8.90224 16.316 9.29324 16.707Z"
                                                      fill="white"/>
                                            </svg>
                                        </button>
                                    </form>
                                }
                                {
                                    creditCardPayment &&
                                    <form action={`${process.env.REACT_APP_BASE_URL}api/pay-via-ssl`} method='post'>
                                        <input type="hidden" value={data?.transaction_amount}
                                               name="transaction_amount"/>
                                        <input type="hidden" value={data?.user_id} name="user_id"/>
                                        <input type="hidden" value={data?.user_name} name="user_name"/>
                                        <input type="hidden" value={data?.user_mobile_number}
                                               name="user_mobile_number"/>
                                        <input type="hidden" value={data?.user_address} name="user_address"/>
                                        <input type="hidden" value={data?.course_id} name="course_id"/>
                                        <input type="hidden" value={data?.course_name} name="course_name"/>
                                        <input type="hidden" value={data?.batch_id} name="batch_id"/>
                                        <input type="hidden" value={data?.batch_name} name="batch_name"/>
                                        <input type="hidden" value={data?.batch_start_date} name="batch_start_date"/>
                                        <input type="hidden" value={data?.batch_total_duration}
                                               name="batch_total_duration"/>
                                        <input type="hidden" value={data?.course_total_price}
                                               name="course_total_price"/>
                                        <input type="hidden" value={data?.admission_period} name="admission_period"/>
                                        <input type="hidden" value={data?.course_original_price}
                                               name="course_original_price"/>
                                        <input type="hidden" value={data?.course_total_price}
                                               name="course_total_price"/>
                                        <input type="hidden" value={data?.coupon_id} name="coupon_id"/>
                                        <input type="hidden" value={data?.reference_code} name="reference_code"/>
                                        <input type="hidden" value={data?.coupon_code} name="coupon_code"/>
                                        <input type="hidden" value={data?.affiliate_code} name="affiliate_code"/>
                                        <input type="hidden" name="book_id"/>
                                        <input type="hidden" name="book_title"/>
                                        <input type="hidden" value="buy_course" name="transaction_type"/>

                                        <button type='submit'
                                                className="tw-bg-[#6F39B7] tw-flex tw-w-full tw-justify-center tw-px-4 tw-py-3 tw-mt-6 tw-rounded-3xl">
                                            <p className="tw-text-white tw-mr-2">পেমেন্ট করো</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24"
                                                 fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M9.29324 16.707C9.68424 17.098 10.3162 17.098 10.7072 16.707L14.7072 12.707C15.0982 12.316 15.0982 11.684 14.7072 11.293L10.7072 7.29299C10.5122 7.09799 10.2562 6.99999 10.0002 6.99999C9.74424 6.99999 9.48824 7.09799 9.29324 7.29299C8.90224 7.68399 8.90224 8.31599 9.29324 8.70699L12.5862 12L9.29324 15.293C8.90224 15.684 8.90224 16.316 9.29324 16.707Z"
                                                      fill="white"/>
                                            </svg>
                                        </button>
                                    </form>
                                }
                            </div>
                            : <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-10">
                                <button
                                    onClick={handleFreeSubscription}
                                    className="tw-bg-[#6F39B7] tw-flex tw-w-full tw-justify-center tw-px-4 tw-py-3 tw-mt-6 tw-rounded-3xl tw-">

                                    <p className="tw-text-white tw-mr-2">কোর্সে ভর্তি হও</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M9.29324 16.707C9.68424 17.098 10.3162 17.098 10.7072 16.707L14.7072 12.707C15.0982 12.316 15.0982 11.684 14.7072 11.293L10.7072 7.29299C10.5122 7.09799 10.2562 6.99999 10.0002 6.99999C9.74424 6.99999 9.48824 7.09799 9.29324 7.29299C8.90224 7.68399 8.90224 8.31599 9.29324 8.70699L12.5862 12L9.29324 15.293C8.90224 15.684 8.90224 16.316 9.29324 16.707Z"
                                              fill="white"/>
                                    </svg>
                                </button>
                            </div>
                    }
                </div>
            </div>
        </>
    );
};

export default PaymentDetails;