import React, { useEffect, useState } from 'react';
import Loader from '../../../shared/Loader/Loader';
import PageTitle from "../../../shared/PageTitle/PageTitle";
import Banner from "./Banner/Banner";
import Questions from "./Questions/Question";

const Faq = () => {

    const[faqs, setFaqs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    
    useEffect(() => {
        async function fetchNotice() {
            let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/faqs`);
            
                data = await data.json();
                setFaqs(data.data);
                setIsLoading(false);
            
        }
        fetchNotice();
    },);

    if(isLoading){
        return ( <Loader/> );
    }

    return (
        <div>
        <PageTitle title="Faq"></PageTitle>
        
            <div style={{ paddingBottom: "22rem" }}>
                <Banner></Banner>
                <Questions faqs={faqs}></Questions>
            </div>

            {/* <Message></Message>
            <Footer></Footer> */}
        </div>
    );
};

export default Faq;