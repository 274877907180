import React from 'react';
import { dateConverterEnglishWithOutYear, topicTypeName } from '../../../../../../../../../utilities/utilities';

const LectureModal = ({syllabusContent}) => {
   /*  let classDuration = Math.abs(new Date(syllabusContent?.live_class?.live_class_end_time) - new Date(syllabusContent?.live_class?.live_class_start_time));
    let convertInHours = classDuration / 3600000; */
    return (
        <div className='modal-assessment-details'>
            <table className="table table-borderless mb-0">
                <tbody className='modal-assessment-details-text'>
                <tr className="">
                        <td className="assessment-details">বিষয়</td>
                        <td className="assessment-details">{topicTypeName(syllabusContent?.assesment_type).name}</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>
                    <tr className="">
                        <td className="assessment-details">Teacher Name</td>
                        <td className="assessment-details">{syllabusContent?.lecture?.teacher_name}</td>
                    </tr>
                    <tr><td className="p-0" colSpan={2}><div className='divider-border'></div></td></tr>  
                    <tr className="">
                        <td className="assessment-details">Lecture Published Date</td>
                        <td className="assessment-details">{dateConverterEnglishWithOutYear(new Date(syllabusContent?.lecture?.lecture_published_date))}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    );
};

export default LectureModal;