import React from "react";
import "./Details.css";
import unionIcon from "../../../../../images/book-half.png";
import subtractIcon from "../../../../../images/Subtract.png";
/* import rightArrow from "../../../../../images/right-arrow.png"; */
import mentor from "../../../../../images/mentor.png";
import bookIcon from "../../../../../images/icon_book.webp";
import defaultVideoImage from "../../../../../images/default_video_banner.jpg";
import { useState, useEffect, useContext } from "react";
import { LMSContext } from "../LMS";
import parse from 'html-react-parser';
import { englishToBengaliNumberFormat } from "../../../../../utilities/utilities";


const Details = () => {
    const [courseInfos, setCourseInfos] = useState([]);
    const { batch_id, course_id } = useContext(LMSContext);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}course-infos?course_id=${course_id}&batch_id=${batch_id}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            .then((res) => res.json())
            .then((data) => setCourseInfos(data.data));
    }, [course_id, batch_id]);

    let subjectCount = courseInfos?.course_detail?.subjects?.length;
    let teacherCount = courseInfos?.course_detail?.teachers?.length;


      const [imageLoadedMap, setImageLoadedMap] = useState({});
    const handleImageLoad = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: true
        }));
    };

    const handleImageError = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: false
        }));
    };

    return (
        <div className="bg-white custom-card-shadow all-assignment-section p-4">
            <div className="row mx-0">
                <div className="col-lg-3 ps-0">
                    {/* subject section */}
                    <div className="details-subject-section">
                        <div className="d-flex align-items-center mb-3">
                            <img
                                src={unionIcon}
                                alt=""
                                width={24}
                                height={24}
                            />
                            <p className="submission-view-top ms-2 mb-0">
                                কোর্সের বিষয়
                            </p>
                        </div>
                        {courseInfos?.course_detail?.subjects?.map(
                            (subject, index) => (
                                <div key={index}>
                                    <div className="d-flex align-items-center w-100">

                                        {imageLoadedMap[index] !== false && (
                                            <img
                                                src={`${courseInfos?.subject_image_path}${subject?.subject_image}`}
                                                width={64} height={64} alt=""
                                                className="px-1"
                                                onLoad={() => handleImageLoad(index)}
                                                onError={() => handleImageError(index)}
                                            />
                                            )}
                                            {imageLoadedMap[index] === false && <img
                                                src={bookIcon}
                                                width={64} height={64} alt=""
                                            />}


                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div>
                                                <p className="result-exam-name mb-0">
                                                    {subject?.subject_name}
                                                </p>
                                                <p className="result-subject-name mb-0">
                                                    {englishToBengaliNumberFormat(
                                                        subject?.lesson_count
                                                    )}
                                                    টি অধ্যায়
                                                </p>
                                            </div>
                                            <div>
                                                {/* <img src={rightArrow} alt="" /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="divider my-3"
                                        style={
                                            subjectCount - 1 === index
                                                ? {
                                                      display: "none",
                                                      backgroundColor:
                                                          "#E9E9E9",
                                                  }
                                                : {
                                                      display: "block",
                                                      backgroundColor:
                                                          "#E9E9E9",
                                                  }
                                        }
                                    ></div>
                                </div>
                            )
                        )}
                    </div>

                    {/* teacher section */}
                    <div className="details-subject-section mt-4">
                        <div className="d-flex align-items-center mb-3">
                            <img src={mentor} alt="" width={24} height={24} />
                            <p className="submission-view-top ms-2 mb-0">
                                মেন্টর
                            </p>
                        </div>

                        {courseInfos?.course_detail?.teachers?.map(
                            (teacher, index) => (
                                <div key={index}>
                                    <div className="d-flex align-items-center w-100">
                                        <img
                                            src={`${courseInfos?.teacher_profile_picture_path}${teacher?.teacher_profile_picture}`}
                                            alt=""
                                            width={64}
                                            height={64}
                                            className="me-2"
                                            style={{ borderRadius: "50%" }}
                                        />

                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div>
                                                <p className="result-exam-name mb-0">
                                                    {teacher?.teacher_name}
                                                </p>
                                                <p className="result-subject-name mb-0">
                                                    {teacher?.teacher_details}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="divider my-3"
                                        style={
                                            teacherCount - 1 === index
                                                ? {
                                                      display: "none",
                                                      backgroundColor:
                                                          "#E9E9E9",
                                                  }
                                                : {
                                                      display: "block",
                                                      backgroundColor:
                                                          "#E9E9E9",
                                                  }
                                        }
                                    ></div>
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className="col-lg-9 pe-0">
                    <div className="details-subject-section">
                        <div className="d-flex align-items-center mb-3">
                            <img
                                src={subtractIcon}
                                alt=""
                                width={24}
                                height={24}
                            />
                            <p className="submission-view-top ms-2 mb-0">
                                বিস্তারিত বর্ণনা
                            </p>
                        </div>
                        {/* courseDetail?.course_detail?.course_introduction_video_link !== undefined && courseDetail?.course_detail?.course_introduction_video_link !== null  */}
                        <div className="d-flex justify-content-center">
                            {courseInfos?.course_detail
                                ?.course_introduction_video_link !==
                                undefined &&
                            courseInfos?.course_detail
                                ?.course_introduction_video_link !== null ? (
                                <iframe
                                    className="course-video-details-section"
                                    src={`https://www.youtube.com/embed/${courseInfos?.course_detail?.course_introduction_video_link}`}
                                    title="YouTube video player"
                                    allowFullScreen
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                ></iframe>
                            ) : courseInfos?.course_detail?.course_picture !==
                                  undefined &&
                              courseInfos?.course_detail?.course_picture !==
                                  null ? (
                                <img
                                    src={`${courseInfos?.course_image_path}${courseInfos?.course_detail?.course_picture}`}
                                    alt="course"
                                    className="course-video-details-section"
                                />
                            ) : (
                                <img
                                    src={`${defaultVideoImage}`}
                                    alt="course"
                                    className="course-video-details-section"
                                />
                            )}
                        </div>
                        <div className="mt-3">
                            {
                                courseInfos?.course_detail?.course_description && 
                                parse(courseInfos?.course_detail?.course_description)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Details;
