import React from 'react';
import checkIcon from '../../../../../../../../images/check-circle-fill.png';
import wrongIcon from '../../../../../../../../images/x-circle-fill.png';
import { fileSeperate, questionType } from '../../../../../../../../utilities/utilities';
import parse from 'html-react-parser';

const SingleQuestion = ({question, q_number, student_answers, teacher_given_marks, student_marks, answer_path_link}) => {
    return (
        <div className='single-question-section-card mb-3'>
                <div className='d-flex card-title-question px-3'>
                    <div className='d-flex align-items-center'>
                    <p className='question-type-submission mb-0 me-2'>{questionType(question?.question_type_id)}</p>
                    <p className='mb-0 question-title-submission me-2'>#{q_number} Question: </p>
                    </div>
                    {/* <div className='mb-0 question-title-submission'>{ReactHtmlParser(question?.search_title)}</div> */}
                </div>
                
                <div className='answer-sheet-section'>
                    <div className='px-3'>
                        <table className="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <td className='pt-3 pb-1 question-title-submission ps-0' style={{ width:'12rem'}}>Question:</td>
                                    <td className='pt-3 pb-1 question-title-submission'>{parse(question?.title)}</td>
                                </tr>
                                
                                <tr>
                                    <td colSpan={2}><div className='total-assignment-divider'></div></td>
                                </tr>

                                <tr>
                                    <td className='py-1 question-title-submission ps-0' style={{ width:'12rem'}}>Your Answer:</td>
                                    <td className='py-1 question-title-submission'>
                                        <div className='d-flex align-items-center'>


                                        {
                                                question?.options?.map((option) =>{
                                                   return (
                                                    student_answers?.map((student_answer, index) =>
                                                        (   
                                                           //for broad question/ file submission/mcq,ma,tf 
                                                              (student_answer?.question_id === option?.question_id) && student_answer?.answer_id === null &&  student_answer?.broad_ans ?
                                                              
                                                                <div key={index}>
                                                                    <span className='question-answer-submission me-3'>{student_answer?.broad_ans}</span>
                                                                </div>
                                                             : (student_answer?.question_id === option?.question_id) && student_answer?.answer_id === null &&  student_answer?.file_answer ?
                                                                
                                                                <div key={index}>
                                                                {

                                                                    fileSeperate(student_answer?.file_answer).map((file,index) =>
                                                                    <span className='question-answer-submission me-3' key={index}>
                                                                            <img src={`${answer_path_link}${file}`} alt="" height={60} width={60}/>

                                                                    </span>
                                                                    )
                                                                }
                                                                </div>
                                                            : 
                                                            (student_answer?.question_id === option?.question_id) ?  (student_answer?.answer_id !== null && option?.id === student_answer?.answer_id ) ? option?.is_correct ?
                                                            <div key={index}>
                                                                {
                                                                    teacher_given_marks && <img  src={checkIcon} alt="" className='img-fluid me-2'/>
                                                                }
                                                                
                                                                <span className='question-answer-submission me-3'>{option?.answer_details}</span>
                                                            </div>

                                                             :
                                                             <div key={index}>
                                                                {
                                                                    teacher_given_marks && <img src={wrongIcon} alt="" className='img-fluid me-2'/>
                                                                }
                                                                
                                                                <span className='question-answer-submission me-3'>{option?.answer_details}</span>
                                                                
                                                            </div> : '':''
                                                        ))
                                                   )
                                                })
                                            }

                                        </div>
                                    </td>
                                </tr>
                                {
                                    teacher_given_marks && 

                                    <>
                                        <tr>
                                            <td colSpan={2}><div className='total-assignment-divider'></div></td>
                                        </tr>
                                        <tr>
                                            <td className='py-1 question-title-submission ps-0' style={{ width:'12rem'}}>Correct Answer:</td>
                                            <td className='py-1 question-title-submission'>
                                            <div className='d-flex align-items-center'>
                                                {
                                                        question?.options?.map((option, index) =>{
                                                        return  (option?.is_correct) ?
                                                        <div key={index}>
                                                            <img src={checkIcon} alt="" className='img-fluid me-2'/>
                                                            <span className='question-answer-submission me-3'>{option?.answer_details}</span>
                                                        </div> : ''

                                                        })
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table>
                        <div className='total-assignment-divider'></div>
                        <div className='mt-3 pb-3'>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <p className='mb-0 font-400'>Total Marks</p>
                                        <span className='mb-0 single-question-mark'>{question.marks}</span>
                                    </div>
                                    <div className='mx-5' style={{ height:'35px', width:'2px', backgroundColor:'#F4F4F4' }}></div>
                                    {/* <div>
                                        <p className='mb-0 font-400'>Obtained Marks</p>
                                        {
                                            teacher_given_marks ? <span className='mb-0 single-question-mark bg-success'>10</span> : <span className='mb-0 single-question-remark'>N/A</span>
                                        }
                                    </div> */}
                                    <div>
                                        <p className='mb-0 font-400'>Obtained Marks</p>
                                        {
                                            teacher_given_marks ? 
                                            student_marks.map(student_mark => 
                                                student_mark.question_id === question.id && <span key={student_mark.question_id} className='mb-0 single-question-mark bg-success'>{student_mark.marks}</span>
                                            )
                                            : <span className='mb-0 single-question-remark'>N/A</span>
                                        }
                                    </div>

                                    <div className='mx-5' style={{ height:'35px', width:'2px', backgroundColor:'#F4F4F4' }}></div>
                                    
                                    <div>
                                        <p className='mb-0 font-400'>Remarks</p>
                                        {
                                            teacher_given_marks ? 
                                            student_marks.map(student_mark => 
                                                student_mark.question_id === question.id ? student_mark.remarks ? <span key={student_mark.question_id} className='mb-0 single-question-remark'>{student_mark.remarks}</span> : <span key={student_mark.question_id} className='mb-0 single-question-remark'>N/A</span>:''
                                            )
                                            : <span className='mb-0 single-question-remark'>N/A</span>
                                        }
                                        
                                    </div>
                                </div>

                        </div>
                    </div>
                </div>
           </div>
    );
};

export default SingleQuestion;