import React, { useEffect, useState } from "react";
import bgImg1 from "../../../../../../images/pattern.png";
import bgImg2 from "../../../../../../images/Mojaru-Page-Cover.jpg";
/* import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2,
} from "react-html-parser"; */
import "./Details.css";
import Loader from "../../../../shared/Loader/Loader";
import parse from 'html-react-parser';

const Details = () => {
    const [aboutUs, setAboutUs] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    

    useEffect(() => {
        async function fetchWebsiteSetting() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/settings`
            );
            response = await response.json();
            setAboutUs(response.data.settings.about_us);
            setIsLoading(false);
        }
        fetchWebsiteSetting();
    }, []);
    if(isLoading){
        return (
            <>
                <Loader/>
            </>
        );
    }
    return (
        <div className="borderTop">
            <div className="position-relative">
                <div className="position-absolute">
                    <img src={bgImg1} alt="" className="img-fluid bg-img2" />
                </div>
                <div className="row m-0">
                    <div className="container details-width">
                        <p className="text-center about-title">
                            আমাদের সম্পর্কে
                        </p>
                        <p className="about-detail">
                            {parse(aboutUs)}
                        </p>
                    </div>

                    <div className="position-relative d-flex justify-content-center mt-5">
                        <img
                            src={bgImg2}
                            alt=""
                            className="details-width"
                            style={{ borderRadius: "16px" }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Details;
