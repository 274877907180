
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import ExcelJS from 'exceljs';
import regBackground from '../../../../../images/bg.png';
import { toast } from "react-toastify";

const AbacusCampaignLeads = () => {
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [password, setPassword] = useState("");
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(false);

    const itemsPerPage = 20;

    const checkAuthorization = async(e) => {

        e.preventDefault();

        if (!password || !fromDate || !toDate) {
            toast.error('All fields are required');
            return;
        }

        const data = {
            password: password,
            type: "free",
            from_date: fromDate,
            to_date: toDate,
        }

        setLoading(true);

        try{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/free-exam-data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'secretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                },
                body: JSON.stringify(data)
            });

            const responseData = await response.json();
            console.log(responseData);

            setUser(responseData?.data?.data);
            setLoading(false);
            if(responseData?.code === 200){
                setIsAuthorized(true);
            }else {
                toast.error(`${responseData?.message}`)
            }

        } catch(error){
            console.log(error);
            setLoading(false);
        }

    };


    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = user.slice(startIndex, endIndex);

    const exportToExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('RegistrationLeads');

        // Define column headers
        worksheet.columns = [
            { header: 'Student Name', key: 'student_name', width: 20 },
            { header: 'Mobile', key: 'phone_number', width: 15 },
            { header: 'Age', key: 'age', width: 10 },
            { header: 'Obtain Mark', key: 'obtain_mark', width: 10 },
            { header: 'Total Mark', key: 'total_mark', width: 10 },
            { header: 'Date', key: 'created_at', width: 15 },
        ];

//student_name	phone_number	age	obtain_mark	total_mark
        // Replace the following data with your actual data source
        const data = user.map(item => ({
            student_name: item.student_name,
            phone_number: item.phone_number,
            age: item.age,
            obtain_mark: item.obtain_mark,
            total_mark: item.total_mark,
            created_at: item.created_at.slice(0, 10),
        }));

        // Add data to the worksheet
        worksheet.addRows(data);

        // Create a buffer from the workbook
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            // Create a temporary anchor element to trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = 'registration-leads.xlsx';
            a.click();
            URL.revokeObjectURL(url);
        });
    };


    const PageContent = () => {
        if (isAuthorized) {
            return (
                <>
                    <h2>Abacus Campaign Registration list</h2>
                    <button className="btn btn-primary" onClick={exportToExcel}>Download Excel</button>
                    <div className="BkashTableList">
                        <table>
                            <thead>
                            <tr>
                                <th>Student Name</th>
                                <th>Mobile</th>
                                <th>Age</th>
                                <th>Obtain Mark</th>
                                <th>Total Mark</th>
                                <th>Created At</th>
                            </tr>
                            </thead>

                            <tbody>
                            {currentPageData.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.student_name}</td>
                                    <td>{item.phone_number}</td>
                                    <td>{item.age}</td>
                                    <td>{item.obtain_mark}</td>
                                    <td>{item.total_mark}</td>
                                    <td>{item.created_at.slice(0, 10)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        current={currentPage}
                        total={user.length}
                        pageSize={itemsPerPage}
                        onChange={setCurrentPage}
                    />
                </>
            );
        } else {
            return (
                <div>
                    <div className="container col-6">
                        <h3 className="text-center mt-5">Authorization Required</h3>
                        <p className="text-center mt-3">Please enter the password to access this page:</p>
                        <form onSubmit={checkAuthorization}>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    className="form-control"
                                    id="exampleInputPassword1"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="fromDate" className="form-label">From Date</label>
                                <input
                                    onChange={(e) => setFromDate(e.target.value)}
                                    type="date"
                                    className="form-control"
                                    id="fromDate"
                                    min="2024-03-10"
                                    max={new Date().toISOString().split('T')[0]}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="toDate" className="form-label">To Date</label>
                                <input
                                    onChange={(e) => setToDate(e.target.value)}
                                    type="date"
                                    className="form-control"
                                    id="toDate"
                                    min="2024-03-10"
                                    max={new Date().toISOString().split('T')[0]}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <section className="BkashLeadsList">
                <Container>
                    <Row>
                        <Col lg={12}>
                            {PageContent()}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>

    );
};

export default AbacusCampaignLeads;