import React, {useEffect} from 'react';
import paymentSuccess from '../../../../images/payment-success.png';
import backHome from '../../../../images/back-icon.png';
import utils from "../../../../utils/index"
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const PaymentSuccess = () => {
    const mobile_or_email = localStorage.getItem('mobile_or_email');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseName = queryParams.get('course_name');
    const coursePrice = queryParams.get('course_total_price');
    const paidAmnount = queryParams.get('paid_amount');
    const coupon_code =  queryParams.get('coupon_code');

    useEffect(()=>{
        TagManager.dataLayer({
            dataLayer:{
                event: 'purchase',
                pagePath: 'Payment Success',
                PageTitle:'Payment Success',
                course:courseName,
                price: coursePrice,
                paidAmnount: paidAmnount,
                currency: 'BDT',
                mobile_or_email: mobile_or_email,
                coupon_code:coupon_code
            }
        })
        const pixelData = {page: 'Payment Success'};
        utils.addFacebookEvent('Payment Success', pixelData);
    },[])
    return (
        <div className='' style={{ height: '98vh' }}>
            <div className='d-flex justify-content-center align-items-center h-100' >
                <div>
                    <img src={paymentSuccess} alt="" className='img-fluid' />
                    <p className='text-center mt-4 mb-2' style={{ fontSize:'40px', fontWeight:'700' }}>Payment Success!</p>
                    <p className='text-center mb-5' style={{ fontSize:'20px', fontWeight:'400', color:'#7C7C7C' }}>Your payment process has been completed successfully.</p>
                    <div className='d-flex justify-content-center'>
                        <button style={{ backgroundColor: '#F27321', color: '#ffffff', fontSize: '20px', fontWeight: '600', border: 'none', padding: '12px 30px', borderRadius: '12px'}}><img src={backHome} alt="" /> <span className="ps-3">Back to Home</span></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;