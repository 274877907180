import React from "react";
import "./Banner.css";
import banner_girl from "../../../../../images/Admission.png";
import { useTranslation } from "react-i18next";

const Banner = () => {
    const {t} = useTranslation();
    return (
        <div className="academic_background ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <div>
                            <p className="banner-title">
                                ক্যাডেট কিংবা বিশ্ববিদ্যালয়
                                <br />
                                মজারুতে এলেই হবে জয়
                            </p>
                            <p className="banner-text">
                                এক্সপার্ট টিচার্স প্যানেল, আনলিমিটেড প্র্যাকটিস,
                                মক টেস্ট আর ভাইভা গ্রুমিং ক্লাসে ভর্তি
                                প্রস্তুতির পুরোটা হবে মজারুতেই
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <img
                            src={banner_girl}
                            alt=""
                            className="img-fluid pb-5 mt-5"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
