import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import "./User.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Home from "./components/Home/Home";
import Academic from "./components/Academic/Academic";
import Skills from "./components/Skills/Skills";
import Genius from "./components/Genius/Genius";
import JobPreparation from "./components/JobPreparation/JobPreparation";
import AdmissionPreparation from "./components/AdmissionPreparation/AdmissionPreparation";
import EBook from "./components/EBook/EBook";
import Registration from "./pages/Registration/Registration";
import Login from "./pages/Login/Login";
import Otp from "./pages/Registration/OTP/Otp";
import axios from "axios";
import About from "./pages/FooterPages/About/About";
import Contact from "./pages/FooterPages/Contact/Contact";
import Faq from "./pages/FooterPages/Faq/Faq";
import PrivacyPolicy from "./pages/FooterPages/PrivacyPolicy/PrivacyPolicy";
import Refund from "./pages/FooterPages/Refund/Refund";
import TermsAndConditions from "./pages/FooterPages/TermsAndConditions/TermsAndConditions";
import NotFoundPage from "./shared/NotFoundPage/NotFoundPage";
import ScrollToTop from "./shared/ScrollToTop/ScrollToTop";
import CourseDetails from "./shared/CourseDetails/CourseDetails";
import Blogs from "./pages/FooterPages/Blogs/Blogs";
import BookDetails from "./components/EBook/BookDetails/BookDetails";
import Header from "./shared/Header/Header";
import Footer from "./shared/Footer/Footer";
import Message from "./shared/Message/Message";
import FreeClassRegistration from "./pages/FreeClassRegistration/FreeClassRegistration";
import BlogDetails from "./pages/FooterPages/Blogs/BlogDetails/BlogDetails";
import AuthorDetail from "./components/EBook/BookDetails/AuthorDetail/AuthorDetail";
import PublisherDetail from "./components/EBook/BookDetails/PublisherDetail/PublisherDetail";
import CategoryAuth from "./pages/Login/RequireAuth/CategoryAuth";
import ScheduleFreeClass from "./components/Home/ScheduleFreeClass/ScheduleFreeClass";
import ScheduleFreeClassList from "./components/Home/ScheduleFreeClass/ScheduleFreeClassList/ScheduleFreeClassList";
import Recorded from "./components/Recorded/Recorded";
import TagManager from "react-gtm-module";
import Marketing from "./pages/TermsAndCondition/Marketing/Marketing";
import TermsOfService from "./pages/TermsAndCondition/TermsOfService/TermsOfService";
import ServiceAgreement from "./pages/TermsAndCondition/ServiceAgreement/ServiceAgreement";


axios.defaults.withCredentials = true;

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('auth_token');
    config.headers.Authorization = token ? `Bearer ${token}` : ``;
    config.headers.SecretKey = `${process.env.REACT_APP_API_URL_SECRET_KEY}`
    return config;
});


function User() {

    const location = useLocation();
    const path = location.pathname;
    const hideMessage = path.startsWith("/blog") || path.startsWith("/blog-details") || path.startsWith("/tos") || path.startsWith("/sla") || path.startsWith("/marketing") ;
    console.log(hideMessage)
    return (
        <>
            <ScrollToTop/>
            <Header location={location.pathname}></Header>
            <Routes>
                {/* Nav Link */}
                <Route path="/" element={<Home></Home>}></Route>
                <Route path="/academic" element={<CategoryAuth><Academic/></CategoryAuth>}></Route>
                <Route path="/skills" element={<CategoryAuth><Skills/></CategoryAuth>}></Route>
                <Route path="/genius" element={<CategoryAuth><Genius/></CategoryAuth>}></Route>
                <Route path="/job-preparation" element={<CategoryAuth><JobPreparation/></CategoryAuth>}></Route>
                <Route path="/admission-preparation"
                       element={<CategoryAuth><AdmissionPreparation/></CategoryAuth>}></Route>
                <Route path="/recorded" element={<CategoryAuth><Recorded/></CategoryAuth>}></Route>
                <Route path="/e-book" element={<EBook></EBook>}></Route>

                {/* login and registration */}
                <Route path="/registration" element={<Registration></Registration>}></Route>
                <Route path="/login" element={<Login></Login>}></Route>
                <Route path="/otp" element={<Otp></Otp>}></Route>


                {/* footer page link */}
                <Route path="/about" element={<About/>}></Route>
                <Route path="/contact" element={<Contact/>}></Route>
                <Route path="/faq" element={<Faq/>}></Route>
                <Route path="/privacy_policy" element={<PrivacyPolicy/>}></Route>
                <Route path="/refund" element={<Refund/>}></Route>
                <Route path="/blog" element={<Blogs/>}></Route>
                {/* <Route path="/blog-details" element={<BlogDetails/>}></Route> */}
                <Route path="/blog-details/:blogTitle" element={<BlogDetails/>}></Route>
                <Route path="/terms_and_condition" element={<TermsAndConditions/>}></Route>

                {/* Course details */}
                <Route path={`/academic/:courseName`} element={<CourseDetails></CourseDetails>}></Route>
                <Route path={`/skills/:courseName`} element={<CourseDetails></CourseDetails>}></Route>
                <Route path={`/genius/:courseName`} element={<CourseDetails></CourseDetails>}></Route>
                <Route path={`/job-preparation/:courseName`} element={<CourseDetails></CourseDetails>}></Route>
                <Route path={`/admission-preparation/:courseName`} element={<CourseDetails></CourseDetails>}></Route>
                <Route path={`/recorded/:courseName`} element={<CourseDetails></CourseDetails>}></Route>
                <Route path={`/free-course-registration`} element={<FreeClassRegistration/>}></Route>

                {/* Book details */}
                <Route path={`/e-book/detail`} element={<BookDetails></BookDetails>}></Route>
                <Route path={`/e-book/detail/author-detail`} element={<AuthorDetail/>}></Route>
                <Route path={`/e-book/detail/publisher-detail`} element={<PublisherDetail/>}></Route>

                {/* Book details */}
                <Route path={`/schedule/class`} element={<ScheduleFreeClass/>}></Route>
                <Route path={`/schedule/class-list`} element={<ScheduleFreeClassList/>}></Route>


                {/* course purchase */}

                {/* not found page */}
                <Route path="*" element={<NotFoundPage></NotFoundPage>}></Route>
                {/*temporary*/}
                <Route path="/marketing" element={<Marketing/>}/>
                <Route path="/tos" element={<TermsOfService/>}/>
                <Route path="sla" element={<ServiceAgreement/>}/>
            </Routes>
            {!hideMessage && <Message location={location.pathname}/>}
            <Footer location={location.pathname}></Footer>
        </>
    );
}

export default User;
