import React from "react";
import "./ClassSchedule.css";
import TotalClass from "./TotalClass/TotalClass";


const ClassSchedule = ({lesson, LessonSerial}) => {

    return (
        <div>
            <div className="accordion-item lesson-accordion-body">
            <h3 className="accordion-header change">
                <button
                    className={`accordion-button ${LessonSerial === 0 ? '' : 'collapsed'} btn-lesson`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#faq-content-${LessonSerial}-${lesson?.lesson_id}`}
                >
                     <span className='fw-500'>{lesson?.lesson_name}</span>
                </button>
            </h3>
            
            <div id={`faq-content-${LessonSerial}-${lesson?.lesson_id}`} className={`accordion-collapse collapse ${LessonSerial === 0 ? 'show' : ''}`} data-bs-parent="#public-syllabus">
                <div className='total-assignment-divider'></div>
                <div className='mt-3 px-2 table-responsive'>
                    <table className='table table-borderless'>

                        <tbody>
                            
                        {
                            lesson?.topics?.length === 0 
                                ?<tr>
                                    <td colSpan={5}>
                                        <p className="d-flex justify-content-center mb-0 w-100" style={{ fontSize: "20px", fontWeight: "600" }}>এখনো কোনো বিষয়বস্তু যুক্ত করা হয়নি।</p> 
                                    </td>
                                </tr> 
                                    :
                            
                            lesson?.topics?.map((topic, index) => 
                                <TotalClass key={index} topic={ topic } topicSerial={index} LessonSerial={LessonSerial}></TotalClass>
                            
                        )}

                        </tbody>

                    </table>
                </div>
            </div>
            </div>


































        </div>
    );
};

export default ClassSchedule;
