import React, { useEffect, useState } from "react";
import img from "../../../../../images/terms.png";
import "./TermsAndConditions.css";
import PageTitle from "../../../shared/PageTitle/PageTitle";
import parse from 'html-react-parser';
import Loader from "../../../shared/Loader/Loader";

const TermsAndConditions = () => {
    const [aboutUs, setAboutUs] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchWebsiteSetting() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/settings`
            );
            response = await response.json();
            setAboutUs(response.data.settings.terms_condition);
            setIsLoading(false);
        }
        fetchWebsiteSetting();
    }, []);

    if(isLoading){
        return ( <Loader/> );
    }
    return (
        <div>
            <PageTitle title="Terms and Condition"></PageTitle>

            <div style={{ paddingBottom: "23rem" }}>
                <div className="borderTop">
                    <div className="container">
                        <div className="row terms-section">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <p className="title">সেবা পাবার শর্ত</p>
                                <p className="details">
                                    {parse(aboutUs)}
                                </p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <img src={img} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
