import React from 'react';
import { Card} from "react-bootstrap";
import { topicTypeName } from '../../../../../utilities/utilities';
import './ActiveDate.css'

const ActiveDate = ({eventByDate}) => {

    /* Random Color */
    /* let colors = [
        "#F08080",
        "#ADD8E6",
        "#FFFACD",
        "#87CEFA",
        "#20B2AA",
        "#FFA07A",
        "#00FA9A",
        "#FFE4E1",
        "#DA70D6",
        "#FFF5EE",
        "#87CEEB",
        "#F5DEB3",
        "#F0F8FF",
        "#FF7F50"
      ]; */
      let colors = [
        "#ffffff"
      ];
    let indexColor = Math.floor(Math.random() * colors.length);
    /* event start time */
    const startTime = new Date(eventByDate?.start).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });

    /* event start time */  
    const endTime = new Date(eventByDate?.end).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      
    /* event month name */ 
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const month = monthNames[new Date(eventByDate?.start).getMonth()];

    /* event date name */ 
    const date = new Date(eventByDate?.start).getDate();

    return (
        
            <div>
                <Card
                style={{ backgroundColor: colors[indexColor] }}
                className="event-card"
                >
                <Card.Body>
                    <Card.Title className='d-flex align-items-center' style={{ color: topicTypeName(eventByDate?.assesment_type).color_class }}><img src={topicTypeName(eventByDate?.assesment_type).img} alt='' className='me-2'/>{topicTypeName(eventByDate?.assesment_type).name}</Card.Title>
                    {/* <Card.Title className=''>{eventByDate?.extendedProps?.batch_name}</Card.Title> */}
                    <p className="event-time  mb-2" style={{ fontSize:'18px' }}>{eventByDate?.subject_name}</p>
                    <p className="event-time  mb-1" style={{ fontSize:'16px', height:'3rem' }}>{eventByDate?.title}</p>
                    <p className="event-time ">{startTime} - {endTime}</p>
                </Card.Body>
                </Card>
                <Card className="event-card date-card">
                <Card.Body className="date-body">
                    <h3 className="event-type month">{month}</h3>
                    <h3 className="event-type date">{date}</h3>
                </Card.Body>
                </Card>
            </div>
    );
};

export default ActiveDate;