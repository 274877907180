import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Card from "./Card/Card";
import i18next from "i18next";
import TagManager from "react-gtm-module";


const Courses = ({categories, courses, path_link}) =>{
  const {t} = useTranslation();

  useEffect(() => {

        const useLanguage = sessionStorage.getItem('useLanguage');
        if(!useLanguage){
            sessionStorage.setItem('useLanguage', 'bn');
            i18next.changeLanguage('bn');
        }else{
            i18next.changeLanguage(useLanguage);
        }
    }, []);
    return(
        <section>
          <p className="d-flex justify-content-center mb-0 course-section">{t('Course of Mojaru')}</p>
            <div className="">
                <ul className="nav nav-pills mb-3 mt-5 d-flex justify-content-center" id="pills-tab" role="tablist">
                  {
                    categories.slice(0,7)?.map((category) =>{
                      return(
                        category?.course_sub_categories.map( (subCategory, index) => (
                          subCategory?.is_showed_in_home_page ?
                          <li className="nav-item mx-2 py-1" role="presentation" key={index}>
                            <button className={`nav-link tabpen-links category-name py-1 ${subCategory?.course_sub_category_id === categories[0]?.course_sub_categories[0]?.course_sub_category_id ? "active" : ""}`} id={`pills-category_${subCategory.course_sub_category_id}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-category_${subCategory.course_sub_category_id}`} type="button" role="tab" aria-controls={`pills-category_${subCategory.course_sub_category_id}`} aria-selected="true">{subCategory.course_sub_category_name}</button>
                          </li>
                          :
                          null
                      )))
                    })
                  }
                </ul>
            </div>
            
            {/* course section */}
            <div className="tab-content mt-5 mb-5" id="pills-tabContent">
              {
                categories.map((category) =>{
                  return(category?.course_sub_categories?.map( subCategory => {
                    return(
                      <div className={`tab-pane fade ${subCategory.course_sub_category_id === categories[0].course_sub_categories[0]?.course_sub_category_id  ? "active show" : ""}`} id={`pills-category_${subCategory.course_sub_category_id}`} role="tabpanel" aria-labelledby={`pills-category_${subCategory.course_sub_category_id}-tab`} key={subCategory.course_sub_category_id}>
                      <div className="container mt-5 mb-5">
                              <div className="row gy-4">
                                 {
                                    (courses.map((course, index) =>{
                                      if(subCategory?.course_sub_category_id === course?.course_sub_category_id) {
                                        return <Card key={index} course={course} path_link={path_link}></Card> 
                                      }
                                      return null;
                                    }))
                                  }
                              </div>
                          </div>
                        </div>
                    )
                  }))
                })
              }
            </div>
                    
        </section>
    );
}

export default Courses;