import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import './PaidStudentInfo.css'
import noPerson from '../../../../../images/blank.png';
import watermarkLogo from '../../../../../images/m_watermark.png';
import logo from '../../../../../images/dashboard-logo.png';
import { eventDate } from '../../../../../utilities/utilities';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import PageTitle from '../../../shared/PageTitle/PageTitle';

const PaidStudentInfo = () => {
    const [image, setImage] = useState('');
    const [file, setFile] = useState();
    const [isChangeImage, setIsChangeImage] = useState(false);
    const [startDate, setStartDate] = useState();
    const [selectedMediumOption, setSelectedMediumOption] = useState('Bangla');
    const [selectedGenderOption, setSelectedGenderOption] = useState('Male');
    const [studentInfo, setStudentInfo] = useState();

    useEffect(() => {
        fetch( `${process.env.REACT_APP_BASE_URL_STUDENT}profile?user_id=${localStorage.getItem("user_id")}`,{
                  method:'GET',
                  headers:{
                      'content-type' : 'application/json',
                      'accept' : 'application/json',
                      'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                      'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                  },
              })
            .then((res) => res.json())
            .then((data) => setStudentInfo(data.data));
    }, []);


    const handleChange = (e) => {
        setImage(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
        setIsChangeImage(true);
    }

   

    const handleMediumOptionChange = (event) => {
        setSelectedMediumOption(event.target.value);
    };

    const handleGenderOptionChange = (event) => {
        setSelectedGenderOption(event.target.value);
    };

    const handelStudentInfo = (e) => {
        e.preventDefault();
        let data = new FormData();
    
        // Validate image
        if (!image) {
            toast.error('Please upload your image');
            return;
        }
        data.append('profile_picture', image);
    
        // Validate father name
        const fatherName = e.target.father_name.value;
        if (!fatherName) {
            toast.error('Please enter father name');
            return;
        }
        data.append('father_name', fatherName);
    
        // Validate mother name
        const motherName = e.target.mother_name.value;
        if (!motherName) {
            toast.error('Please enter mother name');
            return;
        }
        data.append('mother_name', motherName);
    
    
        // Validate date of birth
        if (!startDate) {
            toast.error('Please enter date of birth');
            return;
        }
        data.append('date_of_birth', eventDate(startDate));
    
        // Validate school name
        const schoolName = e.target.school_name.value;
        if (!schoolName) {
            toast.error('Please enter school name');
            return;
        }
        data.append('school', schoolName);
    
    
        // Validate full address
        const fullAddress = e.target.full_address.value;
        if (!fullAddress) {
            toast.error('Please enter full address');
            return;
        }
        data.append('full_address', fullAddress);
    
        // Validate mailing address
        const mailingAddress = e.target.mailing_address.value;
        if (!mailingAddress) {
            toast.error('Please enter courier address');
            return;
        }
        data.append('mailing_address', mailingAddress);
    
        // Validate WhatsApp number
        const whatsAppNumber = e.target.student_whats_app_number.value;
        if (!whatsAppNumber) {
            toast.error('Please enter WhatsApp number');
            return;
        }
        data.append('whats_app_number', whatsAppNumber);
    
        // Validate religion
        const religion = e.target.religion.value;
        if (!religion) {
            toast.error('Please enter religion');
            return;
        }
        data.append('religion', religion);
    
        // Validate medium
        const medium = e.target.medium.value;
        if (!medium) {
            toast.error('Please select medium');
            return;
        }
        data.append('medium', medium);
    
    
        // Validate gender
        const gender = e.target.gender.value;
        if (!gender) {
            toast.error('Please select gender');
            return;
        }
        data.append('gender', gender);
    
    
    
    
    
    
        // Validate class name
        const className = e.target.class_name.value;
        if (!className) {
            toast.error('Please enter class name');
            return;
        }
        data.append('class', className);


        
    
    
    
    
    
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}update-profile?user_id=${localStorage.getItem("user_id")}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
                'SecretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
            },
            body: data,
        })
            .then(res => res.json())
            .then(data => {
                if (data.message_type === 'success') {
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            })
    }

    return (
        <section className='container'>
        <PageTitle title='User Information'></PageTitle>

        <div className='mt-4 mb-2 d-flex justify-content-between align-items-center'>
            <p className='lead-collect-title mb-0'>তোমার তথ্য দিয়ে নিচের ফর্মটি পূরণ করো</p>
            <img src={logo} alt="" className='img-fluid'/>
        </div>
        
        <div className='mb-5'>
        <img src={watermarkLogo} alt="Watermark Logo" className="watermark-logo" />
            <div>
                <p className='mb-2 font-400'>তোমার ছবি<span className='text-danger'>*</span></p>
                <div className="avatar-upload">
                    <div className="avatar-edit">
                        <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"  onChange={handleChange} />
                        <label htmlFor="imageUpload"></label> 
                    </div>
                    <div className="avatar-preview">

                        {
                            file ? 
                                <img className="profile-user-img img-responsive" id="imagePreview" src={file} alt="User profile "/>
                                : 
                                <img className="profile-user-img img-responsive" id="imagePreview" src={noPerson} alt="User profile "/>
                            
                        }

                    </div>
                </div>
            </div>
            <form onSubmit={handelStudentInfo}>
            <div className="row">
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>বাবার নাম<span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="father_name" type="text" placeholder="তোমার বাবার নাম লিখ" />
                </div>
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>মায়ের নাম<span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="mother_name" type="text" placeholder="তোমার মায়ের নাম লিখ " />
                </div>
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>জন্ম তারিখ <span className='text-danger'>*</span></p>
                    <DatePicker showYearDropdown  yearDropdownItemNumber={40} scrollableYearDropdown className="form-control form-control-lg student-profile-info" selected={startDate} onChange={(date) => setStartDate(date)} placeholderText={'তোমার জন্ম তারিখটি দাও'} />
                </div>
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>প্রতিষ্ঠানের নাম<span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="school_name" type="text" placeholder="তোমার বিদ্যালয়ের নাম লিখ" />
                </div>
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>বর্তমান ঠিকানা<span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="full_address" type="text" placeholder="তোমার বর্তমান ঠিকানা লিখ" />
                </div>
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>কুরিয়ার ঠিকানা<span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="mailing_address" type="text" placeholder="তোমার মেইলিং এড্রেস লিখ" />
                </div>
                <div className="col-lg-6">
                    <p className='mb-2 font-400'>হোয়াটস্যাপ নাম্বার <span className='text-danger'>*</span></p>
                    <input className="mb-3 form-control form-control-lg student-profile-info" name="student_whats_app_number" type="text" placeholder="তোমার হোয়াটস্যাপ নাম্বার লিখ" />
                </div>

                <div className="col-lg-6">
                    <p className='mb-2 font-400'>ধর্ম<span className='text-danger'>*</span></p>
                    <select name="religion" className="form-select form-control form-control-lg student-profile-info" id="select_box" >
                        <option value="">তোমার ধর্ম বাছাই করো</option>
                        <option value="Muslim">ইসলাম ধর্মাবলম্বী</option>
                        <option value="Hindu">হিন্দু ধর্মাবলম্বী</option>
                        <option value="Cristian">ক্রিস্টিয়ান ধর্মাবলম্বী</option>
                        <option value="Buddhist">বৌদ্ধ ধর্মাবলম্বী</option>
                        <option value="Other" >অন্যান্য</option> 
                    </select>
                </div>
                
                

                <div className="col-lg-6">
                    <p className="mb-2 font-400">
                        মিডিয়াম<span className="text-danger">*</span>
                    </p>
                    <div className="d-flex align-items-center">
                        <div className="radio">
                        <label style={{ fontWeight: '500', cursor: 'pointer' }}>
                            <input
                            type="radio"
                            name="medium"
                            id="bangla"
                            value="Bangla"
                            checked={selectedMediumOption === 'Bangla'}
                            onChange={handleMediumOptionChange}
                            />
                            &nbsp;&nbsp;Bangla
                        </label>
                        <br />
                        <label style={{ fontWeight: '500', cursor: 'pointer' }}>
                            <input
                            type="radio"
                            name="medium"
                            id="english"
                            value="English"
                            checked={selectedMediumOption === 'English'}
                            onChange={handleMediumOptionChange}
                            />
                            &nbsp;&nbsp;English
                        </label>
                        <br />
                        <label style={{ fontWeight: '500', cursor: 'pointer' }}>
                            <input
                            type="radio"
                            name="medium"
                            id="madrasa"
                            value="Madrasa"
                            checked={selectedMediumOption === 'Madrasa'}
                            onChange={handleMediumOptionChange}
                            />
                            &nbsp;&nbsp;Madrasa
                        </label>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <p className='mb-2 font-400'>লিঙ্গ<span className='text-danger'>*</span></p>
                    <div className='d-flex align-items-center'>

                        <div className="radio">
                            <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                <input type="radio" name="gender" id="boy" value="Male"
                                checked={selectedGenderOption === 'Male'}
                                onChange={handleGenderOptionChange}
                                 />
                                &nbsp;&nbsp;Male
                            </label><br />
                            <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                <input type="radio" name="gender" id="girl" value="Female"
                                checked={selectedGenderOption === 'Female'}
                                onChange={handleGenderOptionChange}
                                />
                                &nbsp;&nbsp;Female
                            </label><br />
                            <label style={{ fontWeight:'500', cursor:'pointer' }}>
                                <input type="radio" name="gender" id="other" value="Other" 
                                checked={selectedGenderOption === 'Other'}
                                onChange={handleGenderOptionChange}
                                />
                                &nbsp;&nbsp;Other
                            </label>
                        </div>
                    </div> 
                </div>

                <div className="col-lg-12 mt-3">
                    <p className='mb-2 font-400'>ক্লাস <span className='text-danger'>*</span></p>
                    <select name="class_name" className="form-select form-control form-control-lg student-profile-info" id="select_box">
                        <option value="">তোমার ক্লাস বাছাই করো</option>
                        {
                            studentInfo?.class_lists.map(class_list => <option key={class_list.class_id}   value={class_list.class_name}>{class_list.class_name}</option>)
                        }
                    </select>
                </div>

                
            </div>


            <div className=" d-flex justify-content-end">
            <input className="btn text-white mt-4 py-2 mb-5" style={{ backgroundColor:'#532D80', fontSize:'22' }} type="submit" value='সংরক্ষণ করুন'></input>
            </div>
            </form>
        </div>
            
        </section>
    );
};

export default PaidStudentInfo;