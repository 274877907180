import React from 'react';
import StudentPageTitle from '../../shared/StudentPageTitle/StudentPageTitle';
import studentProfile from '../../../../images/blank.png';
import './Profile.css'
import StudentInformation from './StudentInformation/StudentInformation';
import StudentCertificates from './StudentCertificates/StudentCertificates';
import StudentResults from './StudentResults/StudentResults';
import StudentAttendance from './StudentAttendance/StudentAttendance';
import { useState } from 'react';
import { useEffect } from 'react';
import EidGiftCard from './EidGiftCard/EidGiftCard';

const Profile = () => {
    const [studentInfo, setStudentInfo] = useState();
    const [refetchData, setRefetchData] = useState(0);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}profile?user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            .then((res) => res.json())
            .then((data) => setStudentInfo(data.data));
    }, [refetchData]);
    
    return (
        <div className="main-content">
                <StudentPageTitle title="Profile"></StudentPageTitle>
                <div className="page-content p-0">
                    <div className="container-fluid">
                        <div className="my-course-section">
                            <div className='profile-details-summary custom-card-shadow'>
                                <div className='d-flex align-items-center'>
                                {
                                    studentInfo?.student_detail?.profile_picture ? <img src={`${studentInfo?.student_profile_image}${studentInfo?.student_detail?.profile_picture}`} alt="" style={{ borderRadius:'4px', width:'120px', height:'120px' }}/>
                                    : <img src={studentProfile} alt="" style={{ borderRadius:'4px', width:'120px', height:'120px' }}/>
                                }
                                    
                                    <div className='ms-4 w-100'>
                                        <p className='student-name'>{studentInfo?.student_detail?.user_name ? studentInfo?.student_detail?.user_name : 'Write your name'}</p>
                                        
                                        {
                                            studentInfo?.profile_completed < 100 ?
                                            <>
                                                <p className='profile-completion'>Profile Completion</p> 
                                                <div className='d-flex align-items-center'>
                                                    <div className="progress w-25" style={{borderRadius:'15px', height: '0.4rem' }}>
                                                        <div className="progress-bar" id="student-profile-progressBar" role="progressbar" style = {studentInfo?.profile_completed && {width: `${studentInfo?.profile_completed}%`, borderRadius:'15px', backgroundColor:'#46C35F' } }   aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    <div className='ms-2' style={{ fontWeight:'500', fontSize:'14px' }}>{studentInfo?.profile_completed}%</div>{/* style={{ width: '50%', borderRadius:'15px', backgroundColor:'#46C35F' }} */}
                                                </div> 
                                            </>
                                        :

                                        <span className='profile-verified'>Verified</span>
                                        }

                                    </div>
                                    
                                </div>

                            </div>

                            <ul className='nav nav-pills py-1 ps-1 bg-white mt-4 custom-card-shadow mb-4' style={{ borderRadius:'8px' }}  id="pills-tab" role="tablist">                                                             
                                <li className="nav-item assignment-submission" role="presentation">
                                    <button className= "nav-link text-dark submission-count active"  id="pills-my-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-my-profile" type="button" role="tab" aria-controls="pills-my-profile" aria-selected="true">My Profile</button>
                                </li>
                                {/* <li className="nav-item assignment-submission" role="presentation">
                                    <button className= "nav-link submission-count"  id="pills-my-certificates-tab" data-bs-toggle="pill" data-bs-target="#pills-my-certificates" type="button" role="tab" aria-controls="pills-my-certificates" aria-selected="false">My Certificates</button>
                                </li> */}
                                <li className="nav-item assignment-submission" role="presentation">
                                    <button className= "nav-link text-dark submission-count"  id="pills-my-results-tab" data-bs-toggle="pill" data-bs-target="#pills-my-results" type="button" role="tab" aria-controls="pills-my-results" aria-selected="false">My Results</button>
                                </li>
                                {/* <li className="nav-item assignment-submission" role="presentation">
                                    <button className= "nav-link submission-count"  id="pills-my-attendance-tab" data-bs-toggle="pill" data-bs-target="#pills-my-attendance" type="button" role="tab" aria-controls="pills-my-attendance" aria-selected="false">My Attendance</button>
                                </li> */}
                                {/* <li className="nav-item assignment-submission" role="presentation">
                                    <button className= "nav-link submission-count text-dark"  id="pills-my-attendance-tab" data-bs-toggle="pill" data-bs-target="#pills-my-attendance" type="button" role="tab" aria-controls="pills-my-attendance" aria-selected="false">Eid Gift Card</button>
                                </li> */}
                            </ul>
                            <div className="tab-content custom-card-shadow bg-white" id="pills-tabContent" style={{ borderRadius:'16px' }}>

                                <div className="tab-pane fade show active" id="pills-my-profile" role="tabpanel" aria-labelledby="pills-my-profile-tab">
                                    <StudentInformation 
                                    studentInfo={studentInfo} 
                                    setRefetchData= {setRefetchData} 
                                    refetchData={refetchData}
                                    />
                                </div>

                                <div className="tab-pane fade" id="pills-my-certificates" role="tabpanel" aria-labelledby="pills-my-certificates-tab">
                                    <StudentCertificates/>
                                </div>

                                <div className="tab-pane fade" id="pills-my-results" role="tabpanel" aria-labelledby="pills-my-results-tab">
                                    <StudentResults
                                        studentName = {studentInfo?.student_detail?.user_name}
                                        profilePath = {studentInfo?.student_profile_image}
                                        studentProfile = {studentInfo?.student_detail?.profile_picture}
                                    />
                                </div>

                                <div className="tab-pane fade" id="pills-my-attendance" role="tabpanel" aria-labelledby="pills-my-attendance-tab">
                                    <StudentAttendance/>
                                </div>
                                <div className="tab-pane fade" id="pills-my-attendance" role="tabpanel" aria-labelledby="pills-my-attendance-tab">
                                    <EidGiftCard 
                                        studentInfo = {studentInfo}
                                        studentName = {studentInfo?.student_detail?.user_name}
                                        profilePath = {studentInfo?.student_profile_image}
                                        studentProfile = {studentInfo?.student_detail?.profile_picture}
                                        refetchData={refetchData}
                                        setRefetchData={setRefetchData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Profile;