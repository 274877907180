import React from "react";
import batchIcon from '../../../../../../images/people-fill.png';
import clockIcon from '../../../../../../images/due-clock-fill.png';
import liveIcon from '../../../../../../images/live type.png';
import './TomorrowLiveClass.css';
import { formatAMPM } from "../../../../../../utilities/utilities";

const TomorrowLiveClass = ({liveCLass}) => {

    let classDuration = Math.abs(new Date(liveCLass?.end) - new Date(liveCLass?.start));
    let convertInHours = classDuration / 3600000;

    const today = new Date(liveCLass?.live_class_date);
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const date = today.getDate(); 
    const month =  months[today.getMonth()]; 
    const day = today.toLocaleDateString('en-US', {weekday: 'short'});

    return (
        <div className="swiper-slide">
        <div className='p-3 live-class-card custom-card-shadow' style={liveCLass?.class_status === 1 ? { border : '1px solid #D0202F'} : { border : 'none'}}>
                <div className='mt-1'>
                    <p className='course-title'>{liveCLass?.course_name}</p>
                </div>

                <div className='mt-3 d-flex align-items-center mb-3'>
                    <div className='class-date-view'>
                        <p className='mb-0 text-center class-month'>{month}</p>
                        <p className='mb-0 text-center class-date'>{!isNaN(date) && date}</p>
                        <p className='mb-0 text-center class-day'>{day}</p>
                    </div>
                    <div className='ms-3'>
                        
                        <div className='d-flex align-items-center mb-2'>
                            <p className='class-subject-name mb-0 me-2'>{liveCLass?.subject_name}</p>
                            {liveCLass?.class_status === 1 && <img src={liveIcon} className="img-fluid" alt="" />}
                             
                        </div>
                        <div className='d-flex align-items-center mb-2'>
                            <img src={batchIcon} alt="" width={15} height={15}/>
                            <p className='font-400 ms-2 mb-0'>{liveCLass?.batch_name}</p>
                        </div>
                        <div className='d-flex align-items-center mb-2'>
                            <img src={clockIcon} alt="" width={15} height={15}/>
                            <p className='font-400 mx-2 mb-0'>{formatAMPM(new Date(liveCLass?.start))}</p>
                            <div className='class-time-pointer'></div>
                            <p className='font-400 ms-2 mb-0'>{convertInHours > 1 ? `${Math.round(convertInHours)} hours` : `${convertInHours*60} minutes`}</p>
                        </div>
                    </div>
                </div>

                {/* mentor section */}
                <div className='mentor-section'>

                    <div className='course_name_area'>
                        <div className='d-flex align-items-center'>
                            <div className="avatar-group ">

                                    {
                                        liveCLass?.teachers?.slice(0,2).map((teacher,index) => 
                                            <div className= { index === 0 ? "avatar" : "avatar avatar-animated"} key={index}>
                                                <img src={`${teacher?.teacher_image_path}${teacher?.teacher_image}`} alt='teacher'/>
                                            </div>
                                        )
                                    }

                                <div className="avatar hidden-avatars" style={liveCLass?.teachers?.length  > 2? { display: "block" } : { display: "none" }}>
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                        +{liveCLass?.teachers?.length - 2}
                                        </div>
                                    </div>
                            </div>
                            <p className='mb-0 ms-2 class-teacher-name'>{liveCLass?.teachers[0]?.teacher_name} {liveCLass?.teachers?.length > 1 ? 'এবং অন্যান্য' : ''} </p>
                            
                        </div>
                    </div>

                </div>
            </div>
      </div>
    );
};

export default TomorrowLiveClass;
