import abacus from "../images/leadCollectImage/abacus.png";
import adorshoLipi from "../images/leadCollectImage/adorsholipi.png";
import programming from "../images/leadCollectImage/programming.png";
import mojarGonit from "../images/leadCollectImage/mojar-gonit.png";
import gonitOlypiad from "../images/leadCollectImage/gonit-olympiad.png";
import cadetPre from "../images/leadCollectImage/cadet-pre.png";
import cadetMission from "../images/leadCollectImage/cadet-mission.png";
import smartEnglish from "../images/leadCollectImage/smart-english.png";

const steps = [
    {
        name: 'কোর্স সিলেক্ট করুন',
        courseInfo: [
            {
                courseImage: abacus,
                courseName: "অ্যাবাকাস মাইন্ড ম্যাথ",
            },
            {
                courseImage: adorshoLipi,
                courseName: "আদর্শলিপি",
            },
            {
                courseImage: programming,
                courseName: "প্রোগ্রামিং ফর কিডস",
            },
            {
                courseImage: mojarGonit,
                courseName: "মজার গণিত",
            },
            {
                courseImage: gonitOlypiad,
                courseName: "গণিত অলিম্পিয়াড",
            },
            {
                courseImage: mojarGonit,
                courseName: "একাডেমিক প্রস্তুতি- ৭ম শ্রেণি",
            },
            {
                courseImage: cadetPre,
                courseName: "ক্যাডেট প্রি-প্রিপারেশন",
            },
            {
                courseImage: cadetMission,
                courseName: "মিশন ক্যাডেট - ২৪",
            },
            {
                courseImage: smartEnglish,
                courseName: "স্মার্ট ইংলিশ",
            },

        ],

        description: 'Vitae sed mi luctus laoreet.',
        href: '#',
        status: 'complete'
    },
    {
        name: 'আপনার সন্তান যে ক্লাসে পড়ে',
        classes: [
            {
                className: "প্রি-স্কুল",
                bgColor: "tw-bg-[#532D80]",
            },
            {
                className: "প্রথম শ্রেণি",
                bgColor: "tw-bg-[#14B8A6]",
            },
            {
                className: "দ্বিতীয় শ্রেণি",
                bgColor: "tw-bg-[#F43F5E]",
            },
            {
                className: "তৃতীয় শ্রেণি",
                bgColor: "tw-bg-[#06B6D4]",
            },
            {
                className: "চতুর্থ শ্রেণি",
                bgColor: "tw-bg-[#6366F1]",
            },
            {
                className: "পঞ্চম শ্রেণি",
                bgColor: "tw-bg-[#F59E0B]",
            },
            {
                className: "ষষ্ঠ শ্রেণি",
                bgColor: "tw-bg-[#8B5CF6]",
            },
            {
                className: "সপ্তম শ্রেণি",
                bgColor: "tw-bg-[#EC4899]",
            },
            {
                className: "অষ্টম শ্রেণি",
                bgColor: "tw-bg-[#F97316]",
            },
        ],
        description: 'Cursus semper viverra facilisis et et some more.',
        href: '#',
        status: 'current',
    },
    {
        name: 'অভিভাবকের তথ্য',
        description: '',
        href: '#', status: 'upcoming'
    },
]

export default steps;