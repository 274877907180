import React from "react";
import './SmartNote.css';
import bookHalfIcon from '../../../../../../images/book-half.png';
import calendarEventIcon from '../../../../../../images/calendar-event-icon.png';
import { Link } from "react-router-dom";
import { dateConverterWithSlashFormate } from "../../../../../../utilities/utilities";

const SmartNote = ({smartNote, smartNoteLink}) => {
    return (
        <div className="col-lg-6 px-2 mt-0 mb-4">
            <div className="assignment-card custom-card-shadow">
                <div className="round-padding">
                    <p className="assignment-name mb-1">{smartNote?.smart_note_name}</p>
                    
                    <div className='d-flex align-items-center mb-2 mt-2'>
                        <img src={bookHalfIcon} className='img-fluid me-2' alt="" />
                        <p className='mb-0 assignment-due-date'>{smartNote?.subject_name}</p>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                        <img src={calendarEventIcon} className='img-fluid me-2' alt="" />
                        <p className='mb-0 assignment-due-date'>প্রকাশের তারিখ :  <span className="balooda-medium">{dateConverterWithSlashFormate(new Date(smartNote?.smart_note_publish_date))}</span></p>
                    </div>

                    {/* {
                        smartNote?.smart_note_pdf_file && <a href={`${smartNoteLink}${smartNote?.smart_note_pdf_file}`} target="_blank" className="btn-view-lecture">ডাউনলোড করুন</a> 
                    } */}
                    {
                        smartNote?.smart_note_pdf_file && <Link to='/view-smart-note?class_content=smart_note' 
                            state={{ smartNoteLink: smartNoteLink, 
                            smart_note_pdf_file: smartNote?.smart_note_pdf_file,
                            course_id: smartNote?.course_id, 
                            batch_id: smartNote?.batch_id}} 
                            className="btn-view-lecture">View Smart Note</Link>
                    }

                    

                    {/* <a href={smartNote?.pdf_link} className="btn-view-lecture">ডাউনলোড করুন</a> */}
                </div>
            </div>
        </div>
    );
};

export default SmartNote;
