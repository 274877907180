import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import syllabusLink from '../../../.../../../../../../images/box-arrow-up-right.png';
import syllabusLock from '../../../.../../../../../../images/syllabus-lock-fill.png';
import { englishToBengaliNumberFormat, topicType } from '../../../../../../../utilities/utilities';
import AssessmentModal from './AssessmentModal/AssessmentModal';

const Topics = ({ topic, topicSerial, LessonSerial, lessonName, unlock, lessons }) => {
    const [assignmentModalShow, setAssignmentModalShow] = useState(false);
    const [showUnlock, setShowUnlock] = useState(false);
    const [stop, setStop] = useState(true);
    const [syllabusContent, setSyllabusContent] = useState({});
    const [openTopic, setOpenTopic] = useState(false);
    const [progressPercent, setProgressPercent] = useState(false);

    useEffect(() => {
        // Fetch syllabus content from API
        async function fetchMyAPI() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL_STUDENT}get-topic-assesment?topic_id=${topic?.topic_id}&user_id=${localStorage.getItem("user_id")}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'accept': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                });
            response = await response.json();
            setSyllabusContent(response.data.topic);
        }
        fetchMyAPI();
    }, [topic?.topic_id]);

    // Unlock the first topic of the next lesson if the last topic of the current lesson is viewed
    if (topicSerial === 0 && LessonSerial > 0 && stop) {
        if (lessons[LessonSerial - 1]?.topics[lessons[LessonSerial - 1]?.topics?.length - 1]?.is_viewed === 1 && lessons[LessonSerial]?.topics[0]?.is_viewed === 0) {
            setShowUnlock(true);
            setStop(false);
        }
    }

    let topicInfo;
    if(syllabusContent){
        if (syllabusContent.assesment_type === 6) {
            topicInfo = {
                course_id: syllabusContent?.lecture?.course_id,
                user_id: localStorage.getItem("user_id"),
                batch_id: syllabusContent?.lecture?.batch_id,
                course_subject_id: syllabusContent?.lecture?.course_subject_id,
            };
        } else if (syllabusContent.assesment_type === 5) {
            topicInfo = {
                course_id: syllabusContent?.smart_note?.course_id,
                user_id: localStorage.getItem("user_id"),
                batch_id: syllabusContent?.smart_note?.batch_id,
                course_subject_id: syllabusContent?.smart_note?.subject_id,
            };
        } else if (syllabusContent.assesment_type === 3) {
            topicInfo = {
                course_id: syllabusContent?.assignment?.course_id,
                user_id: localStorage.getItem("user_id"),
                batch_id: syllabusContent?.assignment?.batch_id,
                course_subject_id: syllabusContent?.assignment?.subject_id,
            };
        }
    }

    if(openTopic){
        async function fetchProgressData () {
            let response = await fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}get-progress-percent?batch_id=${topicInfo?.batch_id}&course_id=${topicInfo?.course_id}&user_id=${localStorage.getItem('user_id')}&course_subject_id=${topicInfo?.course_subject_id}`, {
                method: 'GET',
                headers: {
                    contentType: 'application/json',
                    'accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
                    'secretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                }
            });
            response = await response.json();
            setProgressPercent(response?.data?.progress_percent);
            setOpenTopic(false);
        }
        fetchProgressData();
    }

    return (
        <tr onClick={()=>setOpenTopic(true)}>
            <td className="topic-serial balooda-regular">
                {englishToBengaliNumberFormat(LessonSerial + 1)}.
                {englishToBengaliNumberFormat(topicSerial + 1)}
            </td>
            <td className='topic-category' onClick={unlock || topic?.is_viewed === 1 || (topicSerial === 0 && LessonSerial === 0) || topic?.lock_unlock || showUnlock ? () => setAssignmentModalShow(true) : undefined}><div className='d-flex align-items-center'>{ topicType(topic?.assesment_type) }</div></td>
            <td className='topic-serial' onClick={unlock || topic?.is_viewed === 1 || (topicSerial === 0 && LessonSerial === 0) || topic?.lock_unlock || showUnlock ? () => setAssignmentModalShow(true) : undefined}>:</td>
            <td className='topic-details' onClick={unlock || topic?.is_viewed === 1 || (topicSerial === 0 && LessonSerial === 0) || topic?.lock_unlock || showUnlock ? () => setAssignmentModalShow(true) : undefined}>{topic?.topic_name}</td>
            <td className='btn-topic-link text-end' >
                {
                    unlock  ?
                        <div className='d-flex align-items-center justify-content-end'>
                            {
                                syllabusContent?.assignment?.submission_exists ?
                                    <Link to='/view-submission?class_content=content_list'
                                          state={{ assignment_id: syllabusContent?.assignment?.id,
                                              total_attempt: syllabusContent?.assignment?.total_attempts,
                                              no_of_attempts: syllabusContent?.assignment?.no_of_attempts,
                                              subject: syllabusContent?.assignment.subject_name,
                                              total_questions: syllabusContent?.assignment?.total_questions,
                                              course_id: syllabusContent?.assignment?.course_id,
                                              batch_id: syllabusContent?.assignment?.batch_id,
                                              lessons: lessons,
                                              topic: topic,
                                              progressPercent: progressPercent,
                                              index: LessonSerial,
                                              total_marks: syllabusContent?.assignment?.total_marks }}
                                          className='btn-submission-syllabus text-center d-flex align-items-center me-3' style={{ fontSize:'12px', padding: '3.5px 15px', }}>
                                        <span className=''>সাবমিশন দেখুন</span>
                                    </Link>:" "
                            }
                            <p className='mb-0' onClick={() => setAssignmentModalShow(true)}><img src={syllabusLink} alt="" /></p>
                            <AssessmentModal show={assignmentModalShow} syllabus_content={syllabusContent}  onHide={() => setAssignmentModalShow(false)} lessonname={lessonName} topicid={topic?.topic_id} topicname={topic?.topic_name} LessonSerial={LessonSerial} lessons={lessons} topic={topic} progressPercent={progressPercent}/>
                        </div>

                        :

                        (topic?.is_viewed === 1 || (topicSerial === 0 && LessonSerial === 0) || topic?.lock_unlock || showUnlock )?
                            <div className='d-flex align-items-center justify-content-end'>

                                {
                                    syllabusContent?.assignment?.submission_exists ?
                                        <Link to='/view-submission?class_content=content_list'
                                              state={{ assignment_id: syllabusContent?.assignment?.id,
                                                  total_attempt: syllabusContent?.assignment?.total_attempts,
                                                  no_of_attempts: syllabusContent?.assignment?.no_of_attempts,
                                                  subject: syllabusContent?.assignment.subject_name,
                                                  total_questions: syllabusContent?.assignment?.total_questions,
                                                  course_id: syllabusContent?.assignment?.course_id,
                                                  batch_id: syllabusContent?.assignment?.batch_id,
                                                  lessons: lessons,
                                                  topic: topic,
                                                  index: LessonSerial,
                                                  progressPercent: progressPercent,
                                                  total_marks: syllabusContent?.assignment?.total_marks }}
                                              className='btn-submission-syllabus text-center d-flex align-items-center me-3' style={{ fontSize:'12px', padding: '3.5px 15px', }}>
                                            <span className=''>সাবমিশন দেখুন</span>
                                        </Link>

                                        :

                                        " "
                                }



                                <p className='mb-0' onClick={() => setAssignmentModalShow(true)}><img src={syllabusLink} alt="" /></p>
                                <AssessmentModal show={assignmentModalShow} syllabus_content={syllabusContent}  onHide={() => setAssignmentModalShow(false)} lessonname={lessonName} topicid={topic?.topic_id} topicname={topic?.topic_name} LessonSerial={LessonSerial} lessons={lessons} topic={topic} progressPercent={progressPercent}/>
                            </div>

                            :
                            <p className='mb-0' ><img src={syllabusLock} alt="" /></p>
                }
            </td>
        </tr>
    );
};

export default Topics;
