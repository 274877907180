import React from 'react'

const EidGiftCard = () => {
  return (
    <>
        {/* <div className='p-4 d-flex justify-content-between align-items-center'>
            <p className='mb-0' style={{ fontSize:"20px", fontWeight:'500' }}>My Attendance</p>
        </div>
        <div className='total-assignment-divider'></div>
        <div className='mt-3 ps-4'>
            <h1>My Attendance</h1>
        </div> */}
        hello
    </>
  )
}

export default EidGiftCard