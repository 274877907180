import React from 'react';
import { Link } from 'react-router-dom';
import dueTimeIcon from '../../../../../../images/due-clock-fill.png';
import bookHalfIcon from '../../../../../../images/book-half.png';
import { dateConverterEnglishWithOutYear, englishToBengaliNumberFormat, formatAMPM } from '../../../../../../utilities/utilities';
import './AssignmentCard.css';
import InstructionModal from './InstructionModal/InstructionModal';
import { useState } from 'react';

const AssignmentCard = ({assignment}) => {
    const [modalShow, setModalShow] = useState(false);
    let colors = [
        "#FDAE61",
        "#E9CB77",
        "#F46D43"

      ];
    let indexColor = Math.floor(Math.random() * colors.length);


    return (
        <div className="col-lg-6 px-2 mt-0 mb-4">
            <div className='assignment-card custom-card-shadow'>
            <div className='round-padding'>
                <span className='assignment-type' style={{ backgroundColor: colors[indexColor] }}>{assignment.assignment_type}</span>
                
                <div className='d-flex justify-content-between mt-3'>
                    <div>
                        <p className='assignment-name mb-1'>{assignment.assignment_name}</p>
                        <div className='d-flex align-items-center mb-1'>
                            <img src={bookHalfIcon} className='img-fluid me-2' alt="" />
                            <p className='mb-0 assignment-due-date'>{assignment.subject_name}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <img src={dueTimeIcon} className='img-fluid me-2' alt="" />
                            <p className='mb-0 assignment-due-date'>ডিউ ডেট {formatAMPM(new Date(assignment.assignment_due_date))}, {dateConverterEnglishWithOutYear(assignment.assignment_due_date)}</p>
                        </div>
                    </div>
                    <div>

                        {/* {
                            true ? <div className='mt-2'>
                                        <span className='assignment-marks-after-submission'>
                                            <span className='mb-0 no-marks'>20</span>
                                        </span>
                                    </div>
                                    :
                                    <div className='mt-2'>
                                        <span className='assignment-marks'>
                                        <span className='mb-0 no-marks'>N/A</span>
                                        </span>
                                    </div>

                        } */}

                    </div>
                </div>
            </div>
            <div className='total-assignment-divider'></div>

            <div className='round-padding'>
                <div className='d-flex justify-content-between align-items-center'>
                    <p className='assignment-total-numbers'><span className='balooda-regular'>{englishToBengaliNumberFormat(assignment?.total_marks)}</span> নাম্বার</p>
                    <p className='assignment-total-questions'><span className='balooda-regular'>{englishToBengaliNumberFormat(assignment?.total_questions)}</span> টি প্রশ্ন</p>
                    <p className='assignment-total-time'><span className='balooda-regular'>{englishToBengaliNumberFormat(assignment?.assignment_duration)}</span> মিনিট</p>
                    <p className='assignment-total-attempts'><span className='balooda-regular'>{englishToBengaliNumberFormat(assignment?.total_attempts)}/{englishToBengaliNumberFormat(assignment?.no_of_attempts)}</span> সাবমিশন</p>
                </div>

                

                

                 {
                    assignment?.total_attempts <= 0 ? assignment?.question_show_mode === 1 ?
                        assignment?.assignment_end_date ? (new Date().getTime() >= new Date(assignment?.assignment_end_date).getTime())? 
                    <p className='btn-examover text-center mt-3 bg-danger'>পরীক্ষার সময় শেষ</p> :
                    assignment?.is_running === 1 ?
                        <button onClick={() => setModalShow(true)} className='btn-attempt text-center mt-3'>পরীক্ষা চালিয়ে যাও</button>:
                        <button onClick={() => setModalShow(true)} className='btn-attempt text-center mt-3'>সাবমিট করুন</button>

                    :
                    assignment?.is_running === 1 ?
                        <button onClick={() => setModalShow(true)} className='btn-attempt text-center mt-3'>পরীক্ষা চালিয়ে যাও</button>:
                        <button onClick={() => setModalShow(true)} className='btn-attempt text-center mt-3'>সাবমিট করুন</button>
                    
                    
                    
                    : 
                    <button onClick={() => setModalShow(true)} className='btn-attempt text-center mt-3'>সাবমিট করুন</button> 
                    :

                    assignment?.question_show_mode === 1 ?
                    assignment?.no_of_attempts <= assignment?.total_attempts ?

                    <Link to='/view-submission' 
                              state={{ assignment_id: assignment?.id,
                                       total_attempt: assignment?.total_attempts,
                                       no_of_attempts: assignment?.no_of_attempts,
                                       subject: assignment.subject_name,
                                       total_questions: assignment?.total_questions,
                                       course_id: assignment?.course_id,
                                       batch_id: assignment?.batch_id,
                                       total_marks: assignment?.total_marks }} 
                              className='btn-course text-center mt-3'>সাবমিশন দেখুন</Link>
                    :
                    <div className="row mx-0 gx-2">
                        <div className="col-lg-6 ps-0">
                        <Link to='/view-submission' 
                              state={{ assignment_id: assignment?.id,
                                       total_attempt: assignment?.total_attempts,
                                       no_of_attempts: assignment?.no_of_attempts,
                                       subject: assignment.subject_name,
                                       total_questions: assignment?.total_questions,
                                       course_id: assignment?.course_id,
                                       batch_id: assignment?.batch_id,
                                       total_marks: assignment?.total_marks }} 
                              className='btn-course text-center mt-3'>সাবমিশন দেখুন</Link>
                        </div>
                        
                        
                        {
                            assignment?.assignment_end_date ? 
                                (new Date().getTime() >= new Date(assignment?.assignment_end_date).getTime())? 
                                <div className="col-lg-6 pe-0">
                                    <p className='btn-examover text-center mt-3 bg-danger'>পরীক্ষার সময় শেষ</p>
                                </div>
                                :

                                assignment?.is_running === 1 ?
                                <div className="col-lg-6 pe-0">
                                    <button onClick={() => setModalShow(true)} className='btn-attempt text-center mt-3'>পরীক্ষা চালিয়ে যাও</button>
                                </div>
                                :
                                <div className="col-lg-6 pe-0">
                                    <button onClick={() => setModalShow(true)} className='btn-attempt text-center mt-3'>সাবমিট করুন</button>
                                </div>
                            :

                            assignment?.is_running === 1 ?
                            <div className="col-lg-6 pe-0">
                                <button onClick={() => setModalShow(true)} className='btn-attempt text-center mt-3'>পরীক্ষা চালিয়ে যাও</button>
                            </div>
                            :
                            <div className="col-lg-6 pe-0">
                                <button onClick={() => setModalShow(true)} className='btn-attempt text-center mt-3'>সাবমিট করুন</button>
                            </div>

                        }




                       
                    </div>
                    :

                    assignment?.no_of_attempts <= assignment?.total_attempts ?
                    <Link to='/view-submission' 
                              state={{ assignment_id: assignment?.id,
                                       total_attempt: assignment?.total_attempts,
                                       no_of_attempts: assignment?.no_of_attempts,
                                       subject: assignment.subject_name,
                                       total_questions: assignment?.total_questions,
                                       course_id: assignment?.course_id,
                                       batch_id: assignment?.batch_id,
                                       total_marks: assignment?.total_marks }} 
                              className='btn-course text-center mt-3'>সাবমিশন দেখুন</Link>
                    :
                    <div className="row mx-0 gx-2">
                        <div className="col-lg-6 ps-0">
                        <Link to='/view-submission' 
                              state={{ assignment_id: assignment?.id,
                                       total_attempt: assignment?.total_attempts,
                                       no_of_attempts: assignment?.no_of_attempts,
                                       subject: assignment.subject_name,
                                       total_questions: assignment?.total_questions,
                                       course_id: assignment?.course_id,
                                       batch_id: assignment?.batch_id,
                                       total_marks: assignment?.total_marks }} 
                              className='btn-course text-center mt-3'>সাবমিশন দেখুন</Link>
                        </div>

                        <div className="col-lg-6 pe-0">
                            <button onClick={() => setModalShow(true)} className='btn-attempt text-center mt-3'>সাবমিট করুন</button>
                        </div>
                    </div>
                } 

            </div>

            <InstructionModal
                assignment_id = {assignment?.id}
                assignment_question_show = {assignment?.question_show_mode}
                assignment_time = {assignment?.assignment_duration}
                assignment_attempt = {assignment?.no_of_attempts}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            </div>
        </div>
    );
};

export default AssignmentCard;