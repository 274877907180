import React from 'react';
import './Banner.css';
/* import banner_girl from '../../../../images/course/banner_girl.png' */
import banner_girl from '../../../../../images/Academic.png';

const Banner = () => {
    return (
        <div className="academic_background">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <div>
                            <p className="banner-title">
                                পরীক্ষাভিতি আর নয়,
                                <br />
                                ক্লাসের পড়া ক্লাসেই হয়
                            </p>
                            <p className="banner-text">
                                ফ্লাশকার্ড, গেম, ফান, ছবি, এনিমেশন, পাজল আর মজার
                                <br />
                                শিক্ষা গুরুর সাথে শেখার আনন্দময় জগতে স্বাগতম।
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <img src={banner_girl} alt="" className="img-fluid pb-5" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;