import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import ExcelJS from 'exceljs';


const RegistrationLeadsList = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://new.mojaru.com/api/v1/bkash-lead-index"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setUser(data.data.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = user.slice(startIndex, endIndex);

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('RegistrationLeads');
  
    // Define column headers
    worksheet.columns = [
      { header: 'Guardian Name', key: 'guardian_name', width: 20 },
      { header: 'Student Name', key: 'student_name', width: 20 },
      { header: 'Mobile', key: 'mobile', width: 15 },
      { header: 'Class Name', key: 'class_name', width: 15 },
      { header: 'Age', key: 'age', width: 10 },
      { header: 'Comments', key: 'client_requirment', width: 20 },
      { header: 'Date', key: 'created_at', width: 15 },
    ];
  
    // Replace the following data with your actual data source
    const data = currentPageData.map(item => ({
      guardian_name: item.guardian_name,
      student_name: item.student_name,
      mobile: item.mobile,
      class_name: item.class_name,
      age: item.age,
      client_requirment: item.client_requirment,
      created_at: item.created_at.slice(0, 10),
    }));
  
    // Add data to the worksheet
    worksheet.addRows(data);
  
    // Create a buffer from the workbook
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
  
      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'registration-leads.xlsx';
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  

  return (
    <>
      <section className="BkashLeadsList">
        <Container>
          <Row>
            <Col lg={12}>
              <h2>All Registration Leads</h2>
              <button className="btn btn-primary" onClick={exportToExcel}>Download Excel</button>
              <div className="BkashTableList">
                <table>
                  <thead>
                    <tr>
                      <th>Guardian Name</th>
                      <th>Student Name</th>
                      <th>Mobile</th>
                      <th>Class Name</th>
                      <th>Age</th>
                      {/* <th>District</th> */}
                      {/* <th>Course</th> */}
                      <th>Course List</th>
                      <th>Comments</th>
                      <th>Created At</th>
                    </tr>
                  </thead>

                  <tbody>
                  {currentPageData.map((item) => (
                      <tr key={item.id}>
                      <td>{item.guardian_name}</td>
                      <td>{item.student_name}</td>
                      <td>{item.mobile}</td>
                      <td>{item.class_name}</td>
                      <td>{item.age}</td>
                      {/* <td>{item.district}</td> */}
                      {/* <td>{item.guardian_name}</td> */}
                      <td>
                        <p>{item.course_abakas_mind == 1 && "অ্যাবাকাস মাইন্ড ম্যাথ"}</p>
                        <p>{item.course_cadet_pre_preparation == 1 && "ক্যাডেট প্রি-প্রিপারেশন (৩য়, ৪র্থ ও ৫ম শ্রেণির শিক্ষার্থীদের জন্য)"}</p>
                        <p>{item.course_mission_cadet == 1 && "মিশন ক্যাডেট - ২৪ (৬ষ্ঠ শ্রেণির শিক্ষার্থীদের জন্য)"}</p>
                        <p>{item.course_smart_english == 1 && "স্মার্ট ইংলিশ"}</p>
                        <p>{item.course_math_champs_primary == 1 && "ম্যাথ চ্যাম্পস- প্রাইমারি"}</p>
                        <p>{item.course_programming_for_kids == 1 && "প্রোগ্রামিং ফর কিডস (৭ থেকে ১২ বছর বয়সী দেরা জন্য)"}</p>
                        <p>{item.course_adorso_lipi == 1 && "আদর্শলিপি (৩ থেকে ৪ বছর বয়সী দেরা জন্য)"}</p>
                      </td>
                      <td>{item.client_requirment}</td>
                      <td>{item.created_at.slice(0, 10)}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                current={currentPage}
                total={user.length}
                pageSize={itemsPerPage}
                onChange={setCurrentPage}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default RegistrationLeadsList;
