import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import logo from "../../../../images/logo.png";
import Loader from "../Loader/Loader";
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import "./Header.css";

const Header = ({location}) => {
    const [notices, setNotices] = useState([]);
    const [settings, setSettings] = useState([]);
    const [navlinks, setNavlinks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [studentInfo, setStudentInfo] = useState();
    const [language, setLanguage] = useState('');
    const {t} = useTranslation();

    //Logout
    /* const handelLogOut = (event) => {
        event.preventDefault();
        axios
            .post(`${process.env.REACT_APP_BASE_URL}api/student-logout`)
            .then((res) => {
                if (res.data.message_type === "success") {
                    localStorage.removeItem("auth_token");
                    localStorage.removeItem("auth_credential");
                    navigate("/login");
                }
            });
    }; */
    //setting
    let user = localStorage.getItem("user_id");
    useEffect(()=>{
        async function fetchWebsiteSetting(){
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/settings`
            );
                response = await response.json();
            setSettings(response.data.settings);
            }
            
        
        async function fetchMyAPI() {
          let response = await fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}profile?user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
          response = await response.json();
          setStudentInfo(response?.data?.student_detail);
        }
        if(localStorage.getItem("user_id"))
          fetchMyAPI();

        fetchWebsiteSetting()
    },[user])



    //login and logout button switch
    let AuthButton = "";
    if (!localStorage.getItem("auth_token")) {
        AuthButton = (
            <Link to="/login" style={{ border: "none" }} className="nav-item px-4 ms-2 py-2 rounded-3 text-white bg_color_blue a_tag_decoration">
                {t("LogIn")}
            </Link>
        );
    } else {

        AuthButton = (
            <Link
                to="/dashboard"
                style={{ border: "none" }}
                className="nav-item px-4 ms-2 py-2 rounded-3 text-white bg_color_blue a_tag_decoration border-none"
            >
                {/* ড্যাশবোর্ড */}{studentInfo?.user_name ? studentInfo?.user_name : 'Student Name'}
            </Link>
        );
    }



    //get navlink
    useEffect(() => {
        async function fetchNotice() {
            let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/course-categories`);
                data = await data.json();
                setNavlinks(data?.data?.course_categories);
                setIsLoading(false);   
        }
        fetchNotice();
    }, []);
    //get notice
    useEffect(() => {
        async function fetchNotice() {
            let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/notices`);
                data = await data.json();
                setNotices(data?.data);
                setIsLoading(false);   
        }
        fetchNotice();
    }, []);

    
    //Language Change button 
    useEffect(() => {
        const useLanguage = sessionStorage.getItem('useLanguage');

        if(!useLanguage){
            sessionStorage.setItem('useLanguage', 'bn');
            i18next.changeLanguage('bn');
            setLanguage('bn');
        }else{
            i18next.changeLanguage(useLanguage);
            setLanguage(useLanguage);
        }
    }, []);


    function toggleLanguage() {
    const getLanguage = sessionStorage.getItem('useLanguage')
        
        if(getLanguage === 'bn'){
            sessionStorage.setItem('useLanguage', 'en');
            i18next.changeLanguage('en');
            setLanguage('en');
        }else{
            sessionStorage.setItem('useLanguage', 'bn');
            i18next.changeLanguage('bn');
            setLanguage('bn');
        }
    }


    if(isLoading){
        return (<div className="pos-center">
                    <Loader></Loader>
                </div>);
    }





    return (
        <section className="sticky-top" style={ location === "/login" || location === "/registration" || location === "/otp" ? { display: "none" } : { display: "block" }}>
            <header className="bottom-border">
                {/* <div className="bg_color_blue">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-2 col-sm-2 col-2 py-3 d-flex align-items-center header-link">
                                <div>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-decoration-none"
                                        href={settings?.website_facebook_link}
                                        title="Go to facebook page"
                                        aria-label="Go to facebook page"
                                    >
                                        <i className="fa-brands fa-facebook ps-3 text-white"></i>
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-decoration-none"
                                        href={settings?.website_linkdein_link}
                                        title="Go to linkdein page"
                                        aria-label="Go to linkdein page"
                                    >
                                        <i className="fa-brands fa-linkedin ps-3 text-white"></i>
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-decoration-none"
                                        href={settings?.website_instagram_link}
                                        title="Go to instagram page"
                                        aria-label="Go to instagram page"
                                    >
                                        <i className="fa-brands fa-instagram ps-3 text-white"></i>
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-decoration-none"
                                        href={settings?.website_youtube_page_link}
                                        title="Go to youtube page"
                                        aria-label="Go to youtube page"
                                    >
                                        <i className="fa-brands fa-youtube ps-3 text-white"></i>
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-decoration-none"
                                        href={settings?.website_tiktok_link}
                                        title="Go to tiktok page"
                                        aria-label="Go to tiktok page"
                                    >
                                        <i className="fa-brands fa-tiktok ps-3 text-white"></i>
                                    </a>

                                    <ReactWhatsapp number={`${settings?.whatsapp_number}`}  style={{ border:'none', backgroundColor:'transparent' }} aria-label="whats-message"
                                    aria-labelledby="whats-message-label">
                                        <i className="fa-brands fa-whatsapp ps-lg-3 ps-2 text-white icon-font-size"></i>
                                    </ReactWhatsapp>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-11 col-sm-11 col-11 d-flex justify-content-center align-items-center">
                                <marquee direction="left">
                                    {notices?.map((notice) => (
                                        <p
                                            key={notice.id}
                                            className="d-inline text-decoration-none text-white fs-4 pe-5 mb-0"
                                        >
                                            ***{notice.notice_title}
                                        </p>
                                    ))}
                                </marquee>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-3 d-flex justify-content-end align-items-center py-3 header-contact">
                                <div className="switch">
                                    <div className="switch">
                                        <input id="language-toggle" className="check-toggle check-toggle-round-flat" type="checkbox" checked={language === 'en'}  onChange={toggleLanguage} />
                                        <label htmlFor="language-toggle"></label>
                                        <span className='on' value='bn'>BN</span>
                                        <span className='off' value='en'>EN</span>
                                    </div>
                                </div>



                                <i className="fa-solid fa-envelope-open pe-2 pt-1 text-white"></i>
                                <Link to="/contact" className="text-decoration-none" >
                                    <p className="text-white text-decoration-none mb-0 mt-1">
                                        {t('Contact Us')}
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div> */}

                <nav
                    className="navbar navbar-expand-lg navbar-light top-header"
                >
                    <div className="container">
                        <Link to="/" className="navbar-brand">
                            <img className="tw-w-[160px]" src={logo} height="35" alt="" />
                        </Link>
                        <Button
                            type="button"
                            className="navbar-toggler"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse"
                            style={{ backgroundColor: "white", border: "none" }}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </Button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav ms-auto menu5" style={{ fontSize: "20px" }}>
                                <NavLink to="/" className="nav-item nav-link me-1 custom">
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        {t('Home')}
                                    </span>
                                </NavLink>

                                {navlinks.map((navlink, index) => (
                                    <NavLink
                                        to={`/${navlink.course_category_link}`}
                                        state={{
                                            from: `${navlink.course_category_link}`,
                                            enable: `${navlink.is_enabled}`
                                        }}
                                        key={index}
                                        className="nav-item nav-link me-1 custom"
                                        
                                    >
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        { sessionStorage.getItem('useLanguage') == 'en' ? navlink.course_category_en_name : navlink.course_category_name}
                                    </span>
                                    </NavLink>
                                ))}
                                <NavLink to="/e-book" className="nav-item nav-link me-1 custom"> 
                                    <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                        {t("E-Book")}
                                    </span>
                                </NavLink>
                            </div>

                            <div id="btn-login" className="pt-2">
                                {AuthButton}
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="switch">
                                    <div className="switch">
                                        <input id="language-toggle" className="check-toggle check-toggle-round-flat" type="checkbox" checked={language === 'en'}  onChange={toggleLanguage} />
                                        <label htmlFor="language-toggle"></label>
                                        <span className='on' value='bn'>BN</span>
                                        <span className='off' value='en'>EN</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            {/* <div className="top-yellow-line"></div> */}
        </section>
    );
};

export default Header;
