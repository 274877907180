import React from 'react';
import calendarEventIcon from '../../../../../../images/calendar-event-icon.png';
import userIcon from '../../../../../../images/blank.png';
import noticeTopicIcon from '../../../../../../images/notice-topic-icon.png';
import './Notice.css';
import { useState } from 'react';
import NoticeDetails from './NoticeDetails/NoticeDetails';
const Notice = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <div className="col-lg-4 px-2 mt-0 mb-4">
            <div className="assignment-card custom-card-shadow">
                <div className="round-padding">
                    <div className="row">
                        <div className="col-lg-2 col-md-2 d-flex justify-content-center">
                            <div className='notice-img'>
                                { false ? (
                                    <img
                                        src=''
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        src={userIcon}
                                        alt=""
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-10">
                            <p className="assignment-name mb-1">নোটিসের টাইটেল</p>
                            
                            <div className='d-flex align-items-center mb-2 mt-2'>
                                <img src={noticeTopicIcon} className='img-fluid me-2' alt="" />
                                <p className='mb-0 assignment-due-date'>নোটিসের ধরন</p>
                            </div>
                            <div className='d-flex align-items-center mb-3'>
                                <img src={calendarEventIcon} className='img-fluid me-2' alt="" />
                                <p className='mb-0 assignment-due-date'>প্রকাশের তারিখ :  <span className="balooda-medium">৪/০৯/২০২২</span></p>
                            </div>
                        </div>
                    </div>
                    <p className="btn-view-lecture mb-0" onClick={() => setModalShow(true)}>বিস্তারিত দেখুন</p>
                    <NoticeDetails show={modalShow} onHide={() => setModalShow(false)}/>
                    
                </div>
            </div>
        </div>
    );
};

export default Notice;