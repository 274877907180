import React from 'react';

const ServiceAgreement = () => {
    return (
        <div className="container tw-mb-96">
            <h1  className="tw-text-center tw-mt-8 tw-mb-2 tw-border-b-2 tw-pb-4 tw-text-4xl">Service Agreement</h1>
            <div className="tw-mt-6">
                <h3 className="tw-mt-4 twmb-3">Shared Hosting, Premium, Unlimited, VPS & all Hosting Services:</h3>
                <p className="tw-p-2"> Hardware Replacement Service Level Agreement — Dedicated Services Only
                    mojaru Solutions guarantees that in the event of a dedicated server hardware failure, the faulty hardware will be replaced, in most
                    cases, within 30 to 60 Minutes of identifying the problem. If this guarantee is not met, mojaru Solutions will issue a credit for ten times
                    the actual amount of additional downtime recorded by service monitoring logs. The amount of compensation may not exceed the
                    customer's monthly recurring charge. This guarantee does not include the time it requires to perform additional software-related
                    maintenance, including rebuilding web accounts from backups, cloning hard drives, reloading the operating system, reloading, and

                    configuring applications, or rebuilding RAID arrays.
                </p>
                <h3 className="tw-mt-4 twmb-3">Network Uptime Service Level Agreement - For all Services</h3>
                <p className="tw-p-2">mojaru Solutions guarantees network uptime to be 99.9%. This guarantee assures that all major routing devices within our network
                    are reachable from the global internet 99.9% of the time.

                    To follow our network status, know where your data center location is & track it down from the graph: https://status.mojaruxyz or, for

                    any incident of your hosting server track it down from here: https://secure.mojaru.com.</p>

                <h3 className="tw-mt-4 twmb-3">Network SLA Exclusions</h3>
                <p className="tw-p-2">Many possible situations are completely beyond the control of mojaru Solutions and therefore are not in the scope of this SLA. These
                    situations include:</p>

                <h4 className="tw-mt-2 tw-mb-2">Scheduled Network Maintenance </h4>
                <p className="tw-p-2">- occasionally network maintenance will be required. mojaru Solutions will do everything
                    possible to minimize and avoid downtime during this maintenance. You will receive prior notification of upcoming maintenance at
                    the e-mail address we have on file. Scheduled maintenance periods are not eligible for SLA credits.</p>

                <h4 className="tw-mt-2 tw-mb-2">Hardware Maintenance</h4>
                <p className="tw-p-2">- on rare occasions, the hardware in your Dedicated Server may need maintenance or replacement
                    mojaru Solutions will do everything possible to minimize any downtime in these situations per our hardware replacement SLA.
                    Any downtime incurred as a result of this maintenance will not be counted towards our network SLA.</p>

                <h4 className="tw-mt-2 tw-mb-2">Software Maintenance</h4>
                <p className="tw-p-2">— an important part of managing a Dedicated Server is keeping the software up to date. If you choose to
                    have mojaru Solutions manage your server, occasional software updates will be required to address security or performance
                    issues. Usually, you will experience little or no downtime in these situations, but we cannot guarantee a specific amount of time in
                    all situations.</p>

                <h4 className="tw-mt-2 tw-mb-2">Malicious Attacks</h4>
                <p className="tw-p-2">- If a third party not associated with mojaru Solutions initiates a “Denial of Service” or another form of
                    disabling attack against your Dedicated Server or major portions of our network, mojaru Solutions will do everything in its power
                    to stop the attack but cannot guarantee a resolution time. Thus, we do have DDOS, Malware, and SPAM protection, but sometimes
                    it can fail & we have to act manually. So, it will need some time to fix these types of issues. Sometimes our clients’ hosting or server
                    & application development or faulty development or backdated programming/ developed application will cause malicious/
                    malware/ virus attacks. For this reason, we don’t give any compensation or guarantee. If you have a nulled script that can carry a
                    virus or null script or mining script, we have zero tolerance for this, we will notify & suspend the service</p>

                <h4 className="tw-mt-2 tw-mb-2">Legal Actions</h4>
                <p className="tw-p-2">- In the case that legal action is taken against a customer of mojaru Solutions and mojaru Solutions is
                    required to act following the order, mojaru Solutions shall not be responsible for any SLA damages.</p>

                <h4 className="tw-mt-2 tw-mb-2">cPanel Issues</h4>
                <p className="tw-p-2">if you choose to run cPanel and Web Host Manager on your Dedicated Server, the default install will be configured
                    for automatic updates of cPanel-related software. On occasion, one of these automatic updates could adversely affect all or part
                    of the cPanel-related software on your server. mojaru Solutions will do whatever is necessary to fix any cPanel-related

                    problems but cannot guarantee a resolution time.</p>

                <h3 className="tw-mt-4 twmb-3">Network SLA Remedy - For all Services</h3>
                <p className="tw-p-2">If mojaru Solutions does not meet this SLA, Dedicated Hosting clients will become eligible to request compensation for downtime
                    reported by service monitoring logs. If |echAByte SOlUtIONS Is OF IS NOt directly responsible tor causing the downtime, the customer will
                    receive a credit for 10 times (1,000%) the actual amount of downtime. This means that if your server is unreachable for 1 hour (beyond
                    the 0.0% allowed), you will receive 10 hours of credit in your account.</p>

                <p className="tw-p-2">All requests for compensation must be received within 5 business days of the incident in question. The amount of compensation may
                    not exceed the customer's monthly recurring charge. This SLA does not apply for any month that the customer has been in breach of

                    mojaru Solutions Terms of Service or if the account is in default of payment.</p>

                <h2>Response Time SLAs - For all Services</h2>
               <p className="tw-p-2"> yte Solution’s fully and cor

                   itial response time guarantees:

                   Client Area Support Ticket Initial Response Time Guarantee
                   ¢ Phone Answer Time Guarantee

                   LiveChat Initial Response Time Guarantee

                   Email Initial Response Time Guarantee

                   Whatsapp/ Discord/ Facebook Messenger Initial Response Time Guarantee
                   * Client Area Support Ticket Initial Response Time Guarantee ~- For alll Service

                   ‘Client Portal/ area: https://secure.mojaru.com —
                   Support Ticket Location: https://secure.mojaru.com php
                   Finding your support tickets: https://secure.mojaru.com

                   We are open 24 Hours a Day, 7 Days a week & 365 Days a year for queries, sales, funds, technical, development & hosting server support
                   — Reply time is instant to 10 hours with our support ticket system.

                   That means that when you submit a trouble ticket via our Support Ticket system you are guaranteed a response from a support
                   techni

                   jan within instant to 10 hours. If mojaru Solutions fails to respond within 10 Hours, you will be credited 2 times the amount of
                   time exceeding our SLA commitment. That means that if your ticket goes 1 hour past our stated initial response time guarantee, your

                   account will receive 2 hours of hosting credit.

                   For other services like content, video, digital marketing, web services, Saas, any development, SMS, email & others our team will
                   response at office hour & office days. Our creative & development services support time is 1:00 AM to 06:00 PM (+6 GMT). From Sunday
                   to Thursday.

                   1. Phone Answer Time Guarantee — For all Services
                   We guarantee that a live support agent will answer your phone call within 59 seconds from the time you select the department
                   you wish to speak with. If mojaru Solutions fails to respond within fifty-nine (59) seconds, you will be called back from support.
                   Here is our Official time of response:

                   Phone Number of Bangladesh: Official Working Hours — 10 am to 6 pm (Friday & Saturday Closed) | +06:00 GMT DHAKA time zone.
                   Sales, Query, Network & Critical Development or Hosting Support

                   International Number of USA: Official Working Hours ~ 10 am to 5 pm (Sunday Off) | -04:00 GMT NYC time zone. Only Pre-Sales &

                   network status queries.

                   Callback Request via chat or website: Our support team or sales team will reach you in the next 24 to 72 hours.2. LiveChat Ini

                   Time —Forall
                   We guarantee to have a live support agent answer your LiveChat within 59 seconds to 10 minutes after selecting the department
                   and accurately completing the pre-chat survey questions. If mojaru Solutions fails to respond within the time, you can simply
                   knock us in our official Facebook messenger, WhatsApp, mail, support ticket, or give us a call. If you want us to call you, just send us
                   callback request.

                   3. Email Initial Response Time Guarantee
                   We prioritize email for sales, support, or queries. We will reply or get back to you within 25 minutes to 10 Hours. Our email support for
                   ‘adding funds, technical support, billing, domain, hosting, development, or for a price quotation or agreement is open 7 days a week & 24
                   hours a day. Mail us via: support@mojaru.com, support@mojaru.com

                   We do late responses for price quotations as our engineers research the requirements properly & meet the exact solutions for you &
                   your desire. It can take 5 Hours to 72 Hours from your accepted request time.

                   4. ipp/ Discord/ ger Initial Resp Time
                   Whatsapp, Facebook Messenger, and Discord public chat take 5 mins to 5 hours response time, from 10 am to 10 pm, +6 GMT. This

                   setvice is for pre-sales & basic support only.

                   We won't be able to reply instantly while there is an International / global holiday or a Bangladesh Govt holiday for BD or, a USA Govt

                   holiday for America.

                   In such case, you are requested to contact support ticket via accessing the client area or mailing us via email.</p>

                <h3 className="tw-mt-4 twmb-3">Compensation</h3>

                <p className="tw-p-2">All requests for compensation must be received within 5 business days of the incident in question. The amount of compensation may
                    not exceed the customer's monthly recurring charge. This SLA does not apply for any month that the customer has been in breach of
                    mojaru Solutions Terms of Service or if the account is in default of payment. Duplication of SLA compensation is not allowed. Only one
                    SLA credit may be given per incident. For example: if a network outage SLA event and a phone SLA event happen simultaneously. SLA

                    compensation will only be given for one of the events.

                    mojaru Solutions utilizes some third-party services to respond to customers. These include but are not limited to, our LiveChat
                    system. If a third-party system’s failure prevents mojaru Solutions from honouring the Response Time SLA requirements the SLA
                    event will be ineligible for compensation.</p>

                <h3 className="tw-mt-4 twmb-3">30 Days Money Back Guarantee</h3>

                <p className="tw-p-2">‘Our Quality Guarantee of 30 Days!

                    ‘We are confident in our service. We offer a refund to every customer within 30 days of their registration. If you are dissatisfied with our
                    web hosting service, you may choose to cancel your subscription for a full refund. We offer a refund to every shared, reseller, and VPS
                    server web-hosting customer within 30 days of their registration. If you are dissatisfied with our shared, VPS, dedicated web hosting
                    service, you may choose to cancel your subscription for a full refund.

                    If you apply to the terms stated above, you may request, under this guarantee, a full refund consisting of your purchase price of shared,
                    reseller, and VPS server hosting service provided by mojaru Solutions, less any charges for domain name registration, licensing fee,
                    payment gateway fee, additional paid service like SiteLack / Spamexperts, CodeGuard, Backup Services or any type of Web Designing
                    and Development, Maintenance, Security service, Video Promo, etc., all of which are non-refundable.

                    Any free service like Free Domain registration/renew/transfer or SSL or SiteLock etc. that is included as free with any hosting plan will be
                    immediately suspended once the refund proceeds. But if the customer wants to use them with any of our other hosting services/plans
                    or even wants to use them with any other web hosting service provider, they will have to pay for them at the regular price as mentioned
                    on our website.
                    Ifaclient’s account is not verified, that client will not get any refund. The client must provide his/her real first name and last name and
                    the necessary documents to verify his account as mentioned in our terms of service. The money-back request is only applicable once
                    per client for the first service only. If you have a previous history with a money back for any purchased service from us, then you are no
                    longer eligible for the 30-day money back.

                    You can make this procedure happen by opening a ticket in your client area & describing the matter/ disappointment.

                    For Services: Web Di pI Web Design, ec >, Saas, Management Systern, Automation, SEO, SEM, Digital
                    Marketing, Social fing, SMS & Email ing, yte MTools, Promotion, Campaigns, Video, TVC/ OVC, Promo/

                    Animation/Sstorytelling Videos, Copy, Design, Content, etc.

                    The client who has paid for the service will only get the related service & support.

                    For example, one having a web hosting service with mojaru won't be able to get any service or support for web design, development,
                    ‘or any etror fix. But They can have any support or service for SSL with hosting, changing PHP version, or any hosting or server-related

                    service.

                    For extra service, a support or sales team member will contact the client & provide a quotation or invoice for the service. After

                    acceptance by quotation or paying the invoice for that service, the related service only can be provided.

                    If the client doesn't provide any necessary information or required content on time (scheduled date), that impacts our workflow and
                    delivery time of the service or project; in that case, we will provide 3 working days (additional) for the client to submit the necessary
                    information or required content.

                    If we miss the deadline due to a lack of information and content that we require from the client, extra charges will be included for each

                    feature or service needed to finish the incomplete task as per our related service pricing.

                    If we fail to deliver any service or project on the due date, we will provide 10 days of extra support or extend the revision period at the
                    client's convenience.

                    Developer Attribution: The client agrees to display mojaru Solutions’ name/logo/tags with a hyperlink on the footer of each page of
                    the website. Optional: The client also agrees to include Developer's name and logo in the website's metadata, with a brief description of
                    mojaru Solutions’ role and services. The client must not remove, modify, or obscure mojaru Solutions attribution without a prior

                    paid/signed agreement between both sides. in this matter verbal agreement is not considerable.

                    Note: If mojaru Solutions finds out that the Client’s website or, motive/conduct is harmful to the society/country or spreading 18+
                    contents/terrorism/breaching community guidelines, mojaru Solutions may request and have the rights to remove the attribution
                    from the website.

                    ‘You can view our packages from the client area for full features, requirements, edits, limitations, access, number of pages, number of
                    total revisions, maximum uploads & other options. These are just limited, we cannot give you unlimited services for a fixed price, but you
                    are always welcome to meet your requirements upon support, mail, over the phone office meeting, or online call. You will be given a
                    Quotation after the agreement & a Final Invoice will be mailed via our official mail or if you have an account in our client portal you will
                    be given the invoice there in the billing section. 85% of workings are based on what the consumers need from our client's brand. So, we

                    give priority to what you need rather than what we are providing in the packages. These are for the newbie or those who want basic or
                    informative websites. These apply for Website Design and development, WordPress or Other CMS, News, or any website design &
                    development of business or e-commerce and website customization and Editing. (Without the system of Web Application Solutions like

                    ‘CRM, Management Systems, Restaurants, Gym, Hospital, Education, or any custom web application).

                    The Bill is DUE after indicating and invoice generation upon agreements between mojaru Solutions & It’s Clients. Minimum 50% or
                    upon agreement the invoice should be paid before working. Before Delivery, you will be given to cross-check if there are any mistakes or
                    bugs. You can revise max time of the packages or if you are not our package client you can revise a maximum of 3 times for small or
                    informative websites & 6 times for Quality and big project Web Development.

                    For custom features, requirements, problem-solving, auditing, SEO, analytics, social marketing, campaigns, lead generation, and web
                    services we do not give any guarantee of the results.

                    Once the Website is delivered, we request to change your passwords for emails & panels. We do not take any liabilities after the delivery.
                    ‘We only give support for the existing system we developed. If we find any logs & any changes on the website you have made or any
                    other from your panel in the logs or network, we won't be able to give you our support for free. Please follow our Terms of Services page
                    for detailed Service Coverage.</p>

                <h3 className="tw-mt-4 twmb-3">Terms of Service:</h3>
                <p className="tw-p-2">By accessing our website & services, we assume you accept these terms and conditions in full.

                    Do not continue to use mojaru’s website if you do not accept all the terms and conditions stated on this page.

                    To read the full Tos:
                    https://mojaru.com/tos</p>

                <h3 className="tw-mt-4 twmb-3">Support Policy:</h3>

                <p className="tw-p-2">Response & Communication — Service-related communication will be in groups created by the Client or, mojaru where the Project
                    Manager or Assigned Supervisor will monitor everything including the professional behavior of the assigned team between mojaru
                    Solutions and the Client.

                    NB. Any personal communication with any mojaru Employee is prohibited over any channel. if any client tries to do so, mojaru
                    Solutions can't take any liabilities if the policies and agreement are broken by the employee and client because of the communication

                    made between them which is uncontrolled and unmonitored by the management team.

                    For strategy sharing, content-related queries, and other cases, both mojaru and Client will conduct meetings(Google Meet)
                    approved by the Team Leader or, Project Manager, where meeting reports must be taken for monitoring and tracking purposes.
                    Reporting — For Digital Marketing(SMM, SEO, SEM, SMS, EMAIL)
                    * Once a month for each & every client a promotional report will be provided with billing.
                    * For clients who spend over 1,500 to 10,000 USD in a month, mojaru is bound to provide the report Two times a month if requested
                    by the Client.
                    * For clients who spend over 10,000 USD in a month, mojaru is bound to provide the report Four times a month if requested by the
                    Client.
                    * Other than the above scenarios, 3000 BDT will be charged per month for 5-time reporting & two sessions for strategy. If clients
                    don't meet the criteria & still wish to receive reports 4 to 6 times in a month Support Add on Charges will be added.

                    8/5 Support: mojaru Solutions will provide 5 days(Sunday to Thursday), from 10.30am to 6.30pm support. Where our response time
                    could be 15 minutes to 4 hours depending on tasks and services.

                    Other than for updates/questions/new boost/content-related queries will be replied to on the next working day.

                    Emergency Support: mojaru Solutions will not provide any support over the weekend other than the 8/5 Support, except for some
                    emergency cases, stating as Ads and promotions off, Pause & Safety-Security issues.</p>

                <h3 className="tw-mt-4 twmb-3">Payment Policy:</h3>

                <p className="tw-p-2">Digital Marketing: For Digital Marketing Services new customer will pay 100% of their budget. The recurring advance is 50% of the

                    estimated budget. Another 50% or the rest will be clear through regular invoices provided by mojaru Solutions.

                    Billing for digital marketing will be provided from accounts of mojaru Solutions on the Ist day of each month, it must be clear within 7
                    days after the invoice provided. The bill will be considered as overdue on the 8th day, and on the 14th day Ads and Promotions will stop
                    & 5% late fee will be added to the overdue bill.

                    ‘Over 3,000 USD will be billed Twice a month with pre-payment for the USD amount(without SC & VAT).

                    Content: For Creative Content, the customer will make a 50% advance payment of the total amount.</p>

                <h3 className="tw-mt-4 twmb-3">Privacy Policy:</h3>

                <p className="tw-p-2">mojaru Solutions is committed to protecting the privacy of its customers/ Clients/ Consumers. This privacy policy governs how
                    mojaru uses, maintains, and discloses information collected from customers and users of the mojaru website. We are committed

                    to developing long-lasting relationships with clients based on trust. mojaru will do everything in its power to ensure that your right to
                    privacy is maintained and protected. To read the Privacy Policy:

                    https://mojaru.com/privacy</p>

                <h3 className="tw-mt-4 twmb-3">Complaints:</h3>
                <p className="tw-p-2">‘We would like to think that we always get it right, but unfortunately, there are times when you may feel we have got it wrong. Valid
                    feedback from our customers is an essential part of continuing to improve our services and maintain our high standards.

                    We take all complaints and disputes very seriously and ensure that lessons are learned where applicable. If, unfortunately, you wish to
                    make a complaint or raise a dispute with us, please submit an email to our support team including as much detail as possible about

                    your issue. We will acknowledge your email within 1 to 3 business day/s’ and aim to resolve any issues within 5 to 7 business days.</p>
            </div>
        </div>
    );
};

export default ServiceAgreement;