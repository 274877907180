import React from "react";
import './Lecture.css';
import linkIcon from '../../../../../../images/link-icon.png';
import pdfIcon from '../../../../../../images/pdf-icon.png';
import bookHalfIcon from '../../../../../../images/book-half.png';
import calendarEventIcon from '../../../../../../images/calendar-event-icon.png';
import { Link } from "react-router-dom";
import { dateConverterWithSlashFormate } from "../../../../../../utilities/utilities";

const Lecture = ({lecture}) => {
    return (
        <div className="col-lg-6 px-2 mt-0 mb-4">
            <div className="assignment-card custom-card-shadow">
                <div className="round-padding">
                    <p className="assignment-name mb-1 balooda-regular">{lecture?.lecture_name}</p>
                    <div className="d-flex align-items-center mb-1" style={{ height:'1.3rem' }}>
                        { lecture?.lecture_web_link &&  <img src={linkIcon} className='pe-2' alt="" />}
                        {/* { lecture?.video_id &&  <img src={linkIcon} className='pe-2' alt="" />} */}
                        { lecture?.lecture_file &&  <img src={pdfIcon} className='pe-2' alt="" />}
                        
                    </div>
                    <div className='d-flex align-items-center mb-2 mt-2'>
                        <img src={bookHalfIcon} className='img-fluid me-2' alt="" />
                        <p className='mb-0 assignment-due-date balooda-regular'>{lecture?.lecture_subject}</p>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                        <img src={calendarEventIcon} className='img-fluid me-2' alt="" />
                        <p className='mb-0 assignment-due-date'>প্রকাশের তারিখ :  <span className="balooda-medium">{dateConverterWithSlashFormate(lecture?.lecture_published_date)}</span></p>
                    </div>

                    <Link to='/view-lecture?class_content=lecture' state={{ lecture_id: lecture?.id, course_id: lecture?.course_id, batch_id: lecture?.batch_id,}} className="btn-view-lecture">View Lecture</Link>
                </div>
            </div>
        </div>
    );
};

export default Lecture;
