import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import "./BkashLeads.css";
import watermarkLogo from "../../../../../images/m_watermark.png";
import logo from "../../../../../images/dashboard-logo.png";
import PageTitle from "../../../shared/PageTitle/PageTitle";
import { BiPhoneCall } from "react-icons/bi";
import Footer from "../../../shared/Footer/Footer";
import { Field, useFormik } from "formik";
import { bkashValidation } from "../../../components/Formik/FormikValidation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import utils from "../../../../../utils/index";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const BkashLeads = () => {
  const initialValues = {
    guardian_name: "",
    student_name: "",
    mobile: "",
    class_name: "",
    age: "",
    district: "",
    course_abakas_mind: "",
    course_cadet_pre_preparation: "",
    course_mission_cadet: "",
    course_smart_english: "",
    course_math_champs_primary: "",
    course_programming_for_kids: "",
    course_adorso_lipi: "",
    heared_from: "",
    client_requirment: "",
  };

  useEffect(()=>{
    const pixelData = {page: 'Bkash Leads'};
    utils.addFacebookEvent('Registration', pixelData);
  },[])


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: bkashValidation,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // Disable the submit button to prevent multiple submissions
      setSubmitting(true);
      // Handle form submission here, e.g., send data to your Laravel API
      // You can use Axios or the fetch API to make API requests
      // Example:
      fetch("https://new.mojaru.com/api/v1/bkash-lead-store", {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          resetForm();
          toast.success("Thank you for Submit", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    },
  });


  return (
    <div className="BkashLeadsPage">
      <div className="Social_sideIcon">
        <a
          href="https://www.facebook.com/groups/mojaru.genius"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/fb.gif" alt="" />
        </a>
        <a
          href="https://api.whatsapp.com/send/?phone=8801610075101&text&type=phone_number&app_absent=0"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/whs.gif" alt="" />
        </a>
        <a
          href="https://www.youtube.com/@Mojarutech"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/youtube.gif" alt="" />
        </a>
      </div>
      <PageTitle title="Bkash Leads"></PageTitle>
      <div className="BkashLeads">
        <Container>
          <ToastContainer />
          <Row>
            <Col lg={12}>
              <img
                src={watermarkLogo}
                alt="Watermark Logo"
                className="watermark-logo"
              />
              {/* header */}
              <div className="header">
                <div className="left">
                 <a href='/' target='_blank'>  <img src={logo} alt="" className="img-fluid" /> </a>
                </div>
                <div className="right">
                  <img src="/images/bkash.png" alt="" className="img-fluid" />
                </div>
              </div>
              {/* video */}
              <div className="BkashLeads__video">
                <iframe src="https://www.youtube.com/embed/pvdv_d-VjDs?si=V8pN1HQYJnLrgF__" />
              </div>
              {/* Form */}
              <div className="BkashLeadsForm">
                <h3>নিচের ফর্মটি পূরণ করুন</h3>
                <form onSubmit={formik.handleSubmit}>
                  {/* item */}
                  <div className="custom_input">
                    <label>
                      অভিভাবকের নাম <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="guardian_name"
                      onChange={formik.handleChange}
                      value={formik.values.guardian_name}
                      placeholder="অভিভাবকের নাম"
                    />
                    {formik.touched.guardian_name &&
                    formik.errors.guardian_name ? (
                      <div className="error">{formik.errors.guardian_name}</div>
                    ) : null}
                  </div>
                  {/* item */}
                  <div className="custom_input">
                    <label>সন্তানের নাম</label>
                    <input
                      type="text"
                      name="student_name"
                      placeholder="সন্তানের নাম"
                      onChange={formik.handleChange}
                      value={formik.values.student_name}
                    />
                  </div>
                  {/* item */}
                  <div className="custom_input">
                    <label>
                      ফোন নাম্বার <span>*</span>
                    </label>

                    <PhoneInput
                      country={"bd"}
                      enableSearch={true}
                      value={formik.values.mobile}
                      onChange={(mobile) =>
                        formik.setFieldValue("mobile", mobile)
                      }
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <div className="error">{formik.errors.mobile}</div>
                    ) : null}
                  </div>
                  {/* item */}
                  <div className="custom_input">
                    <label>
                      আপনার সন্তান কোন ক্লাসে পড়ে <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="class_name"
                      onChange={formik.handleChange}
                      value={formik.values.class_name}
                      placeholder="আপনার সন্তান কোন ক্লাসে পড়ে"
                    />
                    {formik.touched.class_name && formik.errors.class_name ? (
                      <div className="error">{formik.errors.class_name}</div>
                    ) : null}
                  </div>
                  {/* item */}
                  <div className="custom_input">
                    <label>
                      আপনার সন্তানের বয়স <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="age"
                      onChange={formik.handleChange}
                      value={formik.values.age}
                      placeholder="আপনার সন্তানের বয়স"
                    />
                    {formik.touched.age && formik.errors.age ? (
                      <div className="error">{formik.errors.age}</div>
                    ) : null}
                  </div>
                  {/* item */}
                  {/* <div className="custom_input">
                    <label>নিজ জেলা</label>
                    <input
                      type="text"
                      name="district"
                      onChange={formik.handleChange}
                      value={formik.values.district}
                      placeholder="নিজ জেলা"
                    />
                  </div> */}
                  {/* item */}
                  <div className="custom_input full_width">
                    <label>
                      আপনার সোনামণিকে মজারুতে কোন কোর্সটি করাতে চান
                      <span>*</span>
                    </label>
                    <div className="custom_input__child">
                      <input
                        type="checkbox"
                        name="course_abakas_mind"
                        onChange={formik.handleChange}
                        value={formik.values.course_abakas_mind}
                        id="one"
                      />
                      <label htmlFor="one">অ্যাবাকাস মাইন্ড ম্যাথ</label>
                    </div>
                    <div className="custom_input__child">
                      <input
                        type="checkbox"
                        name="course_cadet_pre_preparation"
                        onChange={formik.handleChange}
                        value={formik.values.course_cadet_pre_preparation}
                        id="two"
                      />
                      <label htmlFor="two">
                        ক্যাডেট প্রি-প্রিপারেশন (৩য়, ৪র্থ ও ৫ম শ্রেণির
                        শিক্ষার্থীদের জন্য)
                      </label>
                    </div>
                    <div className="custom_input__child">
                      <input
                        type="checkbox"
                        name="course_mission_cadet"
                        onChange={formik.handleChange}
                        value={formik.values.course_mission_cadet}
                        id="three"
                      />
                      <label htmlFor="three">
                        মিশন ক্যাডেট - ২৪ (৬ষ্ঠ শ্রেণির শিক্ষার্থীদের জন্য)
                      </label>
                    </div>
                    <div className="custom_input__child">
                      <input
                        type="checkbox"
                        name="course_smart_english"
                        onChange={formik.handleChange}
                        value={formik.values.course_smart_english + 'course_smart_english'}
                        id="four"
                      />
                      <label htmlFor="four">স্মার্ট ইংলিশ</label>
                    </div>
                    <div className="custom_input__child">
                      <input
                        type="checkbox"
                        name="course_math_champs_primary"
                        onChange={formik.handleChange}
                        value={formik.values.course_math_champs_primary}
                        id="five"
                      />
                      <label htmlFor="five">ম্যাথ চ্যাম্পস- প্রাইমারি</label>
                    </div>
                    <div className="custom_input__child">
                      <input
                        type="checkbox"
                        name="course_programming_for_kids"
                        onChange={formik.handleChange}
                        value={formik.values.course_programming_for_kids}
                        id="six"
                      />
                      <label htmlFor="six">
                        প্রোগ্রামিং ফর কিডস (৭ থেকে ১২ বছর বয়সীদের জন্য)
                      </label>
                    </div>
                    <div className="custom_input__child">
                      <input
                        type="checkbox"
                        name="course_adorso_lipi"
                        onChange={formik.handleChange}
                        value={formik.values.course_adorso_lipi}
                        id="seven"
                      />
                      <label htmlFor="seven">
                        আদর্শলিপি (৩ থেকে ৪ বছর বয়সীদের জন্য)
                      </label>
                    </div>
                  </div>

                  {/* <div className="custom_input full_width">
                    <label>মজারু সম্পর্কে আপনি কথাথেকে জেনেছেন</label>
                    <select
                      name="heared_from"
                      onChange={formik.handleChange}
                      value={formik.values.heared_from}
                    >
                      <option value="default">নির্বাচন করুন</option>
                      <option value="ফ্রেন্ডস এন্ড ফ্যামিলি মাধ্যমে">
                        ফ্রেন্ডস এন্ড ফ্যামিলি মাধ্যমে
                      </option>
                      <option value="ফেসবুক বিজ্ঞাপনের মাধ্যমে">
                        ফেসবুক বিজ্ঞাপনের মাধ্যমে
                      </option>
                      <option value="মজারু জিনিয়াস গ্রুপের মাধমে">
                        মজারু জিনিয়াস গ্রুপের মাধমে
                      </option>
                      <option value="হোয়াটসঅ্যাপ মেসেজের মাধমে">
                        হোয়াটসঅ্যাপ মেসেজের মাধমে
                      </option>
                      <option value="ই-মেইলের মাধ্যমে">ই-মেইলের মাধ্যমে</option>
                    </select>
                  </div> */}

                  <div className="custom_input full_width">
                    <label>আপনার মন্তব্য</label>
                    <textarea
                      name="client_requirment"
                      onChange={formik.handleChange}
                      value={formik.values.client_requirment}
                      rows="5"
                    ></textarea>
                  </div>

                  <div className="custom_input full_width button">
                    <button type="submit">সংরক্ষণ করুন</button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* faq */}
      <section className="faq">
        <Container>
          <Row>
            <Col lg={12}>
              <h3>আপনার সোনামনির পড়ালেখা  সংক্রান্ত যেকোনো জিজ্ঞাসায় কল করুন</h3>
              {/* tabs */}
              <div className="faq__tabs">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>এটা কি অনলাইন কোর্স?</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <span> উত্তর- </span> জি, মজারুর সকল কোর্স ও ক্লাস
                        অনলাইনে অনুষ্ঠিত হয়।
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      কোর্স ম্যাটারিয়াল কিভাবে পাব?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <span> উত্তর- </span> কুরিয়ারের মাধ্যমে আপনার ঠিকানায়
                        হোম ডেলিভারি দেওয়া হবে।
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      কত বছর বয়সি শিশুরা কোর্স করতে পারবে?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <span> উত্তর- </span> মজারুতে ৩-১৩ বছর বয়সী শিশুরা ক্লাস
                        করতে পারবে।
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      সপ্তাহে কত দিন কতক্ষণ ক্লাস হয়?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>উত্তর- সপ্তাহে দুই দিন এক ঘন্টা করে ক্লাস হয়।</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      এই কোর্সে ক্লাসের পড়া পড়ানো হবে?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <span> উত্তর- </span> মজারুর কোর্সের মাধ্যেম শিশুদের
                        ব্রেইন ডেভেলপমেন্ট ও শিক্ষার প্রতি আকর্ষণ তৈরি করা হয়,
                        ফলে ক্লাসের অনেক বিষয় শিশুরা আয়ত্ত করতে পারে।
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      আপনাদের সাথে কিভাবে যোগাযোগ করব?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <span> উত্তর- </span> উপরে দেওয়া ফরমটি ফিলাপ করে দিন
                        আমাদের একজন স্টুডেন্ট কাউন্সিলর আপনাকে ফোন করে সহযোগিতা
                        করবেন অথবা সরাসরি আমাদের হেল্পলাইনে ফোন করুন-
                        <a href="09610990880">
                          <BiPhoneCall /> 09610990880
                        </a>
                         এই নাম্বারে
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* CallUs */}
      <section className="CallUs">
        <Container>
          <Row className="d_flex">
            <Col lg={6}>
              <div className="CallUs_content">
                <h3>যেকোনো প্রয়োজনে এখনই কল করুন</h3>
                <p>
                আপনার সোনামনির পড়ালেখা  সংক্রান্ত যেকোনো জিজ্ঞাসায় কল করুন, মজার শিক্ষা গুরু, মজায় শেখা শুরু

                </p>
                <h4>সকাল ৯ টা - রাত ১০ টা</h4>
                <a href="tel:09610990880">
                  <BiPhoneCall /> 09610990880
                </a>
              </div>
            </Col>
            <Col lg={6}>
              <div className="CallUs_img">
                <img src="/images/callus.webp" alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default BkashLeads;
