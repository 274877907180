import React from 'react';
/* import EaxmAttempted from './EaxmAttempted/EaxmAttempted';
import Graph from './Graph/Graph';
import TotalLearned from './TotalLearned/TotalLearned'; */
import WelcomeSection from './WelcomeSection/WelcomeSection';


const AnalyticContent = () => {
    return (
        <div className="row pe-lg-4">
            
            {/* welcomeSection */}
            <WelcomeSection></WelcomeSection>
            
            {/* total topic learn */}
            {/* <TotalLearned></TotalLearned> */}
            
            {/* time and exam attempt */}
            {/* <EaxmAttempted></EaxmAttempted> */}
            
            {/* graph */}
            {/* <Graph></Graph> */}

        </div>
    );
};

export default AnalyticContent;