import React, { useEffect, useState } from "react";
import PageTitle from "../../shared/PageTitle/PageTitle";
import Book from "./Book/Book";
import './EBook.css';
/* const Book = lazy(() => import("./Book/Book")); */
import notBookFound from '../../../../images/default_img/No-Books-Available.jpg';
import Banner from "./Banner/Banner";
import Loader from "../../shared/Loader/Loader";
/* import EbookCategories from "./EbookCategories/EbookCategories"; */
import Slider from 'react-slick';
import LeftArrow from '../../../../images/arrow_left.png';
import RightArrow from '../../../../images/arrow_right.png';
import imgCategory from "../../../../images/default_book_category.jpg";
import TagManager from "react-gtm-module";
import { useLocation } from 'react-router-dom';
import utils from "../../../../utils/index";


const EBook = () => {
    /* const [books, setBooks] = useState([]); */
    const [ebookCategories, setEbookCategories] = useState([]);
    const [ebooks, setEbooks] =useState([]);
    const [count, setCount] = useState(8);
    const [isLoading, setIsLoading] = useState(true);
    /* let componentMounted = true; */
    /* const [showPrevButton, setShowPrevButton] = useState(false);
    const [showNextButton, setShowNextButton] = useState(true); */
    let location = useLocation();

    useEffect(()=>{
        TagManager.dataLayer({
            dataLayer:{
                event: 'pageView',
                pagePath:location.pathname,
                PageTitle: 'EBook' 
            }
        })
    },[location])

    //E-book Category
    useEffect(()=>{
        const pixelData = {page: 'EBook'};
        utils.addFacebookEvent('Page View', pixelData);
    
        async function fetchEbookCategory(){
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/get-book-category`
            );
            /* if (componentMounted) { */
                response = await response.json();
                setEbookCategories(response.data);
                setIsLoading(false);
            }
        /* } */
        fetchEbookCategory()
       /*  return () => {
            componentMounted = false;
        }; */
    },[])

    
    //All E-book fetch
    useEffect(()=>{
        async function fetchEbook(){
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/book-store`
            );
            /* if (componentMounted) { */
                response = await response.json();
                setEbooks(response.data);
                setIsLoading(false);
                
            /* } */
        }
        fetchEbook()
        /* return () => {
            componentMounted = false;
        }; */
    },[])

    //book filter by category
    const handleClick =(id) =>{
        async function fetchEbook(){
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/book-category-wise-filter?book_category_id=${id}`
            );
                response = await response.json();
                setEbooks(response.data);
        }
        fetchEbook()
    }

    const handelLoadBook = () =>{
        let addCount = parseInt(count) + 4;
        setCount(addCount);
    }

    const handelBookSearch = (event) =>{
        event.preventDefault();
        const searchInfo = {
            book_category_id: event.target.book_category.value,
            book_type_id: event.target.book_type.value,
            book_writter_id: event.target.book_writter.value,
            book_publisher_id: event.target.book_publisher.value,
            book_title: event.target.book_title.value
        }


        async function fetchSearchEbook(){
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/filter-books`,{
                    method: 'POST',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                    },
                    body: JSON.stringify(searchInfo),
                }
            );
                response = await response.json();
                setEbooks(response.data);
        }
        fetchSearchEbook()
    }

    
    if(isLoading){
        return (<Loader/>);
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <img src={LeftArrow} alt="Previous" className="slick-prev" />,
        nextArrow: <img src={RightArrow} alt="Next" className="slick-next" />,
        /* beforeChange: (current, next) => {
        if (next === 0) {
            setShowPrevButton(false);
            setShowNextButton(true);
        } else {
            setShowPrevButton(true);
        } 
    }, */
    /* afterChange: current => {
      if (current === ebookCategories?.ebook_categories?.length - 5) {
        setShowNextButton(false);
      }
    }, */
        responsive: [
        {
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            dots: false
            }
        },
        {
            breakpoint: 768,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            arrows: true
            }
        }
        ]
    };

    return (
        <div>
            <PageTitle title="E-Book"></PageTitle>
            <Banner></Banner>
            <div>
                <div className="container">
                    {/* <EbookCategories ebookCategories={ebookCategories} handleClick={handleClick}/> */}

                    <Slider {...settings} className='mt-5'>
                        {
                             ebookCategories?.ebook_categories?.map(ebookCategory => 
                                <div onClick={()=>handleClick(`${ebookCategory.id}`)} style={{ cursor: 'pointer' }} key={ebookCategory.id}>
                                    <div className='d-flex justify-content-center'>
                                    
                                    {(ebookCategory.book_category_file !== undefined && ebookCategory.book_category_file !== null) ? 
                                        <img src={`${ebookCategories.ebook_category_image_path}${ebookCategory.book_category_file}`} alt="" height={81} width={84}/>
                                            : 
                                            <img src={imgCategory} alt="" height={81} width={84}/>

                                        }
                                    </div>
                                    <p className='m-0 text-center pt-2' style={{ fontSize:'21px', fontWeight: '600' }}>{ebookCategory.book_category}</p>
                                </div>
                             )
                        }
                    </Slider>
                    {/* <div>
                        <button className="slick-prev">Previous</button>
                        <button className="slick-next">Next</button>
                    </div> */}
                </div>
            </div>


        {/* Search E-Book */}
        <div className="container mt-5">
            <form onSubmit={handelBookSearch}>
                <div className="row gy-2">
                    
                    <div className="col-lg-4">
                        <div className="form-group" >
                            <input className="form-control form-control-solid" style={{ fontSize:'18px', backgroundColor:'#F3F3F3' }} placeholder="বইয়ের নাম লিখ" id="book_title" name="book_title"/>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <select className="form-select form-select-lg" style={{ fontSize:'18px', backgroundColor:'#F3F3F3' }} name='book_category'>
                            <option value=''>বইয়ের ক্যাটেগরি সিলেক্ট করুন</option>
                            {
                                ebooks?.ebook_categories?.map(ebook_category => <option key={ebook_category?.id} value={ebook_category?.id} >{ebook_category?.book_category}</option>)
                            }
                        </select>
                    </div>
                    
                    <div className="col-lg-4">
                        <select className="form-select form-select-lg" style={{ fontSize:'18px', backgroundColor:'#F3F3F3' }} name='book_type'>
                            <option value=''>বইয়ের ধরণ সিলেক্ট করুন</option>
                            {
                                ebooks?.types?.map(type => <option key={type?.id}  value={type?.id} >{type?.book_type}</option>)
                            }
                        </select>
                    </div>

                    <div className="col-lg-4">
                        <select className="form-select form-select-lg" style={{ fontSize:'18px', backgroundColor:'#F3F3F3' }} name='book_writter'>
                            <option value=''>লেখক সিলেক্ট করুন</option>
                            {
                                ebooks?.authors?.map(author => <option key={author?.id} value={author?.id}>{author?.writter_name}</option>)
                            }
                        </select>
                    </div>

                    <div className="col-lg-4">
                        <select className="form-select form-select-lg" style={{ fontSize:'18px', backgroundColor:'#F3F3F3' }} name='book_publisher'>
                            <option value=''>প্রকাশনী সিলেক্ট করুন</option>
                            {
                                ebooks?.publishers?.map(publisher => <option key={publisher?.id} value={publisher?.id} >{publisher?.publisher_name}</option>)
                            }
                        </select>
                    </div>

                    <div className="col-lg-4">
                            <input type="submit" className="form-control form-control-solid text-white" name="submit" value="Search" style={{ backgroundColor:'#532D80', height:'100%', fontSize:'1.2rem' }}/>
                    </div>


                </div>
            </form>
        </div>

           {/* <Suspense key={ebook.e_book_id} fallback={<div>Loading.....</div>}></Suspense> */} 
        
            {/* <Books books={ebooks.e_books} path_link={ebooks.ebook_image_path}></Books> */}
            
            <div className="container mt-5 mb-5" style={{ paddingBottom: "20rem" }}>
                <div className="row gy-4 m-0">
                    {ebooks?.e_books?.length > 0 ? (
                        ebooks?.e_books?.slice(0,count).map(ebook =>(
                            
                                <Book key={ebook.e_book_id} ebook={ebook} path_link={ebooks.ebook_image_path}></Book>
                            
                        ))
                    ) : (
                        <>
                        <img src={notBookFound} alt="" className="img-fluid" />
                    </>
                    )}
                </div>
                <div className="d-flex justify-content-center mt-5">
                    <button className=" btn-seeMore px-4 py-2" style={ebooks?.e_books?.length  > 0 && count < ebooks?.e_books?.length ? { display: "block" } : { display: "none" }} onClick={handelLoadBook}>
                        আরো দেখুন
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EBook;
