import React from 'react';
import './MainContent.css'
import LiveClasses from './LiveClasses/LiveClasses'
import AnalyticContent from './AnalyticContent/AnalyticContent';
import StudentPageTitle from '../../shared/StudentPageTitle/StudentPageTitle';

const MainContent = () => {
    return (
        <div className="main-content">
        <StudentPageTitle title="Dashboard"></StudentPageTitle>
            <div className="page-content p-0">
                <div className="container-fluid">
                    <div className="row m-0 p-0">
                        <div className="col-lg-9 col-md-10 col-12 order-lg-1 order-md-1 order-2 live-class-row">
                            <LiveClasses></LiveClasses>
                        </div>

                        <div className="col-lg-3 col-md-2 col-12 order-lg-2 order-md-2 order-1 analysis-section">
                           <AnalyticContent></AnalyticContent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainContent;