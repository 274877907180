import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { useState } from "react";
import AnswerImage from "./AnswerImage/AnswerImage";
import './FileSubmussion.css';

const FileSubmission = ({ question, handelFileSubmission, endSlice, index }) => {
    const [images, setImages] = useState([]);
    const [submitAnswer, setSubmitAnswer] = useState(true);
    /* const [allPath, setAllPath] = useState(""); */
    
    /* const handleMultipleImages = (evnt) => {
    const targetFiles = evnt.target.files;
    const targetFilesObject = [...targetFiles];
    let allPath = '';

    const promises = targetFilesObject.map((file, index) => {
        let data = new FormData();
        data.append("assignment_file", file);

        return fetch(
        `${process.env.REACT_APP_BASE_URL_STUDENT}file-upload-from-app`,
        {
            method: "POST",
            header: {
            "content-type": "application/json",
            },
            body: data,
        }
        )
        .then((res) => res.json())
        .then((data) => {
            if (data.message_type === "success") {
            allPath = allPath + data.data.photo + ",";
            }
            return URL.createObjectURL(file);
        });
    });

    Promise.all(promises)
        .then((selectedFiles) => {
        //console.log(allPath.replace(/,\s*$/, ""));
        handelFileSubmission(allPath.replace(/,\s*$/, ""), question?.id);
        setSubmitAnswer(true);
        setImages(selectedFiles);
        })
        .catch((error) => console.error(error));

    setSubmitAnswer(false);
    }; */

    useEffect(()=>{
        handelFileSubmission(null, question?.id);
    },[])


    const handleMultipleImages = (evnt) => {
  const targetFiles = evnt.target.files;
  const targetFilesObject = [...targetFiles];
  let allPath = '';

  const promises = targetFilesObject.map((file, index) => {
    if (file.size < 500000) {
    // if file size is less than or equal to 0.5MB, resize the image
      
      let data = new FormData();
      data.append("assignment_file", file);

      return fetch(
        `${process.env.REACT_APP_BASE_URL_STUDENT}file-upload-from-app`,
        {
          method: "POST",
          header: {
            "content-type": "application/json",
          },
          body: data,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.message_type === "success") {
            allPath = allPath + data.data.photo + ",";
          }
          return URL.createObjectURL(file);
        });
    } else {
      // if file size is greater than 1MB, upload as is
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          const img = new Image();
          img.src = reader.result;

          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // set the canvas dimensions to the new image dimensions
            let width = img.width;
            let height = img.height;
            const maxDimension = 1024; // maximum dimension for the resized image
            if (width > height) {
              if (width > maxDimension) {
                height *= maxDimension / width;
                width = maxDimension;
              }
            } else {
              if (height > maxDimension) {
                width *= maxDimension / height;
                height = maxDimension;
              }
            }
            canvas.width = width;
            canvas.height = height;

            // draw the resized image on the canvas
            ctx.drawImage(img, 0, 0, width, height);

            // convert canvas image to Blob and append to FormData object
            canvas.toBlob((blob) => {
              let data = new FormData();
              data.append("assignment_file", blob, file.name);

              fetch(
                `${process.env.REACT_APP_BASE_URL_STUDENT}file-upload-from-app`,
                {
                  method: "POST",
                  header: {
                    "content-type": "application/json",
                  },
                  body: data,
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  if (data.message_type === "success") {
                    allPath = allPath + data.data.photo + ",";
                  }
                  resolve(canvas.toDataURL());
                })
                .catch((error) => reject(error));
            }, file.type, 0.7);
          };

          img.onerror = (error) => reject(error);
        };

        reader.onerror = (error) => reject(error);
      });
    }
  });

  Promise.all(promises)
    .then((selectedFiles) => {
      handelFileSubmission(allPath.replace(/,\s*$/, ""), question?.id);
      setSubmitAnswer(true);
      setImages(selectedFiles);
    })
    .catch((error) => console.error(error));

  setSubmitAnswer(false);
};



    return (
        <>
            <div className='d-flex justify-content-between mb-4 mt-5'>
                <p className='mb-0' style={{ fontWeight:'400' }}>Question No #{index ? index : endSlice}</p>
                <p className='mb-0' style={{ fontWeight:'400' }}>Question Type: 
                    <span className='single-question-type ms-1'>
                        {question?.question_type_id === 1 ? 'Multiple Choice Question': 
                        question?.question_type_id === 2 ? 'Multiple Answer': 
                        question?.question_type_id === 3 ? 'TrueFalse': 
                        question?.question_type_id === 4 ? 'Broad Question': 'File Submission'}
                    </span>
                </p>
            </div>
        <div className='single-question text-center unselectable'>{parse(question?.title)}</div>
        
            
            {/* <div className="d-flex justify-content-center form-group my-3 mx-3">
                <input type="file" onChange={handleMultipleImages} multiple />
            </div> */}
            <label htmlFor="images" className="drop-container mb-3" onChange={handleMultipleImages} >
                <span className="drop-title">Click Here to Select All Answers file and Submit Your Answers</span>
                <input type="file" id="images" accept="image/*" multiple required style={{ display: 'none' }}/>
            </label>
            
            {
               images.length > 0 && 
               <h2>Answer: </h2>
            }
            <div className='container'>
                {
                    submitAnswer? <AnswerImage images={images} /> :  <p className='text-danger text-center fs-3'>Please Wait...</p> 
                }
                
            </div>
        </>
    );
};

export default FileSubmission;
