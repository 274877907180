import React, { useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import dashboard from '../../../images/dashboard.png';
import liveStreaming from '../../../images/live-streaming.png';
import calendar from '../../../images/calendar.png';
import course from '../../../images/course.png';
import profile from '../../../images/profile.png';
import eBook from "../../../images/e-book.png";
import Header from './Header/Header';
import eidIcon from '../../../images/eid_gift_card/Gift 1.svg'
import './Dashboard.css'
/* import "./components/student/Student.css"; */
import "../Student.css";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { FaGift } from "react-icons/fa6";
import { useState } from 'react';



const Dashboard = () => {
    const {t} = useTranslation();
    const [isShaking, setShaking] = useState(false);

    useEffect(() => {
        const useLanguage = sessionStorage.getItem('useLanguage');
        if(!useLanguage){
            sessionStorage.setItem('useLanguage', 'bn');
            i18next.changeLanguage('bn');
        }else{
            i18next.changeLanguage(useLanguage);
        }
    }, []);

    useEffect(() => {
        const shakeInterval = setInterval(() => {
            setShaking(true);
            setTimeout(() => setShaking(false), 820);
        }, 2000);
        return () => clearInterval(shakeInterval);
    }, [])

    return (
        <div className='student-background-color'>
            <Header></Header>
                <div className="vertical-menu" style={{zIndex: '999'}}>
                    <div data-simplebar className="h-100 p-0 pt-lg-3 pt-md-3 pt-2 sidebar">
                        <ul className="d-flex justify-content-center p-0" style={{ listStyle: 'none' }}>
                            <div className="sider-bar-items" >
                                <li>
                                    <NavLink to='/dashboard' className="dashboard-nav">
                                        <img src={dashboard} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0">{t('Dashboard')}</p>
                                    </NavLink>
                                </li>
                                {/* <li className="pt-lg-3">
                                    <NavLink to='gift-card' className="dashboard-nav">
                                        <FaGift className={`d-flex justify-content-center sidebar-icon icon-color icon-size ${isShaking ? ' shake' : ''}`}/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0 side-bar">ঈদ কার্ড</p>
                                    </NavLink>
                                </li> */}

                                <li className="pt-lg-3">
                                    <NavLink to='my-course' className="dashboard-nav">
                                        <img src={course} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0">{t('My Course')}</p>
                                    </NavLink>
                                </li>

                                
                                <li className="pt-lg-3">
                                    <NavLink to='live-class' className="dashboard-nav">
                                        <img src={liveStreaming} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0">{t('Live Class')}</p>
                                    </NavLink>
                                </li>
                                <li className="pt-lg-3">
                                    <NavLink to='calendar' className="dashboard-nav">
                                        <img src={calendar} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0">{t('Calendar')}</p>
                                    </NavLink>
                                </li>

                                <li className="pt-lg-3">
                                    <NavLink to='my-books' className="dashboard-nav">
                                        <img src={eBook} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0">{t('E-Book')}</p>
                                    </NavLink>
                                </li>
                                {/* <li className="pt-lg-3">
                                    <NavLink to='myVideo' className="dashboard-nav">
                                        <img src={course} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0"> ই-বুক</p>
                                    </NavLink>
                                </li> */}
                                <li className="pt-lg-3">
                                    <NavLink to='profile' className="dashboard-nav-profile">
                                            <img src={profile} alt=""
                                            className="d-flex justify-content-center profile-img"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center">{t("Profile")}</p>
                                    </NavLink>
                                </li>
                                {/* <div className="sidebar-footer">
                                    <li className="dashboard-nav-profile">
                                        <NavLink to='profile' className="dashboard-nav-profile">
                                                <img src={profile} alt=""
                                                className="d-flex justify-content-center profile-img"/>
                                            <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center">প্রোফাইল</p>
                                        </NavLink>
                                    </li>
                                </div> */}
                            </div>
                        </ul>
                    </div>
                </div>
            <Outlet />

            
                                
        </div>
    );
};

export default Dashboard;