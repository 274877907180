import React from "react";
import { useLocation } from "react-router-dom";
import './AuthorDetail.css'
import PageTitle from "../../../../shared/PageTitle/PageTitle";
import { dateConverter } from "../../../../../../utilities/utilities";
/* import ReactHtmlParser from 'react-html-parser'; */
import parse from 'html-react-parser';

const AuthorDetail = () => {
    const location = useLocation();
    const author = location?.state?.authorDetail;
    const imageLink = location?.state?.imagePath;
    const des = author?.writter_description;

    return (
        <div>
            <PageTitle title="Author Detail"></PageTitle>
            <div className="borderTop" style={{ paddingBottom: "21rem" }}>
                <div className="container">
                    <div className="author_detail-section mt-5">
                    <div className="row">
                        <div className="col-lg-4 author-image-section"> 
                        <img
                            src={`${imageLink}${author?.writter_image}`}
                            alt=""
                            className="img-fluid my-5 author_img"
                        />
                        </div>
                        <div className="col-lg-6 author-details-section">
                            <div>
                            <p className="authorName mb-1">{author?.writter_name}</p>
                            <p className="book-language mb-1">
                            জন্ম :
                                <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {dateConverter(author?.date_of_birth)}
                                </span>
                            </p>
                            </div>
                        </div>
                    </div>

                    </div>

                    <div className="author-des">
                        <div>{ parse(des) }</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthorDetail;
