import * as Yup from "yup";

export const bkashValidation = Yup.object({
  guardian_name: Yup.string()
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  mobile: Yup.string().required("Required"),
  class_name: Yup.string().required("Required"),
  age: Yup.string().required("Required"),
});


export const cadetAdmissionFormValidation = Yup.object({
  guardian_name: Yup.string()
  .max(50, "Must be 50 characters or less")
  .required("Required"),
  student_name: Yup.string().required("Required"),
  cadet_admission_index_number: Yup.string().required("Required"),
  mobile: Yup.string().required("Required"),

})