import React, { useState } from "react";
import AdmissionType from "../../Pages/AdmissionType/AdmissionType";
import BalanceSection from "../../Pages/BalanceSection/BalanceSection";
import PaymentMethod from "../../Pages/PaymentMethod/PaymentMethod";
import backIcon from "../../../../images/back-icon.png";
import utils from "../../../../utils/index";
import TagManager from "react-gtm-module";

const OffCanvas = ({payment, affiliate_id}) => {
    const [active, setActive] = useState("admission-type");
    const [data, setData] = useState({});
    const handleActiveBalanceSection = () => {
        setActive("balance-section");
    };
    const handleActivePaymentMethod = () => {
        TagManager.dataLayer({
            dataLayer:{
                event: 'payment_initiate',
                pagePath :`payment_initiate`,
                PageTitle:`payment_initiate`,
                courseName: payment?.courseName?.course_name,
                courseId: payment?.courseName?.course_id,
                course_maximum_segment_price : payment?.course_maximum_segment_price,
                course_minimum_segment_price  : payment?.course_minimum_segment_price,
                currency: 'BDT',
            }
        })

        const pixelData = {page: 'Course Payment',
        courseName: payment?.courseName?.course_name,
        courseId: payment?.courseName?.course_id,
        course_maximum_segment_price : payment?.course_maximum_segment_price,
        course_minimum_segment_price  : payment?.course_minimum_segment_price,
        currency: 'BDT',};
        utils.addFacebookEvent('Initiate Payment', pixelData);

        setActive("payment-method");
    };

    const handelBack = () => {
        if (active === "balance-section") setActive("admission-type");
        else if (active === "payment-method") setActive("balance-section");
        else document.getElementById("close").click();

    };

    return (
        <div className="offcanvas offcanvas-end payment-select" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header" style={{ padding: "2rem" }}>
                <button id="offcanvasRightLabel" className="payment-back tw-flex" onClick={handelBack}>
                    <img src={backIcon} alt="" />
                    <span className="ps-2">আগের পেজে ফিরে যাও</span>
                </button>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" id="close" style={{ display: "none" }}></button>
            </div>
            <div className="offcanvas-body payment-offcanvas" style={{ padding: "1rem 2rem 2rem 2rem" }}>
                {active === "admission-type" && (<AdmissionType handleActiveBalanceSection={handleActiveBalanceSection}/>)}
                {active === "balance-section" && ( <BalanceSection payment={payment} setData={setData} handleActivePaymentMethod={handleActivePaymentMethod} affiliate_id={affiliate_id} />)}
                {active === "payment-method" && <PaymentMethod data={data}/>}
            </div>
        </div>
    );
};

export default OffCanvas;
