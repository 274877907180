import React, { useState } from "react"
import './Banner.css';
import google_play from "../../../../../images/btn_google_play1.png";
import btn_video from "../../../../../images/btn_video.png";
import { Modal } from "react-bootstrap";
const Banner = ({settings}) => {
  /* const [settings, setSettings] = useState([]);

    //setting
    useEffect(()=>{
        async function fetchWebsiteSetting(){
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/settings`
            );
            response = await response.json();
            setSettings(response.data);
        }

        fetchWebsiteSetting()
    },[]) */


      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
  return (
      <>
          <section className="home_background">
              <div className="container">
                  <h1 className="text-center display-3 banner_title">
                      মজার <span className="text_color_org">শিক্ষাগুরু,</span>{" "}
                      মজায় শেখা শুরু
                  </h1>
                  <p className="text-center pt-2 banner_course_info mb-2">
                      বাংলাদেশের অন্যতম সেরা ই-লার্নিং প্লাটফর্ম, কাজ করছে
                      শিশুদের গাণিতিক দক্ষতা,
                  </p>
                  <p className="text-center banner_course_info mb-2">
                      মানসিক দক্ষতা, প্রযুক্তিগত দক্ষতা ও ভাষাগত দক্ষতা বৃদ্ধি
                      করতে। আনন্দের ছলে
                  </p>
                  <p className="text-center banner_course_info">
                      মানসম্মত শিক্ষা নিশ্চিত করাই আমাদের লক্ষ্য।
                  </p>
                  <div className="d-flex justify-content-center mt-4">
                      <a target="_blank" rel='noreferrer noopener'  className="text-decoration-none" href={settings?.setting?.play_store_link}>
                          <div className="pe-4">
                              <img src={google_play} alt="" />
                          </div>
                      </a>
                      <div>
                          <img
                              src={btn_video}
                              onClick={handleShow}
                              alt=""
                              style={{ cursor: "pointer" }}
                          />
                      </div>
{/*                                           <div class="trial-btn me-4" style={{ border:'1px solid #532d80', borderRadius:'4px' }}>
<a class="book_trial nav-item">
<button class="btn book_trial_btn color-fff" data-event-prop="top_right_nav" style={{ backgroundColor:'#fff' }}>
<span class="btn-text">

<span className="ms-1 text-dark"> Join Schedule Class</span>
</span>
</button>
</a>
</div>

                    <div class="trial-btn">
<a class="book_trial nav-item">
<button class="btn book_trial_btn color-fff" data-event-prop="top_right_nav" style={{ backgroundColor:'#532d80' }}>
<span class="btn-text">
<svg width="17" height="18" viewBox="0 0 17 18" fill="none">
<path d="M1.28571 16.7143V13.8214H4.17857V16.7143H1.28571ZM4.82143 16.7143V13.8214H8.03571V16.7143H4.82143ZM1.28571 13.1786V9.96428H4.17857V13.1786H1.28571ZM4.82143 13.1786V9.96428H8.03571V13.1786H4.82143ZM1.28571 9.32143V6.42857H4.17857V9.32143H1.28571ZM8.67857 16.7143V13.8214H11.8929V16.7143H8.67857ZM4.82143 9.32143V6.42857H8.03571V9.32143H4.82143ZM12.5357 16.7143V13.8214H15.4286V16.7143H12.5357ZM8.67857 13.1786V9.96428H11.8929V13.1786H8.67857ZM5.14286 4.5C5.14286 4.67076 4.99219 4.82143 4.82143 4.82143H4.17857C4.00781 4.82143 3.85714 4.67076 3.85714 4.5V1.60714C3.85714 1.43638 4.00781 1.28571 4.17857 1.28571H4.82143C4.99219 1.28571 5.14286 1.43638 5.14286 1.60714V4.5ZM12.5357 13.1786V9.96428H15.4286V13.1786H12.5357ZM8.67857 9.32143V6.42857H11.8929V9.32143H8.67857ZM12.5357 9.32143V6.42857H15.4286V9.32143H12.5357ZM12.8571 4.5C12.8571 4.67076 12.7065 4.82143 12.5357 4.82143H11.8929C11.7221 4.82143 11.5714 4.67076 11.5714 4.5V1.60714C11.5714 1.43638 11.7221 1.28571 11.8929 1.28571H12.5357C12.7065 1.28571 12.8571 1.43638 12.8571 1.60714V4.5ZM16.7143 3.85714C16.7143 3.15402 16.1317 2.57143 15.4286 2.57143H14.1429V1.60714C14.1429 0.723215 13.4196 0 12.5357 0H11.8929C11.0089 0 10.2857 0.723215 10.2857 1.60714V2.57143H6.42857V1.60714C6.42857 0.723215 5.70536 0 4.82143 0H4.17857C3.29464 0 2.57143 0.723215 2.57143 1.60714V2.57143H1.28571C0.582589 2.57143 0 3.15402 0 3.85714V16.7143C0 17.4174 0.582589 18 1.28571 18H15.4286C16.1317 18 16.7143 17.4174 16.7143 16.7143V3.85714Z" fill="#fff"></path>
</svg>
<span className="ms-1 text-white"> Book a Schedule Class</span>
</span>
</button>
</a>
</div> */}
                  </div>

                  <div className="d-flex justify-content-center mt-5">
                      <div className="d-flex align-items-center">
                          <div>
                              <div>
                                  <i className="fa-solid fa-circle-arrow-right text_color_blue"></i>
                              </div>
                          </div>
                          <div>
                              <p className="fw-bold ps-2 pe-4 text_color_blue banner_course_info_point m-0">
                                  দেশসেরা এক্সপার্ট মেন্টর
                              </p>
                          </div>
                      </div>

                      <div className="d-flex align-items-center">
                          <div>
                              <div>
                                  <i className="fa-solid fa-circle-arrow-right text_color_blue"></i>
                              </div>
                          </div>
                          <div>
                              <p className="fw-bold ps-2 pe-4 text_color_blue banner_course_info_point m-0">
                                  ইন্টারন্যাশনাল স্ট্যান্ডার্ড কারিকুলাম
                              </p>
                          </div>
                      </div>

                      <div className="d-flex align-items-left">
                          <div>
                              <div>
                                  <i className="fa-solid fa-circle-arrow-right text_color_blue"></i>
                              </div>
                          </div>
                          <div>
                              <p className="fw-bold ps-2 text_color_blue banner_course_info_point m-0">
                                  সাশ্রয়ী মূল্যে কোর্স
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="d-flex justify-content-between">
                      <div className="">
                          <img
                              className="img-fluid"
                              src={`${settings.website_image_path}${settings?.setting?.website_home_page_banner_left_side}`}
                              alt=""
                          />
                      </div>

                      <div className="top-boy-section">
                          <img
                              className="img-fluid"
                              src={`${settings.website_image_path}${settings?.setting?.website_home_page_banner_right_side}`}
                              alt=""
                          />
                      </div>
                  </div>
              </div>
          </section>

          <Modal show={show} onHide={handleClose} size="lg" className="p-0 m-0">
              <iframe
                  style={{ borderRadius: "16px" }}
                  width="100%"
                  height="480"
                  src={`https://www.youtube.com/embed/${settings?.setting?.home_banner_video_link}?controls=1&autoplay=1`}
                  title="about-video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
              ></iframe>
          </Modal>
      </>
  );
};

export default Banner;