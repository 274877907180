import React from 'react';
import FeaturedBlog from './FeaturedBlog/FeaturedBlog';
import Slider from 'react-slick';

const FeaturedBlogs = ({blogs,blog_image_path}) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        
    };
    return (
        <section>
            <Slider {...settings}>
                {
                    blogs?.map(featured_blog => <FeaturedBlog key={featured_blog.id} featured_blog={featured_blog} blog_image_path={blog_image_path} shortDesc={featured_blog?.blog_short_desc}></FeaturedBlog>)
                }
            </Slider>
        </section>
    );
};

export default FeaturedBlogs;