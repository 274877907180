import React, { useMemo } from "react";



const ShowRating = ({ count, rating, color, onRating }) => {

    
    const getColor = (index) => {
       if ( rating >= index) {
        return color.filled;
      }
  
      return color.unfilled;
    };
      const showRating = useMemo(() => {
        return Array(count)
          .fill(0)
          .map((_, i) => i + 1)
          .map((idx) => (
            <i key={idx} 
            className="fa-solid fa-star cursor-pointer" 
            style={{ color: getColor(idx) }} 
            ></i>
          ));
      },);


    return (
        <div>
            {showRating}
        </div>
    );
};
  
  ShowRating.defaultProps = {
    count: 5,
    rating: 0,
    color: { 
      filled: "#532D80",
      unfilled: "#DCDCDC",
    },
  };
  

export default ShowRating;