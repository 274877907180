import { create } from 'zustand'

export const useStore = create((set) => ({
    courseDetail:{},
    batchName:'',
    selectedPayment:{},
    payment:{},
    selectedBatch:{},
    setCourseDetailss: (data) => set({courseDetail:data}),
    setBatchNames:(data) => set({batchName:data}),
    setSelectedPayments:(data) => set({selectedPayment:data}),
    setPayments:(data) => set({payment:data}),
    setSelectedBatch:(data)=>set({selectedBatch:data}),
}))

