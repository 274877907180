import {CheckIcon} from '@heroicons/react/24/solid'

const steps = [{id: '01', name: 'কোর্স কনফার্মেশন', href: '#', status: 'complete'}, {
    id: '02', name: 'লগইন', href: '#', status: 'current'
}, {id: '03', name: 'পেমেন্ট করুন ', href: '#', status: 'upcoming'},]

export default function Steps() {
    return (<nav aria-label="Progress">
        <ol role="list"
            className="tw-divide-y tw-divide-gray-300 tw-rounded-md tw-border tw-border-gray-300 md:tw-flex md:tw-divide-y-0">
            {steps.map((step, stepIdx) => (<li key={step.name} className="tw-relative md:tw-flex md:tw-flex-1">
                {step.status === 'complete' ? (
                    <a href={step.href} className="tw-group tw-flex tw-w-full tw-items-center">
                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                  <span
                      className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-indigo-600 tw-group-hover:bg-indigo-800">
                    <CheckIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true"/>
                  </span>
                  <span className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-900">{step.name}</span>
                </span>
                    </a>) : step.status === 'current' ? (<a href={step.href}
                                                            className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium"
                                                            aria-current="step">
                <span
                    className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-indigo-600">
                  <span className="tw-text-indigo-600">{step.id}</span>
                </span>
                    <span
                        className="tw-ml-4 tw-text-sm tw-font-medium tw-text-indigo-600">{step.name}</span>
                </a>) : (<a href={step.href} className="tw-group tw-flex tw-items-center">
                <span className="tw-flex tw-items-center tw-px-6 tw-py-4 tw-text-sm tw-font-medium">
                  <span
                      className="tw-flex tw-h-10 tw-w-10 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-gray-300 tw-group-hover:border-gray-400">
                    <span className="tw-text-gray-500 tw-group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span
                      className="tw-ml-4 tw-text-sm tw-font-medium tw-text-gray-500 tw-group-hover:text-gray-900">{step.name}</span>
                </span>
                </a>)}

                {stepIdx !== steps.length - 1 ? (<>
                    {/* Arrow separator for lg screens and up */}
                    <div className="tw-absolute tw-right-0 tw-top-0 tw-hidden tw-h-full tw-w-5 md:tw-block"
                         aria-hidden="true">
                        <svg
                            className="tw-h-full tw-w-full tw-text-gray-300"
                            viewBox="0 0 22 80"
                            fill="none"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M0 -2L20 40L0 82"
                                vectorEffect="non-scaling-stroke"
                                stroke="currentcolor"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </>) : null}
            </li>))}
        </ol>
    </nav>)
}
