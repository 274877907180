import React from 'react';
import './BookShortDescription.css';
/* import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'; */
import parse from 'html-react-parser';

const BookShortDescription = ({bookDescription}) => {
    const des = bookDescription;
    return (
        <div className='details-container'>
            <div>{ parse(des) }</div>
        </div>
    );
};

export default BookShortDescription;