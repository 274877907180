import React, { useEffect, useState } from 'react';
import { dayDateMonth } from '../../../../../utilities/utilities';
import './ScheduleFreeClass.css';
import TimeSlot from './TimeSlot/TimeSlot';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { toast} from "react-toastify";
import PageTitle from '../../../shared/PageTitle/PageTitle';
import ReactGA from "react-ga4";

const ScheduleFreeClass = () => {
    const [orientationCourseLists, setOrientationCourseLists] = useState([]);
    const [classSlots, setClassSlots] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionTitle, setSelectedOptionTitle] = useState(null);
    const [classListOption, setClassListOption] = useState(null);
    const [selectedOptionStart, setSelectedOptionStart] = useState(true);
    const [slotListOption, setSlotListOption] = useState('');
    const [slotListOptionTitle, setSlotListOptionTitle] = useState(null);
    const [activeButtonValue, setActiveButtonValue] = useState('');
    const [inputMobileNumber, setInputMobileNumber] = useState('');
    const [districts, setDistricts] = useState([]);
    const [lgShow, setLgShow] = useState(false);
    let navigate = useNavigate();
    

    function handleInputChange(event) {
        const newValue = event.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
        setInputMobileNumber(newValue);
    }


    useEffect(()=>{
        async function fetchOrientationCourseList(){
            let response = await fetch(`${process.env.REACT_APP_BASE_URL}api/orientation-course-lists`);
            response = await response.json();
            setOrientationCourseLists(response.data);
        }

        fetchOrientationCourseList()

        async function fetchDistricts() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/districts`
            );
            response = await response.json();
            setDistricts(response.data.districts);
        }
        fetchDistricts();
    },[])


    useEffect(()=>{
        async function fetchOrientationSlots(){
            let response = await fetch(`${process.env.REACT_APP_BASE_URL}api/get-orientation-class-slots?orientation_class_id=${selectedOption}`);
            response = await response.json();
            setClassSlots(response.data);
        }

        fetchOrientationSlots()
    },[selectedOption])

    if(orientationCourseLists[0]?.id && selectedOptionStart){

        setSelectedOption(orientationCourseLists[0]?.id);
        setSelectedOptionTitle(orientationCourseLists[0]?.orientation_class_title);
        setSelectedOptionStart(false);
        
    }

  const handleOrientationClassList = (event) => {
    setSelectedOption(parseInt(event.target.value));
    const labelText = Array.from(event.target.nextSibling.childNodes)
      .filter((node) => node.nodeName !== 'SPAN')
      .map((node) => node.textContent.trim())
      .join('');
    setSelectedOptionTitle(labelText);

    
  };
  const handelClassListChange = (event) => {
    setClassListOption(parseInt(event.target.value));
    
  };

  const handleButtonClick = (event) => {
    setActiveButtonValue(event.currentTarget.textContent);
  }

const handelTimeSlotChange = (event) => {
    setSlotListOption(parseInt(event.target.value));
    setSlotListOptionTitle(event.target.nextSibling.textContent.trim());
    
};


setTimeout(() => {
  const activeButton = document.querySelector('.nav-link.active');
  setActiveButtonValue(activeButton?.textContent.trim());
}, 1000);


const handelBookingSlot = (e) =>{
        e.preventDefault();

        const user_detail = {
            'user_name': e.target.student_name.value,
            'mobile_number': inputMobileNumber,
            'age': e.target.age.value,
            'email': e.target.email.value,
            'organization_name': e.target.school_name.value,
            'class_name': classListOption,
            'district': e.target.district.value,
            'remarks': e.target.opinion.value,
        }

        ReactGA.event({
            category: 'slot book',
            action: 'slot book action',
            label: `clicked slot booking`,
            value:  Number(inputMobileNumber)
          });


        fetch(`${process.env.REACT_APP_BASE_URL}api/free-class-login-registration-v2?user_name=${e.target.student_name.value}&mobile_number=${inputMobileNumber}&age=${e.target.age.value}&email=${e.target.email.value}&organization_name=${e.target.school_name.value}&class_name=${classListOption}&district=${e.target.district.value}&remarks=${e.target.opinion.value}`,{
                    method:'POST',
                    headers:{
                        'content-type' : 'application/json',
                    },
                    body: user_detail
                })
            .then((res) => res.json())
            .then((data) => {
              if(data?.message_type === 'success' && data?.data?.user_id){
                    
                fetch(`${process.env.REACT_APP_BASE_URL}api/booking-slots?slot_id=${slotListOption}&class_id=${classListOption}&free_class_user_id=${data?.data?.user_id}&user_name=${data?.data?.user_name}&mobile_number=${data?.data?.mobile_number}&date=${activeButtonValue}&time=${slotListOptionTitle}`,{
                    method:'POST',
                    headers:{
                        'content-type' : 'application/json',
                    },
                    body: user_detail
                })
            .then((res) => res.json())
            .then((data) => {
              if(data?.message_type === 'success'){
                navigate("/schedule/class-list", {
                        state: {
                            free_class_user_id: data?.data?.free_class_user_id,
                            'age': data?.data?.user_details?.age
                        },
                    });
                    setLgShow(false);
                
              }
              else{
                toast.error(data?.message);
                setLgShow(false);
              }
            });
 
              }else{
                toast.error(data?.message);
              }
            });

    
}





    return (
        <section className='schedule-class-section'>
        <PageTitle title='Schedule Class'></PageTitle>
            <div className='container'>
                <p className='text-center pt-4 mb-0' style={{ fontSize: '24px', fontWeight:'500' }}>Schedule Your Orientation Class</p>
                <p className='text-center mb-5' style={{ color:'#f27321' , fontWeight:'500' }}>Select your preferable slot</p>


                {/* Orientation Course List */}
                <div className="orientation-course mb-3">
                    <p className='schedule-course-title'><i className="fas fa-graduation-cap me-1"></i>Choose your Orientation Class</p>
                    <div className="cf row mx-0 mb-2 gy-2">

                        {
                            orientationCourseLists.length > 0 ?
                            orientationCourseLists?.map(orientationCourseList =>
                                <div className="col-lg-3" key={orientationCourseList?.id}>
                                    <div className="plan">
                                        <input
                                        type="radio"
                                        name={`class-schedule-${orientationCourseList?.id}`}
                                        id={`class-schedule-${orientationCourseList?.id}`}
                                        value={orientationCourseList?.id}
                                        checked={selectedOption === orientationCourseList?.id}
                                        onChange={handleOrientationClassList}
                                        />
                                        <label htmlFor={`class-schedule-${orientationCourseList?.id}`}>{orientationCourseList?.orientation_class_title} <br /> <span style={{ fontSize:'14px' }}>{`${orientationCourseList?.get_class_slots_count} slot available`}</span></label>
                                    </div>
                                </div>
                            )
                            :

                            <p className='text-center text-danger' style={{ fontSize:'18px', fontWeight:'500' }}>Free Demo Class does not set yet</p>
                        }
                    </div>
                </div>
                
                {/* Student Class List */}
                <div className="orientation-course mb-3" id='classSlots-classList-section'>
                    <p className='schedule-course-title'><i className="fas fa-list me-1"></i>Select your class</p>
                    <div className="cf row mx-0 mb-3 gy-2">

                        {
                            classSlots?.class_lists?.map(class_list =>
                                <div className="col-lg-2" key={class_list?.class_id}>
                                    <div className="class-list">
                                        <input
                                        type="radio"
                                        name={`class-list-${class_list?.class_id}`}
                                        id={`class-list-${class_list?.class_id}`}
                                        value={class_list?.class_id}
                                        checked={classListOption === class_list?.class_id}
                                        onChange={handelClassListChange}
                                        />
                                        <label htmlFor={`class-list-${class_list?.class_id}`}>{class_list?.class_name}</label>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                
                {
                    orientationCourseLists.length > 0 &&
                        <>
                            <div className="select-schedule-date mb-3" >
                    <p className='schedule-course-title ps-0'><i className="fas fa-calendar me-1"></i>Select a date</p>
                    <ul className="row nav nav-pills mb-3 mt-1" id="pills-tab" role="tablist">
                        {
                            classSlots?.dates?.map((date, index) =>
                                <li className="col-lg-1 nav-item py-1" role="presentation" key={index}>
                                    <button className={`nav-link schedule-date category-name w-100 test py-1 ${date?.orientation_class_date} ${classSlots?.dates[0]?.orientation_class_date} ${date?.orientation_class_date ===  classSlots?.dates[0]?.orientation_class_date ? "active" : ""}`} 
                                    id={`pills-category_${date?.orientation_class_date}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-category_${date?.orientation_class_date }`} 
                                    type="button" role="tab" aria-controls={`pills-category_${date?.orientation_class_date }`} aria-selected="true" 
                                    onClick={handleButtonClick} >
                                        <span >{dayDateMonth(date?.orientation_class_date).day}</span> <br />
                                        <span style={{ fontSize:'30px' }}>{dayDateMonth(date?.orientation_class_date).date}</span> <br />
                                        <span>{dayDateMonth(date?.orientation_class_date).month}</span>
                                    
                                    </button>
                                </li>
                                
                            )
                        }
                    </ul>

                    <p className='schedule-course-title ps-0'><i className="fas fa-clock me-1"></i>Select your slot for <span style={{ color:'#f27321' , fontWeight:'500' }}>{activeButtonValue}</span></p>
                    <div className="tab-content mt-2 mb-5" id="pills-tabContent">
                        {
                            classSlots?.dates?.map((date, index) =>{
                                return(
                                <div className={`tab-pane fade ${date?.orientation_class_date} ${classSlots?.dates[0]?.orientation_class_date} ${date?.orientation_class_date ===  classSlots?.dates[0]?.orientation_class_date ? "active show" : ""}`} id={`pills-category_${date?.orientation_class_date}`} role="tabpanel" aria-labelledby={`pills-category_${date?.orientation_class_date}-tab`} key={date?.orientation_class_date}>
                                    <div className="time-slot mb-5">
                                        
                                            <div className="cf row mx-0 mb-3 gy-2">
                                                {
                                                    (date?.slots?.map((slot, index) =>{
                                                        if(slot?.orientation_class_date === date?.orientation_class_date) {
                                                            return <TimeSlot slot={slot} slots={date?.slots} handelTimeSlotChange={handelTimeSlotChange}  key={slot?.id}/> 
                                                        }
                                                        return null;
                                                    }))
                                                }
                                            </div>
                                        </div>
                                </div>
                                )
                            })
                        }
                    </div>

                </div>
                
                {
                    (!slotListOption || !classListOption) &&
                <p className='text-center text-danger mb-2'>Please select your class and slot</p>
                }
                <div className='d-block'>

                    <button className='w-100 btn-slot-booking' disabled={!classListOption || !slotListOption} onClick={() => setLgShow(true)}>CONFIRM SLOT</button> 
                </div>
                        </>
                }
                

            </div>
                
            
            <Modal size="lg" show={lgShow} onHide={() => setLgShow(false)} aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton style={{ border:'none' }}>
                {/* <Modal.Title className='text-center' id="example-modal-sizes-title-lg">
                    Large Modal
                </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>

                <p className='slot-modal-description text-center'>You have selected the <span>{selectedOptionTitle}</span> class on <span>{activeButtonValue}</span> at <span>{slotListOptionTitle}</span></p>

                <form onSubmit={handelBookingSlot}>
                  <div className="row">
                    <div className="col-lg-6">
                      <p className='mb-2 font-400'>Name <span className='text-danger'>*</span></p>
                      <input className="mb-3 form-control form-control-lg student-profile-info" name="student_name" type="text" placeholder="Enter Your Full Name" required/>
                    </div>
                    <div className="col-lg-6">
                        <p className='mb-2 font-400'>Age <span className='text-danger'>*</span></p>
                        <input className="mb-3 form-control form-control-lg student-profile-info" name="age" type="text" placeholder="Enter Your Age" required />
                    </div>
                    <div className="col-lg-6">
                        <p className='mb-2 font-400'>Mobile Number <span className='text-danger'>*</span></p>
                        <input className="mb-3 form-control form-control-lg student-profile-info" value={inputMobileNumber} onChange={handleInputChange} name="student_phone_number" type="text" placeholder="Enter Your Mobile Number" required />
                    </div>
                    <div className="col-lg-6">
                        <p className='mb-2 font-400'>Email</p>
                        <input className="mb-3 form-control form-control-lg student-profile-info" name="email" type="text" placeholder="Enter Your Email" />
                    </div>
                    <div className="col-lg-6">
                        <p className='mb-2 font-400'>School Name <span className='text-danger'>*</span></p>
                        <input className="mb-3 form-control form-control-lg student-profile-info" name="school_name" type="text" placeholder="Enter Your School Name" required />
                    </div>
                    <div className="col-lg-6">
                        <p className='mb-2 font-400'>District<span className='text-danger'>*</span></p>
                        <select name='district' className=" mb-3 form-select form-control form-control-lg student-profile-info" id="select_box" required>
                            <option value="" required>Select a District</option>
                            {districts.map((district) => (
                                <option key={district.district_id} value={`${district.district_en_name} - ${district.district_bn_name}`}>
                                    {district.district_en_name} - {district.district_bn_name}
                                </option>
                            ))}
                            
                        </select>
                    </div>

                    <div className="col-lg-12">
                        <p className='mb-2 font-400'>Remarks</p>
                        <textarea className="mb-3 form-control form-control-lg" name="opinion" rows="3"></textarea>
                    </div>
                    
                  </div>
                  <div className=" d-flex justify-content-center">
                    <input className="btn text-white py-2 px-4" style={{ backgroundColor:'#532D80', fontSize:'22' }} type="submit" value='Submit'></input>
                  </div>
                </form>
                </Modal.Body>
                {/* <Modal.Footer className='d-flex justify-content-center' style={{ border:'none' }}>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button className=''  onClick={handleClose}>
                    Save Changes
                </Button>
                </Modal.Footer> */}
            </Modal>
                


        </section>
    );
};

export default ScheduleFreeClass;