import React, { useState } from "react";
import "./LatestCourses.css";
import CourseDetails from "../../shared/CourseDetails/CourseDetails";
import { useLocation } from "react-router-dom";
import OffCanvas from "../../shared/OffCanvas/OffCanvas";
import StudentPageTitle from "../../shared/StudentPageTitle/StudentPageTitle";

const LatestCourses = () => {
    const search = useLocation().search;
    const affiliate_id = new URLSearchParams(search).get("aff");

    const [payment, setPayment] = useState([]);
     const handelPaymentDetail= (user_id, decode_course_id) =>{
        async function fetchMyAPI() {
            let response = await fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}course-payment-information?user_id=${user_id}&course_id=${decode_course_id}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                });
            response = await response.json();
            setPayment(response.data);
        }
        fetchMyAPI();
     }


    return (
        <div className="main-content" style={{ backgroundColor: "#F4F1EB" }}>
            <StudentPageTitle title="Latest Courses"></StudentPageTitle>
           
            <div className="page-content p-0">
            
                <div className="container-fluid">
                <OffCanvas payment={payment} affiliate_id={affiliate_id}/>
                
                    <div className="row">
                    
                        <div className="col-lg-12 col-md-12 col-12 order-lg-1 order-md-1 order-2 live-class-row ">
                        
                            <div className="latest-course-section">
                            
                                <div className="mid-section">
                                <div className="bg-white p-3" style={{ borderRadius: '15px' }}>
                                    <CourseDetails handelPaymentDetail={handelPaymentDetail}></CourseDetails>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        {/* <div className="col-lg-3 col-md-2 col-12 order-lg-2 order-md-2 order-1 analysis-section">
                            <AnalyticContent></AnalyticContent>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LatestCourses;
