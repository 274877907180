import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import i18next from "i18next";
import FormShape1 from "../../../../images/form_shape_1.png"
import FormShape2 from "../../../../images/form_shape_2.png"
import './Message.css'; 
 

const Message = ({location}) =>{
    const [btnLoading, setBtnLoading] = useState(false);
    const [inputMobileNumber, setInputMobileNumber] = useState('');
    const {t} = useTranslation();

    useEffect(()=>{
        const useLanguage = sessionStorage.getItem('useLanguage');
        if(!useLanguage){
            sessionStorage.setItem('useLanguage', 'bn');
            i18next.changeLanguage('bn');
        }else{
            i18next.changeLanguage(useLanguage);
        }

    },[])


    function handleInputChange(event) {
        const newValue = event.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
        setInputMobileNumber(newValue);
    }



    const handelSendMessage = (e) =>{
        e.preventDefault();
        let data = new FormData() 
        /* data.append('sceenshot', image); */
        data.append('user_name', e.target.name.value);
        data.append('user_email_or_mobile', inputMobileNumber);
        data.append('subject', e.target.subject.value);
        data.append('details', e.target.detail.value);

        setBtnLoading(true);


        fetch(`${process.env.REACT_APP_BASE_URL}api/contact-create`,{
            method: 'POST',
            header:{
               'content-type': 'application/json'
            },
            body:data,
        })
        .then(res => res.json())
        .then(data => {
            if(data.message_type === 'success'){
                toast.success(data.message);
                setBtnLoading(false);
                e.target.reset();
                setInputMobileNumber('');
            }else{
                toast.error(data.message);
                setBtnLoading(false);
            }
        })
    }

    return(
        <section className="position-relative message-box-design" style={location === '/login' || location === '/registration' || location === '/otp' || location === "/schedule/class"|| location === "/schedule/class-list"  ? { display: "none" } : { display: "block" }}>
            <div className="position-relative top-0 start-50 translate-middle">
                <div className="container position-relative rounded-3 pb-3" style={{ backgroundColor: "#FFCE39", zIndex: 1 }}>
                    <h3 className="text-center pt-5 fw-bold fs-2">{t("Ask questions without hesitation")},</h3>
                    <h3 className="text-center fw-bold fs-2">{t('You will get an answer every day')}</h3>
                    <div className="position-absolute top-0 end-0 question-bg-top-img">
                        <img src={FormShape1} alt=""/>
                    </div>
                    
                    <Form onSubmit={handelSendMessage}>
                    <div className="row m-0 message_section pt-4">
                        <div id ="input" className="col col-lg-6 col-md-6 col-sm-12 col-12" style={{ zIndex: 3 }}>
                            <div className="pb-3">
                                <input className="form-control form-control-lg" name="name" type="text" placeholder={t('Name')} />
                            </div>
                            <div className="pb-3">
                                <input className="form-control form-control-lg" name="user_email_or_mobile" type="text" value={inputMobileNumber} onChange={handleInputChange} pattern="[0-1]{2}[3-9]{1}[0-9]{8}" placeholder={t('Mobile Number')} />
                            </div>
                            <div className="pb-3">
                                <input className="form-control form-control-lg" name="subject" type="text" placeholder={t('Subject')} />
                            </div>
                                
                        </div>
                        <div className="col col-lg-6 col-md-6 col-sm-12 col-12"  id="write-message">
                            <div className="d-flex align-items-center">
                                <textarea className="form-control" name="detail" placeholder={t('Your Message')}  rows="6" ></textarea>
                            </div>
                        </div>
                        <div className="position-absolute bottom-0 start-0" style={{ zIndex: -1, paddingLeft: 0 }}>
                            <img src={FormShape2} alt=""/>
                        </div>
                    </div>

                    <div className="pt-2 pb-2 d-flex justify-content-center" >
                        <button  className="px-4 py-3 text-white rounded-3 bg_color_blue a_tag_decoration" 
                        style={{ border: "none", fontSize:'22px'}} disabled={btnLoading ? true : false}>{btnLoading ? <><i className="fa fa-spinner fa-spin"></i> {t('Send Your Message')}</> : t('Send Your Message')} </button>
                    </div>
                    </Form>
                </div>
            </div>
        </section>
    );
}

export default Message;