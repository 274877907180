import {Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import React, {useEffect} from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
/* import Student from "./components/student/Student"; */
import "./components/student/Student.css";
import "react-loading-skeleton/dist/skeleton.css";
import Calendar from "./components/student/Dashboard/Calendar/Calendar";
import Dashboard from "./components/student/Dashboard/Dashboard";
import LatestCourses from "./components/student/Dashboard/LatestCourses/LatestCourses";
import LiveClass from "./components/student/Dashboard/LiveClass/LiveClass";
import MainContent from "./components/student/Dashboard/MainContent/MainContent";
import MyBooks from "./components/student/Dashboard/MyBooks/MyBooks";
import MyCourse from "./components/student/Dashboard/MyCourse/MyCourse";
import Profile from "./components/student/Dashboard/Profile/Profile";
import CanNotJoinClass from "./components/student/Pages/CanNotJoinClass/CanNotJoinClass";
import Certificate from "./components/student/Pages/Certificate/Certificate";
import AssignmentQuestionShow
    from "./components/student/Pages/LMS/Assignment/AssignmentQuestionShow/AssignmentQuestionShow";
import FileSubmission
    from "./components/student/Pages/LMS/Assignment/AssignmentQuestionShow/FileSubmission/FileSubmission";
import AssignmentQuestionShowAtaTime
    from "./components/student/Pages/LMS/Assignment/AssignmentQuestionShowAtaTime/AssignmentQuestionShowAtaTime";
import AssignmentSubmissionView
    from "./components/student/Pages/LMS/Assignment/AssignmentSubmissionView/AssignmentSubmissionView";
import AssignmentSubmitAutoCount
    from "./components/student/Pages/LMS/Assignment/AssignmentSubmitAutoCount/AssignmentSubmitAutoCount";
import AssignmentSubmitDone from "./components/student/Pages/LMS/Assignment/AssignmentSubmitDone/AssignmentSubmitDone";
import LectureView from "./components/student/Pages/LMS/Lectures/Lecture/LectureView/LectureView";
import LMS from "./components/student/Pages/LMS/LMS";
import SmartNoteView from "./components/student/Pages/LMS/SmartNotes/SmartNote/SmartNoteView/SmartNoteView";
import PaymentFailed from "./components/student/Pages/PaymentFailed/PaymentFailed";
import PaymentSuccess from "./components/student/Pages/PaymentSuccess/PaymentSuccess";
import TransactionTable from "./components/student/Pages/TransactionTable/TransactionTable";
import RequireAuth from "./components/user/pages/Login/RequireAuth/RequireAuth";
import User from "./components/user/User";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import mixpanel from "mixpanel-browser";
import PaidStudentInfo from "./components/user/pages/LeadCollect/PaidStudentInfo/PaidStudentInfo";
import OrganicAudienceForm from "./components/user/pages/LeadCollect/OrganicAudienceForm/OrganicAudienceForm";
import PaidMarketingStudentInformation
    from "./components/user/pages/LeadCollect/PaidMarketingStudentInformation/PaidMarketingStudentInformation";
import BkashLeads from "./components/user/pages/LeadCollect/BkashLeads/BkashLeads";
import BkashLeadsList from "./components/user/pages/LeadCollect/BkashLeads/BkashLeadsList";
import Registration from "./components/user/pages/Registration/Registration";
import RegistrationLeads from "./components/user/pages/LeadCollect/RegistrationLeads/RegistrationLeads";
import RegistrationLeadsList from "./components/user/pages/LeadCollect/RegistrationLeads/RegistrationLeadsList";
import CadetVivaRegistration from "./components/user/pages/CadetVivaRegistration/CadetVivaRegistration";
import CadetVivaRegistrationList from "./components/user/pages/CadetVivaRegistration/CadetVivaRegistrationList";
import utils from "./utils/index";
import MoreInfo from "./components/user/pages/LeadCollect/MoreInfo/MoreInfo";
import GiftCard from "./components/student/Dashboard/GiftCard/GiftCard";
import MoreInfoLeads from "./components/user/pages/LeadCollect/MoreInfoLeads/MoreInfoLeads";
import AddToCart from "./components/user/shared/AddToCart/AddToCart";
import CoursePayment from "./components/user/shared/AddToCart/CoursePayment/CoursePayment";
import SuccessfulPayment from "./components/user/shared/SuccessfulPayment/SuccessfulPayment";
import CourseDetails from "./components/user/shared/CourseDetails/CourseDetails";
import ResistrationLead from "./components/user/pages/LeadCollect/RegistrationLead/ResistrationLead";
import AbacusCampaign from "./components/user/pages/LeadCollect/ AbacusCampaign/AbacusCampaign";
import Result from "./components/user/pages/LeadCollect/ AbacusCampaign/Result/Result";
import EFT from "./components/user/pages/LeadCollect/EFT/EFT";
import EftRegistrationList from "./components/user/pages/LeadCollect/EFT/EFTRegistrationList";
import AbacusCampaignLeads from "./components/user/pages/LeadCollect/ AbacusCampaign/AbacusCampaignLeads";

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}`);
mixpanel.init(`${process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN}`, {
    debug: true,
});
/* const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_REACT_APP_GTM_ID}`
} */

// TagManager.initialize(`${process.env.REACT_APP_REACT_APP_GTM_ID}`);

function App() {
    const currentLink = window.location.href;

    useEffect(() => {
        utils.addFacebookPageView();
        const tagManagerArgs = {
            gtmId: 'GTM-WQLQXZR5'
        }

        TagManager.initialize(tagManagerArgs)

    }, [])

    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "Custom Title",
    });

    return (
        <div className="h-100">
            <Routes>
                <Route path="*" element={<User/>}></Route>
                <Route element={<Dashboard></Dashboard>}>
                    <Route
                        path="dashboard"
                        element={
                            <RequireAuth>
                                {" "}
                                <MainContent/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="my-course"
                        element={
                            <RequireAuth>
                                {" "}
                                <MyCourse/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="live-class"
                        element={
                            <RequireAuth>
                                {" "}
                                <LiveClass/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="calendar"
                        element={
                            <RequireAuth>
                                {" "}
                                <Calendar/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="my-books"
                        element={
                            <RequireAuth>
                                {" "}
                                <MyBooks/>{" "}
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="profile"
                        element={
                            <RequireAuth>
                                {" "}
                                <Profile/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="transaction-info"
                        element={
                            <RequireAuth>
                                {" "}
                                <TransactionTable/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="my-course/lms"
                        element={
                            <RequireAuth>
                                {" "}
                                <LMS/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="view-submission"
                        element={
                            <RequireAuth>
                                {" "}
                                <AssignmentSubmissionView/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="assignment-question"
                        element={
                            <RequireAuth>
                                {" "}
                                <AssignmentQuestionShow/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="view-lecture"
                        element={
                            <RequireAuth>
                                {" "}
                                <LectureView/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="submit-assignment-with-marks"
                        element={
                            <RequireAuth>
                                {" "}
                                <AssignmentSubmitAutoCount/>{" "}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="view-smart-note"
                        element={
                            <RequireAuth>
                                {" "}
                                <SmartNoteView/>{" "}
                            </RequireAuth>
                        }
                    />
                </Route>
                <Route path="course-payment" element={<CoursePayment/>}/>
                <Route path="success-payment" element={<SuccessfulPayment/>}/>
                <Route path="payment-success" element={<PaymentSuccess/>}/>
                <Route path="payment-failed" element={<PaymentFailed/>}/>

                <Route
                    path="assignment-questions"
                    element={
                        <RequireAuth>
                            {" "}
                            <AssignmentQuestionShowAtaTime/>{" "}
                        </RequireAuth>
                    }
                />
                <Route
                    path="submit-assignment"
                    element={
                        <RequireAuth>
                            {" "}
                            <AssignmentSubmitDone/>{" "}
                        </RequireAuth>
                    }
                />
                <Route
                    path="image-show"
                    element={
                        <RequireAuth>
                            {" "}
                            <FileSubmission/>{" "}
                        </RequireAuth>
                    }
                />
                <Route
                    path="join-class-issue"
                    element={
                        <RequireAuth>
                            <CanNotJoinClass/>
                        </RequireAuth>
                    }
                ></Route>
                <Route
                    path="certificate"
                    element={
                        <RequireAuth>
                            <Certificate/>
                        </RequireAuth>
                    }
                ></Route>

                {/* Lead Collect */}
                <Route
                    path="student-information"
                    element={
                        <RequireAuth>
                            {" "}
                            <PaidStudentInfo/>{" "}
                        </RequireAuth>
                    }
                />
                <Route path="organic-audience-info" element={<OrganicAudienceForm/>}/>
                <Route
                    path="paid-marketing-student-information-form"
                    element={<PaidMarketingStudentInformation/>}
                />
                <Route path="bkash" element={<BkashLeads/>}/>
                <Route path="bkash-leads" element={<BkashLeadsList/>}/>
                <Route path="sign-up" element={<Registration/>}/>
                <Route path="registration" element={<RegistrationLeads/>}/>
                <Route path="registration-leads" element={<RegistrationLeadsList/>}/>
                <Route path="/cadet-viva" element={<CadetVivaRegistration/>}/>
                <Route path="/cadet-viva-list" element={<CadetVivaRegistrationList/>}/>
                <Route path="/info" element={<ResistrationLead/>}/>
                <Route path="/info-leads" element={<MoreInfoLeads/>}/>
                <Route path="latest-courses" element={<LatestCourses/>}/>
                <Route path="course-price" element={<AddToCart/>}/>
                <Route path='abacus-campaign' element={<AbacusCampaign/>}/>
                <Route path='abacus-campaing-leads' element={<AbacusCampaignLeads/>}/>
                <Route path='quiz-result' element={<Result/>}/>
                <Route path='english-fluency-test' element={<EFT/>}/>
                <Route path='eft-leads' element={<EftRegistrationList/>}/>
            </Routes>
            <ToastContainer/>
        </div>
    );
}

export default App;
