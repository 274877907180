import React, {useState, useEffect} from 'react';
import {CheckIcon} from "@heroicons/react/20/solid";
import steps from "../../../../../utils/Constant";
import Accordion from "react-bootstrap/Accordion";
import {BiPhoneCall} from "react-icons/bi";
import CallImage from "../../../../../images/leadCollectImage/MojaruWebCCR.jpg"
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ResistrationLead = () => {
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [parentName, setParentName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [childAge, setChildAge] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [childAgeError, setChildAgeError] = useState('');
    const [stepsStatus, setStepsStatus] = useState([...steps.map(step => step.status)]);

    const navigate = useNavigate();

    useEffect(() => {
        // Function to dynamically change the status of each step
        const updateStepsStatus = () => {
            const updatedStatus = steps.map((step, index) => {
                if (selectedCourses.length > 0 && index === 0) {
                    return 'complete'; // Assuming the first step gets completed if any course is selected
                } else if (selectedClass !== null && index === 1) {
                    return 'complete'; // Assuming the second step gets completed if class is selected
                } else {
                    return step.status; // Maintain the status if not dynamically completed
                }
            });
            setStepsStatus(updatedStatus);
        };

        updateStepsStatus();
    }, [selectedCourses, selectedClass]);

    const handleCourseSelection = (event, course) => {
        if (event.target.checked) {
            setSelectedCourses([...selectedCourses, course]);
        } else {
            setSelectedCourses(selectedCourses.filter(selectedCourse => selectedCourse !== course));
        }
    };
    const handleChange = (event) => {
        setSelectedClass(parseInt(event.target.value));
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value !== '') { // Check if value is a number
            setPhoneNumber(value);
            setPhoneNumberError('');
        } else {
            setPhoneNumberError('ফোন নম্বর অবশ্যই সংখ্যা হতে হবে');
        }
    };

    const handleChildAgeChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value !== '') { // Check if value is a number
            setChildAge(value);
            setChildAgeError('');
        } else {
            setChildAgeError('সন্তানের বয়স অবশ্যই সংখ্যা হতে হবে');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();


        let interestedCourses = selectedCourses.join(', ');

        const data = {
            age: parseInt(childAge),
            class_name: "class " + selectedClass,
            guardian_name: parentName,
            mobile: phoneNumber,
            interested_courses: interestedCourses,
            type: "info"
        }

        console.log(data);

        fetch(`${process.env.REACT_APP_BASE_URL}api/v1/registration-lead-store`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "secretKey": `${process.env.REACT_APP_API_URL_SECRET_KEY}`
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                toast(data.message);
                setSelectedCourses([]);
                setSelectedClass(null);
                setParentName("");
                setPhoneNumber("");
                setChildAge("")
                navigate("/");
            })
    };

    return (
        <div className="tw-max-w-[1080px] tw-mx-auto tw-mt-10">
            <nav aria-label="Progress">
                <ol role="list" className="tw-overflow-hidden">
                    {steps.map((step, stepIdx) => (
                        <li key={step.name}
                            className={classNames(stepIdx !== steps.length - 1 ? 'tw-pb-10' : '', 'tw-relative')}>
                            {step.status === 'complete' ? (
                                <>
                                    {stepIdx !== steps.length - 1 ? (
                                        <div
                                            className="tw-absolute tw-left-4 tw-top-4 tw--ml-px tw-mt-0.5 tw-h-full tw-w-0.5 tw-bg-indigo-600"
                                            aria-hidden="true"/>
                                    ) : null}
                                    <span className="tw-flex tw-h-9 tw-items-center">
                                        <span
                                            className="tw-relative tw-z-10 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-indigo-600 tw-group-hover:bg-indigo-800">
                                            <CheckIcon className="tw-h-5 tw-w-5 tw-text-white" aria-hidden="true"/>
                                        </span>
                                    </span>
                                    <div className="tw-ml-14">
                                        <span className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-col tw-mb-16">
                                        <span
                                            className="tw-font-medium tw--mt-7 tw-mb-8 tw-text-2xl tw-text-[#532D80]">{step.name}</span>
                                            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-10">
                                                    {
                                                        step.courseInfo.map((course, index) => (<>

                                                            <div key={index}
                                                                 className={`relative flex items-start tw-border tw-rounded-2xl tw-max-w-160px tw-max-h-148px ${selectedCourses.includes(course.courseName) ? 'tw-bg-[#F5F3FF] tw-border-purple-500' : ''}`}>

                                                                <div className="tw-p-4">
                                                                    <input
                                                                        id={`${course.courseName}`}
                                                                        name={`${course.courseName}`}
                                                                        type="checkbox"
                                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 tw-cursor-pointer"
                                                                        checked={selectedCourses.includes(course.courseName)}
                                                                        onChange={(event) => handleCourseSelection(event, course.courseName)}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="min-w-0 flex-1 text-sm leading-6 tw-flex tw-flex-col tw-justify-center tw-items-center">
                                                                    <label htmlFor={`${course.courseName}`}
                                                                           className="select-none font-medium text-gray-900 tw-cursor-pointer">
                                                                        <img className="" src={course.courseImage} alt=""/>
                                                                        <p>{course.courseName}</p>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </>))
                                                    }
                                            </div>
                                        </span>
                                    </div>
                                </>
                            ) : step.status === 'current' ? (
                                <>
                                    {stepIdx !== steps.length - 1 ? (
                                        <div
                                            className="tw-absolute tw-left-4 tw-top-4 tw--ml-px tw-mt-0.5 tw-h-full tw-w-0.5 tw-bg-gray-300"
                                            aria-hidden="true"/>
                                    ) : null}
                                    <span className="tw-flex tw-h-9 tw-items-center" aria-hidden="true">
                                        <span
                                            className="tw-relative tw-z-10 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-indigo-600 tw-bg-white">
                                            <span className="tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-indigo-600"/>
                                        </span>
                                    </span>
                                    <div className="tw-ml-14">
                                        <span className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-col tw-mb-16">
                                        <span
                                            className="tw-font-medium tw--mt-7 tw-mb-8 tw-text-2xl tw-text-[#532D80]">{step.name}</span>
                                            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-10">
                                                {
                                                    step.classes.map((Class, index) => <div key={index} className="">
                                                        <input
                                                            type="radio"
                                                            id={`class-${index}`}
                                                            name="hosting"
                                                            value={index}
                                                            className="tw-hidden tw-peer"
                                                            required
                                                            onChange={handleChange}
                                                        />
                                                        <label
                                                            htmlFor={`class-${index}`}
                                                            className={`tw-inline-flex tw-items-center tw-justify-between tw-w-full tw-p-5 tw-text-gray-500 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg tw-cursor-pointer ${
                                                                selectedClass === index ? "tw-bg-purple-100 tw-border-purple-600" : ""
                                                            }`}
                                                        >
                                                            <div className="tw-flex tw-justify-center tw-items-center">
                                                                <p
                                                                    className={`${Class.bgColor} tw-text-white tw-px-4 tw-py-2 tw-mr-2 tw-rounded-full tw-font-bold tw-text-lg`}
                                                                >
                                                                    {index}
                                                                </p>
                                                                <p>{Class.className}</p>
                                                            </div>
                                                        </label>

                                                    </div>)
                                                }
                                            </div>
                                    </span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {stepIdx !== steps.length - 1 ? (
                                        <div
                                            className="tw-absolute tw-left-4 tw-top-4 tw--ml-px tw-mt-0.5 tw-h-full tw-w-0.5 tw-bg-gray-300"
                                            aria-hidden="true"/>
                                    ) : null}
                                    <span className="tw-flex tw-h-9 tw-items-center" aria-hidden="true">
                                        <span
                                            className="tw-relative tw-z-10 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-gray-300 tw-bg-white tw-group-hover:border-gray-400">
                                            <span
                                                className="tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-transparent tw-group-hover:bg-gray-300"/>
                                        </span>
                                    </span>
                                    <div className="tw-ml-14">
                                        <span className="tw-ml-4 tw-flex tw-min-w-0 tw-flex-col">
                                            <span
                                                className="tw-font-medium tw--mt-7 tw-mb-8 tw-text-2xl tw-text-[#532D80]">{step.name}</span>

                                            <form onSubmit={handleSubmit}>
                                                <div>
                                                    <label>অভিভাবকের নাম <span
                                                        className="tw-text-red-700">*</span></label>
                                                    <input className="tw-py-3 tw-rounded-lg tw-pl-4 tw-w-full tw-border"
                                                           type="text"
                                                           value={parentName} // Set the value attribute to state variable
                                                           onChange={(e) => setParentName(e.target.value)}
                                                           required
                                                           placeholder="অভিভাবকের নাম"/>
                                                </div>
                                                <div className="tw-mt-4">
                                                    <label>ফোন নম্বর <span
                                                        className="tw-text-red-700">*</span></label>
                                                    <input className="tw-py-3 tw-w-full tw-border tw-rounded-lg tw-pl-4"
                                                           type="text"
                                                           value={phoneNumber} // Set the value attribute to state variable
                                                           onChange={handlePhoneNumberChange}
                                                           required
                                                           placeholder="ফোন নম্বর"/>
                                                    {phoneNumberError &&
                                                        <p className="tw-mt-2 tw-text-sm tw-text-red-700">{phoneNumberError}</p>}
                                                </div>
                                                <div className="tw-mt-4">
                                                    <label>আপনার সন্তানের বয়স <span
                                                        className="tw-text-red-700">*</span></label>
                                                    <input className="tw-py-3 tw-w-full tw-border tw-rounded-lg tw-pl-4"
                                                           type="text"
                                                           value={childAge} // Set the value attribute to state variable
                                                           onChange={handleChildAgeChange}
                                                           required
                                                           placeholder="সন্তানের বয়স "/>
                                                    {childAgeError &&
                                                        <p className="tw-mt-2 tw-text-sm tw-text-red-700">{childAgeError}</p>}
                                                </div>

                                                <button
                                                    className="tw-mt-8 tw-bg-[#532D80] tw-w-full tw-flex tw-justify-center tw-items-center tw-text-white tw-py-3 tw-rounded-lg">জমা দিন <span
                                                    className="tw-ml-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20"
                                                         viewBox="0 0 21 20" fill="none">
                                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M12.7929 5.29289C13.1834 4.90237 13.8166 4.90237 14.2071 5.29289L18.2071 9.29289C18.5976 9.68342 18.5976 10.3166 18.2071 10.7071L14.2071 14.7071C13.8166 15.0976 13.1834 15.0976 12.7929 14.7071C12.4024 14.3166 12.4024 13.6834 12.7929 13.2929L15.0858 11H3.5C2.94772 11 2.5 10.5523 2.5 10C2.5 9.44772 2.94772 9 3.5 9H15.0858L12.7929 6.70711C12.4024 6.31658 12.4024 5.68342 12.7929 5.29289Z"
                                                            fill="white"/>
                                                    </svg>
                                                </span>
                                                </button>
                                            </form>

                                        </span>
                                    </div>
                                </>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
            <div className="tw-mt-36 tw-bg-gray-100 tw-p-10 tw-rounded-2xl">
                <div className="md:tw-ml-20 md:tw-px-10">
                    <p className="tw-text-2xl tw-font-semibold tw-text-[#532D80] tw-mb-8">আমাদের কোর্স সম্পর্কে জিজ্ঞাসা
                        করুন</p>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>এটা কি অনলাইন কোর্স?</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    <span> উত্তর- </span> জি, মজারুর সকল কোর্স ও ক্লাস
                                    অনলাইনে অনুষ্ঠিত হয়।
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                কোর্স ম্যাটারিয়াল কিভাবে পাব?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    <span> উত্তর- </span> কুরিয়ারের মাধ্যমে আপনার ঠিকানায়
                                    হোম ডেলিভারি দেওয়া হবে।
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                কত বছর বয়সি শিশুরা কোর্স করতে পারবে?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    <span> উত্তর- </span> মজারুতে ৩-১৩ বছর বয়সী শিশুরা ক্লাস
                                    করতে পারবে।
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                সপ্তাহে কত দিন কতক্ষণ ক্লাস হয়?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>উত্তর- সপ্তাহে দুই দিন এক ঘন্টা করে ক্লাস হয়।</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                এই কোর্সে ক্লাসের পড়া পড়ানো হবে?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    <span> উত্তর- </span> মজারুর কোর্সের মাধ্যেম শিশুদের
                                    ব্রেইন ডেভেলপমেন্ট ও শিক্ষার প্রতি আকর্ষণ তৈরি করা হয়,
                                    ফলে ক্লাসের অনেক বিষয় শিশুরা আয়ত্ত করতে পারে।
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                আপনাদের সাথে কিভাবে যোগাযোগ করব?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    <span> উত্তর- </span> উপরে দেওয়া ফরমটি ফিলাপ করে দিন
                                    আমাদের একজন স্টুডেন্ট কাউন্সিলর আপনাকে ফোন করে সহযোগিতা
                                    করবেন অথবা সরাসরি আমাদের হেল্পলাইনে ফোন করুন-
                                    <a href="09610990880">
                                        <BiPhoneCall/> 09610990880
                                    </a>
                                    এই নাম্বারে
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <div className="md:tw-flex tw-mb-16 tw-mt-8">
                <div className="tw-bg-[#542E80] tw-p-10 md:tw-rounded-bl-2xl md:tw-rounded-tl-2xl md:tw-mb-24">
                    <p className="tw-text-[#FFCE39] tw-mb-2">সকাল ৯ টা - রাত ১০ টা</p>
                    <h3 className="tw-text-white tw-mb-2">যেকোনো প্রয়োজনে এখনই কল করুন</h3>
                    <p className="tw-text-white tw-mb-3">আপনার সোনামনির পড়ালেখা সংক্রান্ত যেকোনো জিজ্ঞাসায় কল করুন,
                        মজার শিক্ষা গুরু, মজায় শেখা
                        শুরু</p>
                    <div className="tw-flex tw-items-center tw-max-w-[200px] tw-bg-white tw-py-3 tw-rounded-lg">
                        <svg className="tw-ml-2 tw-mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                             viewBox="0 0 20 20"
                             fill="none">
                            <path
                                d="M17.9241 2.61722C17.8757 2.50014 17.804 2.3904 17.7092 2.29502C17.7078 2.2936 17.7064 2.29219 17.705 2.29078C17.5242 2.11106 17.2751 2 17 2H13C12.4477 2 12 2.44772 12 3C12 3.55228 12.4477 4 13 4H14.5858L11.2929 7.29289C10.9024 7.68342 10.9024 8.31658 11.2929 8.70711C11.6834 9.09763 12.3166 9.09763 12.7071 8.70711L16 5.41421V7C16 7.55228 16.4477 8 17 8C17.5523 8 18 7.55228 18 7V3C18 2.86441 17.973 2.73512 17.9241 2.61722Z"
                                fill="#6B7280"/>
                            <path
                                d="M2 3C2 2.44772 2.44772 2 3 2H5.15287C5.64171 2 6.0589 2.35341 6.13927 2.8356L6.87858 7.27147C6.95075 7.70451 6.73206 8.13397 6.3394 8.3303L4.79126 9.10437C5.90756 11.8783 8.12168 14.0924 10.8956 15.2087L11.6697 13.6606C11.866 13.2679 12.2955 13.0492 12.7285 13.1214L17.1644 13.8607C17.6466 13.9411 18 14.3583 18 14.8471V17C18 17.5523 17.5523 18 17 18H15C7.8203 18 2 12.1797 2 5V3Z"
                                fill="#6B7280"/>
                        </svg>
                        <a className="tw-text-lg" href="tel:09610990880">
                            09610990880
                        </a>
                    </div>
                </div>
                <div className="md:tw-rounded-br-2xl md:tw-rounded-tr-2xl tw-mb-24">
                    <img className="tw-w-full md:tw-rounded-br-2xl md:tw-rounded-tr-2xl" src={CallImage}/>
                </div>
            </div>
        </div>
    );
};

export default ResistrationLead;