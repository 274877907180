import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast} from "react-toastify";
import PageTitle from '../../../../shared/PageTitle/PageTitle';
import ScheduleClassCard from './ScheduleClassCard/ScheduleClassCard';

const ScheduleFreeClassList = () => {
    const [orientationClassess, setOrientationClassess] = useState([]);
    const location = useLocation();
        /* useEffect(() => {
        if (location.state.message) {
            toast.success(location.state.message);
        }
    }, [location.state.message]); */

    useEffect(() => {
        async function fetchOrientationClassInfo() {
            let response = await fetch(`${process.env.REACT_APP_BASE_URL}api/user-booked-slot-lists?free_class_user_id=${location.state.free_class_user_id}`)
            response = await response.json();
            setOrientationClassess(response.data);
        }

        fetchOrientationClassInfo();
    }, [location.state.free_class_user_id]);


  
    

const today = new Date();
    return (
        <section className='container'>
        <PageTitle title='Schedule Class List'></PageTitle>
            
            {
    orientationClassess?.booked_slot_details?.length > 0 ? (
        <>
        {
            orientationClassess?.booked_slot_details?.filter(orientationClasse => {
                    const orientationClassDate = new Date(orientationClasse?.slot?.orientation_class_date);
                    return orientationClassDate.getDate() === today.getDate() 
                })?.length > 0 &&
                    <h4 className='mt-5 mb-3'>আজকের ওরিয়েন্টেশন ক্লাস</h4> 
        }
            <div className="row g-3">
                {orientationClassess?.booked_slot_details?.filter(orientationClasse => {
                    const orientationClassDate = new Date(orientationClasse?.slot?.orientation_class_date);
                    return orientationClassDate.getDate() === today.getDate() 
                })?.map(orientationClasse => (
                    <ScheduleClassCard key={orientationClasse?.id} orientationClasse={orientationClasse} user_name={orientationClassess?.username} mobile_number={orientationClassess?.mobile_number} age={location.state.age}/>
                ))}
            </div>
        {
            orientationClassess?.booked_slot_details?.filter(orientationClasse => {
                    const orientationClassDate = new Date(orientationClasse?.slot?.orientation_class_date);
                    return orientationClassDate.getDate() !== today.getDate() 
                })?.length > 0 &&
                    <h4 className='mt-5 mb-3'>পরবর্তী দিনগুলোর ওরিয়েন্টেশন ক্লাস</h4>
        }
             
            <div className="row g-3">
                {orientationClassess?.booked_slot_details?.filter(orientationClasse => {
                    const orientationClassDate = new Date(orientationClasse?.slot?.orientation_class_date);
                    return orientationClassDate.getDate() !== today.getDate() 
                })?.map(orientationClasse => (
                    <ScheduleClassCard key={orientationClasse?.id} orientationClasse={orientationClasse}/>
                ))}
            </div>
        </>
    ) : (
        <h4 className='mt-5 mb-3 text-center'>এখনো কোনো ওরিয়েন্টেশন ক্লাসে রেজিস্ট্রেশন করা হয় নাই</h4> 
    )
}


        </section>
    );
};

export default ScheduleFreeClassList;