import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {englishToBengaliNumberFormat} from '../../../../utilities/utilities';
import bookIcon from '../../../../images/icon_book.webp';
import './BalanceSection.css';
import TagManager from "react-gtm-module";
import utils from "../../../../utils/index"

const BalanceSection = ({handleActivePaymentMethod, payment, setData, affiliate_id}) => {

    const [duration, setDuration] = useState(0);
    const [initialPrice, setInitialPrice] = useState(0);
    /* const [discount, setDiscount] = useState(0); */
    const [finalPrice, setFinalPrice] = useState(0);
    const [courseId, setCourseId] = useState('');
    const [batch, setBatch] = useState('');
    const [batchId, setBatchId] = useState('0');
    const [batchTotalDuration, setBatchTotalDuration] = useState('');
    const [batchStartDate, setBatchStartDate] = useState('');
    const [batchEndDate, setBatchEndDate] = useState('');
    const [isCouponUsed, setIsCouponUsed] = useState(false);
    const [finalBalanceUsingCoupon, setFinalBalanceUsingCoupon] = useState(0);
    const [couponDetail, setCouponDetail] = useState('');
    /* const [couponPercentageAmount, setCouponPercentageAmount] = useState(); */
    const navigate = useNavigate();


    const handelCoupon = event => {
        event.preventDefault();
        const couponInfo = {
            coupon_code: event.target.promoCode.value
        };

        if (couponInfo.coupon_code === '') {
            toast.error('Coupon code is required');

        } else {
            fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}apply-coupon-code?coupon_code=${event.target.promoCode.value}`)
                .then(res => res.json())
                .then(data => {
                    if (data.message_type === 'success') {
                        let actualPrice = parseInt(finalPrice);
                        setCouponDetail(data.data);
                        /* console.log(data.data); */
                        if (data?.data?.coupon_percentage_or_fixed === 'percentage') {
                            let percentage = parseInt(data?.data?.coupon_amount);
                            setFinalBalanceUsingCoupon(((percentage * actualPrice) / 100));
                            /* setCouponPercentageAmount((percentage*actualPrice)/100);
                            setFinalBalanceUsingCoupon(actualPrice - ((percentage*actualPrice)/100)); */
                        } else {
                            setFinalBalanceUsingCoupon(parseInt(data?.data?.coupon_amount));
                        }
                        setIsCouponUsed(true);
                        toast.success(data.message);
                        event.target.reset();
                    } else {
                        setIsCouponUsed(false);
                        toast.error(data.message);
                    }
                })
        }
    }


    let max_course_price = parseInt(payment?.course_payment_segments[0]?.course_original_price);
    for (let i = 1; i < payment?.course_payment_segments?.length; ++i) {
        let averageMarksPercentage = parseInt(payment?.course_payment_segments[i]?.course_original_price);
        if (averageMarksPercentage > max_course_price) {
            max_course_price = averageMarksPercentage;
        }
    }


    const handelBatch = (event) => {
        setBatch(event.currentTarget.querySelector(`[name=batch_name]`)?.value);
        setBatchId(event.currentTarget.querySelector(`[name=batch_id]`)?.value);
        setBatchStartDate(event.currentTarget.querySelector(`[name=batch_start_date]`)?.value);
        setBatchEndDate(event.currentTarget.querySelector(`[name=batch_end_date]`)?.value);
        setBatchTotalDuration(event.currentTarget.querySelector(`[name=batch_total_duration]`)?.value);
    }
    const handelPrice = (event) => {
        setDuration(event.currentTarget.querySelector(`[name=duration]`)?.value);
        setInitialPrice(event.currentTarget.querySelector(`[name=initial_price]`)?.value);
        /* setDiscount(event.currentTarget.querySelector(`[name=discount]`)?.value); */
        setFinalPrice(event.currentTarget.querySelector(`[name=final_price]`)?.value);
        setCourseId(event.currentTarget.querySelector(`[name=course_id]`)?.value);
    }

    useEffect(() => {
        // active batch
        let Btn_Batch = document.querySelectorAll(".selectBatch button");
        for (let button of Btn_Batch) {
            button.addEventListener('click', (e) => {
                const et = e.currentTarget;
                const active = document.querySelector(".btn_batch_active");
                if (active) {
                    active.classList.remove("btn_batch_active");
                }
                et.classList.add("btn_batch_active");
            });
        }

        //active payment segment
        let selectBalance = document.querySelectorAll(".selectBalance button");
        for (let button of selectBalance) {
            button.addEventListener('click', (e) => {
                const et = e.currentTarget;
                const active = document.querySelector(".btn_balance_active");
                if (active) {
                    active.classList.remove("btn_balance_active");
                }
                et.classList.add("btn_balance_active");
            });
        }

    }, [])

    useEffect(() => {

        const value = {
            transaction_amount: isCouponUsed ? (finalPrice - finalBalanceUsingCoupon) : finalPrice,
            user_id: localStorage.getItem("user_id"),
            user_name: payment?.user_infos?.user_name,
            user_mobile_number: payment?.user_infos?.mobile_number ? payment?.user_infos?.mobile_number : '',
            user_address: '374, Free School Street Hatirpool, Kalabagan, Dhaka-1205',
            course_id: courseId,
            course_name: payment?.courseName?.course_name,
            batch_id: batchId,
            batch_name: batch,
            book_id: 1,
            book_title: 'testBook',
            balance: englishToBengaliNumberFormat(payment?.wallet_balance?.balance),
            batch_start_date: batchStartDate,
            batch_total_duration: batchTotalDuration,
            admission_period: duration,
            course_original_price: initialPrice,
            course_total_price: max_course_price,
            coupon_id: couponDetail?.id,
            reference_code: couponDetail?.reference_code,
            coupon_code: couponDetail?.coupon_code,
            affiliate_code: affiliate_id

            /* course_total_price: payment.course_maximum_segment_price, */
        };

        setData(value);


    }, [finalPrice, courseId, batchId, batch, finalBalanceUsingCoupon, isCouponUsed, batchStartDate, batchTotalDuration, duration, initialPrice, couponDetail]);


    /* Free Course Subscription */
    const handleFreeSubscription = () => {
        const freeSunscription = {
            course_id: payment?.course_id,
            batch_id: batchId,
            user_id: localStorage.getItem("user_id"),
            batch_start_date: batchStartDate,
            batch_end_date: batchEndDate,
            batch_total_duration: batchTotalDuration,
            type: "free",
        };
        fetch(`${process.env.REACT_APP_BASE_URL}api/free-course-subscription`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(freeSunscription)
        })
            .then(res => res.json())
            .then(data => {
                if (data.message_type === 'success') {
                    toast.success(data.message);
                    navigate("/my-course");
                } else {
                    toast.error(data.message);
                }
            })

    };

    /* use 100% cupon code */
    const handleCouponFullSubscription = () => {

        TagManager.dataLayer({
            dataLayer: {
                event: 'payment_initiate',
                pagePath: `payment_initiate`,
                PageTitle: `payment_initiate`,
                courseName: payment?.courseName?.course_name,
                courseId: courseId,
                course_original_price: initialPrice,
                course_total_price: max_course_price,
                price: finalPrice,
                currency: 'BDT',
            }
        })

        const pixelData = {page: 'Balance section'};
        utils.addFacebookEvent('Initiate Payment', pixelData);

        const freeCuponSunscription = {
            transaction_amount: isCouponUsed ? (finalPrice - finalBalanceUsingCoupon) : finalPrice,
            user_id: localStorage.getItem("user_id"),
            user_name: payment?.user_infos?.user_name,
            user_mobile_number: payment?.user_infos?.mobile_number ? payment?.user_infos?.mobile_number : '',
            course_id: courseId,
            course_name: payment?.courseName?.course_name,
            batch_id: batchId,
            batch_name: batch,
            book_id: '',
            book_title: '',
            balance: englishToBengaliNumberFormat(payment?.wallet_balance?.balance),
            batch_start_date: batchStartDate,
            batch_total_duration: batchTotalDuration,
            admission_period: duration,
            course_original_price: initialPrice,
            course_total_price: max_course_price,
            coupon_id: couponDetail?.id,
            coupon_code: couponDetail?.coupon_code,
            affiliate_code: affiliate_id,
            type: 'coupon'
        };

        /* console.log(freeCuponSunscription); */

        fetch(`${process.env.REACT_APP_BASE_URL}api/free-course-subscription`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(freeCuponSunscription),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message_type === "success") {
                    fetch(`${process.env.REACT_APP_BASE_URL}api/pay-via-ssl-for-free-course-web`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(freeCuponSunscription)
                    })
                        .then(res => res.json())
                        .then(data => {
                            if (data.message_type === 'success') {
                                toast.success(data.message);
                                navigate("/my-course");
                            } else {
                                toast.error(data.message);
                            }
                        })
                } else {
                    toast.error(data.message);
                }
            });
    };


    const [imageLoadedMap, setImageLoadedMap] = useState({});
    const handleImageLoad = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: true
        }));
    };

    const handleImageError = (index) => {
        setImageLoadedMap(prevMap => ({
            ...prevMap,
            [index]: false
        }));
    };


    return (
        <div>
            {/* <div className='d-flex justify-content-between align-items-center current-balance-section'>
                <p className='mb-0' style={{ fontSize: '20px' }}>বর্তমান ব্যালেন্স </p>
                <p className='mb-0 current-balance'>৳{englishToBengaliNumberFormat(payment?.wallet_balance?.balance)}</p>
            </div> */}

            <p className='my-4 payment-course-name'>{payment?.courseName?.course_name}</p>

            {/* Bundle Book */}
            <p className='my-3 bundle-title'>বান্ডেলে থাকা বিষয়সমূহ</p>
            <div className='bundle-book mb-4'>
                {payment?.course_subjects?.map((course_subject, index) => (
                    <div className='d-flex align-items-center mb-4' key={course_subject.subject_id}>
                        {imageLoadedMap[index] !== false && (
                            <img
                                src={`${payment.subject_image_path}${course_subject?.subject_image}`}
                                width={30} height={30} alt=""
                                onLoad={() => handleImageLoad(index)}
                                onError={() => handleImageError(index)}
                            />
                        )}
                        {imageLoadedMap[index] === false && <img
                            src={bookIcon}
                            width={30} height={30} alt=""
                        />}
                        <span className='ps-3' style={{fontSize: '22px'}}>{course_subject?.subject_name}</span>
                    </div>
                ))
                }
            </div>


            {/* Batch */}
            {
                payment?.course_batches.length === 0 ?
                    <p className='text-danger' style={{fontSize: '22px', fontWeight: '600'}}>[ N.B: Admission period is
                        over. Please Contact with Mojaru ]</p> : <p className='my-3 bundle-title'>ব্যাচ সিলেক্ট করো</p>
            }

            <div className="selectBatch">
                {
                    payment?.course_batches?.map(course_batch => (
                        <button className='btn-balance' onClick={handelBatch} key={course_batch.id}>
                            <div className='batch'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <p className='mb-0'
                                       style={{fontSize: '22px', fontWeight: '500'}}>{course_batch?.batch_name}</p>


                                    <span style={{display: 'none'}}>
                                        <input type="hidden" name="batch_name" value={course_batch?.batch_name}/>
                                        <input type="hidden" name="batch_id" value={course_batch?.id}/>
                                        <input type="hidden" name="batch_start_date"
                                               value={course_batch?.batch_duration_start_date}/>
                                        <input type="hidden" name="batch_end_date"
                                               value={course_batch?.batch_duration_end_date}/>
                                        <input type="hidden" name="batch_total_duration"
                                               value={course_batch?.batch_total_duration_in_days}/>
                                    </span>
                                </div>
                            </div>
                        </button>
                    ))
                }
            </div>


            {
                payment?.course_payment_segments.length !== 0 &&
                <>
                    {/* Partial Payment */}
                    <p className='my-3 bundle-title'>ভর্তির মেয়াদ সিলেক্ট করো</p>
                    <div className="selectBalance">
                        {
                            payment?.course_payment_segments?.map(course_payment_segment => (
                                <button className='btn-balance' onClick={handelPrice} key={course_payment_segment.id}>
                                    <div className=' partial-balance'>
                                        <div className='d-flex justify-content-between align-items-center pb-2'>
                                            <p className='mb-0 balooda-medium' style={{
                                                fontSize: '22px',
                                                fontWeight: '500'
                                            }}>{englishToBengaliNumberFormat(course_payment_segment?.payment_segment_level)}</p>
                                            <p className='mb-0 balooda-medium' style={{
                                                fontSize: '26px',
                                                color: '#532D80',
                                                fontWeight: '700'
                                            }}>৳{englishToBengaliNumberFormat(course_payment_segment?.course_final_price)}
                                                {course_payment_segment?.course_final_price !== course_payment_segment?.course_original_price ?
                                                    <del className='balooda-regular' style={{
                                                        fontSize: '18px',
                                                        fontWeight: '400'
                                                    }}>৳{englishToBengaliNumberFormat(course_payment_segment?.course_original_price)}</del> : ''}
                                            </p>


                                            <span style={{display: 'none'}}>
                                                <input type="hidden" name="duration"
                                                       value={course_payment_segment?.payment_segment_duration_in_days}/>
                                                <input type="hidden" name="course_id"
                                                       value={course_payment_segment?.course_id}/>
                                                <input type="hidden" name="initial_price"
                                                       value={course_payment_segment?.course_original_price}/>
                                                <input type="hidden" name="discount"
                                                       value={course_payment_segment?.discount_value !== null ? course_payment_segment?.discount_value : '0'}/>
                                                <input type="hidden" name="final_price"
                                                       value={course_payment_segment?.course_final_price}/>
                                            </span>
                                        </div>
                                        {(course_payment_segment?.discount_perecentage_or_amount === 'amount') ? <div
                                                className='discount-percentage balooda-regular'>৳{englishToBengaliNumberFormat(course_payment_segment?.discount_value)} off</div>
                                            : (course_payment_segment?.discount_perecentage_or_amount === 'percentage') ?
                                                <div
                                                    className='discount-percentage balooda-regular'>{englishToBengaliNumberFormat(course_payment_segment?.discount_value)}%
                                                    off</div>
                                                :
                                                <div style={{display: 'none'}}>
                                                    <div className='discount-percentage'>0</div>
                                                </div>
                                        }
                                    </div>
                                </button>
                            ))
                        }
                    </div>

                    {/* admission details */}
                    <p className='mt-4 mb-3 bundle-title'>ভর্তির বিস্তারিত</p>

                    <div className='admission-details'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className='mb-0'>ভর্তির মেয়াদ</p>
                            <p className='mb-0 balooda-medium'>{englishToBengaliNumberFormat(duration)} দিন</p>
                        </div>
                        <div className='admission-details-divider'></div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className='mb-0'>ভর্তি ফি</p>
                            <p className='mb-0 balooda-medium'>৳ {englishToBengaliNumberFormat(initialPrice)}</p>
                        </div>
                        <div className='admission-details-divider'></div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className='mb-0'>ডিসকাউন্ট</p>
                            <p className='mb-0'>৳ {englishToBengaliNumberFormat(initialPrice - finalPrice)}</p>
                        </div>
                    </div>

                    <div className='mt-3 mb-2 d-flex justify-content-between align-items-center total-cost'>
                        <p className='mb-0'>সর্বমোট</p>
                        <p className='mb-0 balooda-medium'>৳ {englishToBengaliNumberFormat(finalPrice)}</p>
                    </div>
                    {/* englishToBengaliNumberFormat(couponPercentageAmount) */}
                    {
                        isCouponUsed ? <div>
                            <div className='mt-1 mb-2 d-flex justify-content-between align-items-center total-cost'>
                                <p className='mb-0'>প্রোমো কোড ডিসকাউন্ট </p>
                                <p className='mb-0 balooda-medium'>৳{couponDetail.coupon_percentage_or_fixed === 'fixed' ? englishToBengaliNumberFormat(couponDetail.coupon_amount) : englishToBengaliNumberFormat(finalBalanceUsingCoupon)}</p>
                            </div>
                            <div className='admission-details-divider '></div>
                            <div className='d-flex justify-content-between align-items-center total-cost'>
                                <p className='mb-0'>বর্তমান সর্বমোট</p>
                                <p className='mb-0 balooda-medium'>৳ {englishToBengaliNumberFormat(finalPrice - finalBalanceUsingCoupon)}</p>
                            </div>

                        </div> : ""
                    }


                    {/* promo code section */}
                    {
                        (finalPrice > 0 && parseInt(batchId) > 0) &&
                        <>
                            <p className='text-center mb-3 mt-5 ' style={{fontSize: '24px', fontWeight: '500'}}>প্রোমো
                                কোড থাকলে তা যোগ করে বুঝে <br/>নাও আকর্ষণীয় ছাড়!</p>

                            <form onSubmit={handelCoupon} className='mb-3'>
                                <div className='row gy-3'>
                                    <div className="col-lg-9 lg-pe-1">
                                        <input className='promo-code-field' name='promoCode' type="text"
                                               placeholder='প্রোমো কোড (যদি থাকে)'/>
                                    </div>
                                    <div className="col-lg-3 lg-ps-1">
                                        <button className='btn-apply' disabled={finalPrice > 0 ? false : true}>Apply
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </>
                    }

                </>
            }


            {/* Course admit button */}

            {

                payment?.course_payment_segments.length !== 0 ?

                    (finalPrice > 0 && parseInt(batchId) > 0 && parseInt(finalPrice) !== finalBalanceUsingCoupon) ?
                        <div className='payment-bottom' onClick={() => handleActivePaymentMethod()}>
                            <button className='btn-admit-course'>কোর্সে ভর্তি হও</button>
                        </div>
                        : (finalPrice <= 0 && parseInt(batchId) <= 0) ?
                            <div className='payment-bottom d-flex justify-content-center align-items-center mb-0'>
                                <p style={{fontSize: '26px', fontWeight: '600', color: 'red'}}>ব্যাচ এবং ভর্তির মেয়াদ
                                    সিলেক্ট করো</p>
                            </div>
                            : (finalPrice <= 0) ?
                                <div className='payment-bottom d-flex justify-content-center align-items-center mb-0'>
                                    <p style={{fontSize: '26px', fontWeight: '600', color: 'red'}}>ভর্তির মেয়াদ সিলেক্ট
                                        করো</p>
                                </div>
                                : parseInt(batchId) <= 0 ?
                                    <div className='payment-bottom d-flex justify-content-center align-items-center mb-0'>
                                        <p style={{fontSize: '26px', fontWeight: '600', color: 'red'}}>ব্যাচ সিলেক্ট করো</p>
                                    </div>
                                    : (finalPrice > 0 && parseInt(batchId) > 0 && parseInt(finalPrice) === finalBalanceUsingCoupon) ?
                                        <div className='payment-bottom'>
                                            <button className='btn-admit-course'
                                                    onClick={() => handleCouponFullSubscription()}>কোর্সে ভর্তি হও
                                            </button>
                                        </div> : ""


                    :

                    (parseInt(batchId) > 0) ?
                        <div className='payment-bottom'>
                            <button className='btn-admit-course' onClick={() => handleFreeSubscription()}>কোর্সে ভর্তি
                                হও
                            </button>
                        </div>
                        : parseInt(batchId) <= 0 ?
                            <div className='payment-bottom d-flex justify-content-center align-items-center mb-0'>
                                <p style={{fontSize: '26px', fontWeight: '600', color: 'red'}}>ব্যাচ সিলেক্ট করো</p>
                            </div> : ""
            }

        </div>
    );
};

export default BalanceSection;