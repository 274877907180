import React from 'react';
import logo from '../../../../../../images/logo.png'

const ExamInfo = ({setStartQuiz}) => {
    return (
        <div
            className="tw-max-w-[1080px] tw-mx-auto tw-bg-gray-100 tw-rounded-2xl tw-flex tw-flex-col tw-justify-center tw-items-center">
            <img className="tw-h-10 tw-w-auto" src={logo} alt="logo"/>
            <h3 className="tw-text-2xl tw-mt-4">সময়সীমা: 10 মিনিট</h3>

            <p className="tw-text-lg tw-mt-2">প্রতিটি উত্তরের জন্য সময়: 1 মিনিট </p>

            <p className="tw-text-lg tw-mt-2">সর্বমোট পরীক্ষার মান: 10</p>

            <p className="tw-text-lg tw-mt-2">প্রতিটি উত্তরের মান: 1</p>

            <p className="tw-text-lg tw-mt-2">ভুল উত্তরের জন্য কোনো নেগেটিভ মার্কিং নেই</p>

            <p className="tw-text-lg tw-mt-2">1 জন 1 টি নাম্বার থেকে সর্বমোট 1 বার পরীক্ষা দিতে পারবে</p>

            <button onClick={() => setStartQuiz(true)}
                    className="tw-bg-purple-500 tw-text-white tw-px-8 tw-py-2 tw-rounded-2xl tw-mt-10 tw-text-2xl">start
            </button>
        </div>
    );
};

export default ExamInfo;