import React, { useEffect, useState } from "react";
import logo from "../../../../images/logo_white.png";
import google_play from "../../../../images/btn_google_play.png";
import { Link } from "react-router-dom";
import "./Footer.css";
import ReactWhatsapp from "react-whatsapp";
import { englishToBengaliNumberFormat } from "../../../../utilities/utilities";
import sslWirelsss from "../../../../images/ssl-wireless.jpg"
import { useTranslation } from "react-i18next";

const Footer = ({location}) => {
    const [settings, setSettings] = useState([]);
    const {t} = useTranslation();

    //setting
    useEffect(() => {
        async function fetchWebsiteSetting() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/settings`
            );
                response = await response.json();
                setSettings(response.data.settings);
        
        }
        fetchWebsiteSetting();
    }, []);

    const year = new Date().getFullYear();
    const yeraBn = englishToBengaliNumberFormat(year);

    const startingYear = 2020;
    const startingYearBn = englishToBengaliNumberFormat(startingYear);

    return (
        <footer
            style={
                location === "/login" ||
                location === "/registration" ||
                location === "/otp" || 
                location === "/schedule/class" ||
                location === "/schedule/class-list" 
                    ? { display: "none" }
                    : { display: "block" }
            }
        >
            <div
                className="container-fluid pb-3"
                style={{ backgroundColor: "#292929" }}
            >
                <div className="container">
                    <div className="row pt-5 gy-5">
                        <div className="col col-lg-3 col-md-3 col-sm-6 col-12">
                            <div className="footer-img">
                                <img src={logo} alt="" />
                            </div>
                            <div>
                                <p className="text_color_gray pt-4 font-16 footer-text">
                                    {t("Mojaru is an e-learning platform.  Mojaru has appeared with a joyful world of learning. Mojaru is working on children's brain and skill development. Mojaru aims to create a skilled workforce to meet the challenges of the Fourth Industrial Revolution. All you need is internet connection, mobile or laptop")}
                                </p>
                            </div>
                            <div className="footer-img">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-decoration-none"
                                    href={settings.website_facebook_link}
                                    title="Go to facebook page"
                                    aria-label="Go to facebook page"
                                >
                                    {" "}
                                    <i className="fa-brands fa-facebook text_color_gray fa-facebook"></i>
                                </a>
                                {/* <a
                                    target="_blank"
                                    className="text-decoration-none"
                                    href={settings.website_twitter_link}
                                >
                                    {" "}
                                    <i className="fa-brands fa-twitter text_color_gray fa-twitter ps-3"></i>
                                </a> */}
                                <a 
                                    target="_blank" 
                                    className="text-decoration-none" 
                                    rel="noreferrer" 
                                    href={settings.website_instagram_link}
                                    title="Go to instagram page"
                                    aria-label="Go to instagram page"
                                >
                                    <i className="fa-brands fa-instagram text_color_gray fa-instagram ps-3"></i>
                                </a>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-decoration-none"
                                    href={settings.website_youtube_page_link}
                                    title="Go to youtube page"
                                    aria-label="Go to youtube page"
                                >
                                    <i className="fa-brands fa-youtube text_color_gray fa-youtube ps-3"></i>
                                </a>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-decoration-none"
                                    href={settings.website_linkdein_link}
                                    title="Go to linkdein page"
                                    aria-label="Go to linkdein page"
                                >
                                    <i className="fa-brands fa-linkedin text_color_gray fa-linkedin ps-3"></i>
                                </a>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-decoration-none"
                                    href={settings?.website_tiktok_link}
                                    title="Go to tiktok page"
                                    aria-label="Go to tiktok page"
                                >
                                    <i className="fa-brands fa-tiktok ps-3 text_color_gray ps-3"></i>
                                </a>

                                <ReactWhatsapp
                                    number={`${settings?.whatsapp_number}`}
                                    style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                    }}
                                    aria-label="whats-message"
                                    aria-labelledby="whats-message-label"
                                >
                                    <i className="fa-brands fa-whatsapp ps-lg-3 ps-2 text-white icon-font-size"></i>
                                </ReactWhatsapp>
                                {/* <a
                                    target="_blank"
                                    className="text-decoration-none"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title={`${settings?.whatsapp_number}`}
                                    style={{ cursor: "pointer" }}
                                >
                                    <i className="fa-brands fa-whatsapp ps-3 text_color_gray ps-3"></i>
                                </a> */}
                            </div>
                        </div>
                        <div className=" col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <h4 className="text-white fw-bold mt-3">
                                        {t("Explore")}
                                    </h4>
                                    <div className="pt-4">
                                        <Link
                                            to="/about"
                                            className="text_color_gray pt-4 font-16 text-decoration-none footerPage"
                                        >
                                            {t("About Us")}
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/terms_and_condition"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            {t("Terms of Service")}
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/faq"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            {t('FAQ')}
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/e-book"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            {t('E-Book')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <h4 className="text-white fw-bold mt-3">
                                        {t('Support')}
                                    </h4>
                                    <div className="pt-4">
                                        <Link
                                            to="/refund"
                                            className="text_color_gray pt-4 font-16 text-decoration-none footerPage"
                                        >
                                            {t('Refund and Return Policy')}
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/privacy_policy"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            {t("Privacy Policy")}
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/blog"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            {t("Blogs")}
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/contact"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            {t('Contact Us')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-3 col-sm-6 col-12">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <h4 className="text-white fw-bold mt-3 footer-img">{t('Download')}</h4>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-decoration-none"
                                        href={settings.play_store_link}
                                        title="Go to google play"
                                        aria-label="Go to google play"
                                    >
                                        <img src={google_play} alt="example" style={{ height: '50px', marginTop:'24px' }} />
                                    </a>
                                    {/* <div className="d-flex justify-content-center pt-3">
                                        <img
                                            src={apple_store}
                                            alt=""
                                            className="rounded-3 bg-white"
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img src={sslWirelsss} className="img-fluid" alt="" />
            </div>
            <div>
                <p
                    className="text-center bg-dark py-3 mb-0"
                    style={{color: "#9B9B9B"}}
                >
                    {t('Copyright')} {sessionStorage.getItem('useLanguage') === 'bn' ? startingYearBn : startingYear} - {sessionStorage.getItem('useLanguage') === 'bn' ? yeraBn : year} {t('© Mojaru Education Technologies Limited. All rights reserved.')}
                </p>
            </div>
        </footer>
    );
};

export default Footer;
