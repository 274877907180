import React, { useEffect, useState } from "react"
import './Contact.css';
import Address from '../../../../../images/address.png';
import Email from '../../../../../images/mail.png';
import Mobile from '../../../../../images/contact.png';
import TopCircle from '../../../../../images/top_circle.png';
import BottomCircle from '../../../../../images/bottom_circle.png';
import PageTitle from "../../../shared/PageTitle/PageTitle";
import Loader from "../../../shared/Loader/Loader";

const Contact = () => {
    const [settings, setSettings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    
    //setting
    useEffect(()=>{
        async function fetchWebsiteSetting(){
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/settings`
            );
            response = await response.json();
            setSettings(response.data.settings);
            setIsLoading(false);
        }

        fetchWebsiteSetting()
    },[])

    if(isLoading){
        return ( <Loader/> );
    }
     
    return (
        <div>
            <PageTitle title="Contact Us"></PageTitle>
            {/* <Header></Header> */}
            <div style={{ width:'100%', height:"2px", backgroundColor:'#ffce39', marginTop:'1px' }}></div>
            <div className='address-section location'>
                <div className="position-relative">
                    <div>
                        <iframe style={{ borderRadius:'20px' }} src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ-yXb1ou5VTcREZwjm3jZjsM&key=AIzaSyCcmAx1WPQ5DZ9z_6attGh00Hs75hex3rI" height="700" width="100%" title="Iframe Example" className="position-absolute"></iframe>
                        
                    </div>
                    <div  className="position-relative container p-lg-5" >
                    
                        <div className='row'>
                        
                            <div className="col-lg-4 col-md-6 col-sm-8 col-11 px-5 address-details position-relative">
                                <div className="position-absolute top-0 end-0">
                                    <img src={TopCircle} alt=""/>
                                </div>
                                <h1 className='text-white address-title ps-3'>যোগাযোগ করুন</h1>
                                <div className='d-flex mt-4 contact-type'>
                                <img src={Address} alt="" className='mt-2'/>
                                    <p className='ms-3 address mb-0'>{settings.website_address}</p>
                                </div>

                                <div className='d-flex align-items-center mt-4 contact-type'>
                                <img src={Email} alt=""/>
                                    {/* <p className='ms-3 address mb-0'>{settings.website_email}</p> */}
                                    <a className='ms-3 address mb-0' href={`mailto:${settings.website_email}`} style={{ textDecoration:'none' }}>{settings.website_email}</a>

                                </div>

                                <div className='d-flex align-items-center mt-4 contact-type'>
                                <img src={Mobile} alt=""/>
                                    {/* <p className='ms-3 address mb-0'>{settings.website_contact}</p> */}
                                    <a className='ms-3 address mb-0' href={`tel:${settings?.website_contact}`} style={{ textDecoration:'none' }}>{settings?.website_contact}</a>
                                </div>
                                <div className="position-absolute bottom-0 start-0">
                                    <img src={BottomCircle} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Message></Message>
            <Footer></Footer> */}
        </div>
    );
};

export default Contact;