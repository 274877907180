import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import StudentPageTitle from '../../../../../shared/StudentPageTitle/StudentPageTitle';
import {FaCalendarAlt} from "react-icons/fa";
import certificateIcon from "../../../../../../../images/course_play_list/certificate-icon 1.png";
import CoursePlaylist from "../../../Syllabus/CoursePlaylist/CoursePlaylist";
import {englishToBengaliNumberFormat} from "../../../../../../../utilities/utilities";

const SmartNoteView = () => {
    let location = useLocation();
    let smartNoteLink = location?.state?.smartNoteLink;
    let smart_note_pdf_file = location?.state?.smart_note_pdf_file;
    let course_id = location?.state?.course_id;
    let batch_id = location?.state?.batch_id;
    let lessons = location?.state?.lessons;
    let topic = location?.state?.topic;
    let lesion_name = location?.state?.lesion_name;
    let index = location?.state?.index;
    let progressPercent = location?.state?.progressPercent;
    const search = location.search;
    const class_content = new URLSearchParams(search).get("class_content");


    return (
        <>
            {
                lessons ? (
                    <div>
                        <div className="main-content">
                            <StudentPageTitle title="View Smart Note" />
                            <div className="page-content p-0">
                                <div className="container-fluid">
                                    <div className="my-course-section">
                                        <h2 className="tw-px-16 tw-mt-8 tw-mb-8">ক্লাস : {lesion_name}</h2>
                                        <div className="md:tw-flex tw-justify-between tw-px-16">
                                            <div className="tw-flex">
                                                {/*<img className="tw-w-10 tw-h-10 tw-mr-4 tw-rounded-lg"*/}
                                                {/*     src={`${lectureDetail?.video_lecture?.teacher_image_path}${lectureDetail?.video_lecture?.teacher_image}`}*/}
                                                {/*     alt="profile image"/>*/}
                                                <div>
                                                    <p className="tw-text-[#6B7280] tw-text-sm">Published by</p>
                                                    {/*<p className="tw-text-gray-700">{lectureDetail?.video_lecture?.teacher_name}</p>*/}
                                                </div>
                                            </div>
                                            <div className="tw-flex">
                                                <FaCalendarAlt className="tw-text-4xl tw-mr-4 tw-text-gray-500" />
                                                <div>
                                                    <p className="tw-text-[#6B7280] tw-text-sm">Published on</p>
                                                    <p className="tw-text-gray-700">২১ এপ্রিল, ২০২৩</p>
                                                </div>
                                            </div>
                                            <div className="tw-flex tw-bg-white tw-px-5 tw-py-3 tw-rounded-lg">
                                                <div
                                                    className="tw-mr-4 tw-border-r-2 tw-border-gray-300 tw-border-sm tw-px-7">
                                                    <p className="tw-text-2xl tw-font-semibold">কোর্স
                                                        প্রোগ্রেস {englishToBengaliNumberFormat(index + 1)}/ {englishToBengaliNumberFormat(lessons.length)}
                                                    </p>
                                                    <div
                                                        className="tw-w-[200px] tw-bg-gray-200 tw-rounded-full h-4 tw-text-white tw-text-sm tw-text-center">
                                                        <div
                                                            className="tw-bg-green-400 tw-h-4 tw-rounded-full"
                                                            style={{width: `${progressPercent}%`}}
                                                        >{progressPercent}%
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className="tw-w-10 tw-h-10" src={certificateIcon} alt="image"/>
                                            </div>
                                        </div>
                                        <div className="md:tw-flex tw-justify-between md:tw-p-10">
                                            <div className="md:tw-w-[65%] hidden md:block">
                                                <div className="lecture-section" style={{ height: '700px', width: '100%' }}>
                                                    <embed src={`${smartNoteLink}${smart_note_pdf_file}`} type="application/pdf" width="100%" height="100%" />
                                                </div>
                                            </div>
                                            <div className="md:tw-w-[30%]">
                                                <CoursePlaylist lessons={lessons} topic={topic} courseId={course_id} batchId={batch_id} index={index} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                ) : (
                    <div className="main-content">
                        <StudentPageTitle title="View Smart Note"></StudentPageTitle>
                        <div className="page-content p-0">
                            <div className="container-fluid">
                                <div className="my-course-section">
                                    {
                                        class_content === 'smart_note' &&
                                        <p className='mb-4'><Link to='/my-course/lms' state={{
                                            course_id: course_id,
                                            batch_id: batch_id,
                                            class_content: 'smart_note'
                                        }} style={{color: '#532D80', fontWeight: '400', textDecoration: 'none'}}>কোর্সের
                                            বিস্তারিত </Link> / <span style={{color: '#918F92', fontWeight: '400'}}> স্মার্ট নোট</span>
                                        </p>
                                    }
                                    <div className="bg-white custom-card-shadow all-assignment-section">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <p className='course-total-assignment mb-0'>স্মার্ট নোটের বিস্তারিত</p>

                                            {
                                                class_content === 'smart_note' ?
                                                    <div className='pe-4'>
                                                        <Link to='/my-course/lms'
                                                              state={{
                                                                  course_id: course_id,
                                                                  batch_id: batch_id,
                                                                  class_content: 'smart_note'
                                                              }} className='back-btn text-center'><i
                                                            className="fa-solid fa-arrow-left-long pe-2"></i>Back to
                                                            Smart Note List</Link>
                                                    </div>
                                                    :
                                                    class_content === 'content_list' ?
                                                        <div className='pe-4'>
                                                            <Link to='/my-course/lms'
                                                                  state={{
                                                                      course_id: course_id,
                                                                      batch_id: batch_id,
                                                                      class_content: 'content_list'
                                                                  }} className='back-btn text-center'><i
                                                                className="fa-solid fa-arrow-left-long pe-2"></i>Back to
                                                                Class List</Link>
                                                        </div>
                                                        :
                                                        ''
                                            }
                                        </div>
                                        <div className='total-assignment-divider'></div>
                                        <div className='lecture-section p-4' style={{height: '700px'}}>
                                            <embed src={`${smartNoteLink}${smart_note_pdf_file}`} type="application/pdf"
                                                   width="100%" height="100%"/>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default SmartNoteView;