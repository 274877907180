import React from 'react';
import './Banner.css';
import banner_girl from "../../../../../images/Skills.png";

const Banner = () => {
    return (
        <div className="academic_background">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex align-items-center">
                        <div>
                            <p className="banner-title">
                                সময়ের এক স্কিল,
                                <br />
                                অসময়ে দশ কিল
                            </p>
                            <p className="banner-text">
                                চতুর্থ শিল্পবিপ্লবের স্কিলগুলো এখনই না শিখলে
                                <br />
                                ভবিষ্যতে আপনাকে পস্তাতে হবে।
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <img
                            src={banner_girl}
                            alt=""
                            className="img-fluid pb-5 mt-5"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;