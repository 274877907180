import React, {  } from 'react';
import PaymentIn from "../../../../../images/arrow_in.png";
import PaymentOut from "../../../../../images/arrow_out.png";
import { dateConverter, englishToBengaliNumberFormat } from '../../../../../utilities/utilities';
import { toast} from "react-toastify";

const TransactionRow = ({transactionInfo}) => {
      /* const [copySuccess, setCopySuccess] = useState(""); */

  const handleCopy = () => {
    navigator.clipboard.writeText(transactionInfo?.paymentID).then(
      () => {
        toast.success("Copied!");
        /* setCopySuccess("Copied!"); */
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };
    return (
        <>
            <tr className="text-center" style={{ fontSize:'16px', fontWeight:'500' }} >
                <td className="py-3">{transactionInfo.transaction_type === 'add_money_to_wallet'? <img src={PaymentIn} alt="" className="pe-2" />: <img src={PaymentOut} alt="" className="pe-2" />} {transactionInfo.transaction_id} </td>
                <td className="py-3">
                    <p onClick={handleCopy} style={{ cursor:'pointer' }} className='mb-0'>{transactionInfo.paymentID  ? transactionInfo?.paymentID?.length > 21 ? transactionInfo.paymentID.slice(0, 21)+'...' : transactionInfo.paymentID   : ''}</p>
                </td>
                <td className="py-3">{transactionInfo.course_name !== null ? `${transactionInfo.course_name}` : 'ওয়ালেট ইন'}</td>
                <td className="py-3 balooda-medium">{dateConverter(transactionInfo.transaction_date)}</td>
                <td className="py-3">{transactionInfo.payment_gateway}</td>
                <td className="py-3 balooda-medium">৳{englishToBengaliNumberFormat(transactionInfo?.transaction_amount)}</td>
            </tr>
            {/* <tr>
                <td colSpan={6}><div style={{ height:'1px', width:'100%', backgroundColor:'#C9C9C9' }}></div></td>
            </tr> */}
        </>
    );
};

export default TransactionRow;