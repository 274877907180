import React, { useEffect, useState } from "react";
import "./Refund.css";
import refund from "../../../../../images/return and refund _4.png";
import parse from 'html-react-parser';
import Loader from "../../../shared/Loader/Loader";

const Refund = () => {
    const [aboutUs, setAboutUs] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchWebsiteSetting() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/settings`
            );
            response = await response.json();
            setAboutUs(response.data.settings.refund_return);
            setIsLoading(false);
        }
        fetchWebsiteSetting();
    }, []);
    if(isLoading){
        return ( <Loader/> );
    }

    return (
        <div>
            {/* <PageTitle title="Refund"></PageTitle> */}
            {/* <Header></Header> */}
            <div style={{ paddingBottom: "23rem" }}>
                <div className="borderTop">
                    <div className="container">
                        <div className="row refund-section">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                {/* <div className="row ">
                                    <div className="col-lg-6 wrapper">
                                        <div className=" ">
                                            <img
                                                src={bannerImg}
                                                alt=""
                                                className="img-fluid banner-top-img"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <img
                                            src={bannerImg2}
                                            alt=""
                                            className="img-fluid banner-top-img"
                                        />
                                    </div>
                                </div> */}
                                <div className="row pt-3">
                                    <div className="col-lg-12">
                                        <img
                                            src={refund}
                                            alt=""
                                            className="img-fluid banner-bottom-img"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <p className="title pt-5 ps-lg-3">
                                    রিফান্ড এবং রিটার্ন নীতি
                                </p>
                                <p className="detail ps-lg-3">
                                    {parse(aboutUs)}
                                </p>
                                {/* <div>
                                    <img
                                        src={bannerImg4}
                                        alt=""
                                        className="img-fluid pe-lg-5 mt-lg-5"
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Message></Message>
            <Footer></Footer> */}
        </div>
    );
};

export default Refund;
