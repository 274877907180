import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import "./BkashLeads.css";
import callCenterImage from "../../../../../images/call-center-final.png";
import watermarkLogo from "../../../../../images/m_watermark.png";
import PageTitle from "../../../shared/PageTitle/PageTitle";
import { BiPhoneCall } from "react-icons/bi";
import Footer from "../../../shared/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import utils from "../../../../../utils/index";
import ReactConfetti from "react-confetti";
import success from '../../../../../images/successful.jpg';
import registration from '../../../../../images/info_page_banner.jpg';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Link } from "react-router-dom";

const RegistrationLeads = () => {
  const [page, setPage] = useState(true);
  const [mobile, setMobile] = useState("");


  const [windowDimension, setWindowDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [isScrolling, setIsScrolling] = useState(false);

  const [Btn, setBtn] = useState(false);

  const detectSize = () => {
    setWindowDimension({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }
  const handleScroll = () => {
    setIsScrolling(true);
    setTimeout(() => setIsScrolling(false), 200); // Set scrolling state to false after 200 milliseconds
  }
  useEffect(() => {
    window.addEventListener('resize', detectSize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', detectSize);
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    const pixelData = { page: 'organic Leads' };
    utils.addFacebookEvent('Registration', pixelData);

  }, [])

  useEffect(() => {
    if (Btn) {
      const timeout = setTimeout(() => {
        setBtn(false);
      }, 10000); // 10 seconds

      return () => clearTimeout(timeout);
    }
  }, [Btn]);


  const handleSubmit = (e) => {
    e.preventDefault();
    
  // Extract values from the event target
  const guardianName = e.target.guardian_name.value;
  const studentName = e.target.student_name.value;
  const className = e.target.class_name.value;
  const age = e.target.age.value;
  const courseAbakasMind = e.target.course_abakas_mind.checked;
  const courseCadetPrePreparation = e.target.course_cadet_pre_preparation.checked;
  const courseAcademicPreparationSeven = e.target.course_academic_preparation_seven.checked;
  const courseMissionCadet = e.target.course_mission_cadet.checked;
  const courseSmartEnglish = e.target.course_smart_english.checked;
  const courseMathChampsPrimary = e.target.course_math_champs_primary.checked;
  const courseProgrammingForKids = e.target.course_programming_for_kids.checked;
  const courseAdorsoLipi = e.target.course_adorso_lipi.checked;
  const clientRequirement = e.target.client_requirment.value;

  let interestedCourses = "";

  if(courseAbakasMind){
    interestedCourses = "অ্যাবাকাস মাইন্ড ম্যাথ, "
  }
  if(courseCadetPrePreparation){
    interestedCourses = interestedCourses + "ক্যাডেট প্রি-প্রিপারেশন, "
  } 
  if(courseAcademicPreparationSeven) {
    interestedCourses = interestedCourses + "মিশন ক্যাডেট - ২৫, "
  } 
  if(courseMissionCadet) {
    interestedCourses = interestedCourses + "একাডেমিক প্রস্তুতি- ৭ম শ্রেণি, "
  } 
  if (courseSmartEnglish) {
    interestedCourses = interestedCourses + "স্মার্ট ইংলিশ, "
  }
  if (courseMathChampsPrimary) {
    interestedCourses = interestedCourses + "Math Olympiad, "
  } 
  if (courseProgrammingForKids) {
    interestedCourses = interestedCourses + "প্রোগ্রামিং ফর কিডস, "
  }
  if(courseAdorsoLipi) {
    interestedCourses = interestedCourses + "আদর্শলিপি"
  }

  console.log(interestedCourses);

  const formData = {
    guardian_name: guardianName,
    student_name: studentName,
    mobile: mobile,
    class_name: className,
    age: age,
    district: "",
    interested_courses: interestedCourses,
    client_requirment: clientRequirement,
    type: "organic"
  }



    console.log(guardianName);
    fetch(`${process.env.REACT_APP_BASE_URL}api/v1/registration-lead-store`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        "secretKey": `${process.env.REACT_APP_API_URL_SECRET_KEY}`
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setBtn(true);
        setPage(false);
        toast.success("Thank you for Submit", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };


  return (
    <>
      <div className="BkashLeadsPage" style={{ marginTop: "3rem" }}>
        <div className="Social_sideIcon">
          <a
            href="https://www.facebook.com/groups/mojaru.genius"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/fb.gif" alt="" />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=8801610075103&text&type=phone_number&app_absent=0"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/whs.gif" alt="" />
          </a>
          <a
            href="https://www.youtube.com/@Mojarutech"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/youtube.gif" alt="" />
          </a>
        </div>
        <PageTitle title="Registration Leads"></PageTitle>
        
        <div className="BkashLeads page-container">
          <Container>
            <ToastContainer />
            <Row>
              <Col lg={12} style={{ padding: '0' }}>
                <img
                  src={watermarkLogo}
                  alt="Watermark Logo"
                  className="watermark-logo"
                />
                <div>
                  <Link to='/'>
                    <img src={registration} alt="" className="" style={{ width: '100%', height: 'auto' }} />
                  </Link>
                </div>
                {page ? (
                  <div className="BkashLeadsForm">
                    <h3>নিচের ফর্মটি পূরণ করুন</h3>
                    
                    <form onSubmit={handleSubmit}>
                      <div className="custom_input">
                        <label>
                          অভিভাবকের নাম <span>*</span>
                        </label>
                        <input
                          type="text"
                          name="guardian_name"
                          placeholder="অভিভাবকের নাম"
                          required
                          maxLength="50"
                        />
                      </div>
                      <div className="custom_input">
                        <label>সন্তানের নাম<span>*</span></label>
                        <input
                          type="text"
                          name="student_name"
                          placeholder="সন্তানের নাম"
                          required
                        />
                      </div>
                      <div className="custom_input">
                        <label>
                          ফোন নাম্বার <span>*</span>
                        </label>
                        <PhoneInput
                          country={"bd"}
                          onChange={(value) => setMobile(value)}
                          enableSearch={true}
                          required
                        />
                      </div>
                      <div className="custom_input">
                        <label>আপনার সন্তান কোন ক্লাসে পড়ে<span>*</span></label>
                        <select
                          name="class_name"
                          required
                        >
                          <option value="" label="ক্লাস নির্বাচন করুন"></option>
                          <option value="Not Admitted" label="Not Admitted - ভর্তি করা হয়নি"></option>
                          <option value="Play" label="Play - প্লে"></option>
                          <option value="Nursery" label="Nursery - নার্সারী"></option>
                          <option value="KG" label="KG - কেজি"></option>
                          <option value="Class 1" label="Class 1 - শ্রেণী ১"></option>
                          <option value="Class 2" label="Class 2 - শ্রেণী ২"></option>
                          <option value="Class 3" label="Class 3 - শ্রেণী ৩"></option>
                          <option value="Class 4" label="Class 4 - শ্রেণী ৪"></option>
                          <option value="Class 5" label="Class 5 - শ্রেণী ৫"></option>
                          <option value="Class 6" label="Class 6 - শ্রেণী ৬"></option>
                          <option value="Class 7" label="Class 7 - শ্রেণী ৭"></option>
                          <option value="Class 8" label="Class 8 - শ্রেণী ৮"></option>
                          <option value="Class 9" label="Class 9 - শ্রেণী ৯"></option>
                          <option value="Class 10" label="Class 10 - শ্রেণী ১০"></option>
                          <option value="Class 11" label="Class 11 - শ্রেণী ১১"></option>
                          <option value="Class 12" label="Class 12 - শ্রেণী ১২"></option>
                          <option value="Drop Out" label="Drop Out - বাদ পড়া"></option>
                        </select>
                      </div>
                      <div className="custom_input">
                        <label htmlFor="age">
                          আপনার সন্তানের বয়স <span>*</span>
                        </label>
                        <input
                          id="age"
                          type="text"
                          name="age"
                          placeholder="আপনার সন্তানের বয়স"
                          required
                        />
                      </div>
                      <div className="custom_input full_width">
                        <label>
                          আপনার সোনামণিকে মজারুতে কোন কোর্সটি করাতে চান
                        </label>
                        <div className="custom_input__child">
                          <input
                            type="checkbox"
                            name="course_abakas_mind"
                            id="course_abakas_mind"
                          />
                          <label htmlFor="course_abakas_mind">অ্যাবাকাস মাইন্ড ম্যাথ (৪ থেকে ১৩ বছরের সোনামণিদের জন্য )</label>
                        </div>
                        <div className="custom_input__child">
                          <input
                            type="checkbox"
                            name="course_cadet_pre_preparation"
                          />
                          <label htmlFor="course_cadet_pre_preparation">
                            ক্যাডেট প্রি-প্রিপারেশন (৩য়, ৪র্থ ও ৫ম শ্রেণির
                            সোনামণিদের জন্য)
                          </label>
                        </div>
                        <div className="custom_input__child">
                          <input
                            type="checkbox"
                            name="course_mission_cadet"
                          />
                          <label htmlFor="course_mission_cadet">
                            মিশন ক্যাডেট - ২৫ (৬ষ্ঠ শ্রেণির সোনামণিদের জন্য)
                          </label>
                        </div>
                        <div className="custom_input__child">
                          <input
                            type="checkbox"
                            name="course_academic_preparation_seven"
                          />
                          <label htmlFor="course_academic_preparation_seven">
                            একাডেমিক প্রস্তুতি- ৭ম শ্রেণি (৭ম শ্রেণির সোনামণিদের জন্য)
                          </label>
                        </div>
                        <div className="custom_input__child">
                          <input
                            type="checkbox"
                            name="course_smart_english"
                          />
                          <label htmlFor="course_smart_english">স্মার্ট ইংলিশ (৫ থেকে ১১ বছর বছরের সোনামণিদের জন্য )</label>
                        </div>
                        <div className="custom_input__child">
                          <input
                            type="checkbox"
                            name="course_math_champs_primary"
                          />
                          <label htmlFor="course_math_champs_primary">Math Olympiad (৪র্থ শ্রেণি থেকে ৮ম শ্রেণির সোনামণিদের জন্য)</label>
                        </div>
                        <div className="custom_input__child">
                          <input
                            type="checkbox"
                            name="course_programming_for_kids"
                          />
                          <label htmlFor="course_programming_for_kids">
                            প্রোগ্রামিং ফর কিডস (৬ থেকে ১২ বছর বছর বয়সী সোনামণিদের জন্য)
                          </label>
                        </div>
                        <div className="custom_input__child">
                          <input
                            type="checkbox"
                            name="course_adorso_lipi"
                          />
                          <label htmlFor="course_adorso_lipi">
                            আদর্শলিপি (৩ থেকে ৫ বছর বয়সী সোনামণিদের জন্য)
                          </label>
                        </div>
                      </div>
                      <div className="custom_input full_width">
                        <label>আপনার মন্তব্য</label>
                        <textarea
                          name="client_requirment"
                          rows="5"
                        ></textarea>
                      </div>
                      <div className="custom_input full_width button">
                        <button type="submit">জমা দিন</button>
                      </div>
                    </form>
                  </div>
                ) : (

                  <div className="container">
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6 text-center">
                        <div className="success-msg">
                          <img className="success-img img-fluid" src={success} alt="success" />
                          <h3 className="mt-3">অভিনন্দন আপনার রেজিস্ট্রেশন সম্পন্ন হয়েছে।</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Form */}

              </Col>
            </Row>
          </Container>
        </div>

        {/* faq */}
        <section className="faq">
          <Container>
            <Row>
              <Col lg={12}>
                <h3>আপনার সোনামনির পড়ালেখা  সংক্রান্ত যেকোনো জিজ্ঞাসায় কল করুন</h3>
                {/* tabs */}
                <div className="faq__tabs">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>এটা কি অনলাইন কোর্স?</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span> উত্তর- </span> জি, মজারুর সকল কোর্স ও ক্লাস
                          অনলাইনে অনুষ্ঠিত হয়।
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        কোর্স ম্যাটারিয়াল কিভাবে পাব?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span> উত্তর- </span> কুরিয়ারের মাধ্যমে আপনার ঠিকানায়
                          হোম ডেলিভারি দেওয়া হবে।
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        কত বছর বয়সি শিশুরা কোর্স করতে পারবে?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span> উত্তর- </span> মজারুতে ৩-১৩ বছর বয়সী শিশুরা ক্লাস
                          করতে পারবে।
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        সপ্তাহে কত দিন কতক্ষণ ক্লাস হয়?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>উত্তর- সপ্তাহে দুই দিন এক ঘন্টা করে ক্লাস হয়।</p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        এই কোর্সে ক্লাসের পড়া পড়ানো হবে?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span> উত্তর- </span> মজারুর কোর্সের মাধ্যেম শিশুদের
                          ব্রেইন ডেভেলপমেন্ট ও শিক্ষার প্রতি আকর্ষণ তৈরি করা হয়,
                          ফলে ক্লাসের অনেক বিষয় শিশুরা আয়ত্ত করতে পারে।
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        আপনাদের সাথে কিভাবে যোগাযোগ করব?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <span> উত্তর- </span> উপরে দেওয়া ফরমটি ফিলাপ করে দিন
                          আমাদের একজন স্টুডেন্ট কাউন্সিলর আপনাকে ফোন করে সহযোগিতা
                          করবেন অথবা সরাসরি আমাদের হেল্পলাইনে ফোন করুন-
                          <a href="09610990880">
                            <BiPhoneCall /> 09610990880
                          </a>
                          এই নাম্বারে
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* CallUs */}
        <section className="CallUs">
          <Container>
            <Row className="d_flex">
              <Col lg={6}>
                <div className="CallUs_content">
                  <h3>যেকোনো প্রয়োজনে এখনই কল করুন</h3>
                  <p>
                    আপনার সোনামনির পড়ালেখা  সংক্রান্ত যেকোনো জিজ্ঞাসায় কল করুন, মজার শিক্ষা গুরু, মজায় শেখা শুরু

                  </p>
                  <h4>সকাল ৯ টা - রাত ১০ টা</h4>
                  <a href="tel:09610990880">
                    <BiPhoneCall /> 09610990880
                  </a>
                </div>
              </Col>
              <Col lg={6}>
                <div className="CallUs_img">
                  <img src={callCenterImage} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>


      {
        Btn && <ReactConfetti
          width={windowDimension.width}
          height={2000 || windowDimension.height}
          numberOfPieces={500}
          tweenDuration={200}
        />
      }
    </>
  );
};

export default RegistrationLeads;
