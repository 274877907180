import React from "react";
import './Blog.css';
import BlogPath from '../../../../../../images/blog_path.png';
import defaultBlogImage from '../../../../../../images/default-blog-thumb.png';
import { Link } from "react-router-dom";

const Blog = ({blog,blog_image_path}) => {
    /* const [isLoading, setISLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setISLoading(false)
        }, 2000);
      }, []); */


        const today = new Date(blog?.created_at);
        const month = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
        const date = today.getDate() + ' ' + month[today.getMonth()] + ', ' + today.getFullYear();

    return (
        <div className="tw-h-[430px] tw-mb-5 tw-rounded-xl tw-shadow tw-mr-4">
            <Link to={`/blog-details/${blog.blog_link}`} className="tw-text-gray-600 popular-blog-card">
                <div className="">
                    {(blog.blog_image !== undefined && blog.blog_image !== null) ? (
                        <img src={`${blog_image_path}${blog.blog_image}`} alt="" className="tw-h-[200px] tw-w-full"
                             style={{borderRadius: '8px'}}/>
                    ) : (
                        <img src={defaultBlogImage} alt="" className="img-fluid" style={{borderRadius: '8px'}}/>
                    )}
                </div>

                <div className="tw-px-6 tw-py-7">
                    <div className="tw-flex tw-items-center">
                        <div className="tw-flex  tw-mr-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"
                                 fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M4.5 1.5C4.08579 1.5 3.75 1.83579 3.75 2.25V3H3C2.17157 3 1.5 3.67157 1.5 4.5V12C1.5 12.8284 2.17157 13.5 3 13.5H12C12.8284 13.5 13.5 12.8284 13.5 12V4.5C13.5 3.67157 12.8284 3 12 3H11.25V2.25C11.25 1.83579 10.9142 1.5 10.5 1.5C10.0858 1.5 9.75001 1.83579 9.75001 2.25V3H5.25001V2.25C5.25001 1.83579 4.91422 1.5 4.5 1.5ZM4.5 5.25C4.08579 5.25 3.75 5.58579 3.75 6C3.75 6.41421 4.08579 6.75 4.5 6.75H10.5C10.9142 6.75 11.25 6.41421 11.25 6C11.25 5.58579 10.9142 5.25 10.5 5.25H4.5Z"
                                      fill="#6B7280"/>
                            </svg>
                            <span className="tw-ml-1 tw-text-sm">{date}</span>
                        </div>
                        <div className="tw-flex tw-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="1" viewBox="0 0 24 1"
                                 fill="none">
                                <path
                                    d="M1 0C0.723858 0 0.5 0.223858 0.5 0.5C0.5 0.776142 0.723858 1 1 1V0ZM1 1H23.8401V0H1V1Z"
                                    fill="#6D758F"/>
                            </svg>
                            <svg className="tw-ml-3" xmlns="http://www.w3.org/2000/svg" width="16" height="15"
                                 viewBox="0 0 16 15"
                                 fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.34008 13.5C11.6538 13.5 14.3401 10.8137 14.3401 7.5C14.3401 4.18629 11.6538 1.5 8.34008 1.5C5.02638 1.5 2.34009 4.18629 2.34009 7.5C2.34009 10.8137 5.02638 13.5 8.34008 13.5ZM9.09008 4.5C9.09008 4.08579 8.7543 3.75 8.34008 3.75C7.92587 3.75 7.59008 4.08579 7.59008 4.5V7.5C7.59008 7.69891 7.6691 7.88968 7.80975 8.03033L9.93107 10.1517C10.224 10.4445 10.6988 10.4445 10.9917 10.1517C11.2846 9.85876 11.2846 9.38388 10.9917 9.09099L9.09008 7.18934V4.5Z"
                                      fill="#6B7280"/>
                            </svg>
                            <p className="tw-ml-1">{blog?.reading_time}</p>
                        </div>
                    </div>
                    <p className="tw-text-xl tw-font-semibold tw-text-gray-700 tw-mt-3"> {blog?.blog_title.length > 42 ? blog?.blog_title.slice(0, 30) : blog?.blog_title} .
                        . .</p>
                    <div className="tw-mt-4">
                        <p>{blog.blog_short_desc ? blog.blog_short_desc.slice(0, 50) : ""} . . . </p>
                    </div>
                    <div className="tw-flex tw-items-center tw-mt-3">
                        <p className="tw-text-lg tw-font-semibold tw-text-[#532D80] tw-mr-1">আরও পরুন</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"
                             fill="none">
                            <path d="M6.35568 1.35095L11.7499 6.49996L6.35568 11.649" stroke="#532D80"
                                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.7499 6.5L1.24997 6.5" stroke="#532D80" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Blog;
                
                
                
