import React from "react";
import "./CourseFeatures.css";
import CircleIconBlack from "../../../../../../images/course_details_icon/arrow.png"

const CourseFeatures = ({course_feature, count, feature_length}) => {
    
    return (
        <div>
            
            <div className='d-flex align-items-center course-duration course-feature-details'>
                    <img src={CircleIconBlack} alt="" height={20} width={20}/>
                    <p className='mb-0 ps-3' style={{ fontSize:'16px' }}>{course_feature.course_feature}</p>
                </div>

                <div className="divider" style={ feature_length-1 === count ? { display: 'none'} : {display: 'block'}}>
                </div>
        </div>

    );
};

export default CourseFeatures;
