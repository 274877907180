import React, { useState, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Login.css";
import registrationLogo from "../../../../images/reg_login_logo.png";
import regBackground from "../../../../images/bg.png";
import logoWatermark from "../../../../images/m_watermark.png";
import PageTitle from "../../shared/PageTitle/PageTitle";
import { englishToBengaliNumberFormat } from "../../../../utilities/utilities";
import ReactGA from "react-ga4";
import mixpanel from 'mixpanel-browser';

const Login = () => {
    const [btnLoading, setBtnLoading] = useState(false);
    const background_image = {
        backgroundImage: `url(${regBackground})`,
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    };
    const navigate = useNavigate();
    const location = useLocation();

    /* const user  = localStorage.getItem("auth_token");
    let from = location.state?.from?.pathname || "/";

    navigate(from, { replace: true }); */
    const memoizedData = useMemo(() => {
        return location.state?.from;
    }, []);

/*     useEffect(()=>{
        var number = 4821,
        a = [],
        sNumber = number.toString();

        for (var i = 0, len = sNumber.length; i < len; i += 1) {
            a.push(+sNumber.charAt(i));
        }
     },[]) */


    const handelLogIn = (event) => {
        event.preventDefault();

        ReactGA.event({
            category: "login",
            action: "login-action",
            label: "login-level", // optional
            value: parseInt(event.target.mobile_or_email.value), // optional, must be a number
        });

        mixpanel.track('Login', {
            number : parseInt(event.target.mobile_or_email.value)
        });

        setBtnLoading(true);
        const login_info = {
            mobile_or_email: event.target.mobile_or_email.value,
        };

        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}login`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(login_info),
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.message_type === "success") {
                navigate("/otp?page=login", {
                    state: {
                        mobile_or_email: event.target.mobile_or_email.value,
                        user_id: data.data.user_id,
                        message: data.message,
                        from: memoizedData,
                    },
                });
                setBtnLoading(false);
            } else {
                toast.error(data.message);
                setBtnLoading(false);
            }
        });
    };


    return (
        <section style={background_image}>
            <PageTitle title="Login"></PageTitle>
            <div className="h-100 d-flex align-items-center position-relative register-container">
                <div className="w-100">
                    <Link to='/' className="logo d-flex justify-content-center">
                        <img src={registrationLogo} alt="" />
                    </Link>

                    <div className="input-container position-relative">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <img
                                src={logoWatermark}
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <form onSubmit={handelLogIn}>
                            <p
                                className="text-center text-white"
                                style={{ fontSize: "32px", fontWeight: "500" }}
                            >
                                User Login
                            </p>
                            <div className="input-section">
                                <input
                                    type="text"
                                    id="mobile_or_email"
                                    name="mobile_or_email"
                                    placeholder="Email / Phone number"
                                    className="input-area mobile_or_email"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="d-flex justify-content-center position-relative">
                                {/* <input
                                    type="submit"
                                    value="Submit"
                                    className="btn_reg"
                                /> */}
                                <button  className="btn_reg" disabled={btnLoading ? true : false}>{btnLoading ? <><i className="fa fa-spinner fa-spin"></i> Submit</> : 'Submit'}</button>

                            </div>

                            <div className="d-flex justify-content-center position-relative mt-3">
                                <div
                                    className="text-decoration-none"
                                >
                                    <p className="text-white mb-0 fs-5">
                                        You don't have an account? <span> <Link to="/sign-up">Registration</Link></span>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                    <p className="position-absolute w-100 d-flex justify-content-center bottom-text">
                    কপিরাইট {englishToBengaliNumberFormat(new Date().getFullYear())} © মজারু এডুকেশন টেকনোলজিস লিমিটেড.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Login;
