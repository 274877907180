import React from 'react';
import './StudentCertificates.css';

const StudentCertificates = () => {
    return (
        <>
            <div className='p-4 d-flex justify-content-between align-items-center'>
                <p className='mb-0' style={{ fontSize:"20px", fontWeight:'500' }}>My Certificates</p>
            </div>

            <div className='total-assignment-divider'></div>
            <div className='mt-3 ps-4'>
                <h1>My Certificates</h1>
            </div>
        </>
    );
};

export default StudentCertificates;