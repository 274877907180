
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import ExcelJS from 'exceljs';
import regBackground from '../../../../../images/bg.png';
import { toast } from "react-toastify";

const RegistrationLeadsList = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [password, setPassword] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);

  const itemsPerPage = 20;

    const checkAuthorization = async(e) => {
      e.preventDefault();
      const data = {
        password: password,
        type: "organic",
        from_date: fromDate,
        to_date: toDate,
      }

      setLoading(true);

      try{
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/registration-lead`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'secretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
          },
          body: JSON.stringify(data)
        });

        const responseData = await response.json();
        console.log(responseData);
        setUser(responseData?.data?.data);
        setLoading(false);
        if(responseData?.code === 200){
          setIsAuthorized(true);
        }else {
          toast.error(`${responseData?.message}`)
        }

      } catch(error){
        console.log(error);
        setLoading(false);
      }

    };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = user.slice(startIndex, endIndex);

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('RegistrationLeads');
  
    // Define column headers
    worksheet.columns = [
      { header: 'Guardian Name', key: 'guardian_name', width: 15 },
      { header: 'Student Name', key: 'student_name', width: 15 },
      { header: 'Mobile', key: 'mobile', width: 15 },
      { header: 'Class Name', key: 'class_name', width: 15 },
      { header: 'Age', key: 'age', width: 10 },
      { header: 'Interested courses', key: 'interested_courses', width: 30 },
      { header: 'Client Requirement', key: 'client_requirement', width: 20 },
      { header: 'Date', key: 'created_at', width: 15 },
    ];
  
    // Replace the following data with your actual data source
    const data = user.map(item => ({
      guardian_name: item.guardian_name,
      student_name: item.student_name,
      mobile: item.mobile,
      class_name: item.class_name,
      age: item.age,
      interested_courses: item.interested_courses,
      client_requirement: item.client_requirment,
      created_at: item.created_at.slice(0, 10),
    }));
  
    // Add data to the worksheet
    worksheet.addRows(data);
  
    // Create a buffer from the workbook
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
  
      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'registration-leads.xlsx';
      a.click();
      URL.revokeObjectURL(url);
    });
  };
  

  const PageContent = () => {
    if (isAuthorized) {
      return (
        <>
              <h2>All Registration Leads</h2>
              <button className="btn btn-primary" onClick={exportToExcel}>Download Excel</button>
              <div className="BkashTableList">
                <table>
                  <thead>
                    <tr>
                      <th>Guardian Name</th>
                      <th>Student Name</th>
                      <th>Mobile</th>
                      <th>Class Name</th>
                      <th>Age</th>
                      <th>Interested courses</th>
                      <th>Comments</th>
                      <th>Created At</th>
                    </tr>
                  </thead>

                  <tbody>
                  {currentPageData.map((item) => (
                      <tr key={item.id}>
                      <td>{item.guardian_name}</td>
                      <td>{item.student_name}</td>
                      <td>{item.mobile}</td>
                      <td>{item.class_name}</td>
                      <td>{item.age}</td>
                       <td>{item.interested_courses}</td>
                      <td>{item.client_requirment}</td>
                      <td>{item.created_at.slice(0, 10)}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                current={currentPage}
                total={user.length}
                pageSize={itemsPerPage}
                onChange={setCurrentPage}
              />
        </>
      );
    } else {
      return (
          <div>
            <div className="container col-6">
              <h3 className="text-center mt-5">Authorization Required</h3>
              <p className="text-center mt-3">Please enter the password to access this page:</p>
              <form onSubmit={checkAuthorization}>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                  <input
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="fromDate" className="form-label">From Date</label>
                  <input
                      onChange={(e) => setFromDate(e.target.value)}
                      type="date"
                      className="form-control"
                      id="fromDate"
                      min="2023-09-15"
                      max={new Date().toISOString().split('T')[0]}
                      required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="toDate" className="form-label">To Date</label>
                  <input
                      onChange={(e) => setToDate(e.target.value)}
                      type="date"
                      className="form-control"
                      id="toDate"
                      min="2023-09-15"
                      max={new Date().toISOString().split('T')[0]}
                      required
                  />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
      );
    }
  };

  return (
      <>
        <section className="BkashLeadsList">
          <Container>
            <Row>
              <Col lg={12}>
                {PageContent()}
              </Col>
            </Row>
          </Container>
        </section>
      </>

  );
};

export default RegistrationLeadsList;
